import React, { useState } from "react";

const Tree = (props) => {
     const sorted = Object.keys(props.data)
    .sort()
    .reduce((accumulator, key) => {
        accumulator[key] = props.data[key];
        return accumulator;
    }, {});
    
    let keys = Object.keys(sorted)
    let rawData = props.rawData
    let root
    let countPlus
    let countMinus
    let rootname
    if(props.root !== undefined){
        root = props.root
    }
    else{
        root =true
    }

    if(props.countPlus !== undefined){
        countPlus =props.countPlus
    }
    else{
        countPlus =0
    }

    if(props.countMinus !== undefined){
        countMinus =props.countMinus
    }
    else{
        countMinus =0
    }

    if(props.rootname !== undefined){
        rootname = props.rootname
    }
    else{
        rootname =""
    }

    return (
        <React.Fragment>
            {keys.map((key,index) => (
                <TreeNode key={index} root = {root} countMinus={countMinus}  countPlus={countPlus} label ={key} num={index} node={props.data[key]} rawData={rawData}  rootname ={rootname} groupedData={props.groupedData} />
          ))}
        </React.Fragment>
    )
}
const Count = (props) => {
    let counter = 0;
    let key =''
    var groupInfo = document.getElementById("group")
    var thenInfo = document.getElementById("then")
    var groupInfoVal = groupInfo.options[groupInfo.selectedIndex].value
    var thenInfoVal = thenInfo.options[thenInfo.selectedIndex].value
    if(props.call === 1){
        key = groupInfoVal
        let data = props.rawData
        for (let item of data) {
            if (item[key] === props.data) {
                counter++;
            }
        }
    }
    else {
        key = thenInfoVal
        let data = props.rawData[props.rootname][props.data]
        let keys = Object.keys(data)
        for (let item of keys){
            let intData = data[item]
            for (let item1 of intData){
                if (item1[key] == props.data) {
                    counter++;
                }
            }
        }
    }
    return (
        <React.Fragment>
           ({counter})
        </React.Fragment>
    )
}


const TreeNode = ({root,countMinus,countPlus,label, num, node,rawData, rootname,groupedData}) => {
    const [childData, setChildData] = useState({
        expand : false,
        countMinus : countMinus,
        countPlus:countPlus,
    });
    let hasChild
    if(typeof node === 'object' && node !== null && !Array.isArray(node)){
        hasChild = true
    }
    else{
        hasChild =false
    }

    const handleRootClick = (e) => {
        setChildData((data) => ({
           ...data,
           expand: !childData.expand,
           countMinus : 0,
           countPlus : 15,

        }))
     }
     const handleClick = (e) => {
        setChildData((data) => ({
           ...data,
           expand: !childData.expand,
           countMinus : 15
        }))
     }
  
    return (
        <React.Fragment>
        {hasChild ? (
            root ? (
                <tr style={{backgroundColor:"#dae6ec",borderBottom:"1px solid grey",borderCollapse:"collapse"}}>
                    <td width="20%" onClick={handleRootClick}>
                        {childData.expand ?
                            <i className="fa fa-minus designParamPlus" aria-hidden="true"></i> :
                            <i className="fa fa-plus designParamPlus" aria-hidden="true"></i>
                        }
                        {label}
                        <Count data = {label} rawData={rawData} call={1}/>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            )
            :(
                <tr style={{backgroundColor:"#dae6ec",borderBottom:"1px solid grey",borderCollapse:"collapse"}}>
                    <td width="20%" onClick={handleClick}>
                        {childData.expand ?
                            <i style={{marginLeft:childData.countMinus}} className="fa fa-minus designParamPlus" aria-hidden="true"></i> :
                            <i style={{marginLeft:childData.countPlus}} className="fa fa-plus designParamPlus" aria-hidden="true"></i>
                        }
                        {label}
                        <Count data = {label} rawData={groupedData} rootname ={rootname} call={2}/>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr> 
            )

        ):
            <RowData countPlus={countPlus} countMinus={countMinus} label={label} data ={node}/>
        }
        {hasChild && childData.expand && (
            <Tree data={node} root={false} rootname={label} countMinus={childData.countMinus} countPlus={childData.countPlus} rawData = {rawData} groupedData={groupedData}/>
            )
        }
      </React.Fragment>
    )
 };

const RowData = ({countPlus, countMinus, label, data }) => {
    const [rowVisible, setRowVisiblity] = useState(false);
    let hasChild
    if(typeof data === 'object' && data !== null && Array.isArray(data)){
        hasChild = true
    }
    else{
        hasChild =false
    }
    return (
        <>
        {hasChild && (
            <tr style={{backgroundColor:"#dae6ec",borderBottom:"1px solid grey",borderCollapse:"collapse"}}>
                <td width="20%" onClick={(e) => setRowVisiblity((v) => !v)}>
                    {rowVisible ? <i style={{marginLeft:countMinus+15}} className="fa fa-minus designParamPlus" aria-hidden="true"></i> : <i style={{marginLeft:countMinus+15}} className="fa fa-plus designParamPlus" aria-hidden="true"></i>}{label}
                    &nbsp;({data.length})
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        )
        }
  
        {hasChild && rowVisible && (
            <RowResults data={data} />
        )}
        </>
    )

  }

  const RowResults = (props) => {
    return (
        <React.Fragment>
            {props.data.map((key,index) => (
                <tr key={index}>
                    <td align="left" width="20%">
                        {key["Layer Group"]}
                    </td>
                    <td align="left" width="10%">
                        {key.Layer}
                    </td>
                    <td align="left" width="10%">
                        {key["Chg."]}
                    </td>
                    <td  align="left" width="15%">
                        {key["Parameter Set"]}
                    </td>
                    <td align="left" width="10%">
                        {key["Design Parameter"]}
                    </td>
                    <td align="left" width="10%">
                        {key["Design Parameter Value"]}
                    </td>
                    <td align="left" width="10%">
                        {key["RMP Name"]}
                    </td>
                    <td align="left" width="15%">
                        {key["Material Function"]}
                    </td>
                </tr>
            ))}
        </React.Fragment>
    )
}

const DesignResults = (props) => {
   return ( 
        <React.Fragment>
           <Tree data={props.data} groupedData={props.data} rawData = {props.vendorData} />
        </React.Fragment>
   )
}

export default DesignResults
