import React from "react"
import { setNonDispalyValue, tableLinkColumn } from "./EventHandler"

const CustomCertifications = (props) => {
   const data = props.data
   var numRows = 0

   if (data != null && data.length > 0) {
      numRows = data.length
   }
   let arr = Object.keys(data[0])

   const getHeader = (headerData = []) => {
      var keys = getKeys(headerData)
      return keys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getKeys = (keysInformation = []) => {
      return Object.keys(keysInformation)
   }
   const getColumnKeys = (columKeyInfo) => {
      return Object.keys(columKeyInfo)
   }

   const RenderColumn = (props) => {
      return props.keys.map((key, index) => {
         if (props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const getRowsData = (tableData) => {
      var items = tableData
      var keys = getKeys(tableData[0])
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const RenderRow = (props) => {
      return props.keys.map((key, index) => {
         if (props.data[key] != null && typeof props.data[key] === "object") {
            let colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn data={props.data[key]} isbold={true} keys={colKeys} />
               </td>
            )
         } else if (
            key.toLowerCase() === "name" &&
            props.data["link"] &&
            props.data["link"] !== ""
         ) {
            let name = props.data[key]
            return tableLinkColumn(props.data["id"], props.data["link"], name, index)
         } else if (setNonDispalyValue(key)) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   return (
      <div className="tableFixHead">
         {numRows > 0 && (
            <table>
               <thead>
                  <tr>
                     {props.header && (
                        <th style={{ textAlign: "center" }} colSpan={arr.length - 2}>
                           <span>{props.header}</span>
                        </th>
                     )}
                  </tr>
                  <tr>
                     {props.subHeader && (
                        <React.Fragment>
                           <th colSpan={arr.length - 6}></th>
                           <th colSpan="4">
                              <span>{props.subHeader}</span>
                           </th>
                        </React.Fragment>
                     )}
                  </tr>
                  <tr>{getHeader(data[0])}</tr>
               </thead>
               <tbody>{getRowsData(data)}</tbody>
            </table>
         )}
      </div>
   )
}
export default CustomCertifications
