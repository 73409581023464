import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { attributeLinks, removeEmptyFromJSON } from "../commonComponents/EventHandler"
import CustomLink from "./CustomLink"

const Attributes = (props) => {
   const [attributes, setAttributes] = useState({})

   useEffect(() => {
      if (props.data) {
         setAttributes(removeEmptyFromJSON(props.data))
      }
      return () => {
         return undefined
      }
   }, [props.data])

   if (props.loading) {
      return (
         <h3 style={{ textAlign: "center", color: "#0640a4" }}>
            <i className="fa fa-spinner fa-spin" style={{ marginRight: "5px" }} />
            Loading........
         </h3>
      )
   }

   const attributeContent = () => {
      var keys = []
      var newKeys = []
      let preValue = 0
      let rowContainer = []
      let colContainer = []
      let contentContainer = []

      keys = Object.keys(attributes)

      if (
         attributes["clasificationRequired"] != null &&
         attributes["clasificationRequired"] !== undefined &&
         !attributes["clasificationRequired"]
      ) {
         keys = keys.filter((item) => item.toLocaleLowerCase() !== "classification")
      }

      keys = keys.filter((item) => item.toLocaleLowerCase() !== "clasificationrequired")

      for (let i = 0; i <= Math.ceil(keys.length - 1); ) {
         preValue = i
         i = i + 3
         newKeys = keys.slice(preValue, i)
         if (newKeys.length < 3) {
            newKeys.push("")
         }
         rowContainer.push(<Row key={i}>{colDetails(newKeys, colContainer)}</Row>)
         contentContainer.push(rowContainer)
         rowContainer = []
         colContainer = []
      }
      return contentContainer
   }

   function colDetails(newKeys = [], colContainer) {
      newKeys.forEach(function (key) {
         if (
            attributes[key] != null &&
            typeof attributes[key] == "object" &&
            attributeLinks(key)
         ) {
            colContainer.push(
               <Col key={key}>
                  <span id="pageheaders">{key}</span>
                  {key !== "" ? ": " : ""}
                  {attributes[key]["link"] &&
                  attributes[key]["link"] !== "" &&
                  attributes[key]["id"] &&
                  attributes[key]["id"].toLowerCase().trim() !== "no access" ? (
                     <CustomLink
                        id={attributes[key]["id"]}
                        link={attributes[key]["link"]}
                        name={attributes[key]["name"]}
                     />
                  ) : (
                     <span>{attributes[key]["name"]}</span>
                  )}
               </Col>
            )
         } else if (attributes[key] != null &&
            key.toLowerCase().trim() === "w&d exception support doc" &&
            typeof attributes[key] == "object"
            ) {
               colContainer.push(
                  <Col key={key}>
                     <span id="pageheaders">{key}</span>
                     {key !== "" ? ": " : ""}
                     {attributes[key].map((value,index) => {
                        if (
                           value["link"] &&
                           value["link"].trim() !== "" &&
                           value["id"] &&
                           value["id"].trim() !== "" &&
                           value["id"].toLowerCase().trim() !== "no access"
                        ) {
                           return (
                              <span key={index}>
                                 <CustomLink
                                    id={value["id"].trim()}
                                    link={value["link"].trim()}
                                    name={value["name"]}
                                 />
                                 {index+1 === attributes[key].length ? "" : "|"}
                              </span>
                           )
                        } else {
                           return (
                              <span key={index}>
                                 {value["name"]}
                                 {index+1 === attributes[key].length ? "" : "|"}
                              </span>
                           )}
                     })                        
                     }
                  </Col>
               )
         }else if (attributes[key] != null && typeof attributes[key] == "object") {
            colContainer.push(
               <Col key={key} style={{"whiteSpace": "pre-wrap"}}>
                  <div className="row" style={{ paddingLeft: "15px" }}>
                     <div>
                        <span id="pageheaders">{key}:</span>
                     </div>
                     <div>{arrayContent(attributes[key])}</div>
                  </div>
               </Col>
            )
         } else if (key.toLowerCase().trim() === "intended markets(r)") {
            colContainer.push(
               <Col key={key}>
                  <span id="pageheaders">{key}</span>
                  {key !== "" ? ": " : ""}
                  {attributes[key].split("|").join(" |")}
               </Col>
            )
         } else if (key.toLowerCase().trim() === "markets approved") {
            colContainer.push(
               <Col key={key}>
                  <span id="pageheaders">{key}</span>
                  {key !== "" ? ": " : ""}
                  {attributes[key].split(",").join(", ")}
               </Col>
            )
         } else {
            colContainer.push(
               <Col key={key} style={{"whiteSpace": "pre-wrap"}}>
                  <span id="pageheaders">{key}</span>
                  {key !== "" ? ": " : ""}
                  {attributes[key]}
               </Col>
            )
         }
      })
      return colContainer
   }

   const arrayContent = (arrayAttributes) => {
      return arrayAttributes.map((co, index) => {
         return <div key={index}>{co}</div>
      })
   }

   return <Container className="lineBreak">{attributes && attributeContent()}</Container>
}

export default Attributes
