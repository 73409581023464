import React, { useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { isLoggedIn, notAuthorized } from "../../../api/AuthService"
import { exceptAllTabs } from "../../../constant/Constants"
import ReportSubmit from "./ReportSubmit"
import textValidation from "./textValidation"

const selectedCheckboxes = new Set()
function PartAndSpec(props) {
   const [data, setData] = useState({
      reportName: props.reportName,
      expandProductBom: false,
      includeProductBom: false,
      includePackageBom: false,
      latestReleseInput: true,
      includeHyperlink: false,
      allTabs: false,
      attributes: false,
      alternate: false,
      approve: false,
      batteryRollUp: false,
      billOfMaterials: false,
      ebomWnD: false,
      fopFormulaIngredient: false,
      fopViewSubstitutes: false,
      gpsAssesments: false,
      marketClearance: false,
      marketOfSale: false,
      materialsAndComposition: false,
      mepSepCertifications: false,
      mepSepCompEqts: false,
      notes: false,
      partCertifications: false,
      partComponentEquivalents: false,
      performanceCharacteristics: false,
      plants: false,
      producingFormula: false,
      sapBomAsFed: false,
      specsAndDocs: false,

      weightsAndDimensions: false,
      pgDSMCharacteristicsLPD: false,
      pgDSMChemicalandPhysicaProperties: false,
      pgDSMDGClassification: false,
      pgDSMDesignParameter: false,
      pgDSMIPandExportControl: false,
      pgDSMSubstitutePartsIn: false,
      pgDSMSustainability: false,
      pgDSMIncludeMaterialCompositionfromComponentEquivalents: false,
      partName: "",
      changeAction: "",
      reportCustomName: "",
      template: "",
      fileData: "",
   })

   const [alertMessage, setMessage] = useState("")
   const [success, setSuccess] = useState(false)
   const [blinkSearch, setBlinkSearch] = useState("reportOptions")

   const handleValidation = (code, msg) => {
      if (code === 999) {
         setSuccess(false)
         setMessage(
            "Please enter Part Names/Change Actions OR upload an Input File to request a report."
         )
         setBlinkSearch("highlightFields")
      } else if (code === 997) {
         setMessage("Please select atleast one tab")
      } else if (code === 200) {
         setSuccess(true)
         selectedCheckboxes.clear()
         setMessage(
            "Your Report will be processed in the background and will complete shortly. Please refresh the screen after few minutes"
         )
      } else if (code === 422 || code === 429 || code === 417 || code === 501) {
         setSuccess(false)
         setMessage(msg)
      } else if (code === 401) {
         notAuthorized(props.history)
      } else if (code === 423) {
         setSuccess(false)
         setMessage(
            "The maximum number of In process report requests (5) has been reached. Please submit another report later."
         )
      } else {
         setSuccess(false)
         setMessage("Oops something went wrong. Please contact Support.")
      }
   }
   const enable_billOfMaterials = () => {
      document.getElementById("billOfMaterials_1").disabled = false
   }
   const disable_billOfMaterials = () => {
      document.getElementById("billOfMaterials_1").disabled = true
   }
   const enable_partComponentEquivalents = () => {
      document.getElementById("partComponentEquivalents_1").disabled = false
   }

   const disable_partComponentEquivalents = () => {
      document.getElementById("partComponentEquivalents_1").disabled = true
   }
   const handleInputChange = (e) => {
      setMessage("")
      const target = e.target
      const checked = target.checked
      const name = target.name
      setData((data) => ({
         ...data,
         [name]: checked,
      }))
      if (name === "includePackageBom" && data.includePackageBom === true) {
         setData((prevData) => ({
            ...prevData,
            includePackageBom: !checked,

            billOfMaterials: false,
         }))

         selectedCheckboxes.add("billOfMaterials")
         enable_billOfMaterials()
      }
      if (name === "includePackageBom" && data.includePackageBom === false) {
         setData((prevData) => ({
            ...prevData,
            includePackageBom: checked,

            billOfMaterials: true,
         }))
         selectedCheckboxes.add("billOfMaterials")
         disable_billOfMaterials()
      } else {
         setData((prevData) => ({
            ...prevData,
            [name]: checked,
         }))
      }
      if (
         name === "pgDSMIncludeMaterialCompositionfromComponentEquivalents" &&
         data.pgDSMIncludeMaterialCompositionfromComponentEquivalents === true
      ) {
         setData((prevData) => ({
            ...prevData,
            pgDSMIncludeMaterialCompositionfromComponentEquivalents: !checked,

            partComponentEquivalents: false,
         }))
         selectedCheckboxes.add("partComponentEquivalents")
         enable_partComponentEquivalents()
      }
      if (
         name === "pgDSMIncludeMaterialCompositionfromComponentEquivalents" &&
         data.pgDSMIncludeMaterialCompositionfromComponentEquivalents === false
      ) {
         setData((prevData) => ({
            ...prevData,
            pgDSMIncludeMaterialCompositionfromComponentEquivalents: checked,

            partComponentEquivalents: true,
         }))
         selectedCheckboxes.add("partComponentEquivalents")
         disable_partComponentEquivalents()
      } else {
         setData((prevData) => ({
            ...prevData,
            [name]: checked,
         }))
      }
      if (name === "includeProductBom" && data.includeProductBom === true) {
         setData((prevData) => ({
            ...prevData,

            includeProductBom: !checked,
            billOfMaterials: false,
         }))
         selectedCheckboxes.add("billOfMaterials")
         enable_billOfMaterials()
      }

      if (name === "includeProductBom" && data.includeProductBom === false) {
         setData((prevData) => ({
            ...prevData,

            includeProductBom: checked,
            billOfMaterials: true,
         }))
         selectedCheckboxes.add("billOfMaterials")
         disable_billOfMaterials()
      } else {
         setData((prevData) => ({
            ...prevData,
            [name]: checked,
         }))
      }
   }

   const handleCheckbox = (e) => {
      setMessage("")
      const target = e.target
      const checked = target.checked
      const name = target.name
      if (name !== "allTabs") {
         setData((data) => ({
            ...data,
            [name]: checked,
            allTabs: false,
         }))
         selectedCheckboxes.delete("allTabs")

         if (!exceptAllTabs.includes(name)) {
            if (checked) {
               if (!selectedCheckboxes.has(name)) {
                  selectedCheckboxes.add(name)
               }
            } else {
               selectedCheckboxes.delete(name)
            }
         }
      } else {
         for (const key of Object.keys(data)) {
            if (!exceptAllTabs.includes(key)) {
               setData((data) => ({
                  ...data,
                  [key]: checked,
               }))
               if (checked) {
                  if (!selectedCheckboxes.has(key)) {
                     selectedCheckboxes.add(key)
                  }
               } else {
                  selectedCheckboxes.clear()
               }
            }
         }
      }
   }

   const handleTemplateTabs = (boxdata) => {
      let allTabsChecked = true
      for (const key of Object.keys(boxdata)) {
         if (!exceptAllTabs.includes(key)) {
            if (boxdata[key]) {
               if (!selectedCheckboxes.has(key)) {
                  selectedCheckboxes.add(key)
               }
            } else {
               allTabsChecked = false
               selectedCheckboxes.delete(key)
            }

            if (allTabsChecked) {
               setData((data) => ({
                  ...data,
                  allTabs: true,
               }))
            } else {
               setData((data) => ({
                  ...data,
                  allTabs: false,
               }))
            }
         }
      }
   }

   const handleTextChange = (e) => {
      e.preventDefault()
      setBlinkSearch("reportOptions")
      setMessage("")
      const textVal = textValidation(e.target.value, e.target.name)
      if (textVal.props.children || e.target.value === "") {
         const { name, value } = e.target
         setData((data) => ({
            ...data,
            [name]: value,
         }))
      }
   }

   const clearMsg = () => {
      setMessage("")
   }

   const handleClearItems = () => {
      document.getElementById("view").value = "0"
      props.clearItem()
   }

   const fileValidation = (e) => {
      e.preventDefault()
      const filePath = e.target.value
      var allowedExtensions = /(\.txt)$/i

      if (!allowedExtensions.exec(filePath)) {
         alert("Invalid file type. Please upload a text file.")
         e.target.value = ""
         setData((data) => ({
            ...data,
            fileData: "",
         }))
         return false
      }
      const reader = new FileReader()
      reader.onload = async (e) => {
         const text = e.target.result
         const removeNewLine = text.replace(/[\n]+/g, ",")
         const formatedText = removeNewLine.replace(/[\t]/g, " ")
         const textOnly = formatedText.replace(/[^a-zA-Z0-9 ,-]/g, "")
         setData((data) => ({
            ...data,
            fileData: textOnly,
         }))
      }
      reader.readAsText(e.target.files[0])
   }

   const handleClearFile = (e) => {
      e.preventDefault()
      var file = document.getElementById("file")
      file.value = ""
      setData((data) => ({
         ...data,
         fileData: "",
      }))
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/")
      } else {
         setData((data) => ({
            ...data,
            partName: props.templateData.partName ? props.templateData.partName : "",
            changeAction: props.templateData.changeAction
               ? props.templateData.changeAction
               : "",
            reportCustomName: props.templateData.reportCustomName
               ? props.templateData.reportCustomName
               : "",
            expandProductBom: props.templateData.expandProductBom
               ? props.templateData.expandProductBom
               : false,
            includeProductBom: props.templateData.includeProductBom
               ? props.templateData.includeProductBom
               : false,
            includePackageBom: props.templateData.includePackageBom
               ? props.templateData.includePackageBom
               : false,
            includeHyperlink: props.templateData.includeHyperlink
               ? props.templateData.includeHyperlink
               : false,
            attributes: props.templateData.attributes
               ? props.templateData.attributes
               : false,
            alternate: props.templateData.alternate
               ? props.templateData.alternate
               : false,
            approve: props.templateData.approve ? props.templateData.approve : false,
            batteryRollUp: props.templateData.batteryRollUp
               ? props.templateData.batteryRollUp
               : false,
            billOfMaterials: props.templateData.billOfMaterials
               ? props.templateData.billOfMaterials
               : false,
            ebomWnD: props.templateData.ebomWnD ? props.templateData.ebomWnD : false,
            fopFormulaIngredient: props.templateData.fopFormulaIngredient
               ? props.templateData.fopFormulaIngredient
               : false,
            fopViewSubstitutes: props.templateData.fopViewSubstitutes
               ? props.templateData.fopViewSubstitutes
               : false,
            gpsAssesments: props.templateData.gpsAssesments
               ? props.templateData.gpsAssesments
               : false,
            marketClearance: props.templateData.marketClearance
               ? props.templateData.marketClearance
               : false,
            marketOfSale: props.templateData.marketOfSale
               ? props.templateData.marketOfSale
               : false,
            materialsAndComposition: props.templateData.materialsAndComposition
               ? props.templateData.materialsAndComposition
               : false,
            mepSepCertifications: props.templateData.mepSepCertifications
               ? props.templateData.mepSepCertifications
               : false,
            mepSepCompEqts: props.templateData.mepSepCompEqts
               ? props.templateData.mepSepCompEqts
               : false,
            notes: props.templateData.notes ? props.templateData.notes : false,
            partCertifications: props.templateData.partCertifications
               ? props.templateData.partCertifications
               : false,
            partComponentEquivalents: props.templateData.partComponentEquivalents
               ? props.templateData.partComponentEquivalents
               : false,
            performanceCharacteristics: props.templateData.performanceCharacteristics
               ? props.templateData.performanceCharacteristics
               : false,
            plants: props.templateData.plants ? props.templateData.plants : false,
            producingFormula: props.templateData.producingFormula
               ? props.templateData.producingFormula
               : false,
            sapBomAsFed: props.templateData.sapBomAsFed
               ? props.templateData.sapBomAsFed
               : false,
            specsAndDocs: props.templateData.specsAndDocs
               ? props.templateData.specsAndDocs
               : false,

            weightsAndDimensions: props.templateData.weightsAndDimensions
               ? props.templateData.weightsAndDimensions
               : false,
            pgDSMChemicalandPhysicaProperties: props.templateData
               .pgDSMChemicalandPhysicaProperties
               ? props.templateData.pgDSMChemicalandPhysicaProperties
               : false,
            pgDSMCharacteristicsLPD: props.templateData.pgDSMCharacteristicsLPD
               ? props.templateData.pgDSMCharacteristicsLPD
               : false,
            pgDSMDGClassification: props.templateData.pgDSMDGClassification
               ? props.templateData.pgDSMDGClassification
               : false,
            pgDSMDesignParameter: props.templateData.pgDSMDesignParameter
               ? props.templateData.pgDSMDesignParameter
               : false,
            pgDSMIPandExportControl: props.templateData.pgDSMIPandExportControl
               ? props.templateData.pgDSMIPandExportControl
               : false,
            pgDSMSubstitutePartsIn: props.templateData.pgDSMSubstitutePartsIn
               ? props.templateData.pgDSMSubstitutePartsIn
               : false,
            pgDSMSustainability: props.templateData.pgDSMSustainability
               ? props.templateData.pgDSMSustainability
               : false,
            pgDSMIncludeMaterialCompositionfromComponentEquivalents: props.templateData
               .pgDSMIncludeMaterialCompositionfromComponentEquivalents
               ? props.templateData
                    .pgDSMIncludeMaterialCompositionfromComponentEquivalents
               : false,
         }))
         handleTemplateTabs(props.templateData)
      }
   }, [props.templateData])

   return (
      <React.Fragment>
         <Form.Group as={Row} className="mb-3" id="expandProductBom">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Expand Product BOM
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="expandProductBom"
                  onChange={handleInputChange}
                  checked={data.expandProductBom}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="includeProductBom">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Include Product BOM Report For FOP on FPP/FPC
            </Form.Label>

            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="includeProductBom"
                  onChange={handleInputChange}
                  checked={data.includeProductBom}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="includePackageBom">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Include Packaging BOM Report
            </Form.Label>

            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="includePackageBom"
                  onChange={handleInputChange}
                  checked={data.includePackageBom}
               />
            </Col>
         </Form.Group>
         <Form.Group
            as={Row}
            className="mb-3"
            id="pgDSMIncludeMaterialCompositionfromComponentEquivalents"
         >
            <Form.Label column sm="5" md="5" className="reportLabels">
               Include Material Composition from Component Equivalents
            </Form.Label>

            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="pgDSMIncludeMaterialCompositionfromComponentEquivalents"
                  onChange={handleInputChange}
                  checked={data.pgDSMIncludeMaterialCompositionfromComponentEquivalents}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="latestReleseInput">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Latest Release Parts Only (Spec Reader only displays Released Parts)
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check type="checkbox" checked={true} disabled />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="includeHyperlink">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Include Hyperlink
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="includeHyperlink"
                  onChange={handleInputChange}
                  checked={data.includeHyperlink}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="pdfFormlabel">
            <Form.Label column sm="11" md="11" className="reportLabels">
               <b>
                  Select Tabs/Worksheets needed in the report.
                  <br></br>
                  PLEASE NOTE: Selecting ALL TABS will result in longer processing time.
               </b>
            </Form.Label>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="allTabs">
            <Form.Label column sm="5" md="5" className="reportLabels">
               ALL TABS
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="allTabs"
                  onChange={handleCheckbox}
                  checked={data.allTabs}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="attributes">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Attributes
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="attributes"
                  onChange={handleCheckbox}
                  checked={data.attributes}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="alternate">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Alternate
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="alternate"
                  onChange={handleCheckbox}
                  checked={data.alternate}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="approve">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Approve
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="approve"
                  onChange={handleCheckbox}
                  checked={data.approve}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="batteryRollUp">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Battery Roll-Up
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="batteryRollUp"
                  onChange={handleCheckbox}
                  checked={data.batteryRollUp}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="billOfMaterials">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Bill of Materials
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  id="billOfMaterials_1"
                  name="billOfMaterials"
                  onChange={handleCheckbox}
                  checked={data.billOfMaterials}
               />
            </Col>
         </Form.Group>

         <Form.Group as={Row} className="mb-3" id="pgDSMCharacteristicsLPD">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Characteristics - LPD
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="pgDSMCharacteristicsLPD"
                  onChange={handleCheckbox}
                  checked={data.pgDSMCharacteristicsLPD}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="pgDSMChemicalandPhysicaProperties">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Chemical and Physical Properties
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="pgDSMChemicalandPhysicaProperties"
                  onChange={handleCheckbox}
                  checked={data.pgDSMChemicalandPhysicaProperties}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="pgDSMDGClassification">
            <Form.Label column sm="5" md="5" className="reportLabels">
               DG Classification
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="pgDSMDGClassification"
                  onChange={handleCheckbox}
                  checked={data.pgDSMDGClassification}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="pgDSMDesignParameter">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Design Parameter
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="pgDSMDesignParameter"
                  onChange={handleCheckbox}
                  checked={data.pgDSMDesignParameter}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="ebomWnD">
            <Form.Label column sm="5" md="5" className="reportLabels">
               EBOM W and D
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="ebomWnD"
                  onChange={handleCheckbox}
                  checked={data.ebomWnD}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="fopFormulaIngredient">
            <Form.Label column sm="5" md="5" className="reportLabels">
               FOP-Formula Ingredient
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="fopFormulaIngredient"
                  onChange={handleCheckbox}
                  checked={data.fopFormulaIngredient}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="fopViewSubstitutes">
            <Form.Label column sm="5" md="5" className="reportLabels">
               FOP-View Substitutes
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="fopViewSubstitutes"
                  onChange={handleCheckbox}
                  checked={data.fopViewSubstitutes}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="gpsAssesments">
            <Form.Label column sm="5" md="5" className="reportLabels">
               GPS Assesments
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="gpsAssesments"
                  onChange={handleCheckbox}
                  checked={data.gpsAssesments}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="pgDSMIPandExportControl">
            <Form.Label column sm="5" md="5" className="reportLabels">
               IP and Export Control
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="pgDSMIPandExportControl"
                  onChange={handleCheckbox}
                  checked={data.pgDSMIPandExportControl}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="marketClearance">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Market Clearance
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="marketClearance"
                  onChange={handleCheckbox}
                  checked={data.marketClearance}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="marketOfSale">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Market Of Sale
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="marketOfSale"
                  onChange={handleCheckbox}
                  checked={data.marketOfSale}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="materialsAndComposition">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Materials And Composition
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="materialsAndComposition"
                  onChange={handleCheckbox}
                  checked={data.materialsAndComposition}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="mepSepCertifications">
            <Form.Label column sm="5" md="5" className="reportLabels">
               MEP-SEP Certifications
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="mepSepCertifications"
                  onChange={handleCheckbox}
                  checked={data.mepSepCertifications}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="mepSepCompEqts">
            <Form.Label column sm="5" md="5" className="reportLabels">
               MEP-SEP Component Equivalents
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="mepSepCompEqts"
                  onChange={handleCheckbox}
                  checked={data.mepSepCompEqts}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="notes">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Notes
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="notes"
                  onChange={handleCheckbox}
                  checked={data.notes}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="partCertifications">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Part Certifications
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="partCertifications"
                  onChange={handleCheckbox}
                  checked={data.partCertifications}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="partComponentEquivalents">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Part Component Equivalents
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="partComponentEquivalents"
                  id="partComponentEquivalents_1"
                  onChange={handleCheckbox}
                  checked={data.partComponentEquivalents}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="performanceCharacteristics">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Performance Characteristics
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="performanceCharacteristics"
                  onChange={handleCheckbox}
                  checked={data.performanceCharacteristics}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="plants">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Plants
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="plants"
                  onChange={handleCheckbox}
                  checked={data.plants}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="producingFormula">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Producing Formula
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="producingFormula"
                  onChange={handleCheckbox}
                  checked={data.producingFormula}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="sapBomAsFed">
            <Form.Label column sm="5" md="5" className="reportLabels">
               SAP BOM As Fed
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="sapBomAsFed"
                  onChange={handleCheckbox}
                  checked={data.sapBomAsFed}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="specsAndDocs">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Specs and Docs
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="specsAndDocs"
                  onChange={handleCheckbox}
                  checked={data.specsAndDocs}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="pgDSMSubstitutePartsIn">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Substitute Parts In
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="pgDSMSubstitutePartsIn"
                  onChange={handleCheckbox}
                  checked={data.pgDSMSubstitutePartsIn}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="pgDSMSustainability">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Sustainability
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="pgDSMSustainability"
                  onChange={handleCheckbox}
                  checked={data.pgDSMSustainability}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="weightsAndDimensions">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Weights and Dimensions
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="weightsAndDimensions"
                  onChange={handleCheckbox}
                  checked={data.weightsAndDimensions}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="partAndSpecFormlabel">
            <Form.Label column sm="11" md="11" className="reportLabels">
               Please enter Part name(s) and/or Change Action(s) below <b>OR</b> upload a
               .txt file containing Part Name(s) and/or Change Action(s).
            </Form.Label>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="partAndSpecFormPart">
            <Form.Label column sm="5" md="5" className="reportLabels" id="impInput">
               Part Name
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Control
                  as="textarea"
                  name="partName"
                  value={data.partName}
                  rows={3}
                  onChange={handleTextChange}
                  className={`${blinkSearch}`}
                  style={{ minHeight: "3rem" }}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="partAndSpecChangeAction">
            <Form.Label column sm="5" md="5" className="reportLabels" id="impInput">
               Change Action
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Control
                  as="textarea"
                  name="changeAction"
                  value={data.changeAction}
                  rows={3}
                  onChange={handleTextChange}
                  className={`${blinkSearch}`}
                  style={{ minHeight: "3rem" }}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="partAndSpecInput">
            <Form.Label column sm="5" md="5" className="reportLabels" id="impInput">
               Input File(ANSI .txt files only)
            </Form.Label>
            <Col sm="6" md="6">
               <input type="file" id="file" onChange={fileValidation} />
               <Button variant="primary" onClick={handleClearFile}>
                  Clear
               </Button>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="partAndSpecCustomnName">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Report Custom Name
            </Form.Label>
            <Col sm="6" md="6" mt="2">
               <Form.Control
                  as="input"
                  name="reportCustomName"
                  value={data.reportCustomName}
                  rows={3}
                  className="reportOptions"
                  onChange={handleTextChange}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="partAndSpecTemplate">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Save as Template (New Template will be created)
            </Form.Label>
            <Col sm="6" md="6" mt="2">
               <Form.Control
                  as="input"
                  name="template"
                  value={data.template}
                  rows={3}
                  className="reportOptions"
                  onChange={handleTextChange}
               />
            </Col>
         </Form.Group>
         <Form.Row>
            <Col md="11" sm="11" align="center">
               <p
                  style={{ marginTop: "4px", fontWeight: "bold" }}
                  className={success ? "successMsg" : "alertMsg"}
               >
                  {alertMessage}
               </p>
            </Col>
         </Form.Row>
         <br />
         <ReportSubmit
            data={data}
            handleClearItems={handleClearItems}
            handler={handleValidation}
            tabSize={selectedCheckboxes.size === 0 ? -1 : selectedCheckboxes.size}
            clearMsg={clearMsg}
         />
         <br />
      </React.Fragment>
   )
}
export default PartAndSpec
