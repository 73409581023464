import React, { useEffect, useState } from "react"
import { Col, Container } from "react-bootstrap"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { FETCH_REPORT_DATA } from "../../constant/Constants"
import HeaderComponent from "../commonComponents/HeaderComponent"
import ReportDetailData from "./ReportDetailData"

function ReportDetails(props) {
   const [data, setData] = useState({
      headerContent: "",
      reportDetail: "",
      ipSecuritySetting: "",
   })
   const [loading, setLoading] = useState(false)
   const searchData = props.match.params.objectID
   const [errorFlag, setErrorFlag] = useState(0)

   useEffect(() => {
      const fetchState = { cancel: false }
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchFppData(fetchState) {
            try {
               setLoading(true)
               var apiData
               const response = await fetchAPIData(`${FETCH_REPORT_DATA}/${searchData}`)
               apiData = response.data

               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContent,
                  reportDetail: apiData.reportDetail,
                  ipSecuritySetting: apiData.ipSecuritySetting,
               }))
               setLoading(false)
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                notAuthorized(props.history)
               }
               setErrorFlag(2)
               console.log("Error", ` API request failed: ${error}`)
               setLoading(false)
            }
         }
         fetchFppData(fetchState)
      }
      return () => {
         fetchState.cancel = true
      }
   }, [searchData, props.history])

   return (
        <React.Fragment>
            <HeaderComponent />
            <Container fluid>
                {loading ? (
                    <Col md="12" style={{ marginTop: "10%" }}>
                        <h2 style={{ textAlign: "center", color: "white" }}>
                            <i
                                className="fa fa-spinner fa-spin"
                                style={{ marginRight: "5px" }}
                            />
                            Loading........
                        </h2>
                    </Col>
                    ) : (
                    <ReportDetailData data ={data} error={errorFlag}/>
                )}
            </Container>
        </React.Fragment>
    )
}
export default ReportDetails
