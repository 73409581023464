import React, { useEffect, useState } from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { DSMReportGenerate, fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { DS_SUBMIT, ExpirationDate, FETCH_DS_FORM_DATA, otherState, policyVisible, ProductDataType, qualificationState } from "../../constant/Constants"
import HeaderComponent from "../commonComponents/HeaderComponent"
import textValidation from "../dynamicReports/ReportTemplates/textValidation"
import SearchDSData from "./SearchDSData"

function CreateDynamicSub(props) {
   const userID = localStorage.getItem("UserId")
   const [formData, setFormData] = useState(
      {
         productDataType: "",
         SubsFrequency: "Daily",
         Policy: "",
         State: "",
         Organization: "",
         Vendor: "",
         Plant: "",
         Brand: "",
         Segment: "",
         ManuFacturingStatus: "",
         expirationDate: "",
         subscriptionID: props.match.params.searchID ? props.match.params.searchID : "",
         subscriptionName: ""
      })
   const [modalShow, setModalShow] = useState(false)
   const [from, setFrom] = useState("")
   const [blinkSearch, setBlinkSearch] = useState("reportOptions")
   const [message, setMessage] = useState("")
   const [success, setSuccess] = useState(false)
   const [doneLoading, setDoneLoading] = useState(false)
   const [disable, setDisable] = useState(false)

   async function fetchData() {
      //Get data by API call
      try {
         const response = await fetchAPIData(`${FETCH_DS_FORM_DATA}/${props.match.params.searchID}`)
         if (response.data) {
            setFormData((data) => ({
               ...data,
               productDataType: response.data.subscriptionDetails["schemaTypeName"],
               Policy: response.data.subscriptionDetails.Policy,
               State: response.data.subscriptionDetails.State,
               Organization: response.data.subscriptionDetails.Organization,
               Vendor: response.data.subscriptionDetails.Vendor,
               Plant: response.data.subscriptionDetails.Plant,
               Brand: response.data.subscriptionDetails.Brand,
               Segment: response.data.subscriptionDetails.Segment,
               ManuFacturingStatus: response.data.subscriptionDetails["Manufacturing Status"],
               expirationDate: response.data.subscriptionDetails["Expiration Date"],
               subscriptionName: response.data.subscriptionDetails.name
            }))
         }
      } catch (error) {
         setFormData((data) => ({
            ...data,
            Vendor: "XYZ",
            Plant: "ABC",
         }))
      }
   }

   function modalsettings() {
      var x = document.getElementsByTagName("body")[0]
      var y = document.getElementById("headcontainer")
      x.style.padding = "0px 0px 0px 0px"
      x.style.margin = "0px 0px 0px 0px"
      y.style.padding = "0px 15px 0px 15px"
      y.style.margin = "0px 0px 0px 0px"
   }

   const handler = (code, msg) => {
      if (code === 401) {
         setSuccess(false)
         notAuthorized(props.history)
      }
      else if (code === 412 || code === 503) {
         setSuccess(false)
         setMessage(msg)
      } else if (code === 200) {
         setSuccess(true)
         setMessage(msg)
      }
      else {
         setSuccess(false)
         setMessage("Oops something went wrong. Please contact Support.")
      }
   }

   const prodDataType = () => {
      if (formData.productDataType !== "" && formData.productDataType !== "Empty") {
         setMessage("")
         setDisable(false)
         setBlinkSearch("reportOptions")
         return true
      }
      else {
         setMessage("Please select a Product Data Type")
         setBlinkSearch("highlightFields")
         setDisable(true)
         return false
      }
   }
   const handleSubmit = () => {
      if (prodDataType()) {
         setDoneLoading(true)
         setDisable(true)
         DSMReportGenerate(`${DS_SUBMIT}`, formData)
            .then((response) => {
               if (response.status === 200) {
                  handler(200, "")
                  alert("Your Subscription details will be updated shortly.")
                  props.history.push("/Subscription")
               }
               setDoneLoading(false)
            })
            .catch((error) => {
               if (error.response && error.response.status) {
                  const err = error.response.data ? error.response.data.message : error
                  handler(error.response.status, err)
               } else {
                  handler(1000, error)
               }
               setDoneLoading(false)
               setDisable(false)
            })
      } else {
         setMessage("Please select a Product Data Type")
         setBlinkSearch("highlightFields")
      }
   }

   const handleModalClick = (e) => {
      setDisable(false)
      setBlinkSearch("reportOptions")
      setMessage("")
      setFrom(e)
      setModalShow(true)
      setTimeout(modalsettings, 0)
   }
   const handleSetFrom = () => {
      setFrom("")
   }
   const handleChange = (e) => {
      const dropdown = ["productDataType", "Policy", "State", "expirationDate"]
      // Handle changes of every input feild
      const { name, value } = e.target
      if (value == "Clear") {
         setDisable(false)
         setBlinkSearch("reportOptions")
         setMessage("")
         setFormData((data) => ({
            ...data,
            [name]: "",
         }))
      }
      else if (dropdown.includes(e.target.name)) {
         const { name, value } = e.target
         setDisable(false)
         setBlinkSearch("reportOptions")
         setMessage("")
         setFormData((data) => ({
            ...data,
            [name]: value,
         }))
         if (name === "productDataType") {
            if (!policyVisible.includes(value)) {
               setFormData((data) => ({
                  ...data,
                  Policy: ""

               }))
            }
            if (value === "Qualification") {
               setFormData((data) => ({
                  ...data,
                  State: "Approved"
               }))
            }
            else {
               setFormData((data) => ({
                  ...data,
                  State: "Release"
               }))
            }
         }
      }
      else {
         setDisable(false)
         setBlinkSearch("reportOptions")
         setMessage("")
         const textVal = textValidation(e.target.value)
         if (textVal.props.children || e.target.value === "") {
            const { name, value } = e.target

            setFormData((data) => ({
               ...data,
               [name]: value,
            }))
         }
      }
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         if (props.match.params.searchID) {
            fetchData() //Fetch Data from API
         }
      }
   }, [props.history])

   return (
      <React.Fragment>
         <HeaderComponent />
         <br />
         <br />
         <Container fluid>
            <Row>
               <Col
                  md={{ span: 10, offset: 1 }}
                  className="contentBackground reportForm shadow"
               >
                  <Form>
                     <Col md="12" align="center" className="loginTextHeader">
                        {formData.subscriptionID !== "" ? "Edit Dynamic Subscription" : "Create Dynamic Subscription"}
                     </Col>
                     <br />
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5" md="5" className="reportLabels" id="impInput" >
                           Product Data Type
                        </Form.Label>
                        <Col sm="4" md="4">
                           <Form.Control
                              as="select"
                              id="productDataType"
                              name="productDataType"
                              className={`${blinkSearch}`}
                              value={formData.productDataType}
                              onChange={handleChange}
                           >
                              {ProductDataType &&
                                 ProductDataType.map((item, index) => (
                                    <option value={item.value} key={index}>{item.display}</option>
                                 ))}
                           </Form.Control>
                        </Col>
                        <Col sm="2" md="2" style={{ color: "#a00" }}>
                           {message === "Please select a Product Data Type" ? message : ""}
                        </Col>
                     </Form.Group>

                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5" md="5" className="reportLabels">
                           Subscription Frequency
                        </Form.Label>
                        <Col sm="4" md="4">
                           <Form.Control
                              id="SF"
                              type="text"
                              name="SubsFrequency"
                              className="reportOptions"
                              placeholder={formData.SubsFrequency}
                              readOnly
                           ></Form.Control>
                        </Col>
                     </Form.Group>
                     {policyVisible.includes(formData.productDataType) ?
                        <Form.Group as={Row} className="mb-3">
                           <Form.Label column sm="5" md="5" className="reportLabels" id="Policy">
                              Policy
                           </Form.Label>
                           <Col sm="4" md="4">
                              <Form.Control
                                 as="select"
                                 id="Policy"
                                 name="Policy"
                                 className="reportOptions"
                                 value={formData.Policy}
                                 onChange={handleChange}
                              >
                                 <option value="">--------Select Policy--------</option>
                                 <option value="Manufacturer Equivalent">
                                    Manufacturer Equivalent
                                 </option>
                                 <option value="Supplier Equivalent">
                                    Supplier Equivalent
                                 </option>
                              </Form.Control>
                           </Col>
                        </Form.Group> : []}
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5" md="5" className="reportLabels" id="impInput">
                           State
                        </Form.Label>
                        <Col sm="4" md="4">
                           <Form.Control
                              id="State"
                              as="select"
                              className="reportOptions"
                              value={formData.State}
                              name="State"
                              onChange={handleChange}
                           >
                              {formData.productDataType === "Qualification" ? qualificationState.map((item, index) => (
                                 <option value={item.value} key={index}>{item.display}</option>
                              )) : otherState.map((item, index) => (
                                 <option value={item.value} key={index}>{item.display}</option>
                              ))}
                           </Form.Control>
                        </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5" md="5" className="reportLabels" id="Organization">
                           Organization
                        </Form.Label>
                        <Col sm="4" md="4">
                           <Form.Control
                              id="Organization"
                              type="text"
                              className="reportOptions"
                              value={formData.Organization || ""}
                              name="Organization"
                              onChange={handleChange}
                              autoComplete="off"
                              readOnly
                              style={{ backgroundColor: 'white' }}
                           ></Form.Control>
                        </Col>
                        <Col sm="0.5" md="0.5">
                           <Form.Control
                              id="Organization1"
                              type="button"
                              className="reportOptions"
                              value="..."
                              onClick={() => handleModalClick("Organization")}
                           ></Form.Control>
                        </Col>
                        <Col sm="1" md="1">
                           <Form.Control
                              id="OrganizationClear"
                              type="button"
                              value="Clear"
                              name="Organization"
                              className="reportOptions"
                              onClick={handleChange}
                              style={{ minWidth: "fit-Content" }}
                           ></Form.Control>
                        </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5" md="5" className="reportLabels" id="Vendor">
                           Vendor
                        </Form.Label>
                        <Col sm="4" md="4">
                           <Form.Control
                              id="Vendor"
                              type="text"
                              className="reportOptions"
                              value={formData.Vendor || ""}
                              name="Vendor"
                              onChange={handleChange}
                              autoComplete="off"
                              readOnly
                              style={{ backgroundColor: 'white' }}
                           ></Form.Control>
                        </Col>
                        <Col sm="0.5" md="0.5">
                           <Form.Control
                              id="Vendor1"
                              type="button"
                              className="reportOptions"
                              value="..."
                              onClick={() => {
                                 handleModalClick("Vendor")
                              }}
                           ></Form.Control>
                        </Col>
                        <Col sm="1" md="1">
                           <Form.Control
                              id="VendorClear"
                              type="button"
                              className="reportOptions"
                              value="Clear"
                              name="Vendor"
                              onClick={handleChange}
                              style={{ minWidth: "fit-Content" }}
                           ></Form.Control>
                        </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5" md="5" className="reportLabels" id="Plant">
                           Plant
                        </Form.Label>
                        <Col sm="4" md="4">
                           <Form.Control
                              id="Plant"
                              type="text"
                              className="reportOptions"
                              value={formData.Plant || ""}
                              name="Plant"
                              onChange={handleChange}
                              autoComplete="off"
                              readOnly
                              style={{ backgroundColor: 'white' }}
                           ></Form.Control>
                        </Col>
                        <Col sm="0.5" md="0.5">
                           <Form.Control
                              id="Plant1"
                              type="button"
                              className="reportOptions"
                              value="..."
                              onClick={() => {
                                 handleModalClick("Plant")
                              }}
                           ></Form.Control>
                        </Col>
                        <Col sm="1" md="1">
                           <Form.Control
                              id="PlantClear"
                              type="button"
                              className="reportOptions"
                              value="Clear"
                              name="Plant"
                              onClick={handleChange}
                              style={{ minWidth: "fit-Content" }}
                           ></Form.Control>
                        </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5" md="5" className="reportLabels" id="Brand">
                           Brand
                        </Form.Label>
                        <Col sm="4" md="4">
                           <Form.Control
                              id="Brand"
                              type="text"
                              className="reportOptions"
                              value={formData.Brand || ""}
                              name="Brand"
                              onChange={handleChange}
                              autoComplete="off"
                              readOnly
                              style={{ backgroundColor: 'white' }}
                           ></Form.Control>
                        </Col>
                        <Col sm="0.5" md="0.5">
                           <Form.Control
                              id="Brand1"
                              type="button"
                              className="reportOptions"
                              value="..."
                              onClick={() => {
                                 handleModalClick("Brand")
                              }}
                           ></Form.Control>
                        </Col>
                        <Col sm="1" md="1">
                           <Form.Control
                              id="BrandClear"
                              type="button"
                              className="reportOptions"
                              value="Clear"
                              name="Brand"
                              onClick={handleChange}
                              style={{ minWidth: "fit-Content" }}
                           ></Form.Control>
                        </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5" md="5" className="reportLabels" id="Segment2">
                           Segment
                        </Form.Label>
                        <Col sm="4" md="4">
                           <Form.Control
                              id="Segment2"
                              type="text"
                              className="reportOptions"
                              value={formData.Segment || ""}
                              name="Segment"
                              onChange={handleChange}
                              autoComplete="off"
                              readOnly
                              style={{ backgroundColor: 'white' }}
                           ></Form.Control>
                        </Col>
                        <Col sm="0.5" md="0.5">
                           <Form.Control
                              id="Segment1"
                              type="button"
                              className="reportOptions"
                              value="..."
                              onClick={() => {
                                 handleModalClick("Segment")
                              }}
                           ></Form.Control>
                        </Col>
                        <Col sm="1" md="1">
                           <Form.Control
                              id="Segment"
                              type="button"
                              className="reportOptions"
                              value="Clear"
                              name="Segment"
                              onClick={handleChange}
                              style={{ minWidth: "fit-Content" }}
                           ></Form.Control>
                        </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5" md="5" className="reportLabels" id="ManuFacturingStatus">
                           Manufacturing Status
                        </Form.Label>
                        <Col sm="4" md="4">
                           <Form.Control
                              id="ManuFacturingStatus"
                              type="text"
                              className="reportOptions"
                              value={formData.ManuFacturingStatus || ""}
                              name="ManuFacturingStatus"
                              onChange={handleChange}
                              autoComplete="off"
                              readOnly
                              style={{ backgroundColor: 'white' }}
                           ></Form.Control>
                        </Col>
                        <Col sm="0.5" md="0.5">
                           <Form.Control
                              id="expire1"
                              type="button"
                              className="reportOptions"
                              value="..."
                              onClick={() => {
                                 handleModalClick("ManuFacturingStatus")
                              }}
                           ></Form.Control>
                        </Col>
                        <Col sm="1" md="1">
                           <Form.Control
                              id="expireClear"
                              type="button"
                              className="reportOptions"
                              value="Clear"
                              name="ManuFacturingStatus"
                              onClick={handleChange}
                              style={{ minWidth: "fit-Content" }}
                           ></Form.Control>
                        </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5" md="5" className="reportLabels" id="ExpiDate">
                           Expiration Date
                        </Form.Label>
                        <Col sm="4" md="4">
                           <Form.Control
                              as="select"
                              id="ExpiDate"
                              className="reportOptions"
                              value={formData.expirationDate}
                              name="expirationDate"
                              onChange={handleChange}
                           >
                              {ExpirationDate &&
                                 ExpirationDate.map((item, index) => (
                                    <option value={item.value} key={index}>{item.display}</option>
                                 ))}
                           </Form.Control>
                        </Col>
                     </Form.Group>
                     <br />
                     <Form.Row>
                        <Col md="12" sm="12" align="center">
                           <p style={{ marginTop: "4px", fontWeight: "bold" }} className={(success ? 'successMsg' : 'alertMsg')}>
                              {message !== "Please select a Product Data Type" ? message : ""}
                           </p>
                        </Col>
                     </Form.Row>
                     <br />
                     <Form.Group as={Row}>
                        <Col sm="4" md="4"></Col>
                        <Col sm="2" md="2" align="right">
                           <Button variant="primary" onClick={handleSubmit} disabled={disable} >
                              {doneLoading ? (
                                 <i className="fa fa-spinner fa-spin" style={{ marginRight: "5px" }} />
                              ) : (
                                 <span>Done</span>
                              )}
                           </Button>
                        </Col>
                        <Col sm="2" md="2">
                           <Button variant="primary" onClick={() => props.history.push("/Subscription")}>Cancel</Button>
                        </Col>
                        <Col sm="4" md="4"></Col>
                     </Form.Group>
                  </Form>
               </Col>
            </Row>
            <br />
         </Container>
         <div>
            <SearchDSData
               show={modalShow}
               onHide={(e) => {
                  setModalShow(false)
                  if (from && e !== undefined) {
                     setFormData((data) => ({
                        ...data,
                        [from]: e,
                     }))
                  }
               }}
               from={from}
               handleSetFrom={handleSetFrom}
            />
         </div>
      </React.Fragment>
   )
}
export default CreateDynamicSub
