import React from "react"
import { Link } from "react-router-dom"
import { addBreadCrumbLinks } from "./EventHandler"

const CustomLink = (props) => {
   const parentName = props.parentData ? props.parentData.Name : ""
   const parentRev = props.parentData ? props.parentData.Revision : ""
   const parentState = props.parentData ? props.parentData.State : ""

   const addStorageItem = (parentName, parentRev, parentState) => {
      localStorage.setItem("parentName", parentName)
      localStorage.setItem("parentRev", parentRev)
      localStorage.setItem("parentState", parentState)
   }

   return (
      <span>
         <Link
            onClick={() => {
               addBreadCrumbLinks(props.name, `/${props.link}/${props.id}`)
               // eslint-disable-next-line no-lone-blocks
               {
                  addStorageItem(parentName, parentRev, parentState)
               }
            }}
            to={(location) => ({ ...location, pathname: `/${props.link}/${props.id}` })}
         >
            <span>{props.name}</span>
         </Link>
      </span>
   )
}

export default CustomLink
