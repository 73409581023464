import React, { useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService";
import { FETCH_DYNAMIC_SUB } from "../../constant/Constants";
import HeaderComponent from "../commonComponents/HeaderComponent";
import SubscriptionDetailData from "./SubscriptionDetailData";

function SubscriptionDetails(props) {
    const [loading, setLoading] = useState(false)
    const [errorFlag, setErrorFlag] = useState(0)
    const subscriptionId = props.match.params.objectID
    const [subscriptionDetails, setSubscriptionDetails]=useState({})
   
    useEffect(() => {
        const fetchState = { cancel: false }
        if (!isLoggedIn()) {
           props.history.push("/login")
        } else {
            async function fetchFppData(fetchState) {
                try {
                    setLoading(true)
                    var apiData
                    const response = await fetchAPIData(`${FETCH_DYNAMIC_SUB}/${subscriptionId}`)
                    apiData = response.data
                    setSubscriptionDetails(apiData.subscriptionDetails)
                    setErrorFlag(0)
                    setLoading(false)
                    
                } catch (error) {
                    if (error.response && error.response.data.status === 401) {
                        notAuthorized(props.history)
                    }
                    setErrorFlag(2)
                    console.log("Error", ` API request failed: ${error}`)
                    setLoading(false)
                }
            }
            fetchFppData(fetchState)
        }
        return () => {
           fetchState.cancel = true
        }
     }, [subscriptionId, props.history])
    
    return (
        <React.Fragment>
        <HeaderComponent />
            <Container fluid>
                {loading ? (
                    <Col md="12" style={{ marginTop: "10%" }}>
                        <h2 style={{ textAlign: "center", color: "white" }}>
                            <i
                                className="fa fa-spinner fa-spin"
                                style={{ marginRight: "5px" }}
                            />
                            Loading........
                        </h2>
                    </Col>
                    ) : (
                    <SubscriptionDetailData data ={subscriptionDetails} id ={subscriptionId} error={errorFlag}/>
                )}
            </Container>
        </React.Fragment>
    );
  }
  export default SubscriptionDetails