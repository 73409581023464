import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import {
   CommonPagekeys,
   DefaultKeys,
   FETCH_COMMONPAGE_DATA
} from "../../constant/Constants"
import Attributes from "../commonComponents/Attributes"
import CommonAttributes from "../commonComponents/CommonAttributes"
import CustomTable from "../commonComponents/CustomTable"
import DownloadLinkTable from "../commonComponents/DownloadLinkTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import GenDocDownloadComponent from "../commonComponents/GenDocComponent"
import GenDocsTable from "../commonComponents/GenDocsFilesDownload"
import HeaderComponent from "../commonComponents/HeaderComponent"
import ReferenceDocsDownload from "../commonComponents/ReferenceDocsDownload"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../commonComponents/TopContentForSpecs"

//import PdfDownloadComponent from '../commonComponents/PdfDownloadComponent'

function ComPageAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributeList: {},
      marketsApproved: [],
      relatedSpecifications: [],
      partAssociation: [],
      referenceDocuments: [],
      relatedACS: [],
      relatedIAPS: [],
      plants: [],
      relatedATS: [],
      //multipleOwnershipAccess: [],
      relatedParts: [],
      ipclasses: [],
      securityClasses: [],
      organizationsData: {},
      Files: [],
      subAttributes: [],
      whereUsed: null,
   })

   const searchData = props.match.params.objectID
   const searchType = props.match.path ? props.match.path.split("/")[2]:""

   const [loading, setLoading] = useState(false)
   const [sideNav, setSideNav] = useState("")
   const [inProp, setInProp] = useState(false)
   const [pdfDataSet, setPdfDataSet] = useState({})

   const [selectedItems, setSelectedItems] = useState(DefaultKeys)

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)

      var values = pdfData(data, obj, IsFilterView)

      setPdfDataSet(values)
   }

   useEffect(() => {
      const fetchState = { cancel: false }
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchCommonGenDoc() {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")

               let sessionKey = searchType + searchData
               var apiData
               if (
                  sessionStorage.getItem(sessionKey) &&
                  sessionStorage.getItem(sessionKey) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(sessionKey))
               } else {
                  // Load async data from an  Api endpoint.
                  const response = await fetchAPIData(
                     `${FETCH_COMMONPAGE_DATA}/${searchType}/${searchData}`
                  )
                  apiData = response.data
                  if (apiData.commonDocument.headerContent)
                     sessionStorage.setItem(sessionKey, JSON.stringify(response.data))
               }

               if (fetchState.cancel) {
                  return
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.commonDocument.headerContent,
                  attributeList: apiData.commonDocument.Attributes,
                  organizationsData: apiData.commonDocument.organizations,

                  relatedSpecifications: apiData.commonDocument.relatedSpecifications,
                  referenceDocuments: apiData.commonDocument.referenceDocuments,
                  plants: apiData.commonDocument.plants,
                  relatedATS: apiData.commonDocument.relatedATS,
                  //multipleOwnershipAccess: apiData.commonDocument.multipleOwnershipAccess,
                  relatedACS: apiData.commonDocument.relatedACS,
                  ipclasses: apiData.commonDocument.ipclasses,
                  relatedParts: apiData.commonDocument.relatedParts,
                  relatedIAPS: apiData.commonDocument.relatedIAPS,
                  securityClasses: apiData.commonDocument.securityClasses,
                  Files: apiData.commonDocument.Files,
                  marketsApproved: apiData.commonDocument.marketsApproved,
                  partAssociation: apiData.commonDocument.partAssociation,
                  subAttributes: apiData.commonDocument.subAttributes,
                  whereUsed: apiData.commonDocument.whereUsed,
               }))

               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  notAuthorized(props.history)
                  return
               }
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchCommonGenDoc()
      }
      return () => {
         fetchState.cancel = true
      }
   }, [searchData, searchType, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} />
         <CSSTransition timeout={500} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={CommonPagekeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" className="contentBackground">
                     <br />

                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row>
                           <Col md="9" sm="9">
                              <TopContent headerContent={data.headerContent} />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              <GenDocDownloadComponent objectID={searchData} />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {data.attributeList && selectedItems["attributeList"] && (
                              <div>
                                 <Card autoFocus>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(this, "0", "Zero")}
                                       aria-controls="Zero"
                                    >
                                       {CommonPagekeys.attributeList}{" "}
                                       <i
                                          id="0"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="Zero"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <Attributes
                                       loading={loading}
                                       data={data.attributeList}
                                    />
                                    {!loading && data.subAttributes && data.subAttributes.length > 0 && (
                                          <React.Fragment>
                                             <div className="accordionDiv">
                                                <CustomTable data={data.subAttributes} />
                                             </div>
                                          </React.Fragment>
                                       )}
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              data.marketsApproved &&
                              data.marketsApproved.length > 0 &&
                              selectedItems["marketsApproved"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "1", "One")}
                                          aria-controls="One"
                                       >
                                          {CommonPagekeys.marketsApproved}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="One"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.marketsApproved} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.partAssociation &&
                              data.partAssociation.length > 0 &&
                              selectedItems["partAssociation"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "13", "Thirteen")}
                                          aria-controls="Thirteen"
                                       >
                                          {CommonPagekeys.partAssociation}{" "}
                                          <i
                                             id="13"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Thirteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DownloadLinkTable
                                          data={data.partAssociation}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedSpecifications &&
                              data.relatedSpecifications.length > 0 &&
                              selectedItems["relatedSpecifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "3", "Three")}
                                          aria-controls="Three"
                                       >
                                          {CommonPagekeys.relatedSpecifications}{" "}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Three"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DownloadLinkTable
                                          data={data.relatedSpecifications}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocuments &&
                              data.referenceDocuments.length > 0 &&
                              selectedItems["referenceDocuments"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "4", "Four")}
                                          aria-controls="Four"
                                       >
                                          {CommonPagekeys.referenceDocuments}{" "}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Four"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ReferenceDocsDownload
                                          data={data.referenceDocuments}
                                          attributes={data.attributeList}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedACS &&
                              data.relatedACS.length > 0 &&
                              selectedItems["relatedACS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "5", "Five")}
                                          aria-controls="Five"
                                       >
                                          {CommonPagekeys.relatedACS}{" "}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Five"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedACS} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedIAPS &&
                              data.relatedIAPS.length > 0 &&
                              selectedItems["relatedIAPS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "6", "Six")}
                                          aria-controls="Six"
                                       >
                                          {CommonPagekeys.relatedIAPS}{" "}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Six"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedIAPS} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.plants &&
                              data.plants.length > 0 &&
                              selectedItems["plants"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "7", "Seven")}
                                          aria-controls="Seven"
                                       >
                                          {CommonPagekeys.plants}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Seven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.plants} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedATS &&
                              data.relatedATS.length > 0 &&
                              selectedItems["relatedATS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "8", "Eight")}
                                          aria-controls="Eight"
                                       >
                                          {CommonPagekeys.relatedATS}{" "}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Eight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedATS} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedParts &&
                              data.relatedParts.length > 0 &&
                              selectedItems["relatedParts"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "9", "Nine")}
                                          aria-controls="Nine"
                                       >
                                          {CommonPagekeys.relatedParts}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Nine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DownloadLinkTable data={data.relatedParts} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ipclasses &&
                              data.ipclasses.length > 0 &&
                              selectedItems["ipclasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "10", "Ten")}
                                          aria-controls="Ten"
                                       >
                                          {CommonPagekeys.ipclasses}{" "}
                                          <i
                                             id="10"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Ten"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.ipclasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClasses &&
                              data.securityClasses.length > 0 &&
                              selectedItems["securityClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "11", "Eleven")}
                                          aria-controls="Eleven"
                                       >
                                          {CommonPagekeys.securityClasses}{" "}
                                          <i
                                             id="11"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Eleven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.securityClasses} />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              data.organizationsData &&
                              selectedItems["organizationsData"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "12",
                                             "Tweleve"
                                          )}
                                          aria-controls="Tweleve"
                                       >
                                          {CommonPagekeys.organizationsData}{" "}
                                          <i
                                             id="12"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Tweleve"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes data={data.organizationsData} />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              data.Files &&
                              data.Files.length > 0 &&
                              selectedItems["Files"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "16",
                                             "Sixteen"
                                          )}
                                          aria-controls="Sixteen"
                                       >
                                          {CommonPagekeys.Files}{" "}
                                          <i
                                             id="16"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Sixteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <GenDocsTable
                                          data={data.Files}
                                          attributes={data.attributeList}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {CommonPagekeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default ComPageAllInfo
