import { saveAs } from "file-saver"
import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { AllInfoPdfGenerate, GenDocFileDownload } from "../../api/AuthService"
import { GENDOCFILE_DOWNLOAD, PDF_DOWNLOAD_LINK } from "../../constant/Constants"
import { expandCollapseAll } from "../commonComponents/EventHandler"

const IRMGenDocComponent = (props) => {
   const [genDocLoading, setGedDocLoading] = useState(false)
   const [loading, setLoading] = useState(false)
   const pdfhide = props.pdfHide ? props.pdfHide : false
   var object_ID = window.location.pathname.split("/")[3]
   var objectID = object_ID ? object_ID : null

   let headerData = JSON.parse(JSON.stringify(props.data))
   let attributeData = JSON.parse(JSON.stringify(props.attributeList))
   let gendocData = JSON.parse(JSON.stringify(props.gendoc))
   let pdfDataSet = JSON.parse(JSON.stringify(props.pdfdataSet))

   const downloadPdfFile = () => {
      let fileName = "download"

      fileName = pdfDataSet["Name"] ? pdfDataSet["Name"] : fileName

      let type = window.location.pathname.split("/")[2]

      setLoading(true)

      AllInfoPdfGenerate(`${PDF_DOWNLOAD_LINK}`, type, pdfDataSet, objectID)
         .then((response) => {
            const blob = new Blob([response.data], {
               type: "application/pdf",
            })

            let fileContents = URL.createObjectURL(blob)
            saveAs(fileContents, fileName)
            setLoading(false)
         })
         .catch((error) => {
            if (error.response && error.response.status === 422) {
               //setShow(true)
               alert(
                  "You have exceeded the limit for the day and access to any additional Part/Specification is blocked! Please contact Support."
               )
            } else {
               alert("Oops, something went wrong. Please contact Support.")
               console.log(" error", error)
            }
            setLoading(false)
         })
   }

   //Download GenDoc File
   const downloadGenDocFile = (data, attribute, gendoc) => {
      setGedDocLoading(true)

      let specialRegex = /[`!@#$%^&*()+\-={};':"\\|<>?~]/
      const filePath = gendoc ? gendoc.split("||")[0] : ""
      const fileName = gendoc ? gendoc.split("||")[1] : ""
      attribute &&
         Object.entries(attribute).forEach(
            ([key, val]) =>
               val &&
               typeof val !== "object" &&
               specialRegex.test(val) &&
               delete attribute[key]
         )
      data &&
         Object.entries(data).forEach(
            ([key, val]) =>
               val &&
               typeof val !== "object" &&
               specialRegex.test(val) &&
               delete data[key]
         )

      GenDocFileDownload(
         `${GENDOCFILE_DOWNLOAD}`,
         btoa(unescape(encodeURIComponent(fileName))),
         btoa(filePath),
         "PDF",
         {},
         data
      )
         .then((response) => {
            const fileContents = new Blob([response.data])
            saveAs(fileContents, fileName)
            setGedDocLoading(false)
         })
         .catch((error) => {
            if (error.response && error.response.status === 404) {
               alert("No file is available to download.")
            } else if (error.response && error.response.status === 403) {
               alert(
                  "There is a file download error -  please contact Support."
               )
            } else if (error.response && error.response.status === 422) {
               // setShow(true)
               alert(
                  "You have exceeded the limit for the day and access to any additional Part/Specification is blocked! Please contact Support."
               )
            } else {
               alert("Oops, something went wrong. Please contact Support.")
               console.log("FCS Error Response:-", error)
            }
            setGedDocLoading(false)
         })
   }

   return (
      <div>
         {props.data && (
            <div>
               <Button
                  style={{ textAlign: "center" }}
                  className="expandCollapseButton"
                  onClick={() =>
                     downloadGenDocFile(headerData, attributeData, gendocData)
                  }
               >
                  {genDocLoading ? (
                     <i
                        className="fa fa-spinner fa-spin"
                        style={{ marginRight: "5px" }}
                     />
                  ) : (
                     <span>GenDoc &#8595;</span>
                  )}
               </Button>{" "}
               &nbsp;
               {!pdfhide && (
                  <Button
                     style={{ textAlign: "center" }}
                     className="expandCollapseButton"
                     onClick={downloadPdfFile}
                  >
                     {loading ? (
                        <i
                           className="fa fa-spinner fa-spin"
                           style={{ marginRight: "5px" }}
                        />
                     ) : (
                        <span>PDF &#8595;</span>
                     )}
                  </Button>
               )}
               &nbsp;
               <Button
                  id="btnExpandAll"
                  className="expandCollapseButton shadow"
                  onClick={expandCollapseAll}
               >
                  Collapse All
               </Button>
            </div>
         )}
      </div>
   )
}
export default IRMGenDocComponent
