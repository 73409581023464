import React from "react"
import CustomLink from "./CustomLink"

const CustomTrasportUnit = (props) => {
   const trasportUnit = props.transportUnitHead.transportUnit
   const trasportUnit1 = props.transportUnitHead.transportUnit1

   const trasportUnit_contents = trasportUnit.map((trasport, index) => (
      <tr key={index}>
         <td>{trasport.palletType}</td>
         <td>{trasport.gtin}</td>
         <td>{trasport.depth}</td>
         <td>{trasport.width}</td>
         <td>{trasport.height}</td>
         <td>{trasport.dimensionUnitOfMeasure}</td>
         <td>{trasport.tupVolume}</td>
         <td>{trasport.tupUom}</td>
         <td>{trasport.grossWeightWithPallet}</td>
         <td>{trasport.grossWeightWithoutPallet}</td>
         <td>{trasport.unitOfMeasure1}</td>
         {/* <td>{trasport.unitOfMeasure2}</td> */}
      </tr>
   ))

   let transportUnit1_Contents = []
   if (trasportUnit1 != null) {
      transportUnit1_Contents = trasportUnit1.map((trasport, index) => (
         <tr key={index}>
            <td>{trasport.palletType}</td>
            <td>{trasport.numberOfCustomerUnitsPerLayer}</td>
            <td>{trasport.numberOfLayersPerTransportUnit}</td>
            <td>{trasport.numberOfCustomerUnitsPerTransportUnit}</td>
            <td>{trasport.cubeEfficiency}</td>
            <td>{trasport.warehousePalletStackHeightMaximum}</td>
            <td>{trasport.warehouseCaseStackHeightMaximum}</td>
            <td>{trasport.truckPalletStackHeightMaximum}</td>
            <td>
               {trasport.link &&
               trasport.link !== "" &&
               trasport.id &&
               trasport.id !== "" ? (
                  <CustomLink
                     id={trasport.id}
                     link={trasport.link.toUpperCase().trim()}
                     name={trasport.stackingPatternGCASCode}
                  />
               ) : (
                  <span>{trasport.stackingPatternGCASCode}</span>
               )}
            </td>
         </tr>
      ))
   }
   return (
      <div className="tableFixHead">
         <table>
            <thead>
               <tr>
                  <th rowSpan="2">
                     <span>Pallet Type </span>
                  </th>
                  <th rowSpan="2">
                     <span>GTIN</span>
                  </th>
                  <th colSpan="3">
                     <span>Dimensions - OD with Bulge</span>
                  </th>
                  <th rowSpan="2">
                     <span>Unit of Measure</span>
                  </th>
                  <th rowSpan="2">
                     <span>Transport Unit Volume</span>
                  </th>
                  <th rowSpan="2">
                     <span>Unit of Measure</span>
                  </th>
                  <th rowSpan="2">
                     <span>Gross Weight With Pallet</span>
                  </th>
                  <th rowSpan="2">
                     <span>Gross Weight Without Pallet</span>
                  </th>
                  <th rowSpan="2">
                     <span>Unit of Measure</span>
                  </th>
               </tr>
               <tr>
                  <th>Depth</th>
                  <th>Width</th>
                  <th>Height</th>
               </tr>
            </thead>

            <tbody>{trasportUnit_contents}</tbody>
         </table>
         {trasportUnit1 != null && (
            <table>
               <thead>
                  <tr>
                     <th>
                        <span>Pallet Type </span>{" "}
                     </th>
                     <th>
                        <span>Number of Customer Units per Layer</span>{" "}
                     </th>
                     <th>
                        <span>Number of Layers per Transport Unit</span>{" "}
                     </th>
                     <th>
                        <span>Number of Customer Units per Transport Unit</span>{" "}
                     </th>
                     <th>
                        <span>Cube Efficiency</span>{" "}
                     </th>
                     <th>
                        <span>Warehouse:Pallet Stack Height Maximum</span>{" "}
                     </th>
                     <th>
                        <span>Warehouse: Case Stack Height Maximum</span>{" "}
                     </th>
                     <th>
                        <span>Truck: Pallet Stack Height Maximum</span>{" "}
                     </th>
                     <th>
                        <span>Stacking Pattern Name</span>{" "}
                     </th>
                  </tr>
               </thead>
               <tbody>{transportUnit1_Contents}</tbody>
            </table>
         )}
      </div>
   )
}
export default CustomTrasportUnit
