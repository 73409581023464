import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../../api/AuthService"
import { FETCH_REPORT_TEMPLATE } from "../../../constant/Constants"
import HeaderComponent from "../../commonComponents/HeaderComponent"
import "../../main.css"
import TemplateResults from "./TemplateResults"

function TemplateInfo(props) {
   const [TemplateData, setTemplateData] = useState([])

   const [loading, setLoading] = useState(true)
   const [errorFlag, setErrorFlag] = useState()
   const [search, setSearch] = useState({
      type: "",
      name: "",
   })

   async function fetchData() {
      try {
         setLoading(true)
         const TemplateResults = await fetchAPIData(`${FETCH_REPORT_TEMPLATE}`)
         if (TemplateResults.data) {
            if (TemplateResults.data.templateColumns != null) {
               setTemplateData(TemplateResults.data.templateColumns)
               setErrorFlag(0)
               setLoading(false)
            } else {
               setErrorFlag(1)
               setLoading(false)
            }
         }
      } catch (error) {
         if (error.response && error.response.status && error.response.status === 401) {
            notAuthorized(props.history)
         } else {
            setErrorFlag(2)
            setLoading(false)
         }
      }
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         fetchData()
      }
   }, [props.history])

   return (
      <React.Fragment>
         <HeaderComponent />
         <Container fluid>
            <Row>
               <Col md="12" style={{ marginTop: "40px", marginBottom: "20px" }}>
                  <TemplateResults
                     results={TemplateData}
                     error={errorFlag}
                     refreshHandler={fetchData}
                     load={loading}
                     type={search.type}
                  />
               </Col>
            </Row>
         </Container>
      </React.Fragment>
   )
}
export default TemplateInfo

