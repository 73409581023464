import React from "react"
import CustomLink from "./CustomLink"
import { setNonDispalyValue, tableLinkColumn } from "./EventHandler"

const CustomSapBom = (props) => {
   const data = props.data
   var numRows = 0

   if (data != null && data.length > 0) {
      numRows = data.length
   }

   const getHeader = (headerData = []) => {
      var keys = getKeys(headerData)
      return keys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getKeys = (keysInformation = []) => {
      return Object.keys(keysInformation)
   }
   const getColumnKeys = (columKeyInfo) => {
      return Object.keys(columKeyInfo)
   }

   const RenderColumn = (props) => {
      return props.keys.map((key, index) => {
         if (props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const getRowsData = (tableData) => {
      var items = tableData
      var keys = getKeys(tableData[0])
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }
   const RenderLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         let id = props.data["id"]
         let link = props.data["link"]

         if (
            props.data[key] &&
            key.toLowerCase().trim() === "name" &&
            id &&
            id.toLowerCase().trim() !== "no access" &&
            link &&
            link != null
         ) {
            let name = props.data[key]
            return (
               <div key={index} style={{ minWidth: "9rem" }}>
                  {link ? (
                     <div>
                        <span>
                           <CustomLink id={id} link={link} name={name} />
                        </span>
                     </div>
                  ) : (
                     <span>{name}</span>
                  )}
               </div>
            )
         } else if (setNonDispalyValue(key)) {
            return null
         } else if (props.data[key] != null)
            return (
               <div key={index}>
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const RenderRow = (props) => {
      return props.keys.map((key, index) => {
         if (
            props.data[key] != null &&
            typeof props.data[key] == "object" &&
            key.toLowerCase().trim() === "transport unit"
         ) {
            let colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderLinkColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         }
         if (props.data[key] != null && typeof props.data[key] === "object") {
            let colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn data={props.data[key]} isbold={true} keys={colKeys} />
               </td>
            )
         } else if (
            key.toLowerCase() === "name" &&
            props.data["link"] &&
            props.data["link"] !== ""
         ) {
            let name = props.data[key]
            return tableLinkColumn(props.data["id"], props.data["link"], name, index)
         } else if (setNonDispalyValue(key)) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   return (
      <div className="tableFixHead">
         {numRows > 0 && (
            <table id="tab-separtor">
               <thead>
                  <tr>
                     {props.header && (
                        <th style={{ textAlign: "center" }} colSpan="7">
                           <span>{props.header}</span>
                        </th>
                     )}
                  </tr>
                  <tr>{getHeader(data[0])}</tr>
               </thead>
               <tbody>{getRowsData(data)}</tbody>
            </table>
         )}
      </div>
   )
}
export default CustomSapBom
