import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { FETCH_REPORT_DATA } from "../../constant/Constants"
import HeaderComponent from "../commonComponents/HeaderComponent"
import "../main.css"
import ReportResults from "./ReportResults"

function ReportInfo(props) {
   const [reportData, setReportData] = useState([])
   const [loading, setLoading] = useState(true)
   const [errorFlag, setErrorFlag] = useState()

   async function fetchData() {
      try {
         setLoading(true)
         setErrorFlag()
         const ReportResults = await fetchAPIData(`${FETCH_REPORT_DATA}`)

         if (ReportResults.data && ReportResults.data.reportColumns != null) {
            setReportData(ReportResults.data.reportColumns)
            setErrorFlag(0)
            setLoading(false)
         } else {
            setErrorFlag(1)
            setLoading(false)
         }
      } catch (error) {
         if (error.response && error.response.status && error.response.status === 401) {
            notAuthorized(props.history)
         } else {
            setErrorFlag(2)
            setLoading(false)
         }
      }
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/")
      } else {
         fetchData()
      }
   }, [props.history])

   return (
      <React.Fragment>
         <HeaderComponent />
         <Container fluid>
            <Row>
               <Col md="12" sm="12" style={{ marginTop: "40px", marginBottom: "20px" }}>
                  <Container fluid className="searchDataContainer">
                     <Row>
                        <Col
                           md="12"
                           sm="12"
                           align="center"
                           className="loginTextHeader"
                           style={{ marginTop: "10px" }}
                        >
                           DSM Reports
                        </Col>
                     </Row>
                     <Row>
                        <Col md="12" sm="12">
                           <ReportResults
                              results={reportData}
                              error={errorFlag}
                              refreshHandler={fetchData}
                              load={loading}
                           />
                        </Col>
                     </Row>
                  </Container>
               </Col>
            </Row>
         </Container>
      </React.Fragment>
   )
}
export default ReportInfo
