import React from "react"
import CustomLink from "./CustomLink"

const DerviedTable = (props) => {
   const data = props.data
   var numRows = 0

   if (data != null && data.length > 0) {
      numRows = data.length
   }

   const getHeader = () => {
      var keys = getKeys()
      return keys.map((key, index) => {
         if (
            key.toLowerCase() === "derivedid" ||
            key.toLowerCase() === "derivedlink" ||
            key.toLowerCase() === "sourceid" ||
            key.toLowerCase() === "sourcelink"
         ) {
            return null
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getRowsData = () => {
      var items = data
      var keys = getKeys()
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const getKeys = function () {
      return Object.keys(data[0])
   }

   const getColumnKeys = function (columKeyInfo) {
      return Object.keys(columKeyInfo)
   }

   const RenderColumn = function (props) {
      return props.keys.map((key, index) => {
         return (
            <div key={index}>
               <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
               <span>{props.data[key]}</span>
            </div>
         )
      })
   }

   const RenderRow = (props) => {
      return props.keys.map((key, index) => {
         if (props.data[key] != null && typeof props.data[key] === "object") {
            var colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn data={props.data[key]} keys={colKeys} />
               </td>
            )
         } else if (
            key.toLowerCase().trim() === "derived name" &&
            props.data["derivedid"] &&
            props.data["derivedid"].toLowerCase().trim() !== "no access"
         ) {
            var name = props.data[key]
            return (
               <td style={{ minWidth: "9rem" }} key={index}>
                  {props.data["derivedlink"] != null &&
                  props.data["derivedlink"] !== "" ? (
                     <CustomLink
                        id={props.data["derivedid"]}
                        link={props.data["derivedlink"]}
                        name={name}
                     />
                  ) : (
                     <span>{name}</span>
                  )}
               </td>
            )
         } else if (
            key.toLowerCase().trim() === "source name" &&
            props.data["sourceid"] &&
            props.data["sourceid"].toLowerCase().trim() !== "no access"
         ) {
            var source_Name = props.data[key]
            return (
               <td style={{ minWidth: "9rem" }} key={index}>
                  {props.data["sourcelink"] != null && props.data["sourcelink"] !== "" ? (
                     <CustomLink
                        id={props.data["sourceid"]}
                        link={props.data["sourcelink"]}
                        name={source_Name}
                     />
                  ) : (
                     <span>{source_Name}</span>
                  )}
               </td>
            )
         } else if (
            key.toLowerCase() === "derivedid" ||
            key.toLowerCase() === "derivedlink" ||
            key.toLowerCase() === "sourceid" ||
            key.toLowerCase() === "sourcelink"
         ) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   return (
      <div className="tableFixHead">
         {numRows > 0 && (
            <table>
               <thead>
                  <tr>
                     <th style={{backgroundColor:"white"}}>
                        <span>{props.derived}</span>
                     </th>
                  </tr>
                  <tr>{getHeader()}</tr>
               </thead>
               <tbody>{getRowsData()}</tbody>
            </table>
         )}
      </div>
   )
}
export default DerviedTable
