import React from "react"

function textValidationCertRoll(value, name) {
   const validateText = (value, name) => {
      let exp
      if (name && (name === "template" || name === "reportCustomName")) {
         exp = "^[A-Za-z0-9_-]+$"
      } else {
         exp = "^[A-Za-z0-9 /\\n\\t.,_-]+$"
      }
      if (value.match(exp)) {
         return true
      } else {
         return false
      }
   }
   return <React.Fragment>{validateText(value, name)}</React.Fragment>
}
export default textValidationCertRoll
