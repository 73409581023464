import React, { useState, useEffect } from "react"
import { InputGroup, DropdownButton, Dropdown } from "react-bootstrap"
import { SearchOptionValues } from "../../constant/Constants"

export default function SearchOptions(props) {
   // const [value, setValue] = useState(serarchType[0] ? serarchType[0].display : "GCAS/Name");
   const [value, setValue] = useState("GCAS/Name")

   useEffect(() => {
      var serarchType = []
      if (props.value) {
         serarchType = SearchOptionValues.filter((optons) => {
            return optons.value.toLowerCase().match(props.value)
         })
      }
      if (serarchType[0] && serarchType[0].display) {
         setValue(serarchType[0].display)
      }
      return () => {
         return undefined
      }
   }, [props.value])

   const drpSearchAs = (evt) => {
      var items = JSON.parse(evt)
      setValue(items.display)
      props.handleChooseOption(items.value)
   }

   return (
      <div>
         <DropdownButton
            style={{ backgroundcolor: "#09f", height: "35px" }}
            onSelect={drpSearchAs}
            as={InputGroup.Prepend}
            // variant="outline-secondary"
            title={value}
            id="input-group-dropdown-1"
         >
            {SearchOptionValues &&
               SearchOptionValues.map((item, index) => (
                  // <OverlayTrigger key={index} overlay={(<Tooltip id={item.value}>{item.toolTip}</Tooltip>)} placement="right">
                  <Dropdown.Item
                     key={index}
                     eventKey={JSON.stringify(item)}
                     title={item.toolTip}
                  >
                     {item.display}
                  </Dropdown.Item>
                  // </OverlayTrigger>
               ))}
         </DropdownButton>
      </div>
   )
}
