import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Collapse, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { APPKeys, DefaultKeys, FETCH_APP } from "../../constant/Constants"
import Attributes from "../commonComponents/Attributes"
import ComIngredientStatement from "../commonComponents/ComIngredientStatement"
import CommonAttributes from "../commonComponents/CommonAttributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import ComSML from "../commonComponents/ComSML"
import CustomMasterSpecification from "../commonComponents/CustomMasterSpecification"
import CustomMCTable from "../commonComponents/CustomMatrialAndComposition"
import { CustomMultiBomTable } from "../commonComponents/CustomMultiBomTable"
import { CustomMultiSubstituteTable } from "../commonComponents/CustomMultiSubstituteTable"
import CustomPCTable from "../commonComponents/CustomPCTable"
import CustomSapBom from "../commonComponents/CustomSapBom"
import CustomTable from "../commonComponents/CustomTable"
import CustomDerivedTable from "../commonComponents/DerivedTable"
import DownloadLinkTable from "../commonComponents/DownloadLinkTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import GenDocsFileDownTable from "../commonComponents/GenDocsFilesDownload"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import PlainCertifications from "../commonComponents/PlainCertifications"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../commonComponents/TopContentLatest"
import DesignParam from "./DesignParam"

function AppAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributeObject: {},
      weightCharcteristic: [],
      dangerousGoodsClassification: [],
      globalHarmonizedStandard: [],
      ingredientStatement: [],
      storageTransportationLabelingAssessmentData: {},
      notes: [],
      productPartStabilityDocument: [],
      masterPartStabilityDocument: [],
      sapBOMFed: [],
      chemicalAndPhysicalPropertiesGHCDGCClassification: {},
      chemicalAndPhysicalPropertiesWarehouseClassification: {},
      marketClearance: [],
      materialsAndCompositions: [],
      billOfMaterials: {},
      substitutions: [],
      relatedSpecification: [],
      masterSpecification: [],
      referenceDocuments: [],
      performanceCharacteristics: [],
      characteristics: [],
      plants: [],
      alternates: [],
      relatedATS: [],
      materialProduced: [],
      lifecycleApprovalPowerview: [],
      ownershipObject: {},
      ipClasses: [],
      securityClass: [],
      organizationObject: {},
      files: [],
      gpsAssessments: [],
      smartLabel: [],
      certifications: [],
      certificationValidation: [],
      derivedTo: [],
      derivedFrom: [],
      stabilityResult: [],
      masterAttribute: {},
      designParameter: [],
      whereUsed: null,
   })

   const searchData = props.match.params.objectID
   const [loading, setLoading] = useState(true)
   const [sideNav, setSideNav] = useState()
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)
      var values = pdfData(data, obj, IsFilterView)
      setPdfDataSet(values)
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchAppData() {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")
               var apiData

               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  // Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_APP}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContentObject)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContentObject
                     ? apiData.headerContentObject
                     : data.headerContent,
                  attributeObject: apiData.attributeObject,
                  organizationObject: apiData.organizationObject,
                  billOfMaterials: apiData.billOfMaterials,
                  storageTransportationLabelingAssessmentData:
                     apiData.storageTransportationLabelingAssessmentData,
                  derivedTo: apiData.derivedTo,
                  derivedFrom: apiData.derivedFrom,
                  lifecycleApprovalPowerview: apiData.lifecycleApprovalPowerview,
                  ownershipObject: apiData.ownershipObject,
                  plants: apiData.plants,
                  weightCharcteristic: apiData.weightCharcteristic,
                  relatedSpecification: apiData.relatedSpecification,
                  notes: apiData.notes,
                  dangerousGoodsClassification: apiData.dangerousGoodsClassification,
                  globalHarmonizedStandard: apiData.globalHarmonizedStandard,
                  stabilityResult: apiData.stabilityResult,
                  relatedATS: apiData.relatedATS,
                  performanceCharacteristics: apiData.performanceCharacteristics,
                  characteristics: apiData.characteristics,
                  masterSpecification: apiData.masterSpecification,
                  masterAttribute: apiData.masterAttribute,
                  materialProduced: apiData.materialProduced,
                  referenceDocuments: apiData.referenceDocuments,
                  sapBOMFed: apiData.sapBOMFed,
                  substitutions: apiData.substitutions,
                  materialsAndCompositions: apiData.materialsAndCompositions,
                  marketClearance: apiData.marketClearance,
                  productPartStabilityDocument: apiData.productPartStabilityDocument,
                  masterPartStabilityDocument: apiData.masterPartStabilityDocument,
                  ipClasses: apiData.ipClasses,
                  alternates: apiData.alternates,
                  files: apiData.files,
                  securityClass: apiData.securityClass,
                  certifications: apiData.certifications,
                  ingredientStatement: apiData.ingredientStatement,
                  gpsAssessments: apiData.gpsAssessment,
                  smartLabel: apiData.smartLabel,
                  certificationValidation: apiData.certificationValidation,
                  chemicalAndPhysicalPropertiesGHCDGCClassification:
                     apiData.chemicalAndPhysicalPropertiesGHCDGCClassification,
                  chemicalAndPhysicalPropertiesWarehouseClassification:
                     apiData.chemicalAndPhysicalPropertiesWarehouseClassification,
                  designParameter: apiData.designParameter,
                  whereUsed: apiData.whereUsed,
               }))

               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  console.log("Error", ` Not authorie: ${error}`)
                  notAuthorized(props.history)
                  return
               }
               console.log("Error", ` API request failed: ${error}`)
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchAppData()
      }
      return () => {
         return undefined
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={APPKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" id="print" className="contentBackground">
                     <br />
                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="9" sm="9">
                              <TopContent headerContent={data.headerContent} />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              <PdfDownloadComponent
                                 genDocHide={!data.headerContent.gendocView}
                                 pdfDataSet={pdfDataSet}
                              />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {selectedItems["attributeObject"] &&
                              data.attributeObject != null && (
                                 <div>
                                    <Card autoFocus>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "0",
                                             "collapseZero"
                                          )}
                                          aria-controls="collapseZero"
                                       >
                                          {APPKeys.attributeObject}{" "}
                                          <i
                                             id="0"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseZero"
                                          name="collapseItem"
                                       >
                                          <Attributes
                                             loading={loading}
                                             data={data.attributeObject}
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.weightCharcteristic &&
                              data.weightCharcteristic.length > 0 &&
                              selectedItems["weightCharcteristic"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "1",
                                             "collapseOne"
                                          )}
                                          aria-controls="collapseOne"
                                       >
                                          {APPKeys.weightCharcteristic}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseOne"
                                          name="collapseItem"
                                       >
                                          <CustomTable data={data.weightCharcteristic} />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.dangerousGoodsClassification &&
                              data.dangerousGoodsClassification.length > 0 &&
                              selectedItems["dangerousGoodsClassification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "2",
                                             "collapseTwo"
                                          )}
                                          aria-controls="collapseTwo"
                                       >
                                          {APPKeys.dangerousGoodsClassification}{" "}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseTwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable
                                          data={data.dangerousGoodsClassification}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.globalHarmonizedStandard &&
                              data.globalHarmonizedStandard.length > 0 &&
                              selectedItems["globalHarmonizedStandard"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "3",
                                             "collapseThree"
                                          )}
                                          aria-controls="collapseThree"
                                       >
                                          {APPKeys.globalHarmonizedStandard}{" "}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseThree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable
                                          data={data.globalHarmonizedStandard}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ingredientStatement &&
                              data.ingredientStatement.length > 0 &&
                              selectedItems["ingredientStatement"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "4", "four")}
                                          aria-controls="four"
                                       >
                                          {APPKeys.ingredientStatement}{" "}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="four"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComIngredientStatement
                                          data={data.ingredientStatement}
                                          parentData={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.storageTransportationLabelingAssessmentData &&
                              selectedItems[
                              "storageTransportationLabelingAssessmentData"
                              ] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "5", "five")}
                                          aria-controls="five"
                                       >
                                          {
                                             APPKeys.storageTransportationLabelingAssessmentData
                                          }
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="five"
                                          name="collapseItem"
                                       >
                                          <CommonAttributes
                                             data={
                                                data.storageTransportationLabelingAssessmentData
                                             }
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.notes &&
                              data.notes.length > 0 &&
                              selectedItems["notes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "6", "six")}
                                          aria-controls="six"
                                       >
                                          {APPKeys.notes}{" "}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="six"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.notes} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.productPartStabilityDocument &&
                              data.productPartStabilityDocument.length > 0 &&
                              selectedItems["productPartStabilityDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "7", "seven")}
                                          aria-controls="seven"
                                       >
                                          {APPKeys.productPartStabilityDocument}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="seven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable
                                          data={data.productPartStabilityDocument}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.masterPartStabilityDocument &&
                              data.masterPartStabilityDocument.length > 0 &&
                              selectedItems["masterPartStabilityDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "8", "eight")}
                                          aria-controls="eight"
                                       >
                                          {APPKeys.masterPartStabilityDocument}{" "}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="eight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable
                                          data={data.masterPartStabilityDocument}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.sapBOMFed &&
                              data.sapBOMFed.length > 0 &&
                              selectedItems["sapBOMFed"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "9", "nine")}
                                          aria-controls="nine"
                                       >
                                          {APPKeys.sapBOMFed}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="nine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSapBom data={data.sapBOMFed}></CustomSapBom>
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.chemicalAndPhysicalPropertiesGHCDGCClassification &&
                              selectedItems[
                              "chemicalAndPhysicalPropertiesGHCDGCClassification"
                              ] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "10", "ten")}
                                          aria-controls="ten"
                                       >
                                          {
                                             APPKeys.chemicalAndPhysicalPropertiesGHCDGCClassification
                                          }
                                          <i
                                             id="10"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="ten"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes
                                          data={
                                             data.chemicalAndPhysicalPropertiesGHCDGCClassification
                                          }
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.chemicalAndPhysicalPropertiesWarehouseClassification &&
                              selectedItems[
                              "chemicalAndPhysicalPropertiesWarehouseClassification"
                              ] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "11", "elevan")}
                                          aria-controls="elevan"
                                       >
                                          {
                                             APPKeys.chemicalAndPhysicalPropertiesWarehouseClassification
                                          }
                                          <i
                                             id="11"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="elevan"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes
                                          data={
                                             data.chemicalAndPhysicalPropertiesWarehouseClassification
                                          }
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.marketClearance &&
                              selectedItems["marketClearance"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "12", "twelve")}
                                          aria-controls="twelve"
                                       >
                                          {APPKeys.marketClearance}{" "}
                                          <i
                                             id="12"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twelve"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.marketClearance} />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              data.materialsAndCompositions &&
                              data.materialsAndCompositions.length > 0 &&
                              selectedItems["materialsAndCompositions"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "13",
                                             "thirteen"
                                          )}
                                          aria-controls="thirteen"
                                       >
                                          {APPKeys.materialsAndCompositions}{" "}
                                          <i
                                             id="13"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMCTable
                                          data={data.materialsAndCompositions}
                                       />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              data.billOfMaterials &&
                              selectedItems["billOfMaterials"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "14",
                                             "fourteen"
                                          )}
                                          aria-controls="fourteen"
                                       >
                                          {APPKeys.billOfMaterials}{" "}
                                          <i
                                             id="14"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMultiBomTable data={data.billOfMaterials} />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              data.substitutions &&
                              data.substitutions.length > 0 &&
                              selectedItems["substitutions"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "15",
                                             "fifteen"
                                          )}
                                          aria-controls="fifteen"
                                       >
                                          {APPKeys.substitutions}{" "}
                                          <i
                                             id="15"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fifteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMultiSubstituteTable
                                          data={data.substitutions}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedSpecification &&
                              data.relatedSpecification.length > 0 &&
                              selectedItems["relatedSpecification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "16",
                                             "sixteen"
                                          )}
                                          aria-controls="sixteen"
                                       >
                                          {APPKeys.relatedSpecification}{" "}
                                          <i
                                             id="16"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="sixteen"
                                          name="collapseItem"
                                       >
                                          <DownloadLinkTable
                                             data={data.relatedSpecification}
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.masterSpecification &&
                              data.masterSpecification.length > 0 &&
                              selectedItems["masterSpecification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "17",
                                             "seventeen"
                                          )}
                                          aria-controls="seventeen"
                                       >
                                          {APPKeys.masterSpecification}{" "}
                                          <i
                                             id="17"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="seventeen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMasterSpecification
                                          data={data.masterSpecification}
                                       />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              data.referenceDocuments &&
                              data.referenceDocuments.length > 0 &&
                              selectedItems["referenceDocuments"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "18",
                                             "eighteen"
                                          )}
                                          aria-controls="eighteen"
                                       >
                                          {APPKeys.referenceDocuments}{" "}
                                          <i
                                             id="18"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="eighteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComRefDocDownload
                                          data={data.referenceDocuments}
                                          attributes={data.attributeObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              data.performanceCharacteristics &&
                              data.performanceCharacteristics.length > 0 &&
                              selectedItems["performanceCharacteristics"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "19",
                                             "nineteen"
                                          )}
                                          aria-controls="nineteen"
                                       >
                                          {APPKeys.performanceCharacteristics}{" "}
                                          <i
                                             id="19"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="nineteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPCTable
                                          data={data.performanceCharacteristics}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.characteristics &&
                              data.characteristics.length > 0 &&
                              selectedItems["characteristics"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "20",
                                             "twenty"
                                          )}
                                          aria-controls="twenty"
                                       >
                                          {APPKeys.characteristics}{" "}
                                          <i
                                             id="20"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twenty"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPCTable
                                          data={data.characteristics}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.plants &&
                              data.plants.length > 0 &&
                              selectedItems["plants"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "21", "twentyOne")}
                                          aria-controls="twentyOne"
                                       >
                                          {APPKeys.plants}{" "}
                                          <i
                                             id="21"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="twentyOne"
                                          name="collapseItem"
                                       >
                                          <CustomTable data={data.plants} />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.alternates &&
                              data.alternates.length > 0 &&
                              selectedItems["alternates"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "22",
                                             "twentytwo"
                                          )}
                                          aria-controls="twentytwo"
                                       >
                                          {APPKeys.alternates}{" "}
                                          <i
                                             id="22"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentytwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.alternates} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedATS &&
                              data.relatedATS.length > 0 &&
                              selectedItems["relatedATS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "23",
                                             "twentythree"
                                          )}
                                          aria-controls="twentythree"
                                       >
                                          {APPKeys.relatedATS}{" "}
                                          <i
                                             id="23"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentythree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedATS} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.materialProduced &&
                              data.materialProduced.length > 0 &&
                              selectedItems["materialProduced"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "24",
                                             "twentyfour"
                                          )}
                                          aria-controls="twentyfour"
                                       >
                                          {APPKeys.materialProduced}{" "}
                                          <i
                                             id="24"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyfour"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMCTable data={data.materialProduced} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.lifecycleApprovalPowerview &&
                              data.lifecycleApprovalPowerview.length > 0 &&
                              selectedItems["lifecycleApprovalPowerview"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "25",
                                             "twentyfive"
                                          )}
                                          aria-controls="twentyfive"
                                       >
                                          {APPKeys.lifecycleApprovalPowerview}{" "}
                                          <i
                                             id="25"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="twentyfive"
                                          name="collapseItem"
                                       >
                                          <CustomDerivedTable
                                             data={data.lifecycleApprovalPowerview}
                                             derived="Tasks/Signatures"
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.ownershipObject != null &&
                              selectedItems["ownershipObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "26",
                                             "twentysix"
                                          )}
                                          aria-controls="twentysix"
                                       >
                                          {APPKeys.ownershipObject}{" "}
                                          <i
                                             id="26"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="twentysix"
                                          name="collapseItem"
                                       >
                                          <CommonAttributes data={data.ownershipObject} />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.ipClasses &&
                              data.ipClasses.length > 0 &&
                              selectedItems["ipClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "27",
                                             "twentyseven"
                                          )}
                                          aria-controls="twentyseven"
                                       >
                                          {APPKeys.ipClasses}{" "}
                                          <i
                                             id="27"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyseven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.ipClasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClass &&
                              data.securityClass.length > 0 &&
                              selectedItems["securityClass"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "28",
                                             "twentyeight"
                                          )}
                                          aria-controls="twentyeight"
                                       >
                                          {APPKeys.securityClass}{" "}
                                          <i
                                             id="28"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyeight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.securityClass} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              selectedItems["organizationObject"] &&
                              data.organizationObject && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "29",
                                             "twentynine"
                                          )}
                                          aria-controls="twentynine"
                                       >
                                          {APPKeys.organizationObject}{" "}
                                          <i
                                             id="29"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="twentynine"
                                          name="collapseItem"
                                       >
                                          <CommonAttributes
                                             data={data.organizationObject}
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.files &&
                              data.files.length > 0 &&
                              selectedItems["files"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "30",
                                             "thirty"
                                          )}
                                          aria-controls="thirty"
                                       >
                                          {APPKeys.files}{" "}
                                          <i
                                             id="30"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirty"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <GenDocsFileDownTable
                                          data={data.files}
                                          attributes={data.attributeObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.gpsAssessments &&
                              data.gpsAssessments.length > 0 &&
                              selectedItems["gpsAssessments"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "31", "thirtyone")}
                                          aria-controls="thirtyone"
                                       >
                                          {APPKeys.gpsAssessments}{" "}
                                          <i
                                             id="31"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtyone"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.gpsAssessments} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.smartLabel &&
                              data.smartLabel.length > 0 &&
                              selectedItems["smartLabel"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "32",
                                             "thirtytwo"
                                          )}
                                          aria-controls="thirtytwo"
                                       >
                                          {APPKeys.smartLabel}{" "}
                                          <i
                                             id="32"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtytwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComSML
                                          data={data.smartLabel}
                                          parentData={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.certifications &&
                              data.certifications.length > 0 &&
                              selectedItems["certifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "33",
                                             "thirtythree"
                                          )}
                                          aria-controls="thirtythree"
                                       >
                                          {APPKeys.certifications}{" "}
                                          <i
                                             id="33"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtythree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <PlainCertifications data={data.certifications} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.certificationValidation &&
                              data.certificationValidation.length > 0 &&
                              selectedItems["certificationValidation"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "34",
                                             "thirtyfour"
                                          )}
                                          aria-controls="thirtyfour"
                                       >
                                          {APPKeys.certificationValidation}{" "}
                                          <i
                                             id="34"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtyfour"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.certificationValidation} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.designParameter &&
                              data.designParameter.length > 0 &&
                              selectedItems["designParameter"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "35",
                                             "thirtyfive"
                                          )}
                                          aria-controls="thirtyfive"
                                       >
                                          {APPKeys.designParameter}{" "}
                                          <i
                                             id="35"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtyfive"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DesignParam data={data.designParameter} />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {APPKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default AppAllInfo
