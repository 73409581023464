import React from "react"
import { Link } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import { validateDate } from "../commonComponents/EventHandler"
import "./Pagination.css"
require("react-data-components/css/table-twbs.css")

var DataTable = require("react-data-components").DataTable

const SearchResults = ({ results, loading }) => {
   var inProp = true

   function addLinkData(linkKey, link) {
      var arr = []
      let searchItems = {}

      searchItems["name"] = linkKey
      searchItems["link"] = link
      arr.push(searchItems)
      sessionStorage.removeItem("breadCrumb")
      sessionStorage.setItem("breadCrumb", JSON.stringify(arr))
   }

   const renderMapUrl = (val, result) =>
      result.link != null && result.link !== "" ? (
         <Link
            onClick={() =>
               addLinkData(result.name, `/${result.link.toUpperCase()}/${result.id}`)
            }
            to={(location) => ({
               ...location,
               pathname: `/${result.link.toUpperCase()}/${result.id}`,
            })}
         >
            <span>{result.name}</span>
         </Link>
      ) : (
         result.name
      )

   const tableColumns = [
      { title: "Name", prop: "name", render: renderMapUrl, className: "text-center" },
      { title: "Revision", prop: "revision", className: "colWidth" },
      { title: "Type", prop: "displaytype", className: "colWidth" },
      { title: "Title", prop: "title", className: "preserveSpace" },
      { title: "Description", prop: "description", className: "preserveSpace colDiscWidth" },
      { title: "Maturity State", prop: "maturityState" },
      {
         title: "Last Modified Date",
         prop: "lastModification",
         render: validateDate,
         sortable: false,
      },
      {
         title: "Release Date",
         prop: "releasedate",
         render: validateDate,
         sortable: false,
      },
      {
         title: "Creation Date",
         prop: "creationDate",
         render: validateDate,
         sortable: false,
      },
      { title: "Originator", prop: "originator" },
   ]

   return (
      <div className="searchScrollbar">
         <CSSTransition in={inProp} timeout={500} classNames="my-node">
            <DataTable
               className="container"
               keys={["id","name","revision"]}
               columns={tableColumns}
               initialData={results}
               initialPageLength={20}
               // initialSortBy={{ prop: 'revision', order: 'descending' }}
               pageLengthOptions={[20, 50, 100]}
            />
         </CSSTransition>
      </div>
   )
}

export default SearchResults
