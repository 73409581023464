import { saveAs } from "file-saver"
import React, { useState } from "react"
import { Alert } from "react-bootstrap"
import LoadingOverlay from "react-loading-overlay"
import { fetchAPIData } from "../../api/AuthService"
import { DOWNLOAD_LINK } from "../../constant/Constants"
import CustomLink from "./CustomLink"
import { setNonDispalyValue, tableLinkColumn } from "./EventHandler"

const CustomMasterSpecification = (props) => {
   let data = []
   data = props.data

   const [loading, setLoading] = useState(false)
   const [show, setShow] = useState(false)
   const [errorMeg, setErrorMsg] = useState(false)

   const getHeader = (headerData = []) => {
      var keys = getKeys(headerData)

      return keys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         if (key.toLowerCase() === "zcsurl") {
            return <th key={key}></th>
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getKeys = (keysInformation = []) => {
      return Object.keys(keysInformation)
   }
   const getColumnKeys = (columKeyInfo) => {
      return Object.keys(columKeyInfo)
   }

   const RenderColumn = (props) => {
      return props.keys.map((key, index) => {
         if (props.data[key] !== "" && props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const RenderLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         let masterid = props.data["id"]
         if (
            props.data[key] &&
            key.toLowerCase() === "master part name" &&
            masterid &&
            masterid.toLowerCase().trim() !== "no access"
         ) {
            let name = props.data[key]
            let link = props.data["link"]

            return (
               <div key={index} style={{ minWidth: "9rem" }}>
                  {link ? (
                     <CustomLink id={masterid} link={link} name={name} />
                  ) : (
                     <span>{name}</span>
                  )}
               </div>
            )
         } else if (setNonDispalyValue(key)) {
            return null
         } else if (props.data[key] != null)
            return (
               <div key={index}>
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const getRowsData = (tableData) => {
      var items = tableData
      var keys = getKeys(tableData[0])
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const RenderRow = (props) => {
      return props.keys.map((key, index) => {
         if (
            props.data[key] != null &&
            typeof props.data[key] == "object" &&
            key.toLowerCase() === "master part name"
         ) {
            let colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderLinkColumn
                     isbold={true}
                     id={props.data["id"]}
                     data={props.data[key]}
                     keys={colKeys}
                  />
               </td>
            )
         }
         if (props.data[key] != null && typeof props.data[key] === "object") {
            let colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn data={props.data[key]} isbold={true} keys={colKeys} />
               </td>
            )
         } else if (
            key.toLowerCase() === "name" &&
            props.data["id"] &&
            props.data["id"].toLowerCase().trim() !== "no access"
         ) {
            let name = props.data[key]
            return tableLinkColumn(props.data["id"], props.data["link"], name, index)
         }
         if (key.toLowerCase() === "zcsurl") {
            //check for access
            let isAccess = true
            if (
               (props.data["Originator"] &&
                  props.data["Originator"].toLowerCase().trim() === "no access") ||
               (props.data["id"] && props.data["id"].toLowerCase().trim() === "no access")
            ) {
               isAccess = false
            }

            return (
               <td key={index}>
                  {isAccess && props.data["id"] ? (
                     <button onClick={() => downloadFile(props.data["id"].trim())}>
                        <i className="fa fa-download" aria-hidden="true"></i>
                     </button>
                  ) : (
                     <button disabled>
                        <i className="fa fa-download" aria-hidden="true"></i>
                     </button>
                  )}
               </td>
            )
         } else if (setNonDispalyValue(key)) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   const downloadFile = (object_ID) => {
      var fileName = "download"
      setLoading(true)

      fetchAPIData(`${DOWNLOAD_LINK}${object_ID}`, { responseType: "blob" })
         .then(function (response) {
            if (
               response.headers["filename"] != null &&
               response.headers["filename"] !== ""
            ) {
               fileName = response.headers["filename"]
            }
            const fileContents = new Blob([response.data])
            saveAs(fileContents, fileName)
            setLoading(false)
         })
         .catch((error) => {
            if (error.response && error.response.status === 404) {
               setShow(true)
               setErrorMsg("No file available to download")
            } else if (error.response && error.response.status === 422) {
               setShow(true)
               setErrorMsg(
                  "You have exceeded the limit for the day and access to any additional Part/Specification is blocked! Please contact Support."
               )
            } else {
               setShow(true)
               setErrorMsg("Oops, something went wrong. Please contact Support.")
               console.log("FCS Error Response:-", error)
            }
            setLoading(false)
         })
   }

   if (show) {
      return (
         <Alert
            style={{ textAlign: "center", marginLeft: "8rem", marginRight: "8rem" }}
            variant="info"
            onClose={() => setShow(false)}
            dismissible
         >
            <p>{errorMeg}</p>
         </Alert>
      )
   }

   return (
      <div className="tableFixHead">
         {data && data.length > 0 && (
            <LoadingOverlay active={loading} spinner text="Downloading....">
               <table
               >
                  <thead>
                     <tr>{getHeader(data[0])}</tr>
                  </thead>
                  <tbody>{getRowsData(data)}</tbody>
               </table>
            </LoadingOverlay>
         )}
      </div>
   )
}
export default CustomMasterSpecification
