import React from "react"
import Attribute from "../commonComponents/Attributes"
import CustomFormulaProcessFormula from "./CustomFormulaProcessFormula"

export const CustomMultiFILFormulaProcessFormula = (props) => {
   const billOfMaterials = props.data
   const attribute = billOfMaterials.topHeader
   const bomsOfFOP = billOfMaterials.formulationProcessFormula

   const table_Contents = (bomsOfFOP) =>
      bomsOfFOP.map((bom, index) => {
         return (
            <div key={index}>
               <CustomFormulaProcessFormula data={bom} />
               <hr />
            </div>
         )
      })
   return (
      <div>
         {attribute && (
            <div>
               <Attribute data={attribute} /> <hr />
            </div>
         )}
         {bomsOfFOP && table_Contents(bomsOfFOP)}
      </div>
   )
}
