import React, { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { DSMReportGenerate } from "../../../api/AuthService"
import { REPORT_SUBMIT } from "../../../constant/Constants"



   
            
      
function ReportSubmitCharMaster(props) {
   
   const [disabled, setDisabled] = useState(false)
   const [submitLoading, setSubmitLoading] = useState(false)

   let clientErrorCode = 0;
   const history = useHistory()


  

   const validateForm = () => {
      let formValid = true;
      if (
         props.data.reportName === "Characteristic Master Report"  
      ) 
      {
        if (
            props.data.buisness_Areas.length===0        
         ) {
            formValid = false
         }  
         if (
            props.data.buisness_Areas.length>10
         ){
            formValid=false
         }
      } 
      return formValid
   }

   const handleSubmit = () => {
      if (validateForm()) {
         props.clearMsg()
        
         const sanitized = props.data
         

         setSubmitLoading(true)
         setDisabled(true)
         
         DSMReportGenerate(`${REPORT_SUBMIT}`, sanitized)
            .then((response) => {
               props.handler(response.status)
              
               setTimeout(() => {
                  history.goBack()
               }, 2000);
               setSubmitLoading(false)
            })
            .catch((error) => {
               if (error.response && error.response.status) {
                  props.handler(error.response.status, error.response.data.message)
               } else {
                  props.handler(1000, error)
               }
               setSubmitLoading(false)
               setDisabled(false)
            })
      } else {
         if (clientErrorCode !== 0) {
            props.handler(clientErrorCode)
         } else if(props.data.buisness_Areas.length===0) {
            props.handler(999)
         }
         else if(props.data.buisness_Areas.length>10) {
            props.handler(123)
         }
         setDisabled(false)
      }
   }

   return (
      <React.Fragment>
         <Row>
            <Col md="11" align="center">
               <Button variant="primary" onClick={handleSubmit} disabled={disabled}>
                  {submitLoading ? (
                     <i className="fa fa-spinner fa-spin" style={{ marginRight: "5px" }} />
                  ) : (
                     <span>Submit</span>
                  )}
               </Button>
               &nbsp;
               <Button variant="primary" onClick={history.goBack}>
                  Cancel
               </Button>
               &nbsp;
               <Button variant="primary" onClick={props.handleClearItems}>
                  Reset
               </Button>
            </Col>
         </Row>
      </React.Fragment>
   )
}
export default ReportSubmitCharMaster
