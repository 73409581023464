import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { CUPKeys, DefaultKeys, FETCH_CUP } from "../../constant/Constants"
import Attributes from "../commonComponents/Attributes"
import CommonAttributes from "../commonComponents/CommonAttributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import CustomBOMTable from "../commonComponents/CustomBOMTable"
import CustomMasterSpecification from "../commonComponents/CustomMasterSpecification"
import CustomPCTable from "../commonComponents/CustomPCTable"
import CustomSubstitute from "../commonComponents/CustomSubstitute"
import CustomTable from "../commonComponents/CustomTable"
import CustomDerivedTable from "../commonComponents/DerivedTable"
import DownloadLinkTable from "../commonComponents/DownloadLinkTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import GenDocsFileDownTable from "../commonComponents/GenDocsFilesDownload"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../commonComponents/TopContentLatest"

function CupAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributes: {},
      weightCharacterstics: [],
      storageTransportData: {},
      notes: [],
      billOfMaterials: [],
      subStitutes: [],
      relatedSpecifications: [],
      masterSpecifications: [],
      referenceDocument: [],
      performanceCharacteristic: [],
      plants: [],
      relatedATS: [],
      lifecycleApproval: [],
      ownership: {},
      ipClasses: [],
      securityClasses: [],
      organizations: {},
      files: [],
      derivedTo: [],
      derivedFrom: [],
      whereUsed: null,
   })
   const searchData = props.match.params.objectID
   const [loading, setLoading] = useState(true)
   const [sideNav, setSideNav] = useState()
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)

      var values = pdfData(data, obj, IsFilterView)

      setPdfDataSet(values)
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchCupData() {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")
               var apiData

               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  // Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_CUP}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContent)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
                  //   if (fetchState.cancel) { return; }
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContent
                     ? apiData.headerContent
                     : data.headerContent,
                  attributes: apiData.attributes,
                  organizations: apiData.organizations,
                  storageTransportData: apiData.storageTransportData,
                  billOfMaterials: apiData.billOfMaterials,
                  lifecycleApproval: apiData.lifecycleApproval,
                  ownership: apiData.ownership,
                  masterSpecifications: apiData.masterSpecifications,
                  derivedTo: apiData.derivedTo,
                  derivedFrom: apiData.derivedFrom,
                  notes: apiData.notes,
                  subStitutes: apiData.substitutes,
                  securityClasses: apiData.securityClasses,
                  relatedATS: apiData.relatedATS,
                  relatedSpecifications: apiData.relatedSpecifications,
                  referenceDocument: apiData.referenceDocument,
                  performanceCharacteristic: apiData.performanceCharacteristic,
                  weightCharacterstics: apiData.weightCharacterstics,
                  ipClasses: apiData.ipClasses,
                  files: apiData.files,
                  plants: apiData.plants,
                  whereUsed: apiData.whereUsed,
               }))
               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  console.log("Error", ` Not authorie: ${error}`)
                  notAuthorized(props.history)
                  return
               }
               console.log("Error", ` API request failed: ${error}`)
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchCupData()
      }
      return () => {
         return undefined
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={CUPKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" className="contentBackground">
                     <br />
                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="9" sm="9">
                              <TopContent headerContent={data.headerContent} />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              <PdfDownloadComponent pdfDataSet={pdfDataSet} />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {selectedItems["attributes"] && data.attributes && (
                              <div>
                                 <Card autoFocus>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(
                                          this,
                                          "0",
                                          "collapseZero"
                                       )}
                                       aria-controls="collapseZero"
                                    >
                                       {CUPKeys.attributes}{" "}
                                       <i
                                          id="0"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="collapseZero"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <Attributes
                                       loading={loading}
                                       data={data.attributes}
                                    />
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              data.weightCharacterstics &&
                              selectedItems["weightCharacterstics"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "1",
                                             "collapseOne"
                                          )}
                                          aria-controls="collapseOne"
                                       >
                                          {CUPKeys.weightCharacterstics}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseOne"
                                       name="collapseItem"
                                    >
                                       <CommonAttributes
                                          data={data.weightCharacterstics}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              selectedItems["storageTransportData"] &&
                              data.storageTransportData && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "2",
                                             "collapseTwo"
                                          )}
                                          aria-controls="collapseTwo"
                                       >
                                          {CUPKeys.storageTransportData}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseTwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes
                                          data={data.storageTransportData}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.notes &&
                              data.notes.length > 0 &&
                              selectedItems["notes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "3",
                                             "collapseThree"
                                          )}
                                          aria-controls="collapseThree"
                                       >
                                          {CUPKeys.notes}{" "}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseThree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.notes} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.billOfMaterials &&
                              data.billOfMaterials.length > 0 &&
                              selectedItems["billOfMaterials"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "4",
                                             "collapseFour"
                                          )}
                                          aria-controls="collapseFour"
                                       >
                                          {CUPKeys.billOfMaterials}{" "}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseFour"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable bom={data.billOfMaterials} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.subStitutes &&
                              data.subStitutes.length > 0 &&
                              selectedItems["subStitutes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "5",
                                             "collapseFive"
                                          )}
                                          aria-controls="collapseFive"
                                       >
                                          {CUPKeys.subStitutes}{" "}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseFive"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSubstitute data={data.subStitutes} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedSpecifications &&
                              data.relatedSpecifications.length > 0 &&
                              selectedItems["relatedSpecifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "6",
                                             "collapseSix"
                                          )}
                                          aria-controls="collapseSix"
                                       >
                                          {CUPKeys.relatedSpecifications}{" "}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseSix"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DownloadLinkTable
                                          data={data.relatedSpecifications}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.masterSpecifications &&
                              data.masterSpecifications.length > 0 &&
                              selectedItems["masterSpecifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "7",
                                             "collapseSeven"
                                          )}
                                          aria-controls="collapseSeven"
                                       >
                                          {CUPKeys.masterSpecifications}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseSeven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMasterSpecification
                                          data={data.masterSpecifications}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocument &&
                              data.referenceDocument.length > 0 &&
                              selectedItems["referenceDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "8",
                                             "collapseEight"
                                          )}
                                          aria-controls="collapseEight"
                                       >
                                          {CUPKeys.referenceDocument}{" "}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseEight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComRefDocDownload
                                          data={data.referenceDocument}
                                          attributes={data.attributes}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.performanceCharacteristic &&
                              data.performanceCharacteristic.length > 0 &&
                              selectedItems["performanceCharacteristic"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "9",
                                             "collapseNine"
                                          )}
                                          aria-controls="collapseNine"
                                       >
                                          {CUPKeys.performanceCharacteristic}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseNine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPCTable
                                          data={data.performanceCharacteristic}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.plants &&
                              data.plants.length > 0 &&
                              selectedItems["plants"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "10",
                                             "collapseTen"
                                          )}
                                          aria-controls="collapseTen"
                                       >
                                          {CUPKeys.plants}
                                          <i
                                             id="10"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseTen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.plants} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedATS &&
                              data.relatedATS.length > 0 &&
                              selectedItems["relatedATS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "15",
                                             "collapseFifteen"
                                          )}
                                          aria-controls="collapseFifteen"
                                       >
                                          {CUPKeys.relatedATS}{" "}
                                          <i
                                             id="15"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseFifteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedATS} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.lifecycleApproval &&
                              data.lifecycleApproval.length > 0 &&
                              selectedItems["lifecycleApproval"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "11",
                                             "collapseElevan"
                                          )}
                                          aria-controls="collapseElevan"
                                       >
                                          {CUPKeys.lifecycleApproval}{" "}
                                          <i
                                             id="11"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseElevan"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomDerivedTable
                                          data={data.lifecycleApproval}
                                          derived="Tasks/Signatures"
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading && data.ownership && selectedItems["ownership"] && (
                              <div>
                                 <Card>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(
                                          this,
                                          "12",
                                          "collapseTwelve"
                                       )}
                                       aria-controls="collapseTwelve"
                                    >
                                       {CUPKeys.ownership}{" "}
                                       <i
                                          id="12"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="collapseTwelve"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <CommonAttributes data={data.ownership} />
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              data.ipClasses &&
                              data.ipClasses.length > 0 &&
                              selectedItems["ipClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "13",
                                             "collapseThirteen"
                                          )}
                                          aria-controls="collapseThirteen"
                                       >
                                          {CUPKeys.ipClasses}{" "}
                                          <i
                                             id="13"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseThirteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.ipClasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClasses &&
                              data.securityClasses.length > 0 &&
                              selectedItems["securityClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "14",
                                             "collapseFourteen"
                                          )}
                                          aria-controls="collapseFourteen"
                                       >
                                          {CUPKeys.securityClasses}{" "}
                                          <i
                                             id="14"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseFourteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.securityClasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              selectedItems["organizations"] &&
                              data.organizations && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "16",
                                             "collapseSixteen"
                                          )}
                                          aria-controls="collapseSixteen"
                                       >
                                          {CUPKeys.organizations}{" "}
                                          <i
                                             id="16"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseSixteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes data={data.organizations} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.files &&
                              data.files.length > 0 &&
                              selectedItems["files"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "17",
                                             "collapseSeventeen"
                                          )}
                                          aria-controls="collapseSeventeen"
                                       >
                                          {CUPKeys.files}{" "}
                                          <i
                                             id="17"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseSeventeen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <GenDocsFileDownTable
                                          data={data.files}
                                          attributes={data.attributes}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              ((data.derivedFrom && data.derivedFrom.length > 0) ||
                                 (data.derivedTo && data.derivedTo.length > 0)) &&
                              selectedItems["derivedParts"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "18",
                                             "collapseEighteen"
                                          )}
                                          aria-controls="collapseEighteen"
                                       >
                                          {CUPKeys.derivedParts}{" "}
                                          <i
                                             id="18"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseEighteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomDerivedTable
                                          data={data.derivedFrom}
                                          derived="Derived From"
                                       />
                                       <CustomDerivedTable
                                          data={data.derivedTo}
                                          derived="Derived To"
                                       />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {CUPKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default CupAllInfo
