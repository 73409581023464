import React from "react"
import CustomLink from "./CustomLink"
import { setNonDispalyValue, tableLinkColumn } from "./EventHandler"
const CustomSubstitute = (props) => {
   let data = []
   data = props.data

   const numRows = data.length
   const getHeader = () => {
      var keys = getKeys()
      return keys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getRowsData = () => {
      var items = data
      var keys = getKeys()
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const getKeys = function () {
      return Object.keys(data[0])
   }

   const getColumnKeys = function (columKeyInfo) {
      return Object.keys(columKeyInfo)
   }

   const RenderColumn = function (props) {
      return props.keys.map((key, index) => {
         if (props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }
   const RenderLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         const object_ID = props.data["id"]
         if (
            props.data[key] &&
            (key.toLowerCase() === "sp" || key.toLowerCase() === "sf" || key.toLowerCase() == "name") &&
            object_ID &&
            object_ID.toLowerCase().trim() !== "no access"
         ) {
            let name = props.data[key]
            let link = props.data["link"]

            return (
               <div key={index} style={{ minWidth: "9rem" }}>
                  {(key.toLowerCase() == "sp" || key.toLowerCase() == "sf") ? (
                     <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>
                  ) : " "}
                  <span>
                     {link ? (
                        <CustomLink id={object_ID} link={link} name={name} />
                     ) : (
                        <span>{name}</span>
                     )}
                  </span>
               </div>
            )
         }
         // else if (props.data[key] && (key.toLowerCase() === "link" || key.toLowerCase()==="id")) {
         //     return null;
         // }
         else if (key && (key.toLowerCase() === "link" || key.toLowerCase() === "id")) {
            return null
         } else if (props.data[key] != null)
            return (
               <div key={index}>
                  {key.toLowerCase() !== "name" ? <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span> : " "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const getLinksArray = function (data, link, id) {
      let elements = data.map((value, index) => {
         let num = index + 1
         if (
            link[index] &&
            link[index].trim() !== "" &&
            id[index] &&
            id[index].trim() !== "" &&
            id[index].toLowerCase().trim() !== "no access"
         ) {
            return (
               <span key={index}>
                  <CustomLink
                     id={id[index].trim()}
                     link={link[index].trim()}
                     name={value}
                  />
                  {num === data.length ? "" : "|"}
               </span>
            )
         } else {
            return (
               <span key={index}>
                  {value}
                  {num === data.length ? "" : "|"}
               </span>
            )
         }
      })
      return elements
   }
   const RenderBaseColumn = function (props) {
      return props.keys.map((key, index) => {
         if (props.data[key] != null) {
            if (key.toLowerCase() === "bc" || key.toLowerCase() === "cn") {
               let nameData = props.data[key]["name"]
                  ? props.data[key]["name"].split("|")
                  : []
               let nameLink = props.data[key]["link"]
                  ? props.data[key]["link"].split("|")
                  : []
               let nameId = props.data[key]["id"] ? props.data[key]["id"].split("|") : []
               let getnamelinks = getLinksArray(nameData, nameLink, nameId)
               return (
                  <div key={index}>
                     <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span>
                     <span>{getnamelinks}</span>
                  </div>
               )
            } else if (props.data[key] && setNonDispalyValue(key)) {
               return null
            } else {
               return (
                  <div key={index}>
                     <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                     <span>{props.data[key]}</span>
                  </div>
               )
            }
         } else return null
      })
   }
   const RenderRow = (props) => {
      var colKeys = []
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            typeof props.data[key] == "object" &&
            (key.includes("Substitute For") || key.includes("Substitute Parts"))
         ) {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderLinkColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         }
         if (
            props.data[key] &&
            typeof props.data[key] == "object" &&
            key.includes("Base Code")
         ) {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderBaseColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         }else if (props.data[key] != null && typeof props.data[key] == "object" && key.toLowerCase() == "substitution name") {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderLinkColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         }
         else if (props.data[key] != null && typeof props.data[key] == "object") {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         } else if (
            key.toLowerCase() === "name" &&
            props.data["link"] &&
            props.data["link"] !== ""
         ) {
            let name = props.data[key]
            return tableLinkColumn(props.data["id"], props.data["link"], name, index)
         } else if (setNonDispalyValue(key)) {
            return null
         } else return <td key={index}>{props.data[key]}</td>
      })
   }

   return (
      <div className="tableFixHead">
         {numRows > 0 && (
            <table>
               <thead>
                  <tr>{getHeader()}</tr>
               </thead>
               <tbody>{getRowsData()}</tbody>
            </table>
         )}
      </div>
   )
}
export default CustomSubstitute
