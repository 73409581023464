import jwt from "jwt-decode"
import { LOG_LOGOUT_API, REFRESH_PING } from "../constant/Constants"
import Api from "./Api"

export const pingLogin = (url) => {
   const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
   }
   return Api.get(url, {
      headers,
   }).then((res) => {
      if (res.data.authToken !== null) {
         setTokenData(res.data)
         sessionStorage.setItem("freshLogin", true)
       
         return Promise.resolve(res)
      } else {
         return Promise.reject(res)
      }
   })
}

//Post api call to ping to get refresh token and logout the user
export const postApiCall = (url) => {
   return Api.post(url).then(_checkStatus)
}

export const getAuthentiationHeaders = () => {
   const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
   }

   if (isLoggedIn()) {
      headers["Authorization"] = "Bearer " + getToken()
      headers["access_token"] = localStorage.getItem("id_token")
   }

   return headers
}
// performs api calls to fecth all info data
export const fetchAPIData = (url, options) => {
   return Api.get(url, {
      headers: getAuthentiationHeaders(),
      ...options,
   }).then(_checkStatus)
}

//decode and set the access token , auth token and other params to local storage
export const setTokenData = (data) => {
   if (data.access_token) {
      var accessToken = jwt(data.access_token)
      // console.log("Token details:-",accessToken)
      var userName = accessToken.FirstName + " " + accessToken.LastName
      localStorage.setItem("User", userName)
      localStorage.setItem("UserId", accessToken.Username)
      localStorage.setItem("expiry", accessToken.exp)
      localStorage.setItem("id_token", data.access_token)
   }
   if (data.authToken) localStorage.setItem("authToken", data.authToken)
   var authToken = jwt(data.authToken)
   var secAuthority = authToken.securityAuthorities
   
   if(authToken.expirationDate){
      localStorage.setItem("expirationDate",authToken.expirationDate);
   }
   const emailPreference = authToken.preference
   if(authToken.preference){
      sessionStorage.setItem("emailNotifications",emailPreference)
   }
     
   if (secAuthority != null) {
      var roleName = ""
      for (var i = 0; i < secAuthority.length; i++) {
         if (secAuthority[i].authority.includes("Spec Reader Contract Manufacturer")) {
            roleName = "CM"
         }
         if (secAuthority[i].authority.includes("Spec Reader Supplier")) {
            roleName = "SP"
         }
      }
      localStorage.setItem("role", roleName)
   }

   if (data.refresh_token) localStorage.setItem("refresh_token", data.refresh_token)
}

//to check if the auth token exists in local storage
export const isLoggedIn = (calledFrom, value) => {
   const token = getToken()
   let flag = isTokenExpired()
   if (token !== "undefined" && token && flag !== 0) {
      if (flag === 1) {
         refreshToken(REFRESH_PING)
      }
      return true
   } else {
      if (calledFrom && calledFrom === "gcas") {
         localStorage.setItem("calledFrom", "gcas")
         localStorage.setItem("gcas", value)
      } else {
         localStorage.removeItem("calledFrom")
         localStorage.removeItem("gcas")
      }
      return false
   }
}

//clear the local storage and navigate the user to logout
export const notAuthorized = (object) => {
   clearSessionAndLocalStorage(object)
}

// Retrieves the user token from localStorage
export const getToken = () => {
   return localStorage.getItem("authToken")
}

export const getProfile = () => {
   return getToken()
}

// Raises an error in case response status is not a success
const _checkStatus = (response) => {
   if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response
   } else {
      var error = new Error(response.statusText)
      error.response = response
      throw error
   }
}

// To check if the token is expired or not
export const isTokenExpired = () => {
   const exp = localStorage.getItem("expiry")
   if (exp < Date.now() / 1000) {
      return 0
      //when token is expired
   } else if (exp - Math.trunc(Date.now() / 1000) <= 300) {
      return 1
      //when token is about to expire in 5 minutes
   } else {
      return 2
      //when token is active
   }
}

// To refresh the token
export const refreshToken = async (url) => {
   const token = localStorage.getItem("refresh_token")
   try {
      const response = await postApiCall(`${url}?refresh_token=${token}`)
      setTokenData(response.data)
   } catch (error) {
      console.log("Error", ` API request failed: ${error}`)
   }
}

// Logout the user
export const logout = async (object) => {
   //  const token = localStorage.getItem('refresh_token')
   var pathName = ""

   if (object.location) {
      pathName = object.location.pathname.split("/")[1]
   }
   try {
      if (localStorage.getItem("UserId")) {
         const response = await LogLogOut_Details(
            LOG_LOGOUT_API,
            localStorage.getItem("UserId"),
            pathName
         )

         if (response && response.status === 200) {
            console.log("log out successfully from app.", response)
         }
      }
   } catch (error) {
      console.log("Error", ` API request failed: ${error}`)
   } finally {
      clearSessionAndLocalStorage()
   }
}

const clearSessionAndLocalStorage = () => {
   try {
      localStorage.removeItem("id_token")
      localStorage.removeItem("User")
      localStorage.clear()
      sessionStorage.clear()
      // window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`
      window.location.href = `${process.env.REACT_APP_redirectUri}`
   } catch (error) {
      console.log("Error", ` API request failed: ${error}`)
   }
}

//save the user prefrences
export const savePref = (url, name, list) => {
   const data = {
      id: { userId: localStorage.getItem("UserId"), objectName: name },
      userOnAccess: list,
   }
   return Api.post(url, data).then((res) => {
      return Promise.resolve(res)
   })
}

//AllInfo pdf formation
export const AllInfoPdfGenerate = (url, type, pdfData, objectID, view) => {
   const data = {
      objectType: type,
      role: localStorage.getItem("role") ? localStorage.getItem("role") : "",
      data: pdfData,
      objectID: objectID,
      view: view,
   }
   return Api.post(url, data, {
      responseType: "arraybuffer",
      headers: getAuthentiationHeaders(),
   }).then((res) => {
      return Promise.resolve(res)
   })
}

export const GenDocFileDownload = (
   url,
   fileName,
   filePath,
   fileType,
   attributes,
   headerContent,
   Title,
   calledFrom
) => {
   const data = {
      filename: fileName,
      filepath: filePath,
      fileType: fileType,
      attributes: attributes,
      headerContent: headerContent,
      title: Title ? Title : "",
      calledFrom: calledFrom ? calledFrom : "",
   }

   return Api.post(url, data, {
      responseType: "blob",
      headers: getAuthentiationHeaders(),
   }).then((res) => {
      return Promise.resolve(res)
   })
}

export const LogLogOut_Details = (url, userName, page) => {
   const data = {
      userName: userName,
      page: page,
   }
   return Api.post(url, data).then((res) => {
      return Promise.resolve(res)
   })
}

export const DSMReportGenerate = (url, data) => {
   return Api.post(url, data, {
      headers: getAuthentiationHeaders(),
   }).then((res) => {
      return Promise.resolve(res)
   })
}
export const DSMDelete = (url, data) => {
   return Api.post(url, data, {
      headers: getAuthentiationHeaders(),
   }).then((res) => {
      return Promise.resolve(res)
   })
}

export const MoveRemoveAPI = (url, data) => {
   return Api.put(url, data, {
      headers: getAuthentiationHeaders(),
   }).then((res) => {
      return Promise.resolve(res)
   })
}

export const EmailPreference = (url, data) => {
   return Api.put(url, data, {
      headers: getAuthentiationHeaders(),
   }).then((res) => {
      return Promise.resolve(res)
   })
}

export const EBPViewPdfDownload = (url, data) => {
   return Api.post(url, data, {
      responseType: "arraybuffer",
      headers: getAuthentiationHeaders(),
   }).then((res) => {
      return Promise.resolve(res)
   })
}