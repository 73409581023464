import React from "react"
import { Col, Row } from "react-bootstrap"

const SmlTopContent = (props) => {
   const prop = props.headerContent

   if (props.loading) {
      return (
         <h3 style={{ marginLeft: "30rem", color: "#0640a4" }}>
            <i className="fa fa-spinner fa-spin" style={{ marginRight: "5px" }} />
            Loading........
         </h3>
      )
   }
   return (
      <div>
         <React.Fragment>
            <Row>
               {props.parentName !== undefined && (
                  <Col md="3" sm="2.5">
                     <span id="pageheaders">Name: </span>
                     {props.parentName}
                  </Col>
               )}
               {props.parentRev !== undefined && (
                  <Col md="2" sm="2.5">
                     <span id="pageheaders">Revision: </span>
                     {props.parentRev}
                  </Col>
               )}
               <Col md="2" sm="2.5">
                  <span id="pageheaders">State: </span>
                  Released
               </Col>
               {prop["Smart Label Name"] !== undefined && (
                  <Col md="4" sm="4">
                     <span id="pageheaders">Smart Label Name: </span>
                     {prop["Smart Label Name"]}
                  </Col>
               )}
            </Row>
         </React.Fragment>
      </div>
   )
}

export default SmlTopContent
