import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { DefaultKeys, FETCH_RMP, PREF_MSG, RMPKeys } from "../../constant/Constants"
import Attributes from "../commonComponents/Attributes"
import CommonAttribute from "../commonComponents/CommonAttributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import CustomCertifications from "../commonComponents/CustomCertifications"
import CustomMasterSpecification from "../commonComponents/CustomMasterSpecification"
import CustomMCTable from "../commonComponents/CustomMatrialAndComposition"
import CustomPCTable from "../commonComponents/CustomPCTable"
import CustomPQRView from "../commonComponents/CustomPQRView"
import CustomTable from "../commonComponents/CustomTable"
import CustomDerivedTable from "../commonComponents/DerivedTable"
import DownloadLinkTable from "../commonComponents/DownloadLinkTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import GenDocsFileDownTable from "../commonComponents/GenDocsFilesDownload"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../commonComponents/TopContentLatest"

function RmpAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributes: {},
      profileIdentification: {},
      physicalProperties: {},
      perfumeProperties: {},
      chemicalMolecularProperties: {},
      detergentSurfactantProperties: {},
      batteryDetails: {},
      weightCharacteristic: [],
      chemicalClassification: {},
      sustainability: {},
      stabilityDocument: [],
      materialsAndComposition: [],
      relatedSpecifications: [],
      masterSpecifications: [],
      referenceDocument: [],
      performanceCharcteristic: [],
      halbPlants: [],
      rohPlants: [],
      pqrViewManufacturer: [],
      pqrSupplierView: [],
      certifications: [],
      packagingCertificationsMaster:[],
      alternates: [],
      relatedATS: [],
      producingFormula: [],
      startingMaterial: [],
      rdsites: [],
      lifeCycleApprovalPowerview: [],
      ownerShip: {},
      ipClasses: [],
      securityClasses: [],
      organizations: {},
      files: [],
      gpsAssessments: [],
      // reach: {},
       substancesMaterials: [],
      // plants: [],
      // manufacturerEquivalents: [],
      // supplierEquivalents: [],
      // masterAttributes: {},
      // notes: [],
      whereUsed: null,
   })

   const [loading, setLoading] = useState(true)
   const searchData = props.match.params.objectID
   const [sideNav, setSideNav] = useState()
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})
   const role = localStorage.getItem("role")

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)
      var values = pdfData(data, obj, IsFilterView)
      setPdfDataSet(values)
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchFppData() {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")
               var apiData

               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  // Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_RMP}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContent)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContent
                     ? apiData.headerContent
                     : data.headerContent,
                  attributes: apiData.attributes,
                  organizations: apiData.organizations,
                  profileIdentification: apiData.profileIdentification,
                  physicalProperties: apiData.physicalProperties,
                  chemicalClassification: apiData.chemicalClassification,
                  perfumeProperties: apiData.perfumeProperties,
                  chemicalMolecularProperties: apiData.chemicalMolecularProperties,
                  detergentSurfactantProperties: apiData.detergentSurfactantProperties,
                  // reach: apiData.reach,
                  // plants: apiData.plants,
                  lifeCycleApprovalPowerview: apiData.lifecycleApprovalPowerview,
                  ownerShip: apiData.ownership,
                  // manufacturerEquivalents: apiData.manufacturerEquivalents,
                  // supplierEquivalents: apiData.supplierEquivalents,
                  relatedSpecifications: apiData.relatedSpecifications,
                  masterSpecifications: apiData.masterSpecifications,
                  // masterAttributes: apiData.masterAttributes,
                  weightCharacteristic: apiData.weightCharacteristic,
                  substancesMaterials: apiData.substancesMaterials,
                  performanceCharcteristic: apiData.performanceCharcteristic,
                  //notes: apiData.notes,
                  startingMaterial: apiData.startingMaterial,
                  relatedATS: apiData.relatedATS,
                  alternates: apiData.alternates,
                  certifications: apiData.certifications,
                  materialsAndComposition: apiData.materialsAndComposition,
                  // derivedTo: apiData.derivedTo,
                  // derivedFrom: apiData.derivedFrom,
                  rohPlants: apiData.rohPlants,
                  halbPlants: apiData.halbPlants,
                  referenceDocument: apiData.referenceDocument,
                  securityClasses: apiData.securityClasses,
                  producingFormula: apiData.producingFormula,
                  stabilityDocument: apiData.stabilityDocument,
                  pqrSupplierView: apiData.pqrSupplierView,
                  pqrViewManufacturer: apiData.pqrViewManufacturer,
                  ipClasses: apiData.ipClasses,
                  rdsites: apiData.rdsites,
                  files: apiData.files,
                  batteryDetails: apiData.batteryDetails,
                  gpsAssessments: apiData.gpsAssessments,
                  packagingCertificationsMaster: apiData.packagingCertificationsMaster,
                  sustainability: apiData.sustainability,
                  whereUsed: apiData.whereUsed,
               }))
               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  notAuthorized(props.history)
                  return
               }
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         fetchFppData()
         window.scrollTo(0, 0)
      }
      return () => {
         return undefined
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={RMPKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" className="contentBackground">
                     <br />
                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="9" sm="9">
                              <TopContent headerContent={data.headerContent} />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              <PdfDownloadComponent pdfDataSet={pdfDataSet} />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col autoFocus md="12" className="dataCol contentBackground">
                           {data.attributes && selectedItems["attributes"] && (
                              <div>
                                 <Card>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(this, "0", "Zero")}
                                       aria-controls="Zero"
                                    >
                                       {RMPKeys.attributes}{" "}
                                       <i
                                          id="0"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="Zero"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <Attributes
                                       loading={loading}
                                       data={data.attributes}
                                    />
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              data.profileIdentification &&
                              selectedItems["profileIdentification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "1", "One")}
                                          aria-controls="One"
                                       >
                                          {RMPKeys.profileIdentification}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="One"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute
                                          data={data.profileIdentification}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.physicalProperties &&
                              selectedItems["physicalProperties"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "2", "Two")}
                                          aria-controls="Two"
                                       >
                                          {RMPKeys.physicalProperties}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Two"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.physicalProperties} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.perfumeProperties &&
                              selectedItems["perfumeProperties"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "3", "Three")}
                                          aria-controls="Three"
                                       >
                                          {RMPKeys.perfumeProperties}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Three"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.perfumeProperties} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.chemicalMolecularProperties &&
                              selectedItems["chemicalMolecularProperties"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "4", "Four")}
                                          aria-controls="Four"
                                       >
                                          {RMPKeys.chemicalMolecularProperties}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Four"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute
                                          data={data.chemicalMolecularProperties}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.detergentSurfactantProperties &&
                              selectedItems["detergentSurfactantProperties"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "5", "Five")}
                                          aria-controls="Five"
                                       >
                                          {RMPKeys.detergentSurfactantProperties}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Five"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute
                                          data={data.detergentSurfactantProperties}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.batteryDetails &&
                              selectedItems["batteryDetails"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "6", "six")}
                                          aria-controls="six"
                                       >
                                          {RMPKeys.batteryDetails}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="six"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.batteryDetails} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.weightCharacteristic &&
                              data.weightCharacteristic.length > 0 &&
                              selectedItems["weightCharacteristic"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "7", "seven")}
                                          aria-controls="seven"
                                       >
                                          {RMPKeys.weightCharacteristic}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="seven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.weightCharacteristic} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.chemicalClassification &&
                              selectedItems["chemicalClassification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "8", "eight")}
                                          aria-controls="eight"
                                       >
                                          {RMPKeys.chemicalClassification}{" "}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="eight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute
                                          data={data.chemicalClassification}
                                          header={
                                             role === "" ?
                                             "This is primarily used by the Flavors and Fragrances and P&G Chemicals" : false
                                          }
                                       />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.sustainability &&
                              selectedItems["sustainability"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "32",
                                             "thirtytwo"
                                          )}
                                          aria-controls="thirtytwo"
                                       >
                                          {RMPKeys.sustainability}{" "}
                                          <i
                                             id="32"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtytwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.sustainability} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.stabilityDocument &&
                              data.stabilityDocument.length > 0 &&
                              selectedItems["stabilityDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "9", "nine")}
                                          aria-controls="nine"
                                       >
                                          {RMPKeys.stabilityDocument}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="nine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.stabilityDocument} />
                                    </div>
                                 </div>
                              )}
                           {!loading && data.substancesMaterials && data.substancesMaterials.length > 0 && selectedItems["substancesMaterials"] &&
                                        <div>
                                            <Card>
                                                <Card.Body className="accordionStyle"
                                                    onClick={handleClick.bind(this, "32", "collapseThirtyTwo")}
                                                    aria-controls="collapseThirtyTwo">
                                                    {RMPKeys.substancesMaterials} <i id="32" name="accordItem" className="fa fa-minus-circle accordIconStyle"></i></Card.Body>
                                            </Card>
                                            <div className="accordionDiv shadow" id="collapseThirtyTwo" name="collapseItem" style={{ display: 'block' }}>
                                                <CustomMCTable data={data.substancesMaterials} />
                                            </div>
                                        </div>
                                    }
                           {!loading &&
                              data.materialsAndComposition &&
                              data.materialsAndComposition.length > 0 &&
                              selectedItems["materialsAndComposition"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "10", "ten")}
                                          aria-controls="ten"
                                       >
                                          {RMPKeys.materialsAndComposition}{" "}
                                          <i
                                             id="10"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="ten"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMCTable
                                          data={data.materialsAndComposition}
                                       />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              data.relatedSpecifications &&
                              data.relatedSpecifications.length > 0 &&
                              selectedItems["relatedSpecifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "11", "elevan")}
                                          aria-controls="elevan"
                                       >
                                          {RMPKeys.relatedSpecifications}{" "}
                                          <i
                                             id="11"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="elevan"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DownloadLinkTable
                                          data={data.relatedSpecifications}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.masterSpecifications &&
                              data.masterSpecifications.length > 0 &&
                              selectedItems["masterSpecifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "12", "twelve")}
                                          aria-controls="twelve"
                                       >
                                          {RMPKeys.masterSpecifications}{" "}
                                          <i
                                             id="12"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twelve"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMasterSpecification
                                          data={data.masterSpecifications}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocument &&
                              data.referenceDocument.length > 0 &&
                              selectedItems["referenceDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "13",
                                             "thirteen"
                                          )}
                                          aria-controls="thirteen"
                                       >
                                          {RMPKeys.referenceDocument}{" "}
                                          <i
                                             id="13"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComRefDocDownload
                                          data={data.referenceDocument}
                                          attributes={data.attributes}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.performanceCharcteristic &&
                              data.performanceCharcteristic.length > 0 &&
                              selectedItems["performanceCharcteristic"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "14",
                                             "fourteen"
                                          )}
                                          aria-controls="fourteen"
                                       >
                                          {RMPKeys.performanceCharcteristic}
                                          <i
                                             id="14"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPCTable
                                          data={data.performanceCharcteristic}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.halbPlants &&
                              data.halbPlants.length > 0 &&
                              selectedItems["halbPlants"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "15",
                                             "fifteen"
                                          )}
                                          aria-controls="fifteen"
                                       >
                                          {RMPKeys.halbPlants}{" "}
                                          <i
                                             id="15"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fifteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.halbPlants} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.rohPlants &&
                              data.rohPlants.length > 0 &&
                              selectedItems["rohPlants"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "16",
                                             "sixteen"
                                          )}
                                          aria-controls="sixteen"
                                       >
                                          {RMPKeys.rohPlants}{" "}
                                          <i
                                             id="16"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="sixteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.rohPlants} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.pqrViewManufacturer &&
                              data.pqrViewManufacturer.length > 0 &&
                              selectedItems["pqrViewManufacturer"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "17",
                                             "seventeen"
                                          )}
                                          aria-controls="seventeen"
                                       >
                                          {RMPKeys.pqrViewManufacturer}
                                          <i
                                             id="17"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="seventeen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPQRView data={data.pqrViewManufacturer} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.pqrSupplierView &&
                              data.pqrSupplierView.length > 0 &&
                              selectedItems["pqrSupplierView"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "18",
                                             "eighteen"
                                          )}
                                          aria-controls="eighteen"
                                       >
                                          {RMPKeys.pqrSupplierView}
                                          <i
                                             id="18"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="eighteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPQRView data={data.pqrSupplierView}  view="supplier" />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.certifications &&
                              data.certifications.length > 0 &&
                              selectedItems["certifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "19",
                                             "nineteen"
                                          )}
                                          aria-controls="nineteen"
                                       >
                                          {RMPKeys.certifications}{" "}
                                          <i
                                             id="19"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="nineteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomCertifications
                                          data={data.certifications}
                                          header={
                                             "Manufacturer Equivalent Part/Supplier Equivalent Part Certifications"
                                          }
                                          subHeader={"Certifications"}
                                       />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.packagingCertificationsMaster && data.packagingCertificationsMaster.packagingCertifications &&
                              data.packagingCertificationsMaster.packagingCertifications.length > 0 &&
                              selectedItems["packagingCertificationsMaster"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "18",
                                             "Eighteen"
                                          )}
                                          aria-controls="Eighteen"
                                       >
                                          {RMPKeys.packagingCertificationsMaster}{" "}
                                          <i
                                             id="18"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Eighteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.packagingCertificationsMaster.packagingCertifications} 
                                          certHeader = {data.packagingCertificationsMaster.header}/>
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.alternates &&
                              data.alternates.length > 0 &&
                              selectedItems["alternates"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "20", "twenty")}
                                          aria-controls="twenty"
                                       >
                                          {RMPKeys.alternates}{" "}
                                          <i
                                             id="20"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twenty"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.alternates} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedATS &&
                              data.relatedATS.length > 0 &&
                              selectedItems["relatedATS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "21",
                                             "twentyone"
                                          )}
                                          aria-controls="twentyone"
                                       >
                                          {RMPKeys.relatedATS}{" "}
                                          <i
                                             id="21"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyone"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedATS} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.producingFormula &&
                              data.producingFormula.length > 0 &&
                              selectedItems["producingFormula"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "22",
                                             "twentytwo"
                                          )}
                                          aria-controls="twentytwo"
                                       >
                                          {RMPKeys.producingFormula}{" "}
                                          <i
                                             id="22"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentytwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.producingFormula} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.startingMaterial &&
                              data.startingMaterial.length > 0 &&
                              selectedItems["startingMaterial"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "23",
                                             "twentythree"
                                          )}
                                          aria-controls="twentythree"
                                       >
                                          {RMPKeys.startingMaterial}{" "}
                                          <i
                                             id="23"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentythree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.startingMaterial} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.rdsites &&
                              data.rdsites.length > 0 &&
                              selectedItems["rdsites"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "24",
                                             "twentyfour"
                                          )}
                                          aria-controls="twentyfour"
                                       >
                                          {RMPKeys.rdsites}{" "}
                                          <i
                                             id="24"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyfour"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.rdsites} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.lifeCycleApprovalPowerview &&
                              data.lifeCycleApprovalPowerview.length > 0 &&
                              selectedItems["lifeCycleApprovalPowerview"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "25",
                                             "twentyfive"
                                          )}
                                          aria-controls="twentyfive"
                                       >
                                          {RMPKeys.lifeCycleApprovalPowerview}
                                          <i
                                             id="25"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyfive"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomDerivedTable
                                          data={data.lifeCycleApprovalPowerview}
                                          derived="Tasks/Signatures"
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading && data.ownerShip && selectedItems["ownerShip"] && (
                              <div>
                                 <Card>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(this, "26", "twentysix")}
                                       aria-controls="twentysix"
                                    >
                                       {RMPKeys.ownerShip}{" "}
                                       <i
                                          id="26"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="twentysix"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <CommonAttribute data={data.ownerShip} />
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              data.ipClasses &&
                              data.ipClasses.length > 0 &&
                              selectedItems["ipClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "27",
                                             "twentyseven"
                                          )}
                                          aria-controls="twentyseven"
                                       >
                                          {RMPKeys.ipClasses}{" "}
                                          <i
                                             id="27"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyseven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.ipClasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClasses &&
                              data.securityClasses.length > 0 &&
                              selectedItems["securityClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "28",
                                             "twentyeight"
                                          )}
                                          aria-controls="twentyeight"
                                       >
                                          {RMPKeys.securityClasses}{" "}
                                          <i
                                             id="28"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyeight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.securityClasses} />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              data.organizations &&
                              selectedItems["organizations"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "29",
                                             "twentynine"
                                          )}
                                          aria-controls="twentynine"
                                       >
                                          {RMPKeys.organizations}{" "}
                                          <i
                                             id="29"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentynine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.organizations} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.files &&
                              data.files.length > 0 &&
                              selectedItems["files"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "30", "thirty")}
                                          aria-controls="thirty"
                                       >
                                          {RMPKeys.files}{" "}
                                          <i
                                             id="30"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirty"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <GenDocsFileDownTable
                                          data={data.files}
                                          attributes={data.attributes}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.gpsAssessments &&
                              data.gpsAssessments.length > 0 &&
                              selectedItems["gpsAssessments"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "31",
                                             "thirtyone"
                                          )}
                                          aria-controls="thirtyone"
                                       >
                                          {RMPKeys.gpsAssessments}{" "}
                                          <i
                                             id="31"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtyone"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.gpsAssessments} />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {RMPKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                              
                           {selectedItems["message"] && (
                              <p className="errorMsgStyle">{PREF_MSG}</p>
                           )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default RmpAllInfo
