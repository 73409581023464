import React from "react"
import { setNonDispalyValue, tableLinkColumn } from "./EventHandler"

const PlainCertifications = (props) => {
   let data = []
   data = props.data

   const getHeader = (headerData = []) => {
      var keys = getKeys(headerData)
      return keys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getKeys = (keysInformation = []) => {
      return Object.keys(keysInformation)
   }

   const RenderColumn = (props) => {
      let mktData = props.data.split(",")
      return mktData.map((key, index) => {
         if (key != null)
            return (
               <div key={index}>
                  <span>{key}</span>
               </div>
            )
         else return null
      })
   }

   const getRowsData = (tableData) => {
      var items = tableData
      var keys = getKeys(tableData[0])
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const RenderRow = (props) => {
      return props.keys.map((key, index) => {
         if (props.data[key] != null && key.toLowerCase() === "market") {
            return (
               <td key={index}>
                  <RenderColumn data={props.data[key]} />
               </td>
            )
         } else if (
            (key.toLowerCase() === "product part name" ||
               key.toLowerCase() === "product part") &&
            props.data["productLink"] &&
            props.data["productLink"] !== ""
         ) {
            let name = props.data[key]
            return tableLinkColumn(
               props.data["productId"],
               props.data["productLink"],
               name,
               index
            )
            // <td style={{ minWidth: "9rem" }} key={index}>
            //    {props.data["productId"] &&
            //    props.data["productId"].toLowerCase().trim() !== "no access" ? (
            //       <CustomLink
            //          id={props.data["productId"].trim()}
            //          link={props.data["productLink"]}
            //          name={name}
            //       />
            //    ) : (
            //       <span>{name}</span>
            //    )}
            // </td>
         } else if (setNonDispalyValue(key)) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   return (
      <div className="tableFixHead">
         {data && data.length > 0 && (
            <table>
               <thead>
                  <tr>{getHeader(data[0])}</tr>
               </thead>
               <tbody>{getRowsData(data)}</tbody>
            </table>
         )}
      </div>
   )
}
export default PlainCertifications
