import React from "react"
import CustomLink from "./CustomLink"
import { setNonDispalyValue } from "./EventHandler"

const CustomFormulaProcessFormula = (props) => {
   let data = []
   data = props.data
   // const numRows = data.length

   const getHeader = () => {
      var keys = getKeys()
      return keys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getRowsData = () => {
      var items = data
      var keys = getKeys()
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const getKeys = function () {
      return Object.keys(data[0])
   }

   const getColumnKeys = function (columKeyInfo) {
      return Object.keys(columKeyInfo)
   }

   const getSPLinks = function (data, link, id) {
      var elements = data.map((value, index) => {
         var num = index + 1
         if (
            link[index] &&
            link[index].trim() !== "" &&
            id[index] &&
            id[index].trim() !== "" &&
            id[index].toLowerCase().trim() !== "no access"
         ) {
            return (
               <span key={index}>
                  <CustomLink
                     id={id[index].trim()}
                     link={link[index].trim()}
                     name={value}
                  />
                  {num === data.length ? "" : "|"}
               </span>
            )
         } else {
            return (
               <span key={index}>
                  {value}
                  {num === data.length ? "" : "|"}
               </span>
            )
         }
      })
      return elements
   }

   const RenderColumn = function (props) {
      return props.keys.map((key, index) => {
         if (props.data[key] != null) {
            if (
               key.toLowerCase() === "sp" &&
               props.parentKey ===
                  "unitsvalue(uv)certifications(c)substituteparts(sp)state(s)rev(r)"
            ) {
               var nameData = props.data[key] ? props.data[key].split("|") : []
               var nameLink = props.parentData["splink"]
                  ? props.parentData["splink"].split("|")
                  : []
               var nameId = props.parentData["spid"]
                  ? props.parentData["spid"].split("|")
                  : []
               var getnamelinks = getSPLinks(nameData, nameLink, nameId)
               return (
                  <div key={index}>
                     <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span>
                     {getnamelinks}
                  </div>
               )
            } else {
               return (
                  <div key={index}>
                     <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                     <span>{props.data[key]}</span>
                  </div>
               )
            }
         } else return null
      })
   }
   const RenderLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            props.id &&
            props.id.toLowerCase().trim() !== "no access" &&
            (key.toLowerCase() === "n" ||
               key.toLowerCase() === "ppn" ||
               key.toLowerCase() === "sp")
         ) {
            let name = props.data[key]

            return (
               <div key={index} style={{ minWidth: "9rem" }}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span>{" "}
                  <span>
                     {props.link ? (
                        <CustomLink id={props.id} link={props.link} name={name} />
                     ) : (
                        <span>{name}</span>
                     )}
                  </span>
               </div>
            )
         }

         // else if (props.data[key])
         else if (props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }
   const RenderParentLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            props.data["id"] &&
            props.data["id"].toLowerCase().trim() !== "no access" &&
            (key.toLowerCase() === "name")
         ) {
            let name = props.data[key]
            return (
               <div key={index} style={{ minWidth: "9rem" }}>
                  <span>
                     {props.data["link"] ? (
                        <CustomLink
                           id={props.data["id"]}
                           link={props.data["link"]}
                           name={name}
                        />
                     ) : (
                        <span>{name}</span>
                     )}
                  </span>
               </div>
            )
         } else if (props.data[key] && setNonDispalyValue(key)) {
            return null
         }
         // else if (props.data[key])
         else if (props.data[key] != null)
            return (
               <div key={index}>
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const RenderRow = (props) => {
      var colKeys = []
      return props.keys.map((key, index) => {
         if (
            props.data[key] != null &&
            typeof props.data[key] == "object" &&
            key === "Name(N) Type(T)"
         ) {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderLinkColumn
                     isbold={true}
                     id={props.data["id"]}
                     link={props.data["link"]}
                     data={props.data[key]}
                     keys={colKeys}
                  />
               </td>
            )
         }
         if (
            props.data[key] &&
            typeof props.data[key] == "object" &&
            (key === "Parent Name")
         ) {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderParentLinkColumn
                     isbold={true}
                     id={props.data["id"]}
                     link={props.data["link"]}
                     data={props.data[key]}
                     keys={colKeys}
                  />
               </td>
            )
         }
         if (
            props.data[key] != null &&
            typeof props.data[key] == "object" &&
            key.includes("Units Value (UV) Certifications (C) Substitute Parts (SP)")
         ) {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn
                     isbold={true}
                     data={props.data[key]}
                     keys={colKeys}
                     parentData={props.data}
                     parentKey="unitsvalue(uv)certifications(c)substituteparts(sp)state(s)rev(r)"
                  />
               </td>
            )
         }
         if (props.data[key] != null && typeof props.data[key] == "object") {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         } else if (setNonDispalyValue(key)) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   return (
      <div className="tableFixHead">
         {data && data.length > 0 && (
            <table>
               <thead
                  style={{
                     visibility: props.isHeader !== "false" ? "visible" : "collapse",
                  }}
               >
                  <tr>{getHeader()}</tr>
               </thead>
               <tbody>{getRowsData()}</tbody>
            </table>
         )}
      </div>
   )
}
export default CustomFormulaProcessFormula
