import React from "react"
import CustomLink from "./CustomLink"
import { isLinkEnabled, setNonDispalyValue, tableLinkColumn } from "./EventHandler"

const ComSML = (props) => {
   let data = []
   data = props.data
   const parentData = JSON.parse(JSON.stringify(props.parentData))

   const getHeader = (headerData = []) => {
      var keys = getKeys(headerData)
      return keys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getKeys = (keysInformation = []) => {
      return Object.keys(keysInformation)
   }
   const getColumnKeys = (columKeyInfo) => {
      return Object.keys(columKeyInfo)
   }

   const RenderColumn = (props) => {
      return props.keys.map((key, index) => {
         if (props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const RenderLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            props.data["id"] &&
            props.data["id"].toLowerCase().trim() !== "no access" &&
            key.toLowerCase().trim() === "name"
         ) {
            let name = props.data[key]

            return (
               <div key={index} style={{ minWidth: "9rem" }}>
                  <span>
                     {props.data["link"] ? (
                        <CustomLink
                           id={props.data["id"]}
                           link={props.data["link"]}
                           name={name}
                        />
                     ) : (
                        <span>{name}</span>
                     )}
                  </span>
               </div>
            )
         } else if (setNonDispalyValue(key)) {
            return null
         }

         // else if (props.data[key])
         else if (props.data[key] != null)
            return (
               <div key={index}>
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const getRowsData = (tableData) => {
      var items = tableData
      var keys = getKeys(tableData[0])
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const RenderRow = (props) => {
      return props.keys.map((key, index) => {
         if (
            props.data[key] != null &&
            typeof props.data[key] === "object" &&
            isLinkEnabled(key)
         ) {
            let colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderLinkColumn
                     isbold={true}
                     id={props.data["id"]}
                     link={props.data["link"]}
                     data={props.data[key]}
                     keys={colKeys}
                  />
               </td>
            )
         } else if (props.data[key] != null && typeof props.data[key] === "object") {
            let colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn data={props.data[key]} isbold={true} keys={colKeys} />
               </td>
            )
         } else if (
            (key.toLowerCase().trim() === "name" ||
               key.toLowerCase().trim() === "master product part name") &&
            props.data["link"] &&
            props.data["link"] !== ""
         ) {
            let name = props.data[key]
            return tableLinkColumn(
               props.data["id"],
               props.data["link"],
               name,
               index,
               parentData
            )
         } else if (
            (key.toLowerCase().trim() === "product part name" ||
               key.toLowerCase().trim() === "product part") &&
            props.data["productLink"] &&
            props.data["productLink"] !== ""
         ) {
            let name = props.data[key]
            return tableLinkColumn(
               props.data["productId"],
               props.data["productLink"],
               name,
               index
            )
         } else if (setNonDispalyValue(key)) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   return (
      <div className="tableFixHead">
         {data && data.length > 0 && (
            <table>
               <thead>
                  <tr>
                     {props.header && (
                        <th style={{ textAlign: "left" }} colSpan={2}>
                           <span>{props.header}</span>
                        </th>
                     )}
                  </tr>
                  <tr>{getHeader(data[0])}</tr>
               </thead>
               <tbody>{getRowsData(data)}</tbody>
            </table>
         )}
      </div>
   )
}
export default ComSML
