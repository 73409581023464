import React from "react"
import CustomLink from "./CustomLink"
import { setNonDispalyValue, tableLinkColumn } from "./EventHandler"

const CustomBatteryRollUpAndRegisterCtrl = (props) => {
   let data = []
   data = props.data
   //data.push(cond)
   const getHeader = () => {
      let keys = getKeys()
      return keys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getRowsData = () => {
      let items = data
      let keys = getKeys()
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const getKeys = function () {
      return Object.keys(data[0])
   }

   const getColumnKeys = function (columKeyInfo) {
      return Object.keys(columKeyInfo)
   }

   const RenderColumn = function (props) {
      return props.keys.map((key, index) => {
         if (props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }
   const RenderLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            props.data["id"] &&
            props.data["id"].toLowerCase().trim() !== "no access" &&
            (key.toLowerCase() === "n" ||
               key.toLowerCase() === "ppn" ||
               key.toLowerCase() === "sp")
         ) {
            let name = props.data[key]

            return (
               <div key={index} style={{ minWidth: "9rem" }}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span>{" "}
                  <span>
                     {props.data["link"] ? (
                        <CustomLink
                           id={props.data["id"]}
                           link={props.data["link"]}
                           name={name}
                        />
                     ) : (
                        <span>{name}</span>
                     )}
                  </span>
               </div>
            )
         } else if (setNonDispalyValue(key)) {
            return null
         }

         // else if (props.data[key])
         else if (props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const RenderRow = (props) => {
      var colKeys = []
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            typeof props.data[key] == "object" &&
            (key.includes("Name(N)") ||
               key === "Name(N) Type(T)" ||
               key.includes("Product Part Name"))
         ) {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderLinkColumn
                     isbold={true}
                     id={props.data["id"]}
                     link={props.data["link"]}
                     data={props.data[key]}
                     keys={colKeys}
                  />
               </td>
            )
         }

         if (props.data[key] && typeof props.data[key] == "object") {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         } else if (
            key.toLowerCase() === "name" &&
            props.data["link"] &&
            props.data["link"] !== ""
         ) {
            let name = props.data[key]
            return tableLinkColumn(props.data["id"], props.data["link"], name, index)
         } else if (setNonDispalyValue(key)) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   return (
      <div className="tableFixHead">
         {data && data.length > 0 && (
            <table id="tab-separtor">
               <thead
                  style={{
                     visibility: props.isHeader !== "false" ? "visible" : "collapse",
                  }}
               >
                  <tr>{getHeader()}</tr>
               </thead>
               <tbody>{getRowsData()}</tbody>
            </table>
         )}
      </div>
   )
}
export default CustomBatteryRollUpAndRegisterCtrl
