import React, { useMemo, useState } from "react"
import CustomLink from "./CustomLink"
import { setNonDispalyValue, tableLinkColumn } from "./EventHandler"

const CustomPQRView = (props) => {
   let data = []
   data = props.data

   const useSortableData = (items, config = null) => {
      const [sortConfig, setSortConfig] = useState({key:"Name",direction:"ascending"})
      const sortedItems = useMemo(() => {
         let sortableItems = [...items]
         if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
               if (a[sortConfig.key] === b[sortConfig.key]) {
                  if (a["Revision"] < b["Revision"]) {
                     return sortConfig.direction === "ascending" ? -1 : 1
                  }
                  if (a["Revision"] > b["Revision"]) {
                     return sortConfig.direction === "ascending" ? 1 : -1
                  }
                  
                  return 0
               } else {
                  if (a[sortConfig.key] < b[sortConfig.key]) {
                     return sortConfig.direction === "ascending" ? -1 : 1
                  }
                  if (a[sortConfig.key] > b[sortConfig.key]) {
                     return sortConfig.direction === "ascending" ? 1 : -1
                  }
                  
                  return 0
               }
            })
         }
         return sortableItems
      }, [items, sortConfig])

      const requestSort = (key) => {
         let direction = "ascending"
         if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === "ascending"
         ) {
            direction = "descending"
         }
         setSortConfig({ key, direction })
      }
      return { item: sortedItems, requestSort, sortConfig }
   }

   const { item, requestSort, sortConfig } = useSortableData(props.data)

   const getClassNamesFor = (Name) => {
      if (!sortConfig) {
         return "fa fa-sort"
      } else if (sortConfig.key === Name) {
         if (sortConfig.direction === "ascending") {
            return "fa fa-caret-up"
         } else {
            return "fa fa-caret-down"
         }
      } else {
         return "fa fa-sort"
      }
   }

   const getHeader = () => {
      var keys = getKeys()
      return keys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         if (key === 'Name' && props.view !== 'supplier'){
            return (
               <th key={key} >
                  Name&nbsp;
                  <i
                  onClick={() => requestSort("Name")}
                  className={getClassNamesFor("Name")}
                  ></i>
               </th>
            )
         } 
         else
         {
            return (
               <th key={key}>
                  <span>{key}</span>
               </th>
            )
         }
         
      })
   }

   const getRowsData = () => {
      var items = item
      var keys = getKeys()
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const getKeys = function () {
      return Object.keys(data[0])
   }

   const getColumnKeys = function (columKeyInfo) {
      return Object.keys(columKeyInfo)
   }

   const RenderColumn = function (props) {
      return props.keys.map((key, index) => {
         if (props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const RenderChildern = function (props) {
      var colKeys=[]
      colKeys = getColumnKeys(props.data[0])
      return props.data.map((row, index) => {
         return (
            <table key={index}>
               <tbody>
                  <tr>
                     <td>
                     <RenderSubRow key={index} data={row} keys={colKeys} />
                     </td>
                  </tr>
               </tbody>
            </table>
         )
      })
   }
   const RenderSubRow=(props) => {
      return props.keys.map((key,index)=>{
         if (
            props.data[key] &&
            typeof props.data[key] == "object" &&
            key.includes("AN")
         ) {
            return(
               <div key={index}>
                     <span className="bomFontClass">{key}:</span>
                     <span>
                        {  props.data[key]["id"] &&
                           props.data[key]["id"].toLowerCase().trim() !== "no access" &&
                           props.data[key]["link"] ? (
                           <CustomLink
                              id={props.data[key]["id"]}
                              link={props.data[key]["link"]}
                              name={props.data[key]["name"]}
                           />
                           ) : (
                              <span>{props.data[key]["name"]}</span>
                           )
                        }
                     </span>
               </div>
            )
         }
         else{
            return(
               <div key={index}>
                     <span className="bomFontClass">{key}:</span>{" "}
                     <span>{props.data[key]}</span>
               </div>
            )
         }
      })

   }

   const getPCLinks = function (data, link, id) {
      var elements = data.map((value, index) => {
         if (
            link[index] &&
            link[index].trim() !== "" &&
            id[index] &&
            id[index].trim() !== "" &&
            id[index].toLowerCase().trim() !== "no access"
         ) {
            return (
               <React.Fragment key={index}>
                  <span>
                     <CustomLink
                        id={id[index].trim()}
                        link={link[index].trim()}
                        name={value}
                     />
                  </span>
                  <br />
               </React.Fragment>
            )
         } else {
            return (
               <React.Fragment key={index}>
                  <span>{value}</span>
                  <br />
               </React.Fragment>
            )
         }
      })
      return elements
   }
   const RenderLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         if (props.data[key] && key.toLowerCase() === "n") {
            let nameData = props.data[key] ? props.data[key].split("|") : []
            let nameLink = props.data["link"] ? props.data["link"].split("|") : []
            let nameId = props.data["id"] ? props.data["id"].split("|") : []
            let getnamelinks = getPCLinks(nameData, nameLink, nameId)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span>
                  {getnamelinks}
               </div>
            )
         } else if (setNonDispalyValue(key)) {
            return null
         } else if (props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const RenderRow = (props) => {
      var colKeys = []
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            typeof props.data[key] == "object" &&
            key.includes("PQR Qualification Name (N)")
         ) {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderLinkColumn
                     isbold={true}
                     id={props.data["id"]}
                     data={props.data[key]}
                     keys={colKeys}
                  />
               </td>
            )
         } else if (
            key.toLowerCase() === "name" &&
            props.data["id"] &&
            props.data["id"].toLowerCase() !== "no access"
         ) {
            var name = props.data[key]
            return tableLinkColumn(props.data["id"], props.data["link"], name, index)
         }
         else if (
            props.data[key] &&
            typeof props.data[key] == "object" &&
            key.includes("Allowed MEP Name (AN) Allowed MEP Title (AT) Allowed MEP Vendor (AV)")
         ) {
            return (
               <td key={index}>
                  <RenderChildern data={props.data[key]}/>
               </td>
            )
         }
         if (props.data[key] && typeof props.data[key] == "object") {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         } else if (setNonDispalyValue(key)) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   return (
      <div className="tableFixHead">
         {props.data && props.data.length > 0 && (
            <table>
               <thead>
                  <tr>
                     <th style={{backgroundColor:"white"}} colSpan={props.type && props.type == "PMP" ? props.view && props.view ==="supplier" ? 9 : 8 : props.view && props.view ==="supplier" ? 7 : 6 }></th>
                     <th style={{backgroundColor:"white"}}>
                        <span>PQR</span>
                     </th>
                  </tr>
                  <tr>{getHeader()}</tr>
               </thead>
               <tbody>{getRowsData()}</tbody>
            </table>
         )}
      </div>
   )
}
export default CustomPQRView
