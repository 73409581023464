// import { fetchAPIData, notAuthorized, isLoggedIn } from '../../api/AuthService'
import React from "react"
import CustomLink from "./CustomLink"

export const handleClick = (num, num1) => {
   var obj = document.getElementById(num)
   var obj1 = document.getElementById(num1)
   if (obj.className === "fa fa-minus-circle accordIconStyle") {
      obj.className = "fa fa-plus-circle accordIconStyle"
      obj1.style.display = "none"
   } else {
      obj.className = "fa fa-minus-circle accordIconStyle"
      obj1.style.display = "block"
   }
}

export const tableLinkColumn = (linkID, linkUrl, linkName, index, parentData) => {
   return (
      <td style={{ minWidth: "9rem" }} key={index}>
         {linkUrl && linkID && linkID.toLowerCase().trim() !== "no access" ? (
            <CustomLink
               id={linkID.trim()}
               link={linkUrl}
               name={linkName}
               parentData={parentData}
            />
         ) : (
            <span>{linkName}</span>
         )}
      </td>
   )
}
export const setNonDispalyValue = (key) => {
   if (
      key.toLowerCase() === "id" ||
      key.toLowerCase() === "link" ||
      key.toLowerCase() === "productlink" ||
      key.toLowerCase() === "productid" ||
      key.toLowerCase() === "tupid" ||
      key.toLowerCase() === "tuplink" ||
      key.toLowerCase() === "spid" ||
      key.toLowerCase() === "splink" ||
      key.toLowerCase() === "pid" ||
      key.toLowerCase() === "plink" ||
      key.toLowerCase() === "methodlink" ||
      key.toLowerCase() === "methodid" ||
      key.toLowerCase() === "reflink" ||
      key.toLowerCase() === "refid" ||
      key.toLowerCase() === "isfileattached"
   ) {
      return true
   }
}
export const focusOnSection = (num) => {
   var obj = document.getElementById(num)
   var obj1 = document.getElementById(
      document.getElementById(num).parentNode.parentNode.nextSibling.id
   )

   if (obj.className === "fa fa-plus-circle accordIconStyle") {
      obj.className = "fa fa-minus-circle accordIconStyle"
      obj1.style.display = "block"
   }
}

export const checkFilterView = () => {
   var items = document.getElementsByName("UserPrefCheckBox")
   let isFilterView = false
   let counter = 0

   if (items && items.length > 0) {
      for (var i = 0; i < items.length; i++) {
         if (items[i].type === "checkbox" && items[i].checked) {
            isFilterView = true
            counter++
         }
      }
      if (items.length === counter) {
         isFilterView = false
      }
   }

   return isFilterView
}

export const validateDate = (date) => {
   var dateTime = ""

   if (date != null && date !== "") {
      dateTime = date.toLowerCase().trim()

      if (dateTime === "no access") {
         return date
      }
      if (dateTime.endsWith("am")) {
         dateTime = dateTime.replace("am", "")
      }
      if (dateTime.endsWith("pm")) {
         dateTime = dateTime.replace("pm", "")
      }
   }
   return dateTime
}

export const expandCollapseAll = () => {
   var btn = document.getElementById("btnExpandAll")
   var obj = document.getElementsByName("collapseItem")
   var obj1 = document.getElementsByName("accordItem")
   var i
   if (btn.innerText === "Collapse All") {
      for (i = 0; i < obj.length; i++) {
         obj[i].style.display = "none"
         obj1[i].className = "fa fa-plus-circle accordIconStyle"
      }
      btn.innerText = "Expand All"
   } else {
      for (i = 0; i < obj.length; i++) {
         obj[i].style.display = "block"
         obj1[i].className = "fa fa-minus-circle accordIconStyle"
      }
      btn.innerText = "Collapse All"
   }
}

export const mapKeys = (data, obj) => {
   var list = {}
   var listArray = Object.keys(data)

   for (var i = 0; i < listArray.length; i++) {
      if (data[listArray[i]] !== undefined && data[listArray[i]] !== null) {
         if (Array.isArray(data[listArray[i]]) && data[listArray[i]].length <= 0) {
            continue
         } else {
            if (obj[listArray[i]]) list[listArray[i]] = obj[listArray[i]]
         }
      }
   }
   if (
      (data.derivedTo && data.derivedTo.length > 0) ||
      (data.derivedFrom && data.derivedFrom.length > 0)
   ) {
      //list["derivedParts"] = "Derived Parts"
      list["derivedParts"] = obj["derivedParts"]
   }

   return list
}

export const pdfData = (data, obj, isFilterView) => {
   var keys = []
   var values = {}

   keys = Object.keys(obj)

   if (data["headerContent"] && data["headerContent"].Name)
      values["Name"] = data["headerContent"].Name
   if (data["headerContent"] && data["headerContent"].Revision)
      values["Rev"] = data["headerContent"].Revision

   if (obj.derivedParts)
      if (data.derivedTo && data.derivedTo.length > 0) keys.push("derivedTo")
   if (obj.derivedParts)
      if (data.derivedFrom && data.derivedFrom.length > 0) keys.push("derivedFrom")

   if (data.derivedParts === undefined || data.derivedParts === null) {
      keys = keys.filter((arrayItem) => arrayItem !== "derivedParts")
   }

   values["sections"] = keys
   values["isFilterView"] = isFilterView
   return values
}

export const errorHandling = (error, historyObject) => {
   sessionStorage.removeItem("409ErrorMsg")

   if (error.response && error.response.data.status) {
      if (error.response.data.status === 409) {
         sessionStorage.setItem("409ErrorMsg", error.response.data.message)
      }
      historyObject.push(`/serviceNotFound/${error.response.data.status}`)
      return
   } else {
      historyObject.push("/serviceNotFound/500", "FPP")
   }
}

export const removeEmptyFromJSON = (obj) => {
   Object.entries(obj).forEach(
      ([key, val]) =>
         (val && typeof val === "object" && removeEmptyFromJSON(val)) ||
         (val === null && delete obj[key])
      //||(key==="clasificationRequired" && !obj["clasificationRequired"] && delete obj["Classification"])
      //((val === null) && delete obj[key])
   )

   return obj
}

export const addBreadCrumbLinks = (value, link) => {
   var searchItemsNew = {}
   var searchItemsOld = []
   var data
   if (
      sessionStorage.getItem("breadCrumb") != null &&
      sessionStorage.getItem("breadCrumb") !== ""
   ) {
      data = JSON.parse(sessionStorage.getItem("breadCrumb"))
      searchItemsOld = data
   }

   if (searchItemsOld.length > 0) {
      searchItemsNew["name"] = value
      searchItemsNew["link"] = link
      if (searchItemsOld[searchItemsOld.length - 1]["link"] !== link) {
         searchItemsOld.push(searchItemsNew)
         sessionStorage.removeItem("breadCrumb")
         sessionStorage.setItem("breadCrumb", JSON.stringify(searchItemsOld))
      }
   }
}

export const isLinkEnabled = (key) => {
   if (
      key.toLowerCase().trim() === "leg details" ||
      key.toLowerCase().trim() === "supplier info sheet name" ||
      key.toLowerCase().trim() === "stability document" ||
      key.toLowerCase().trim() === "formula number" ||
      key.toLowerCase().trim() === "stability reports" ||
      key.toLowerCase().trim() === "product part" ||
      key.toLowerCase().trim() === "product part name" ||
      key.toLowerCase().trim() === "ingredient statement" ||
      key.toLowerCase().trim() === "gps ingredient statement name" ||
      key.toLowerCase().trim() === "name" ||
      key.toLowerCase().trim() === "master part name" ||
      key.toLowerCase().trim() === "cas#" ||
      key.toLowerCase().trim() === "substitution combination number" ||
      key.toLowerCase().trim() === "substitution name" ||
      key.toLowerCase().trim() === "smart label"

   ) {
      return true
   }
}

export const attributeLinks = (key) => {
   if (
      key.toLowerCase().trim() === "assigned part" ||
      key.toLowerCase().trim() === "customer"
   ) {
      return true
   }
}
