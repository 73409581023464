import React from "react"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

const BreadCrumb = (data) => {
   var props = data.props
   const [items, setItems] = useState([])
   var action = props.history.action
   var pathName = props.history.location.pathname

   useEffect(() => {
      function setBreadCrumb(pathName) {
         var newArray = []
         var searchItemsNew = {}
         var arrItems = []

         if (
            sessionStorage.getItem("breadCrumb") != null &&
            sessionStorage.getItem("breadCrumb") !== ""
         ) {
            arrItems = JSON.parse(sessionStorage.getItem("breadCrumb"))
            var index = arrItems.findIndex((e) => e.link === pathName)
            if (index < 0 && action === "POP") {
               sessionStorage.removeItem("breadCrumb")
               addValueToSessionStorage()
            }
            else if (index < 0 && !pathName.includes("search")) {
               addValueToSessionStorage()
            } else {
               newArray = arrItems.slice()
               //delete element by index
               newArray.splice(index + 1)

               resetSessionStorage()
            }
         } else {
            addValueToSessionStorage()
         }

         function resetSessionStorage() {
            sessionStorage.removeItem("breadCrumb")
            sessionStorage.setItem("breadCrumb", JSON.stringify(newArray))
            setItems(JSON.parse(sessionStorage.getItem("breadCrumb")))
         }

         function addValueToSessionStorage() {
            if (props.name) {
               searchItemsNew["name"] = props.name
               searchItemsNew["link"] = pathName
               arrItems.push(searchItemsNew)
               newArray = arrItems.slice()
               resetSessionStorage()
            }
         }
      }
      setBreadCrumb(pathName)

      return () => {
         return undefined
      }
   }, [pathName, props.name])

   const deleteItems = (e, name) => {
      //find index of element
      var index = items.findIndex((e) => e.name === name)
      //copy array
      var newArray = items.slice()
      //delete element by index
      newArray.splice(index + 1)
      sessionStorage.removeItem("breadCrumb")
      sessionStorage.setItem("breadCrumb", JSON.stringify(newArray))
   }

   return (
      <div className="accordIconStyle">
         {items && items.length > 0 && (
            <ul className="breadcrumb">
               {items.map((item, index) => (
                  <li key={index}>
                     <Link
                        to={item.link}
                        link={item.link}
                        onClick={(e) => deleteItems(e, item.name)}
                     >
                        {item.name}
                     </Link>
                  </li>
               ))}
            </ul>
         )}
      </div>
   )
}

export default BreadCrumb
