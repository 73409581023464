import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { DefaultKeys, FCKeys, FETCH_FC } from "../../constant/Constants"
import Attribute from "../commonComponents/Attributes"
import CommonAttribute from "../commonComponents/CommonAttributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import { CustomMultiBomTable } from "../commonComponents/CustomMultiBomTable"
import { CustomMultiSubstituteTable } from "../commonComponents/CustomMultiSubstituteTable"
import CustomPCTable from "../commonComponents/CustomPCTable"
import CustomTable from "../commonComponents/CustomTable"
import DownloadLinkTable from "../commonComponents/DownloadLinkTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import GenDocDownloadComponent from "../commonComponents/GenDocComponent"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../commonComponents/TopContents"

function FcAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributesObject: {},
      organizationsObject: {},
      finalIngredients: {},
      billOfMaterials: {},
      substitutes: [],
      performanceCharacteristic: [],
      specification: [],
      relatedACS: [],
      relatedIAPS: [],
      referenceDocument: [],
      countryClearance: [],
      plants: [],
      ownershipObject: {},
      ipclasses: [],
      securityClaases: [],
      isATS: [],
      hasATS: [],
      technicalStandardSupersedes: [],
      technicalStandardSupersededBy: [],
      materialProducedFormulation: [],
      whereUsed: null,
   })

   const [loading, setLoading] = useState(false)
   const searchData = props.match.params.objectID
   const [sideNav, setSideNav] = useState("")
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)

      var values = pdfData(data, obj, IsFilterView)

      setPdfDataSet(values)
   }

   useEffect(() => {
      const fetchState = { cancel: false }

      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchFppData(fetchState) {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")
               // setSelectedItems(DefaultKeys)
               var apiData
               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  //Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_FC}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContentObject)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
                  //apiData = JSON.parse(sessionStorage.getItem(searchData))
               }
               if (fetchState.cancel) {
                  return
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContentObject
                     ? apiData.headerContentObject
                     : data.headerContent,
                  //headerContent: null,
                  attributesObject: apiData.attributeObject,
                  organizationsObject: apiData.organizationObject,
                  finalIngredients: apiData.finalIngredients,
                  billOfMaterials: apiData.billOfMaterials,
                  plants: apiData.plants,
                  ownershipObject: apiData.ownershipObject,
                  specification: apiData.specification,
                  performanceCharacteristic: apiData.performanceCharacteristic,
                  countryClearance: apiData.countryClearances,
                  ipclasses: apiData.ipClasses,
                  securityClaases: apiData.securityClasses,
                  substitutes: apiData.substitutes,
                  referenceDocument: apiData.referenceDocument,
                  relatedACS: apiData.relatedACS,
                  relatedIAPS: apiData.relatedIAPS,
                  isATS: apiData.isATS,
                  hasATS: apiData.hasATS,
                  technicalStandardSupersedes: apiData.technicalStandardSupersedes,
                  technicalStandardSupersededBy: apiData.technicalStandardSupersededBy,
                  materialProducedFormulation: apiData.materialProducedFormulation,
                  whereUsed: apiData.whereUsed,
               }))
               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  console.log("Error", ` Not authorie: ${error}`)
                  notAuthorized(props.history)
                  return
               }
               console.log("Error", ` API request failed: ${error}`)
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchFppData(fetchState)
      }

      return () => {
         fetchState.cancel = true
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} css={true} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={FCKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" id="print" className="contentBackground">
                     <br />

                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="9" sm="9">
                              <TopContent
                                 headerContent={data.headerContent}
                                 addionalField={true}
                              />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              {sideNav && data.headerContent.isStructured === "True" ? (
                                 <PdfDownloadComponent pdfDataSet={pdfDataSet} />
                              ) : (
                                 <GenDocDownloadComponent objectID={searchData} />
                              )}
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {selectedItems["attributesObject"] &&
                              data.attributesObject != null && (
                                 <div>
                                    <Card autoFocus>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "0",
                                             "collapseZero"
                                          )}
                                          aria-controls="collapseZero"
                                       >
                                          {FCKeys.attributesObject}{" "}
                                          <i
                                             id="0"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseZero"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <Attribute
                                          loading={loading}
                                          data={data.attributesObject}
                                       />
                                       {!loading && data.isATS && data.isATS.length > 0 && (
                                          <React.Fragment>
                                             <br />
                                             <Card>
                                                <Card.Body
                                                   style={{
                                                      fontWeight: "bold",
                                                      backgroundColor: "#eef1f4",
                                                   }}
                                                >
                                                   IS ATS For
                                                </Card.Body>
                                             </Card>
                                             <div className="accordionDiv">
                                                <CustomTable data={data.isATS} />
                                             </div>
                                          </React.Fragment>
                                       )}
                                       {!loading &&
                                          data.hasATS &&
                                          data.hasATS.length > 0 && (
                                             <React.Fragment>
                                                <br />
                                                <Card>
                                                   <Card.Body
                                                      style={{
                                                         fontWeight: "bold",
                                                         backgroundColor: "#eef1f4",
                                                      }}
                                                   >
                                                      Has ATS:
                                                   </Card.Body>
                                                </Card>
                                                <div className="accordionDiv">
                                                   <CustomTable data={data.hasATS} />
                                                </div>
                                             </React.Fragment>
                                          )}
                                       {!loading &&
                                          data.technicalStandardSupersedes &&
                                          data.technicalStandardSupersedes.length > 0 && (
                                             <React.Fragment>
                                                <br />
                                                <div className="accordionDiv">
                                                   <CustomTable
                                                      data={
                                                         data.technicalStandardSupersedes
                                                      }
                                                   />
                                                </div>
                                             </React.Fragment>
                                          )}
                                       {!loading &&
                                          data.materialProducedFormulation &&
                                          data.materialProducedFormulation.length > 0 && (
                                             <React.Fragment>
                                                <br />
                                                <Card>
                                                   <Card.Body
                                                      style={{
                                                         fontWeight: "bold",
                                                         backgroundColor: "#eef1f4",
                                                      }}
                                                   >
                                                      Material Produced by this
                                                      Formulation:{" "}
                                                   </Card.Body>
                                                </Card>
                                                <div className="accordionDiv">
                                                   <CustomTable
                                                      data={
                                                         data.materialProducedFormulation
                                                      }
                                                   />
                                                </div>
                                             </React.Fragment>
                                          )}
                                       {!loading &&
                                          data.technicalStandardSupersededBy &&
                                          data.technicalStandardSupersededBy.length >
                                             0 && (
                                             <React.Fragment>
                                                <br />
                                                <Card>
                                                   <Card.Body
                                                      style={{
                                                         fontWeight: "bold",
                                                         backgroundColor: "#eef1f4",
                                                      }}
                                                   >
                                                      This Technical Standard is
                                                      superseded by
                                                   </Card.Body>
                                                </Card>
                                                <div className="accordionDiv">
                                                   <CustomTable
                                                      data={
                                                         data.technicalStandardSupersededBy
                                                      }
                                                   />
                                                </div>
                                             </React.Fragment>
                                          )}
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.organizationsObject &&
                              selectedItems["organizationsObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "1",
                                             "collapseOne"
                                          )}
                                          aria-controls="collapseOne"
                                       >
                                          {FCKeys.organizationsObject}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseOne"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.organizationsObject} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.finalIngredients &&
                              selectedItems["finalIngredients"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "2",
                                             "collapseTwo"
                                          )}
                                          aria-controls="collapseTwo"
                                       >
                                          {FCKeys.finalIngredients}{" "}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseTwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       {data.finalIngredients.topHeaderfi && (
                                          <Attribute
                                             data={data.finalIngredients.topHeaderfi}
                                          ></Attribute>
                                       )}
                                       {data.finalIngredients.fi &&
                                          data.finalIngredients.fi.length > 0 && (
                                             <CustomTable
                                                data={data.finalIngredients.fi}
                                             />
                                          )}
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.billOfMaterials &&
                              selectedItems["billOfMaterials"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "3",
                                             "collapseThree"
                                          )}
                                          aria-controls="collapseThree"
                                       >
                                          {FCKeys.billOfMaterials}{" "}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseThree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMultiBomTable data={data.billOfMaterials} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutes &&
                              data.substitutes.length > 0 &&
                              selectedItems["substitutes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "4",
                                             "collapseFour"
                                          )}
                                          aria-controls="collapseFour"
                                       >
                                          {FCKeys.substitutes}{" "}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseFour"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMultiSubstituteTable
                                          data={data.substitutes}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.performanceCharacteristic &&
                              data.performanceCharacteristic.length > 0 &&
                              selectedItems["performanceCharacteristic"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "5",
                                             "collapseFive"
                                          )}
                                          aria-controls="collapseFive"
                                       >
                                          {FCKeys.performanceCharacteristic}{" "}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseFive"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPCTable
                                          data={data.performanceCharacteristic}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.specification &&
                              data.specification.length > 0 &&
                              selectedItems["specification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "6",
                                             "collapseSix"
                                          )}
                                          aria-controls="collapseSix"
                                       >
                                          {FCKeys.specification}{" "}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseSix"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DownloadLinkTable data={data.specification} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedACS &&
                              data.relatedACS.length > 0 &&
                              selectedItems["relatedACS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "7",
                                             "collapseSeven"
                                          )}
                                          aria-controls="collapseSeven"
                                       >
                                          {FCKeys.relatedACS}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseSeven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedACS} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedIAPS &&
                              data.relatedIAPS.length > 0 &&
                              selectedItems["relatedIAPS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "8",
                                             "collapseEight"
                                          )}
                                          aria-controls="collapseEight"
                                       >
                                          {FCKeys.relatedIAPS}{" "}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseEight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedIAPS} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocument &&
                              data.referenceDocument.length > 0 &&
                              selectedItems["referenceDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "9",
                                             "collapseNine"
                                          )}
                                          aria-controls="collapseNine"
                                       >
                                          {FCKeys.referenceDocument}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseNine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComRefDocDownload
                                          data={data.referenceDocument}
                                          attributes={data.attributesObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.countryClearance &&
                              data.countryClearance.length > 0 &&
                              selectedItems["countryClearance"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "10",
                                             "collapseTen"
                                          )}
                                          aria-controls="collapseTen"
                                       >
                                          {FCKeys.countryClearance}{" "}
                                          <i
                                             id="10"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseTen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.countryClearance} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.plants &&
                              data.plants.length > 0 &&
                              selectedItems["plants"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "11",
                                             "collapseElevan"
                                          )}
                                          aria-controls="collapseElevan"
                                       >
                                          {FCKeys.plants}{" "}
                                          <i
                                             id="11"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseElevan"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.plants} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ownershipObject != null &&
                              selectedItems["ownershipObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "12",
                                             "collapseTwelve"
                                          )}
                                          aria-controls="collapseTwelve"
                                       >
                                          {FCKeys.ownershipObject}{" "}
                                          <i
                                             id="12"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseTwelve"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.ownershipObject} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ipclasses &&
                              data.ipclasses.length > 0 &&
                              selectedItems["ipclasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "13",
                                             "collapseThirteen"
                                          )}
                                          aria-controls="collapseThirteen"
                                       >
                                          {FCKeys.ipclasses}{" "}
                                          <i
                                             id="13"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseThirteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.ipclasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClaases &&
                              data.securityClaases.length > 0 &&
                              selectedItems["securityClaases"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "14",
                                             "collapseFourteen"
                                          )}
                                          aria-controls="collapseFourteen"
                                       >
                                          {FCKeys.securityClaases}{" "}
                                          <i
                                             id="14"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseFourteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.securityClaases} />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {FCKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}

                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )

   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default FcAllInfo
