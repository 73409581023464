import React from "react"
import { Card, Table } from "react-bootstrap"
import CustomLink from "../commonComponents/CustomLink"

const IRMRelatedPartsStudyProtocol = (props) => {
   let data = []
   data = props.data
   const getRowsData = (data) => {
      var items = data

      return items.map((row, index) => {
         return (
            <div key={index}>
               <Card>
                  <Card.Body
                     style={{
                        fontWeight: "bold",
                        backgroundColor: "#eef1f4",
                     }}
                  >
                     Leg Details
                  </Card.Body>
               </Card>
               <br />
               <RenderRow data={row} />
               <br />
            </div>
         )
      })
   }

   const RenderRow = (props) => {
      return (
         <React.Fragment>
            <Table striped borderless size="sm" responsive>
               <thead>
                  <tr>
                     {props.header && (
                        <th style={{ textAlign: "left" }} colSpan={2}>
                           <span>{props.header}</span>
                        </th>
                     )}
                  </tr>
                  <tr>
                     <th colSpan="2">
                        <span>Leg Details </span>
                     </th>
                     <th>
                        <span>Type</span>
                     </th>
                     <th>
                        <span>Title</span>
                     </th>
                     <th>
                        <span>Description</span>
                     </th>
                     <th colSpan="2">
                        <span>Total Number of Panelist in Leg</span>
                     </th>
                     <th colSpan="4">
                        <span>Number of Product Codes Panelist will recieve</span>
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <Legone data={props.data} />
               </tbody>

               <thead>
                  <tr>
                     <th>
                        <span>Part </span>{" "}
                     </th>
                     <th>
                        <span>Revision </span>{" "}
                     </th>
                     <th>
                        <span>State </span>{" "}
                     </th>
                     <th>
                        <span>Phase </span>{" "}
                     </th>
                     <th>
                        <span>Product Code</span>{" "}
                     </th>
                     <th>
                        <span>Comments</span>{" "}
                     </th>
                     <th>
                        <span>Site for Product Manufacturing</span>{" "}
                     </th>
                     <th>
                        <span>
                           Site if “Other” (Mandatory When Site for Product Manufacturing
                           is set to Other)
                        </span>{" "}
                     </th>
                     <th>
                        <span>Usage/Frequency same as current market product?</span>{" "}
                     </th>
                     <th>
                        <span>
                           Usage/Frequency of Test Product if No see details entered or file
                           attached
                        </span>{" "}
                     </th>
                     <th>
                        <span>Number of Units per Panelist</span>{" "}
                     </th>
                     <th>
                        <span>Packing Site</span>{" "}
                     </th>
                     <th>
                        <span>Washout Time between Products</span>{" "}
                     </th>
                     <th>
                        <span>
                           Select all entries where an EBP will be utilized in the
                           execution of the Study
                        </span>{" "}
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <Legtwo data={props.data} />
               </tbody>

               <thead>
                  <tr>
                     <th>
                        <span>Part </span>{" "}
                     </th>
                     <th colSpan="2">
                        <span>
                           Provide specific Micro Information based on BU work process
                        </span>{" "}
                     </th>
                     <th>
                        <span># of Panelist Completes</span>{" "}
                     </th>
                     <th>
                        <span># of Panelist Placements</span>{" "}
                     </th>
                     <th>
                        <span># Units to Label</span>{" "}
                     </th>
                     <th>
                        <span>Label Description (Attached if needed)</span>{" "}
                     </th>
                     <th>
                        <span>Is Product repackaged?</span>{" "}
                     </th>
                     <th>
                        <span>Unit Weight or Volume</span>{" "}
                     </th>
                     <th>
                        <span>Stability Sample Count</span>{" "}
                     </th>
                     <th>
                        <span>Analytical Sample Count</span>{" "}
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <Legthree data={props.data} />
               </tbody>

               <thead>
                  <tr>
                     <th>
                        <span>Part </span>{" "}
                     </th>
                     <th colSpan="2">
                        <span>R&D Team Sample Count</span>{" "}
                     </th>
                     <th>
                        <span>R&D Retains</span>{" "}
                     </th>
                     <th colSpan="2">
                        <span>Number of Units to Ship</span>{" "}
                     </th>
                     <th>
                        <span>Net Sample Count</span>{" "}
                     </th>
                     <th colSpan="4">
                        <span>Batch /Manufacturer Lot #</span>{" "}
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <Legfour data={props.data} />
               </tbody>
            </Table>
         </React.Fragment>
      )
   }

   const Legone = (props) => {
      return props.data.map((name, index) => {
         if (name.Type === "Leg") {
            return (
               <tr key={index}>
                  <td colSpan="2">
                     {name["Leg Details"].link &&
                     name["Leg Details"].id &&
                     name["Leg Details"].id.toLowerCase().trim() !== "no access" ? (
                        <CustomLink
                           id={name["Leg Details"].id}
                           link={name["Leg Details"].link}
                           name={name["Leg Details"].name}
                        />
                     ) : (
                        <span>{name["Leg Details"].name}</span>
                     )}
                  </td>
                  <td>{name["Type"]}</td>
                  <td>{name["Title"]}</td>
                  <td>{name["Description"]}</td>
                  <td colSpan="2">{name["Total Number of Panelists in Leg"]}</td>
                  <td colSpan="4">
                     {name["Number of Product Codes Panelist will receive"]}
                  </td>
               </tr>
            )
         } else return null
      })
   }

   const Legtwo = (props) => {
      return props.data.map((name, index) => {
         if (name.Type !== "Leg") {
            return (
               <tr key={index}>
                  <td>
                     {name["Leg Details"].link &&
                     name["Leg Details"].id &&
                     name["Leg Details"].id.toLowerCase().trim() !== "no access" ? (
                        <CustomLink
                           id={name["Leg Details"].id}
                           link={name["Leg Details"].link}
                           name={name["Leg Details"].name}
                        />
                     ) : (
                        <span>{name["Leg Details"].name}</span>
                     )}
                  </td>
                  <td>{name["Revision"]}</td>
                  <td>{name["State"]}</td>
                  <td>{name["Phase"]}</td>
                  <td>{name["Product Code"]}</td>
                  <td>{name["Comments"]}</td>
                  <td>{name["Site for Product Manufacturing"]}</td>
                  <td>{name["Site if Other"]}</td>
                  <td>{name["Usage/Frequency same as current market product?"]}</td>
                  <td>
                     {
                        name[
                           "Usage/Frequency of Test Product, if No enter details or attach file"
                        ]
                     }
                  </td>
                  <td>{name["Number of Units per Panelist"]}</td>
                  <td>{name["Packing Site"]}</td>
                  <td>{name["Washout Time between Products"]}</td>
                  <td>
                     {
                        name[
                           "Select all entries where an EBP will be utilized in the execution of the Study"
                        ]
                     }
                  </td>
               </tr>
            )
         } else return null
      })
   }

   const Legthree = (props) => {
      return props.data.map((name, index) => {
         if (name.Type !== "Leg") {
            return (
               <tr key={index}>
                  <td>
                     {name["Leg Details"].link &&
                     name["Leg Details"].id &&
                     name["Leg Details"].id.toLowerCase().trim() !== "no access" ? (
                        <CustomLink
                           id={name["Leg Details"].id}
                           link={name["Leg Details"].link}
                           name={name["Leg Details"].name}
                        />
                     ) : (
                        <span>{name["Leg Details"].name}</span>
                     )}
                  </td>
                  <td colSpan="2">
                     {name["Provide specific Micro Information based on BU work process"]}
                  </td>
                  <td>{name["# of Panelist Completes"]}</td>
                  <td>{name["# of Panelist Placements"]}</td>
                  <td>{name["# Units to Label"]}</td>
                  <td>{name["Label Description (attach if needed)"]}</td>
                  <td>{name["Is Product repackaged?"]}</td>
                  <td>{name["Unit Weight or Volume"]}</td>
                  <td>{name["Stability Sample Count"]}</td>
                  <td>{name["Analytical Sample Count"]}</td>
               </tr>
            )
         } else return null
      })
   }

   const Legfour = (props) => {
      return props.data.map((name, index) => {
         if (name.Type !== "Leg") {
            return (
               <tr key={index}>
                  <td>
                     {name["Leg Details"].link &&
                     name["Leg Details"].id &&
                     name["Leg Details"].id.toLowerCase().trim() !== "no access" ? (
                        <CustomLink
                           id={name["Leg Details"].id}
                           link={name["Leg Details"].link}
                           name={name["Leg Details"].name}
                        />
                     ) : (
                        <span>{name["Leg Details"].name}</span>
                     )}
                  </td>
                  <td colSpan="2">{name["R&D Team Sample Count"]}</td>
                  <td>{name["R&D Retains"]}</td>
                  <td colSpan="2">{name["Number of Units to Ship"]}</td>
                  <td>{name["Net Sample Count"]}</td>
                  <td colSpan="4">{name["Batch Manufacturer Lot #"]}</td>
               </tr>
            )
         } else return null
      })
   }
   return <div>{data && getRowsData(data)}</div>
}
export default IRMRelatedPartsStudyProtocol
