import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { removeEmptyFromJSON } from "../commonComponents/EventHandler"

const CommonAttributes = (props) => {
   const [data, setData] = useState({})
   const col = props.col ? parseInt(props.col) : 2

   useEffect(() => {
      if (props.data) {
         setData(removeEmptyFromJSON(props.data))
      }
      return () => {
         return undefined
      }
   }, [props.data])

   const attributeContent = () => {
      let newKeys = []
      let keys = []
      let preValue = 0
      let rowContainer = []
      let colContainer = []
      let contenContainer = []

      keys = Object.keys(data)

      for (let i = 0; i <= Math.ceil(keys.length - 1); ) {
         preValue = i
         i = i + col
         newKeys = keys.slice(preValue, i)
         rowContainer.push(<Row key={i}>{colDetails(newKeys, colContainer)}</Row>)
         contenContainer.push(rowContainer)
         rowContainer = []
         colContainer = []
      }
      return contenContainer
   }

   function colDetails(newKeys, colContainer) {
      newKeys.forEach(function (key) {
         if (data[key] != null && typeof data[key] == "object") {
            colContainer.push(
               <Col key={key}>
                  <div className="row" style={{ paddingLeft: "15px" }}>
                     <div>
                        <span id="pageheaders">{key}:</span>
                     </div>
                     <div>{arrayContent(data[key])}</div>
                  </div>
               </Col>
            )
         } else {
            if (props.type && key.trim() === "Master Part Name") {
               return null
            } else if (key.toLowerCase().trim() === "irmstaticstatement") {
               colContainer.push(
                  <Col style={{ flexGrow: props.isSpace ? 0.3 : 1 }} key={key}>
                     {data[key]}
                  </Col>
               )
            } else {
               colContainer.push(
                  <Col style={{ flexGrow: props.isSpace ? 0.3 : 1 }} key={key}>
                     <span id="pageheaders">{key}:</span> {data[key]}
                  </Col>
               )
            }
         }
      })
      return colContainer
   }
   const arrayContent = (arrayData) => {
      return arrayData.map((co, index) => {
         return <div key={index}>{co}</div>
      })
   }
   return (
      <React.Fragment>
         {data && (
            <React.Fragment>
               {props.header && (
                  <Col md="12" sm="12" style={{ textAlign: "center" }}>
                     <span id="pageheaders">{props.header}</span>
                     <br />
                  </Col>
               )}
               {attributeContent()}
            </React.Fragment>
         )}
      </React.Fragment>
   )
}

export default CommonAttributes
