import React from "react"
import { Alert, Col, Row } from "react-bootstrap"

const TopContentForSpecs = (props) => {
   const prop = props.headerContent
   if (props.loading) {
      return (
         <h3 style={{ marginLeft: "30rem", color: "#0640a4" }}>
            <i className="fa fa-spinner fa-spin" style={{ marginRight: "5px" }} />
            Loading........
         </h3>
      )
   }
   return (
      <div>
         <React.Fragment>
            <Row>
               <Col md="3" sm="3">
                  <span id="pageheaders">Name: </span>
                  {prop.Name}
               </Col>
               {prop["Revision"] !== undefined && prop["Revision"] != null && (
                  <Col md="3" sm="3">
                     <span id="pageheaders">Revision: </span>
                     {prop.Revision}
                  </Col>
               )}
               <Col md="3" sm="3">
                  <span id="pageheaders">State: </span>
                  {prop.State}
               </Col>
               {prop["Has ATS"] !== undefined && prop["Has ATS"] != null && (
                  <Col md="3" sm="3">
                     <span id="pageheaders">Has ATS: </span>
                     {prop["Has ATS"]}
                  </Col>
               )}
            </Row>
            <Row>
               {prop["Has ATS in BOM"] !== undefined && prop["Has ATS in BOM"] != null && (
                  <Col md="3" sm="3" align="right">
                     <span id="pageheaders">Has ATS in BOM: </span>
                     {prop["Has ATS in BOM"]}
                  </Col>
               )}
               {prop["SAP Type"] !== undefined && prop["SAP Type"] != null && (
                  <Col md="3" sm="3">
                     <span id="pageheaders">SAP Type: </span>
                     {prop["SAP Type"]}
                  </Col>
               )}
               {prop["Copy List Name"] !== undefined && prop["Copy List Name"] != null && (
                  <Col md="3" sm="3">
                     <span id="pageheaders">Copy List Name: </span>
                     {prop["Copy List Name"]}
                  </Col>
               )}
               {prop["Smart Label Name"] !== undefined &&
                  prop["Smart Label Name"] != null && (
                     <Col md="3" sm="3">
                        <span id="pageheaders">Smart Label Name: </span>
                        {prop["Smart Label Name"]}
                     </Col>
                  )}
            </Row>
            <br />
            {prop.error && (
               <Row>
                  <Col style={{ marginTop: "2px", textAlign: "center" }}>
                     <Alert
                        style={{
                           padding: "0px",
                           fontWeight: "bold",
                           fontSize: "13px",
                           color: "red",
                           margin: "0px",
                        }}
                     >
                        {prop.error}
                     </Alert>
                  </Col>
               </Row>
            )}
         </React.Fragment>
      </div>
   )
}

export default TopContentForSpecs
