import React from "react"
import { Table } from "react-bootstrap"
import CustomLink from "../commonComponents/CustomLink"
import { setNonDispalyValue } from "../commonComponents/EventHandler"

export const CustomFppPackingUnit = (props) => {
   let items = []
   items = props.packingUnit
   const table_Contents = () => {
      return (
         <React.Fragment>
            <Table borderless size="sm" responsive>
               <PackingUnit data={items} />
            </Table>
         </React.Fragment>
      )
   }

   const getKeys = (keysInformation = []) => {
      return Object.keys(keysInformation)
   }

   const HeaderRow1 = (props) => {
      let topKeys = getKeys(props.data[0])
      return topKeys.map((key) => {
         if (setNonDispalyValue(key)) {
            return null
         } else if (key.toLowerCase().trim() === "dimensions - od with bulge") {
            return (
               <th key={key} colSpan="3">
                  <span>{key}</span>
               </th>
            )
         } else {
            return (
               <th rowSpan={props.span} key={key}>
                  <span>{key}</span>
               </th>
            )
         }
      })
   }

   const HeaderRow2 = (props) => {
      let bottomKeys = getKeys(props.data)
      return bottomKeys.map((key) => {
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const RowData = (props) => {
      let items = props.data
      let keys = getKeys(props.data[0])
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const RenderLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            props.data["id"] &&
            key.toLowerCase() === "name"
         ) {
            let name = props.data[key]

            return (
               <div key={index} style={{ minWidth: "9rem" }}>
                  <span>
                     {props.data["link"] && props.data["id"].toLowerCase().trim() !== "no access" ? (
                        <CustomLink
                           id={props.data["id"]}
                           link={props.data["link"]}
                           name={name}
                        />
                     ) : (
                        <span>{name}</span>
                     )}
                  </span>
               </div>
            )
         } else if (props.data[key] && setNonDispalyValue(key)) {
            return null
         }
      })
   }

   const RenderRow = (props) => {
      return props.keys.map((key, index) => {
         if (
            props.data[key] != null &&
            typeof props.data[key] === "object" &&
            key.toLowerCase().trim() === "dimensions - od with bulge"
         ) {
            let colKeys = getColumnKeys(props.data[key])
            return colKeys.map((row, index) => {
               return <td key={index}>{props.data[key][row]}</td>
            })
         } else if (
            props.data[key] != null &&
            typeof props.data[key] === "object" &&
            key.toLowerCase().trim() === "packing unit type"
         ) {
            let colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderLinkColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   const PackingUnit = (props) => {
      let data = []
      data = props.data
      return (
         <React.Fragment>
            {data && data.length > 0 && (
               <React.Fragment>
                  <thead>
                     <tr>
                        <HeaderRow1 data={data} span={2} />
                     </tr>
                     <tr>
                        <HeaderRow2 data={data[0]["Dimensions - OD with Bulge"]} />
                     </tr>
                  </thead>
                  <tbody>
                     <RowData data={data} />
                  </tbody>
               </React.Fragment>
            )}
         </React.Fragment>
      )
   }

   const getColumnKeys = (columKeyInfo) => {
      return Object.keys(columKeyInfo)
   }
   return <div>{items && table_Contents()}</div>
}
export default CustomFppPackingUnit
