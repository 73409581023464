import React from "react"
import CustomLink from "./CustomLink"
import { setNonDispalyValue } from "./EventHandler"

const CustomPCTable = (props) => {
   let data = []
   data = props.data

   const getHeader = () => {
      var keys = getKeys()
      return keys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getRowsData = () => {
      var items = data
      var keys = getKeys()
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const getKeys = function () {
      return Object.keys(data[0])
   }

   const getColumnKeys = function (columKeyInfo) {
      return Object.keys(columKeyInfo)
   }

   const getPCLinks = function (data, link, id) {
      var elements = data.map((value, index) => {
         var num = index + 1
         if (
            link[index] &&
            link[index].trim() !== "" &&
            id[index] &&
            id[index].trim() !== "" &&
            id[index].toLowerCase().trim() !== "no access"
         ) {
            return (
               <span key={index}>
                  <CustomLink
                     id={id[index].trim()}
                     link={link[index].trim()}
                     name={value}
                  />
                  {num === data.length ? "" : "|"}
               </span>
            )
         } else {
            return (
               <span key={index}>
                  {value}
                  {num === data.length ? "" : "|"}
               </span>
            )
         }
      })
      return elements
   }

   const RenderColumn = function (props) {
      return props.keys.map((key, index) => {
         // if (props.data[key] !== "" && props.data[key] != null) {
         if (props.data[key] != null) {
            if (setNonDispalyValue(key)) {
               return null
            } else if (
               key.toLowerCase().trim() === "p" &&
               props.data["id"] &&
               props.data["id"].toLowerCase().trim() !== "no access"
            ) {
               let name = props.data[key]

               return (
                  <div key={index} style={{ minWidth: "9rem" }}>
                     <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                     <span>
                        {props.data["link"] ? (
                           <CustomLink
                              id={props.data["id"]}
                              link={props.data["link"]}
                              name={name}
                           />
                        ) : (
                           <span>{name}</span>
                        )}
                     </span>
                  </div>
               )
            } else if (key.toLowerCase() === "name") {
               let nameData = props.data[key] ? props.data[key].split("|") : []
               let nameLink = props.data["methodLink"]
                  ? props.data["methodLink"].split("|")
                  : []
               let nameId = props.data["methodId"]
                  ? props.data["methodId"].split("|")
                  : []
               let getnamelinks = getPCLinks(nameData, nameLink, nameId)
               return (
                  <div key={index}>
                     <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span>
                     {getnamelinks}
                  </div>
               )
            } else if (
               typeof props.data[key] == "object" &&
               key &&
               key.toLowerCase() === "cps"
            ) {
               let names = props.data[key]["name"]
                  ? props.data[key]["name"].split("|")
                  : []
               let links = props.data[key]["link"]
                  ? props.data[key]["link"].split("|")
                  : []
               let ids = props.data[key]["id"] ? props.data[key]["id"].split("|") : []
               let getLinks = getPCLinks(names, links, ids)
               return (
                  <div key={index}>
                     <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span>
                     {getLinks}
                  </div>
               )
            } else if (
               key.toLowerCase() === "ref" ||
               key.toLowerCase().includes("tmrdn")
            ) {
               var refData = props.data[key] ? props.data[key].split("|") : []
               var refLink = props.data["refLink"] ? props.data["refLink"].split("|") : []
               var refId = props.data["refId"] ? props.data["refId"].split("|") : []
               var getlinks = getPCLinks(refData, refLink, refId)
               return (
                  <div key={index}>
                     <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span>
                     {getlinks}
                  </div>
               )
            }else if(
               props.data[key] != null &&
               key.toLowerCase() === "cmn"
            ){
               var name = props.data[key]["name"] ? props.data[key]["name"].split("|") : []
               var link = props.data[key]["link"] ? props.data[key]["link"].split("|") : []
               var id = props.data[key]["id"] ? props.data[key]["id"].split("|") : []
               var getlinks = getPCLinks(name, link, id)
               return (
                  <div key={index}>
                     <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span>
                     {getlinks}
                  </div>
               )
            }

            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span className="lineBreak">{props.data[key]}</span>
               </div>
            )
         } else return null
      })
   }

   const RenderRow = (props) => {
      var colKeys = []
      return props.keys.map((key, index) => {
         if (props.data[key] != null && typeof props.data[key] == "object") {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         } else if (setNonDispalyValue(key)) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   return (
      <div className="performanceTableFixHead">
         {data && data.length > 0 && (
            <table id="tab-separtor"> 
               <thead>
                  <tr>{getHeader()}</tr>
               </thead>
               <tbody>{getRowsData()}</tbody>
            </table>
         )}
      </div>
   )
}
export default CustomPCTable
