import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { DefaultKeys, FETCH_PQR, PQRKeys } from "../../constant/Constants"
import Attribute from "../commonComponents/Attributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import CustomTable from "../commonComponents/CustomTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import SideNav from "../commonComponents/SideNav"

function PqrAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributesObject: {},
      referenceDocument: [],
      qualified: [],
      plants: [],
      whereUsed: null,
   })

   const [loading, setLoading] = useState(true)
   const searchData = props.match.params.objectID
   const [sideNav, setSideNav] = useState()
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)
      var values = pdfData(data, obj, IsFilterView)
      setPdfDataSet(values)
   }

   useEffect(() => {
      const fetchState = { cancel: false }
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchApmpData(fetchState) {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")

               var apiData

               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  // Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_PQR}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContentObject)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
               }
               if (fetchState.cancel) {
                  return
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContentObject
                     ? apiData.headerContentObject
                     : data.headerContent,
                  attributesObject: apiData.attributesObject,
                  plants: apiData.plants,
                  referenceDocument: apiData.referenceDocument,
                  qualified: apiData.qualified,
                  whereUsed: apiData.whereUsed,
               }))

               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  console.log("Error", ` Not authorie: ${error}`)
                  notAuthorized(props.history)
                  return
               }
               console.log("Error", ` API request failed: ${error}`)
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchApmpData(fetchState)
      }

      return () => {
         fetchState.cancel = true
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={PQRKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" className="contentBackground">
                     <br />
                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="4" sm="4">
                              {/* <span style={{ marginLeft: "1rem" }} id="pageheaders">
                                 Name:{" "}
                              </span>
                              {data.headerContent.Name} */}
                           </Col>
                           <Col md="3" sm="3">
                              {/* <span id="pageheaders">Revision: </span>
                              {data.headerContent.Revision} */}
                           </Col>
                           <Col md="5" sm="5" align="right">
                              <PdfDownloadComponent
                                 pdfDataSet={pdfDataSet}
                                 pdfHide={true}
                                 genDocHide={true}
                              />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {selectedItems["attributesObject"] &&
                              data.attributesObject != null && (
                                 <div>
                                    <Card autoFocus>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "0", "zero")}
                                          aria-controls="zero"
                                       >
                                          {PQRKeys.attributesObject}{" "}
                                          <i
                                             id="0"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="zero"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <Attribute
                                          loading={loading}
                                          data={data.attributesObject}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocument &&
                              data.referenceDocument.length > 0 &&
                              selectedItems["referenceDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "1", "one")}
                                          aria-controls="one"
                                       >
                                          {PQRKeys.referenceDocument}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="one"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComRefDocDownload
                                          data={data.referenceDocument}
                                          attributes={data.attributesObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.qualified &&
                              data.qualified.length > 0 &&
                              selectedItems["qualified"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "2", "two")}
                                          aria-controls="two"
                                       >
                                          {PQRKeys.qualified}{" "}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="two"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.qualified} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.plants &&
                              data.plants.length > 0 &&
                              selectedItems["plants"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "3", "three")}
                                          aria-controls="three"
                                       >
                                          {PQRKeys.plants}{" "}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="three"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.plants} />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {PQRKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default PqrAllInfo
