import React, { useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { isLoggedIn, notAuthorized } from "../../../api/AuthService"
import ReportSubmit from "./ReportSubmit"
import textValidation from "./textValidation"

function ATSReport(props) {
   const [data, setData] = useState({
      reportName: props.reportName,
      latestReleseInput: true,
      partName: "",
      reportCustomName: "",
      template: "",
      includeChecked: false,
      fileData: "",
      changeAction: ""
   })
   const [message, setMessage] = useState("")
   const [success, setSuccess] = useState(false)
   const [blinkSearch, setBlinkSearch] = useState("reportOptions")

   const handleIncludeChange = (e) => {
      setData((data) => ({
         ...data,
         includeChecked: !data.includeChecked,
      }))
   }

   const handleValidation = (code, msg) => {
      if (code === 999) {
         setSuccess(false)
         setMessage(
            "Please enter Part Names OR upload an Input File to request a report."
         )
         setBlinkSearch("highlightFields")
      } else if (code === 200) {
         setSuccess(true)
         setMessage(
            "Your Report will be processed in the background and will complete shortly. Please refresh the screen after few minutes"
         )
      } else if (code === 422 || code === 429 || code === 417 || code === 501) {
         setSuccess(false)
         setMessage(msg)
      } else if (code === 401) {
         notAuthorized(props.history)
      } else if (code === 423) {
         setSuccess(false)
         setMessage(
            "The maximum number of In process report requests (5) has been reached. Please submit another report later."
         )
      } else {
         setSuccess(false)
         setMessage("Oops something went wrong. Please contact Support.")
      }
   }

   const clearMsg = () => {
      setMessage("")
   }

   const handleClearItems = () => {
      document.getElementById("view").value = "0"
      props.clearItem()
   }

   const handleInputChange = (e) => {
      e.preventDefault()
      setBlinkSearch("reportOptions")
      setMessage("")
      const textVal = textValidation(e.target.value, e.target.name)
      if (textVal.props.children || e.target.value === "") {
         const { name, value } = e.target
         setData((data) => ({
            ...data,
            [name]: value,
         }))
      }
   }

   const fileValidation = (e) => {
      e.preventDefault()
      const filePath = e.target.value
      // Allowing file type
      var allowedExtensions = /(\.txt)$/i

      if (!allowedExtensions.exec(filePath)) {
         alert("Invalid file type. Please upload a text file.")
         e.target.value = ""
         setData((data) => ({
            ...data,
            fileData: "",
         }))
         return false
      }
      const reader = new FileReader()
      reader.onload = async (e) => {
         const text = e.target.result
         const removeNewLine = text.replace(/[\n]+/g, ",")
         const formatedText = removeNewLine.replace(/[\t]/g, " ")
         const textOnly = formatedText.replace(/[^a-zA-Z0-9 ,-]/g, "")
         setData((data) => ({
            ...data,
            fileData: textOnly
         }))
      }
      reader.readAsText(e.target.files[0])
   }

   const handleClearFile =(e) =>{
      e.preventDefault()
      var file = document.getElementById('file')
      file.value=''
      setData((data) => ({
         ...data,
         fileData: ''
      }))
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/")
      } else {
         setData((data) => ({
            ...data,
            partName: props.templateData.partName ? props.templateData.partName : "",
            reportCustomName: props.templateData.reportCustomName ? props.templateData.reportCustomName : "",
            includeChecked: props.templateData.includeHyperlink ? props.templateData.includeHyperlink : false,
            reportName: props.reportName ? props.reportName : "",
         }))
      }
   }, [props.templateData])

   return (
      <React.Fragment>
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Use Latest Released Input Part
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check type="checkbox" checked={true} disabled />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Include Hyperlink
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  value={data.includeChecked}
                  checked={data.includeChecked}
                  onChange={handleIncludeChange}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="11" md="11" className="reportLabels">
               Please enter Part Name below OR upload a .txt file containing Part Name.
            </Form.Label>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="5" md="5" className="reportLabels" id="impInput">
               Part Name
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Control
                  as="textarea"
                  name="partName"
                  value={data.partName}
                  rows={3}
                  onChange={handleInputChange}
                  className={`${blinkSearch}`}
                  style={{ minHeight: "3rem" }}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="5" md="5" className="reportLabels" id="impInput">
               Input File(ANSI .txt files only)
            </Form.Label>
            <Col sm="6" md="6">
               <input type="file" id="file" onChange={fileValidation} />
               <Button variant="primary" onClick={handleClearFile}>
                  Clear
               </Button>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Report Custom Name
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Control
                  name="reportCustomName"
                  value={data.reportCustomName}
                  onChange={handleInputChange}
                  className="reportOptions"
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Save as Template (New Template will be created)
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Control
                  name="template"
                  value={data.template}
                  onChange={handleInputChange}
                  className="reportOptions"
               />
            </Col>
         </Form.Group>
         <Form.Row>
            <Col md="11" sm="11" align="center">
               <p style={{ marginTop: "4px", fontWeight: "bold" }} className={(success ? 'successMsg' : 'alertMsg')}>
                  {message}
               </p>
            </Col>
         </Form.Row>
         <br />
         <ReportSubmit
            data={data}
            handleClearItems={handleClearItems}
            handler={handleValidation}
            clearMsg={clearMsg}
         />
         <br />
      </React.Fragment>
   )
}
export default ATSReport
