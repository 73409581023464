import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { attributeLinks } from "../commonComponents/EventHandler"
import CustomLink from "./CustomLink"

const SingleColumnAttributes = (props) => {
   const data = props.data
   if (props.loading) {
      return (
         <h3 style={{ textAlign: "center", color: "#0640a4" }}>
            <i className="fa fa-spinner fa-spin" style={{ marginRight: "5px" }} />
            Loading........
         </h3>
      )
   }

   const attributeContent = () => {
      let newKeys = []
      let keys = []
      let preValue = 0
      let rowContainer = []
      let colContainer = []
      let contenContainer = []

      keys = Object.keys(data)

      for (let i = 0; i <= Math.ceil(keys.length - 1); ) {
         preValue = i
         i = i + 1
         newKeys = keys.slice(preValue, i)
         rowContainer.push(<Row key={i}>{colDetails(newKeys, colContainer)}</Row>)
         contenContainer.push(rowContainer)
         rowContainer = []
         colContainer = []
      }
      return contenContainer
   }

   function colDetails(newKeys, colContainer) {
      newKeys.forEach(function (key) {
         if (data[key] != null && typeof data[key] == "object" && attributeLinks(key)) {
            colContainer.push(
               <Col key={key}>
                  <span id="pageheaders">{key}</span>
                  {key !== "" ? ": " : ""}
                  {data[key]["link"] &&
                  data[key]["link"] !== "" &&
                  data[key]["id"] &&
                  data[key]["id"].toLowerCase().trim() !== "no access" ? (
                     <CustomLink
                        id={data[key]["id"]}
                        link={data[key]["link"]}
                        name={data[key]["name"]}
                     />
                  ) : (
                     <span>{data[key]["name"]}</span>
                  )}
               </Col>
            )
         } else if (data[key] != null && typeof data[key] == "object") {
            colContainer.push(
               <Col key={key}>
                  <div className="row">
                     <div className="col-md-2">
                        <span id="pageheaders">{key}:</span>
                     </div>
                     <div className="col-md-4">{arrayContent(data[key])}</div>
                  </div>
               </Col>
            )
         } else {
            colContainer.push(
               <Col key={key}>
                  <span id="pageheaders">{key}:</span> {data[key]}
               </Col>
            )
         }
      })
      return colContainer
   }
   const arrayContent = (arrayData) => {
      return arrayData.map((co, index) => {
         return <div key={index}>{co}</div>
      })
   }
   return <Container>{data && attributeContent()}</Container>
}

export default SingleColumnAttributes
