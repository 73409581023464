import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { DefaultKeys, FETCH_MCOP, MCOPKeys } from "../../constant/Constants"
import Attribute from "../commonComponents/Attributes"
import CommonAttribute from "../commonComponents/CommonAttributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import CustomBOMTable from "../commonComponents/CustomBOMTable"
import CustomMasterSpecification from "../commonComponents/CustomMasterSpecification"
import CustomPCTable from "../commonComponents/CustomPCTable"
import CustomSubstitute from "../commonComponents/CustomSubstitute"
import CustomTable from "../commonComponents/CustomTable"
import DerivedTable from "../commonComponents/DerivedTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import GenDocsFileDownTable from "../commonComponents/GenDocsFilesDownload"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../commonComponents/TopContentForSpecs"

function McopAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributeObject: {},
      notes: [],
      reference: [],
      billOfMaterials: [],
      substitutions: [],
      relatedSpecification: [],
      referenceDocument: [],
      performanceCharacteristics: [],
      lifecycleApprovalPowerview: [],
      ownershipObject: {},
      ipClasses: [],
      securityClasses: [],
      organizationObject: {},
      files: [],
      relatedATS: [],
      whereUsed: null,
   })

   const [loading, setLoading] = useState(true)
   const searchData = props.match.params.objectID
   const [sideNav, setSideNav] = useState()
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)
      var values = pdfData(data, obj, IsFilterView)
      setPdfDataSet(values)
   }

   useEffect(() => {
      const fetchState = { cancel: false }
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchMppData(fetchState) {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")
               var apiData

               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  // Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_MCOP}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContentObject)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
               }
               if (fetchState.cancel) {
                  return
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContentObject
                     ? apiData.headerContentObject
                     : data.headerContent,
                  attributeObject: apiData.attributeObject,
                  organizationObject: apiData.organizationObject,
                  relatedSpecification: apiData.relatedSpecification,
                  lifecycleApprovalPowerview: apiData.lifecycleApprovalPowerview,
                  ownershipObject: apiData.ownershipObject,
                  billOfMaterials: apiData.billOfMaterials,
                  reference: apiData.reference,
                  performanceCharacteristics: apiData.performanceCharacteristics,
                  notes: apiData.notes,
                  substitutions: apiData.substitutions,
                  securityClasses: apiData.securityClasses,
                  referenceDocument: apiData.referenceDocument,
                  relatedATS: apiData.relatedATS,
                  ipClasses: apiData.ipClasses,
                  files: apiData.files,
                  whereUsed: apiData.whereUsed,
               }))
               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  console.log("Error", ` Not authorie: ${error}`)
                  notAuthorized(props.history)
                  return
               }
               console.log("Error", ` API request failed: ${error}`)
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchMppData(fetchState)
      }

      return () => {
         fetchState.cancel = true
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={MCOPKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" id="print" className="contentBackground">
                     <br />
                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="9" sm="9">
                              <TopContent headerContent={data.headerContent} />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              <PdfDownloadComponent pdfDataSet={pdfDataSet} />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {selectedItems["attributeObject"] && data.attributeObject && (
                              <div>
                                 <Card autoFocus>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(
                                          this,
                                          "0",
                                          "collapseZero"
                                       )}
                                       aria-controls="collapseZero"
                                    >
                                       {MCOPKeys.attributeObject}{" "}
                                       <i
                                          id="0"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="collapseZero"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <Attribute
                                       loading={loading}
                                       data={data.attributeObject}
                                    />
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              data.notes &&
                              data.notes.length > 0 &&
                              selectedItems["notes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "1",
                                             "collapseOne"
                                          )}
                                          aria-controls="collapseOne"
                                       >
                                          {MCOPKeys.notes}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseOne"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.notes} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.reference &&
                              data.reference.length > 0 &&
                              selectedItems["reference"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "13", "thirteen")}
                                          aria-controls="thirteen"
                                       >
                                          {MCOPKeys.reference}{" "}
                                          <i
                                             id="13"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.reference} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.billOfMaterials &&
                              data.billOfMaterials.length > 0 &&
                              selectedItems["billOfMaterials"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "2",
                                             "collapseTwo"
                                          )}
                                          aria-controls="collapseTwo"
                                       >
                                          {MCOPKeys.billOfMaterials}{" "}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseTwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable
                                          bom={data.billOfMaterials}
                                       ></CustomBOMTable>
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutions &&
                              data.substitutions.length > 0 &&
                              selectedItems["substitutions"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "3",
                                             "collapseThree"
                                          )}
                                          aria-controls="collapseThree"
                                       >
                                          {MCOPKeys.substitutions}{" "}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseThree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSubstitute data={data.substitutions} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedSpecification &&
                              data.relatedSpecification.length > 0 &&
                              selectedItems["relatedSpecification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "4",
                                             "collapseFour"
                                          )}
                                          aria-controls="collapseFour"
                                       >
                                          {MCOPKeys.relatedSpecification}{" "}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseFour"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMasterSpecification
                                          data={data.relatedSpecification}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocument &&
                              data.referenceDocument.length > 0 &&
                              selectedItems["referenceDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "5",
                                             "collapseFive"
                                          )}
                                          aria-controls="collapseFive"
                                       >
                                          {MCOPKeys.referenceDocument}{" "}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseFive"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComRefDocDownload
                                          data={data.referenceDocument}
                                          attributes={data.attributeObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.performanceCharacteristics &&
                              data.performanceCharacteristics.length > 0 &&
                              selectedItems["performanceCharacteristics"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "6",
                                             "collapseSix"
                                          )}
                                          aria-controls="collapseSix"
                                       >
                                          {MCOPKeys.performanceCharacteristics}{" "}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseSix"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPCTable
                                          data={data.performanceCharacteristics}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.lifecycleApprovalPowerview &&
                              data.lifecycleApprovalPowerview.length > 0 &&
                              selectedItems["lifecycleApprovalPowerview"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "7",
                                             "collapseSeven"
                                          )}
                                          aria-controls="collapseSeven"
                                       >
                                          {MCOPKeys.lifecycleApprovalPowerview}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseSeven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DerivedTable
                                          data={data.lifecycleApprovalPowerview}
                                          derived="Tasks/Signatures"
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ownershipObject &&
                              selectedItems["ownershipObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "8",
                                             "collapseEight"
                                          )}
                                          aria-controls="collapseEight"
                                       >
                                          {MCOPKeys.ownershipObject}{" "}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseEight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.ownershipObject} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ipClasses &&
                              data.ipClasses.length > 0 &&
                              selectedItems["ipClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "9",
                                             "collapseNine"
                                          )}
                                          aria-controls="collapseNine"
                                       >
                                          {MCOPKeys.ipClasses}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseNine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.ipClasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClasses &&
                              data.securityClasses.length > 0 &&
                              selectedItems["securityClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "10",
                                             "collapseTen"
                                          )}
                                          aria-controls="collapseTen"
                                       >
                                          {MCOPKeys.securityClasses}{" "}
                                          <i
                                             id="10"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseTen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.securityClasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.organizationObject &&
                              selectedItems["organizationObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "11",
                                             "collapseElevan"
                                          )}
                                          aria-controls="collapseElevan"
                                       >
                                          {MCOPKeys.organizationObject}{" "}
                                          <i
                                             id="11"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseElevan"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.organizationObject} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.files &&
                              data.files.length > 0 &&
                              selectedItems["files"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "12",
                                             "collapseTwelve"
                                          )}
                                          aria-controls="collapseTwelve"
                                       >
                                          {MCOPKeys.files}{" "}
                                          <i
                                             id="12"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseTwelve"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <GenDocsFileDownTable
                                          data={data.files}
                                          attributes={data.attributeObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedATS &&
                              data.relatedATS.length > 0 &&
                              selectedItems["relatedATS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "14",
                                             "collapseFourteen"
                                          )}
                                          aria-controls="collapseFourteen"
                                       >
                                          {MCOPKeys.relatedATS}{" "}
                                          <i
                                             id="14"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseFourteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedATS} />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {MCOPKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default McopAllInfo
