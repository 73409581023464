import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { DefaultKeys, FETCH_FPP, FPPKeys } from "../../constant/Constants"
import Attribute from "../commonComponents/Attributes"
import ComIngredientStatement from "../commonComponents/ComIngredientStatement"
import CommonAttribute from "../commonComponents/CommonAttributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import FppCountriesOfSale from "../commonComponents/CountriesOfSale"
import CustomBatteryRollUpAndRegisterCtrl from "../commonComponents/CustomBatteryRollUpAndRegisterCtrl"
import CustomBOMTable from "../commonComponents/CustomBOMTable"
import CustomMasterSpecification from "../commonComponents/CustomMasterSpecification"
import { CustomMultiBomTable } from "../commonComponents/CustomMultiBomTable"
import { CustomMultiSubstituteTable } from "../commonComponents/CustomMultiSubstituteTable"
import CustomPCTable from "../commonComponents/CustomPCTable"
import CustomSapBom from "../commonComponents/CustomSapBom"
import CustomSubstitute from "../commonComponents/CustomSubstitute"
import CustomTable from "../commonComponents/CustomTable"
import DerivedTable from "../commonComponents/DerivedTable"
import DownloadLinkTable from "../commonComponents/DownloadLinkTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import GenDocsFileDownTable from "../commonComponents/GenDocsFilesDownload"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import PlainCertifications from "../commonComponents/PlainCertifications"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../commonComponents/TopContentLatest"
import BaseCodeDetails from "./BaseCodeDetails"
import CustomFppPackingUnit from "./CustomFppPackingUnit"
import CustomFppTransport from "./CustomFppTransport"

function FppAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributesObject: {},
      smartLabelRollupData: [],
      dangerousGoodsClassification: [],
      globalHarmonizedStandard: [],
      ingredientStatement: [],
      storageTransportDataObject: {},
      stabilityResults: [],
      warHouseClassification: {},
      batteryRollUp: [],
      batteryCalculation: [],
      sapBomasFed: [],
      notes: [],
      cupArtwork: [],
      marketOfSale: {},
      registrationDetails: [],
      billOfMaterials: {},
      substitutes: [],
      billOfMaterialsCustomerUnit: [],
      substitutesCustomerUnit: [],
      bomInnerPack: [],
      substitutesInnerPack: [],
      billOfMaterialsConsumerUnit: [],
      substitutesConsumerUnit: [],
      billOfMaterialsTransportUnit: [],
      substitutesTransportUnit: [],
      billOfMaterialsMasterCustomerUnit: [],
      substitutesMasterCustomerUnit: [],
      bomMasterInnerPack: [],
      substitutesMasterInnerPack: [],
      bomMasterConsumerUnit: [],
      substitutesMasterConsUnit: [],
      relatedSpecifications: [],
      masterSpecifications: [],
      referenceDocument: [],
      performanceCharacteristic: [],
      plants: [],
      weightsDimensionsObject: {},
      packingUnit: [],
      transportUnitHead: {
         transportUnit: [],
         transportUnit1: [],
      },
      relatedATS: [],
      baseCodeDetails: [],
      substituteBaseCodeDetails: [],
      lifecycleApprovalPowerview: [],
      ownershipObject: {},
      ipClasses: [],
      securityClaases: [],
      organizationsObject: {},
      files: [],
      certifications: [],
      packagingCertifications: [],
      derivedTo: [],
      derivedFrom: [],
      uniqueFormulaIdentifier: [],
      billOfMaterialsFinishedProductPart: [],
      whereUsed: null,
   })

   const [loading, setLoading] = useState(false)
   const searchData = props.match.params.objectID
   const [sideNav, setSideNav] = useState("")
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)
      var values = pdfData(data, obj, IsFilterView)
      setPdfDataSet(values)
   }

   useEffect(() => {
      const fetchState = { cancel: false }

      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchFppData(fetchState) {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")
               // setSelectedItems(DefaultKeys)

               var apiData
               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  //Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_FPP}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContent)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
                  //apiData = JSON.parse(sessionStorage.getItem(searchData))
               }
               if (fetchState.cancel) {
                  return
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContent
                     ? apiData.headerContent
                     : data.headerContent,
                  //headerContent: null,
                  attributesObject: apiData.attributesObject,
                  smartLabelRollupData: apiData.smartLabelRollupData,
                  organizationsObject: apiData.organizationsObject,
                  storageTransportDataObject: apiData.storageTransportDataObject,
                  notes: apiData.notes,
                  cupArtwork: apiData.cupArtwork,
                  billOfMaterials: apiData.billOfMaterials,
                  billOfMaterialsCustomerUnit: apiData.billOfMaterialsCustomerUnit,
                  substitutesCustomerUnit: apiData.substitutesCustomerUnit,
                  billOfMaterialsConsumerUnit: apiData.billOfMaterialsConsumerUnit,
                  billOfMaterialsTransportUnit: apiData.billOfMaterialsTransportUnit,
                  billOfMaterialsMasterCustomerUnit:
                     apiData.billOfMaterialsMasterCustomerUnit,
                  sapBomasFed: apiData.sapBomasFed,
                  plants: apiData.plants,
                  derivedTo: apiData.derivedTo,
                  derivedFrom: apiData.derivedFrom,
                  lifecycleApprovalPowerview: apiData.lifecycleApprovalPowerview,
                  ownershipObject: apiData.ownershipObject,
                  weightsDimensionsObject: apiData.weightsDimensionsObject,
                  packingUnit: apiData.packingUnit,
                  transportUnitHead: apiData.transportUnitHead,
                  relatedSpecifications: apiData.relatedSpecifications,
                  masterSpecifications: apiData.masterSpecifications,
                  performanceCharacteristic: apiData.performanceCharacteristic,
                  warHouseClassification: apiData.warHouseClassification,
                  batteryRollUp: apiData.batteryRollUp,
                  batteryCalculation: apiData.batteryCalculation,
                  marketOfSale: apiData.marketOfSale,
                  registrationDetails: apiData.registrationDetails,
                  bomInnerPack: apiData.bomInnerPack,
                  bomMasterInnerPack: apiData.bomMasterInnerPack,
                  dangerousGoodsClassification: apiData.dangerousGoodsClassification,
                  globalHarmonizedStandard: apiData.globalHarmonizedStandard,
                  ingredientStatement: apiData.ingredientStatement,
                  stabilityResults: apiData.stabilityResults,
                  relatedATS: apiData.relatedATS,
                  baseCodeDetails: apiData.baseCodeDetails,
                  substituteBaseCodeDetails: apiData.substituteBaseCodeDetails,
                  securityClaases: apiData.securityClaases,
                  substitutes: apiData.substitutes,
                  substitutesInnerPack: apiData.substitutesInnerPack,
                  substitutesMasterCustomerUnit: apiData.substitutesMasterCustomerUnit,
                  substitutesMasterInnerPack: apiData.substitutesMasterInnerPack,
                  bomMasterConsumerUnit: apiData.bomMasterConsumerUnit,
                  substitutesMasterConsUnit: apiData.substitutesMasterConsUnit,
                  referenceDocument: apiData.referenceDocument,
                  uniqueFormulaIdentifier: apiData.uniqueFormulaIdentifier,
                  substitutesConsumerUnit: apiData.substitutesConsumerUnit,
                  substitutesTransportUnit: apiData.substitutesTransportUnit,
                  billOfMaterialsFinishedProductPart:
                     apiData.billOfMaterialsFinishedProductPart,
                  stabilityDocument: apiData.stabilityDocument,
                  ipClasses: apiData.ipClasses,
                  files: apiData.files,
                  certifications: apiData.certifications,
                  packagingCertifications: apiData.packagingCertifications,
                  whereUsed: apiData.whereUsed
               }))
               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  notAuthorized(props.history)
                  return
               }
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchFppData(fetchState)
      }

      return () => {
         fetchState.cancel = true
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={FPPKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" id="print" className="contentBackground">
                     <br />

                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="9" sm="9">
                              <TopContent headerContent={data.headerContent} />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              {sideNav && (
                                 <PdfDownloadComponent pdfDataSet={pdfDataSet} />
                              )}
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {selectedItems["attributesObject"] &&
                              data.attributesObject != null && (
                                 <div>
                                    <Card autoFocus>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "0", "zero")}
                                          aria-controls="zero"
                                       >
                                          {FPPKeys.attributesObject}{" "}
                                          <i
                                             id="0"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="zero"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <Attribute
                                          loading={loading}
                                          data={data.attributesObject}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.smartLabelRollupData &&
                              data.smartLabelRollupData.length > 0 &&
                              selectedItems["smartLabelRollupData"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "44",
                                             "fortyFour"
                                          )}
                                          aria-controls="fortyFour"
                                       >
                                          {FPPKeys.smartLabelRollupData}{" "}
                                          <i
                                             id="44"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fortyFour"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.smartLabelRollupData} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.dangerousGoodsClassification &&
                              data.dangerousGoodsClassification.length > 0 &&
                              selectedItems["dangerousGoodsClassification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "1", "one")}
                                          aria-controls="one"
                                       >
                                          {FPPKeys.dangerousGoodsClassification}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="one"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable
                                          bom={data.dangerousGoodsClassification}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.globalHarmonizedStandard &&
                              data.globalHarmonizedStandard.length > 0 &&
                              selectedItems["globalHarmonizedStandard"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "2", "two")}
                                          aria-controls="two"
                                       >
                                          {FPPKeys.globalHarmonizedStandard}{" "}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="two"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable
                                          data={data.globalHarmonizedStandard}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ingredientStatement &&
                              data.ingredientStatement.length > 0 &&
                              selectedItems["ingredientStatement"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "45",
                                             "fourtyFive"
                                          )}
                                          aria-controls="fourtyFive"
                                       >
                                          {FPPKeys.ingredientStatement}{" "}
                                          <i
                                             id="45"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourtyFive"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComIngredientStatement
                                          data={data.ingredientStatement}
                                          parentData={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              selectedItems["storageTransportDataObject"] &&
                              data.storageTransportDataObject != null && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "3", "three")}
                                          aria-controls="three"
                                       >
                                          {FPPKeys.storageTransportDataObject}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="three"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute
                                          data={data.storageTransportDataObject}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.stabilityResults &&
                              data.stabilityResults.length > 0 &&
                              selectedItems["stabilityResults"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "4", "four")}
                                          aria-controls="four"
                                       >
                                          {FPPKeys.stabilityResults}{" "}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="four"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.stabilityResults} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.warHouseClassification != null &&
                              selectedItems["warHouseClassification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "5", "five")}
                                          aria-controls="five"
                                       >
                                          {FPPKeys.warHouseClassification}{" "}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="five"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute
                                          data={data.warHouseClassification}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.batteryRollUp &&
                              data.batteryRollUp.length > 0 &&
                              selectedItems["batteryRollUp"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "46",
                                             "fourtySix"
                                          )}
                                          aria-controls="fourtySix"
                                       >
                                          {FPPKeys.batteryRollUp}{" "}
                                          <i
                                             id="46"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourtySix"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBatteryRollUpAndRegisterCtrl
                                          data={data.batteryRollUp}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.batteryCalculation &&
                              data.batteryCalculation.length > 0 &&
                              selectedItems["batteryCalculation"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "47",
                                             "fourtySeven"
                                          )}
                                          aria-controls="fourtySeven"
                                       >
                                          {FPPKeys.batteryCalculation}{" "}
                                          <i
                                             id="47"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourtySeven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.batteryCalculation} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.sapBomasFed &&
                              data.sapBomasFed.length > 0 &&
                              selectedItems["sapBomasFed"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "6", "six")}
                                          aria-controls="six"
                                       >
                                          {FPPKeys.sapBomasFed}{" "}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="six"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSapBom data={data.sapBomasFed} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.notes &&
                              data.notes.length > 0 &&
                              selectedItems["notes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "7", "seven")}
                                          aria-controls="seven"
                                       >
                                          {FPPKeys.notes}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="seven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.notes} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.cupArtwork &&
                              data.cupArtwork.length > 0 &&
                              selectedItems["cupArtwork"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "48",
                                             "foutyEight"
                                          )}
                                          aria-controls="foutyEight"
                                       >
                                          {FPPKeys.cupArtwork}{" "}
                                          <i
                                             id="48"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="foutyEight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DownloadLinkTable data={data.cupArtwork} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.marketOfSale != null &&
                              selectedItems["marketOfSale"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "28",
                                             "twentyeight"
                                          )}
                                          aria-controls="twentyeight"
                                       >
                                          {FPPKeys.marketOfSale}{" "}
                                          <i
                                             id="28"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyeight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <FppCountriesOfSale
                                          countriesOfSale={data.marketOfSale}
                                          extraColumn={true}
                                       ></FppCountriesOfSale>
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.registrationDetails &&
                              data.registrationDetails.length > 0 &&
                              selectedItems["registrationDetails"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "49",
                                             "fourtyNine"
                                          )}
                                          aria-controls="fourtyNine"
                                       >
                                          {FPPKeys.registrationDetails}{" "}
                                          <i
                                             id="49"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourtyNine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBatteryRollUpAndRegisterCtrl
                                          data={data.registrationDetails}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.billOfMaterials &&
                              selectedItems["billOfMaterials"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "8", "eight")}
                                          aria-controls="eight"
                                       >
                                          {FPPKeys.billOfMaterials}{" "}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="eight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMultiBomTable
                                          data={data.billOfMaterials}
                                       ></CustomMultiBomTable>
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutes &&
                              data.substitutes.length > 0 &&
                              selectedItems["substitutes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "9", "nine")}
                                          aria-controls="nine"
                                       >
                                          {FPPKeys.substitutes}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="nine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMultiSubstituteTable
                                          data={data.substitutes}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.billOfMaterialsCustomerUnit &&
                              data.billOfMaterialsCustomerUnit.length > 0 &&
                              selectedItems["billOfMaterialsCustomerUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "10", "ten")}
                                          aria-controls="ten"
                                       >
                                          {FPPKeys.billOfMaterialsCustomerUnit}{" "}
                                          <i
                                             id="10"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="ten"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable
                                          bom={data.billOfMaterialsCustomerUnit}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutesCustomerUnit &&
                              data.substitutesCustomerUnit.length > 0 &&
                              selectedItems["substitutesCustomerUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "11", "elevan")}
                                          aria-controls="elevan"
                                       >
                                          {FPPKeys.substitutesCustomerUnit}{" "}
                                          <i
                                             id="11"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="elevan"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSubstitute
                                          data={data.substitutesCustomerUnit}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.bomInnerPack &&
                              data.bomInnerPack.length > 0 &&
                              selectedItems["bomInnerPack"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "12", "twelve")}
                                          aria-controls="twelve"
                                       >
                                          {FPPKeys.bomInnerPack}{" "}
                                          <i
                                             id="12"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twelve"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable bom={data.bomInnerPack} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutesInnerPack &&
                              data.substitutesInnerPack.length > 0 &&
                              selectedItems["substitutesInnerPack"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "13",
                                             "thirteen"
                                          )}
                                          aria-controls="thirteen"
                                       >
                                          {FPPKeys.substitutesInnerPack}{" "}
                                          <i
                                             id="13"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSubstitute
                                          data={data.substitutesInnerPack}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.billOfMaterialsConsumerUnit &&
                              data.billOfMaterialsConsumerUnit.length > 0 &&
                              selectedItems["billOfMaterialsConsumerUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "14",
                                             "fourteen"
                                          )}
                                          aria-controls="fourteen"
                                       >
                                          {FPPKeys.billOfMaterialsConsumerUnit}{" "}
                                          <i
                                             id="14"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable
                                          bom={data.billOfMaterialsConsumerUnit}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutesConsumerUnit &&
                              data.substitutesConsumerUnit.length > 0 &&
                              selectedItems["substitutesConsumerUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "15",
                                             "fifteen"
                                          )}
                                          aria-controls="fifteen"
                                       >
                                          {FPPKeys.substitutesConsumerUnit}{" "}
                                          <i
                                             id="15"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fifteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSubstitute
                                          data={data.substitutesConsumerUnit}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.billOfMaterialsTransportUnit &&
                              data.billOfMaterialsTransportUnit.length > 0 &&
                              selectedItems["billOfMaterialsTransportUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "16",
                                             "sixteen"
                                          )}
                                          aria-controls="sixteen"
                                       >
                                          {FPPKeys.billOfMaterialsTransportUnit}{" "}
                                          <i
                                             id="16"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="sixteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable
                                          bom={data.billOfMaterialsTransportUnit}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutesTransportUnit &&
                              data.substitutesTransportUnit.length > 0 &&
                              selectedItems["substitutesTransportUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "17",
                                             "seventeen"
                                          )}
                                          aria-controls="seventeen"
                                       >
                                          {FPPKeys.substitutesTransportUnit}{" "}
                                          <i
                                             id="17"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="seventeen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSubstitute
                                          data={data.substitutesTransportUnit}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.billOfMaterialsMasterCustomerUnit &&
                              data.billOfMaterialsMasterCustomerUnit.length > 0 &&
                              selectedItems["billOfMaterialsMasterCustomerUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "18",
                                             "eighteen"
                                          )}
                                          aria-controls="eighteen"
                                       >
                                          {FPPKeys.billOfMaterialsMasterCustomerUnit}{" "}
                                          <i
                                             id="18"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="eighteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable
                                          bom={data.billOfMaterialsMasterCustomerUnit}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutesMasterCustomerUnit &&
                              data.substitutesMasterCustomerUnit.length > 0 &&
                              selectedItems["substitutesMasterCustomerUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "19",
                                             "nineteen"
                                          )}
                                          aria-controls="nineteen"
                                       >
                                          {FPPKeys.substitutesMasterCustomerUnit}{" "}
                                          <i
                                             id="19"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="nineteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSubstitute
                                          data={data.substitutesMasterCustomerUnit}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.bomMasterInnerPack &&
                              data.bomMasterInnerPack.length > 0 &&
                              selectedItems["bomMasterInnerPack"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "20", "twenty")}
                                          aria-controls="twenty"
                                       >
                                          {FPPKeys.bomMasterInnerPack}{" "}
                                          <i
                                             id="20"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twenty"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable bom={data.bomMasterInnerPack} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutesMasterInnerPack &&
                              data.substitutesMasterInnerPack.length > 0 &&
                              selectedItems["substitutesMasterInnerPack"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "21",
                                             "twentyone"
                                          )}
                                          aria-controls="twentyone"
                                       >
                                          {FPPKeys.substitutesMasterInnerPack}{" "}
                                          <i
                                             id="21"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyone"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSubstitute
                                          data={data.substitutesMasterInnerPack}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.bomMasterConsumerUnit &&
                              data.bomMasterConsumerUnit.length > 0 &&
                              selectedItems["bomMasterConsumerUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "22",
                                             "twentytwo"
                                          )}
                                          aria-controls="twentytwo"
                                       >
                                          {FPPKeys.bomMasterConsumerUnit}{" "}
                                          <i
                                             id="22"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentytwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable bom={data.bomMasterConsumerUnit} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutesMasterConsUnit &&
                              data.substitutesMasterConsUnit.length > 0 &&
                              selectedItems["substitutesMasterConsUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "23",
                                             "twentythree"
                                          )}
                                          aria-controls="twentythree"
                                       >
                                          {FPPKeys.substitutesMasterConsUnit}{" "}
                                          <i
                                             id="23"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentythree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSubstitute
                                          data={data.substitutesMasterConsUnit}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedSpecifications &&
                              data.relatedSpecifications.length > 0 &&
                              selectedItems["relatedSpecifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "24",
                                             "twentyfour"
                                          )}
                                          aria-controls="twentyfour"
                                       >
                                          {FPPKeys.relatedSpecifications}{" "}
                                          <i
                                             id="24"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyfour"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DownloadLinkTable
                                          data={data.relatedSpecifications}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.masterSpecifications &&
                              data.masterSpecifications.length > 0 &&
                              selectedItems["masterSpecifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "25",
                                             "twentyfive"
                                          )}
                                          aria-controls="twentyfive"
                                       >
                                          {FPPKeys.masterSpecifications}{" "}
                                          <i
                                             id="25"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyfive"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMasterSpecification
                                          data={data.masterSpecifications}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocument &&
                              data.referenceDocument.length > 0 &&
                              selectedItems["referenceDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "26",
                                             "twentysix"
                                          )}
                                          aria-controls="twentysix"
                                       >
                                          {FPPKeys.referenceDocument}{" "}
                                          <i
                                             id="26"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentysix"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComRefDocDownload
                                          data={data.referenceDocument}
                                          attributes={data.attributesObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.performanceCharacteristic &&
                              data.performanceCharacteristic.length > 0 &&
                              selectedItems["performanceCharacteristic"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "27",
                                             "twentyseven"
                                          )}
                                          aria-controls="twentyseven"
                                       >
                                          {FPPKeys.performanceCharacteristic}{" "}
                                          <i
                                             id="27"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyseven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPCTable
                                          data={data.performanceCharacteristic}
                                       />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              data.plants &&
                              data.plants.length > 0 &&
                              selectedItems["plants"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "29",
                                             "twentynine"
                                          )}
                                          aria-controls="twentynine"
                                       >
                                          {FPPKeys.plants}{" "}
                                          <i
                                             id="29"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentynine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.plants} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.weightsDimensionsObject != null &&
                              selectedItems["weightsDimensionsObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "34",
                                             "thirtyfour"
                                          )}
                                          aria-controls="thirtyfour"
                                       >
                                          {FPPKeys.weightsDimensionsObject}{" "}
                                          <i
                                             id="34"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtyfour"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute
                                          data={data.weightsDimensionsObject}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.packingUnit &&
                              data.packingUnit.length > 0 &&
                              selectedItems["packingUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "35",
                                             "thirtyfive"
                                          )}
                                          aria-controls="thirtyfive"
                                       >
                                          {FPPKeys.packingUnit}{" "}
                                          <i
                                             id="35"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtyfive"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomFppPackingUnit
                                          packingUnit={data.packingUnit}
                                       ></CustomFppPackingUnit>
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.transportUnitHead &&
                              data.transportUnitHead != null &&
                              selectedItems["transportUnitHead"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {FPPKeys.transportUnitHead}{" "}
                                          <i
                                             id="36"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtysix"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomFppTransport
                                          transportUnitHead={data.transportUnitHead}
                                       ></CustomFppTransport>
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedATS &&
                              data.relatedATS.length > 0 &&
                              selectedItems["relatedATS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "37",
                                             "thirtyseven"
                                          )}
                                          aria-controls="thirtyseven"
                                       >
                                          {FPPKeys.relatedATS}{" "}
                                          <i
                                             id="37"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtyseven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedATS} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.baseCodeDetails &&
                              data.baseCodeDetails.length > 0 &&
                              selectedItems["baseCodeDetails"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "50", "fifty")}
                                          aria-controls="fifty"
                                       >
                                          {FPPKeys.baseCodeDetails}{" "}
                                          <i
                                             id="50"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fifty"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <BaseCodeDetails data={data.baseCodeDetails} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substituteBaseCodeDetails &&
                              data.substituteBaseCodeDetails.length > 0 &&
                              selectedItems["substituteBaseCodeDetails"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "51",
                                             "fiftyOne"
                                          )}
                                          aria-controls="fiftyOne"
                                       >
                                          {FPPKeys.substituteBaseCodeDetails}{" "}
                                          <i
                                             id="51"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fiftyOne"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSubstitute
                                          data={data.substituteBaseCodeDetails}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.lifecycleApprovalPowerview &&
                              data.lifecycleApprovalPowerview.length > 0 &&
                              selectedItems["lifecycleApprovalPowerview"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "30", "thirty")}
                                          aria-controls="thirty"
                                       >
                                          {FPPKeys.lifecycleApprovalPowerview}{" "}
                                          <i
                                             id="30"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirty"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DerivedTable
                                          data={data.lifecycleApprovalPowerview}
                                          derived="Tasks/Signatures"
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ownershipObject != null &&
                              selectedItems["ownershipObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "31",
                                             "thirtyone"
                                          )}
                                          aria-controls="thirtyone"
                                       >
                                          {FPPKeys.ownershipObject}{" "}
                                          <i
                                             id="31"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtyone"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.ownershipObject} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ipClasses &&
                              data.ipClasses.length > 0 &&
                              selectedItems["ipClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "32",
                                             "thirtytwo"
                                          )}
                                          aria-controls="thirtytwo"
                                       >
                                          {FPPKeys.ipClasses}{" "}
                                          <i
                                             id="32"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtytwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.ipClasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClaases &&
                              data.securityClaases.length > 0 &&
                              selectedItems["securityClaases"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "33",
                                             "thirtythree"
                                          )}
                                          aria-controls="thirtythree"
                                       >
                                          {FPPKeys.securityClaases}{" "}
                                          <i
                                             id="33"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtythree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.securityClaases} />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              selectedItems["organizationsObject"] &&
                              data.organizationsObject != null && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "38",
                                             "thirtyeight"
                                          )}
                                          aria-controls="thirtyeight"
                                       >
                                          {FPPKeys.organizationsObject}{" "}
                                          <i
                                             id="38"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtyeight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.organizationsObject} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.files &&
                              data.files.length > 0 &&
                              selectedItems["files"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "39",
                                             "thirtynine"
                                          )}
                                          aria-controls="thirtynine"
                                       >
                                          {FPPKeys.files}{" "}
                                          <i
                                             id="39"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtynine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <GenDocsFileDownTable
                                          data={data.files}
                                          attributes={data.attributesObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.certifications &&
                              data.certifications.length > 0 &&
                              selectedItems["certifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "40", "fourty")}
                                          aria-controls="fourty"
                                       >
                                          {FPPKeys.certifications}{" "}
                                          <i
                                             id="40"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourty"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <PlainCertifications data={data.certifications} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.packagingCertifications &&
                              data.packagingCertifications.length > 0 &&
                              selectedItems["packagingCertifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "52",
                                             "FiftyTwo"
                                          )}
                                          aria-controls="FiftyTwo"
                                       >
                                          {FPPKeys.packagingCertifications}{" "}
                                          <i
                                             id="52"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="FiftyTwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.packagingCertifications} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              ((data.derivedFrom && data.derivedFrom.length > 0) ||
                                 (data.derivedTo && data.derivedTo.length > 0)) &&
                              selectedItems["derivedParts"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "41",
                                             "fourtyone"
                                          )}
                                          aria-controls="fourtyone"
                                       >
                                          {FPPKeys.derivedParts}{" "}
                                          <i
                                             id="41"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourtyone"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DerivedTable
                                          data={data.derivedFrom}
                                          derived="Derived From"
                                       />
                                       <DerivedTable
                                          data={data.derivedTo}
                                          derived="Derived To"
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.uniqueFormulaIdentifier &&
                              data.uniqueFormulaIdentifier.length > 0 &&
                              selectedItems["uniqueFormulaIdentifier"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "42",
                                             "fourtytwo"
                                          )}
                                          aria-controls="fourtytwo"
                                       >
                                          {FPPKeys.uniqueFormulaIdentifier}{" "}
                                          <i
                                             id="42"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourtytwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.uniqueFormulaIdentifier} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.billOfMaterialsFinishedProductPart &&
                              data.billOfMaterialsFinishedProductPart.length > 0 &&
                              selectedItems["billOfMaterialsFinishedProductPart"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "43",
                                             "fourtythree"
                                          )}
                                          aria-controls="fourtythree"
                                       >
                                          {FPPKeys.billOfMaterialsFinishedProductPart}{" "}
                                          <i
                                             id="43"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourtythree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable
                                          bom={data.billOfMaterialsFinishedProductPart}
                                       />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "44",
                                             "fourtyfour"
                                          )}
                                          aria-controls="fourtyfour"
                                       >
                                          {FPPKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )

   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default FppAllInfo
