import React from "react"
import CustomLink from "./CustomLink"
import { setNonDispalyValue, tableLinkColumn } from "./EventHandler"

const CustomEnterpriseParts = (props) => {
   const data = props.data
   var numRows = 0

   if (data != null && data.length > 0) {
      numRows = data.length
   }

   const getHeader = (headerData = []) => {
      var keys = getKeys(headerData)
      return keys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         if (key.toLowerCase() === "pname") {
            return (
               <th key={key}>
                  <span>Name</span>
               </th>
            )
         }
         if (key.toLowerCase() === "pstate") {
            return (
               <th key={key}>
                  <span>State</span>
               </th>
            )
         }
         if (key.toLowerCase() === "prevision") {
            return (
               <th key={key}>
                  <span>Revision</span>
               </th>
            )
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getKeys = (keysInformation = []) => {
      return Object.keys(keysInformation)
   }

   const getRowsData = (tableData) => {
      var items = tableData
      var keys = getKeys(tableData[0])
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const getPQRLinks = function (data, link, id) {
      if (data !== "") {
         var elements = data.map((value, index) => {
            if (
               link[index] &&
               link[index].trim() !== "" &&
               link[index].trim() !== "null" &&
               id[index] &&
               id[index].trim() !== "" &&
               id[index].trim() !== "null" &&
               id[index].toLowerCase().trim() !== "no access"
            ) {
               return (
                  <div key={index}>
                     <CustomLink
                        id={id[index].trim()}
                        link={link[index].trim()}
                        name={value}
                     />
                  </div>
               )
            } else {
               return <div key={index}>{value}</div>
            }
         })
         return elements
      } else {
         return null
      }
   }

   const RenderRow = (props) => {
      return props.keys.map((key, index) => {
         if (
            key.toLowerCase() === "name" &&
            props.data["link"] &&
            props.data["link"] !== ""
         ) {
            let name = props.data[key]
            return tableLinkColumn(props.data["id"], props.data["link"], name, index)
         } else if (key.toLowerCase() === "pname") {
            let nameData = props.data[key] ? props.data[key].split("|") : []
            let nameLink = props.data["plink"] ? props.data["plink"].split("|") : []
            let nameId = props.data["pid"] ? props.data["pid"].split("|") : []
            let getnamelinks = getPQRLinks(nameData, nameLink, nameId)
            return <td key={index}>{getnamelinks}</td>
         } else if (key.toLowerCase() === "pstate" || key.toLowerCase() === "prevision") {
            let keyName = props.data[key] ? props.data[key].split("|") : []
            let getnamelinks = getPQRLinks(keyName, "", "")
            return <td key={index}>{getnamelinks}</td>
         } else if (setNonDispalyValue(key)) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   return (
      <div className="tableFixHead">
         {numRows > 0 && (
            <table>
               <thead>
                  <tr>
                     <th style={{backgroundColor:"white"}} colSpan="5">
                        <span></span>
                     </th>
                     <th style={{backgroundColor:"white"}}>
                        <span>PQRs</span>
                     </th>
                  </tr>
                  <tr>{getHeader(data[0])}</tr>
               </thead>
               <tbody>{getRowsData(data)}</tbody>
            </table>
         )}
      </div>
   )
}
export default CustomEnterpriseParts
