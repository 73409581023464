import React from "react"
import CustomLink from "./CustomLink"

const CustomAlternates = (props) => {
   let data = []
   data = props.data
   const getHeader = () => {
      var keys = getKeys()
      return keys.map((key, index) => {
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getRowsData = () => {
      var items = data
      var keys = getKeys()
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const getKeys = function () {
      return Object.keys(data[0])
   }

   const getColumnKeys = function (columKeyInfo) {
      return Object.keys(columKeyInfo)
   }

   const RenderColumn = function (props) {
      return props.keys.map((key, index) => {
         if (props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }
   const RenderLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            key.toLowerCase() === "name" &&
            props.data["id"] &&
            props.data["link"] &&
            props.data["id"].toLowerCase().trim() !== "no access"
         ) {
            let name = props.data[key]
            let link = props.data["link"]
            let id = props.data["id"]

            return (
               <div key={index} style={{ minWidth: "9rem" }}>
                  <span>
                     {link ? (
                        <CustomLink id={id} link={link} name={name} />
                     ) : (
                        <span>{name}</span>
                     )}
                  </span>
               </div>
            )
         } else if (
            props.data[key] &&
            (key.toLowerCase() === "link" || key.toLowerCase() === "id")
         ) {
            return null
         } else if (props.data[key] != null)
            return (
               <div key={index}>
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const RenderRow = (props) => {
      var colKeys = []
      return props.keys.map((key, index) => {
         if (
            props.data[key] != null &&
            typeof props.data[key] == "object" &&
            key === "Name"
         ) {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderLinkColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         }
         if (props.data[key] != null && typeof props.data[key] == "object") {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   return (
      <div className="tableFixHead">
         {data && data.length > 0 && (
            <table>
               <thead>
                  <tr>{getHeader()}</tr>
               </thead>
               <tbody>{getRowsData()}</tbody>
            </table>
         )}
      </div>
   )
}
export default CustomAlternates
