import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Collapse, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { ASLKeys, DefaultKeys, FETCH_ASL } from "../../constant/Constants"
import Attributes from "../commonComponents/Attributes"
import CommonAttributes from "../commonComponents/CommonAttributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import CustomTable from "../commonComponents/CustomTable"
import DownloadLinkTable from "../commonComponents/DownloadLinkTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../commonComponents/TopContents"

function AslAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributeObject: {},
      //new added
      relatedSpecification: [],
      referenceDocuments: [],
      supplierInformations: [],
      pgInternalInformtions: [],
      ownershipObject: {},
      ipClasses: [],
      securityClass: [],
      organizationObject: {},
      technicalStandardSupersedes: [],
      whereUsed: null,
   })

   const searchData = props.match.params.objectID
   const [loading, setLoading] = useState(true)
   const [sideNav, setSideNav] = useState()
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})
   const userRole = localStorage.getItem("role")

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)
      var values = pdfData(data, obj, IsFilterView)
      setPdfDataSet(values)
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchAslData() {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")
               var apiData

               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  // Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_ASL}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContentObject)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContentObject
                     ? apiData.headerContentObject
                     : data.headerContent,
                  attributeObject: apiData.attributeObject,
                  organizationObject: apiData.organizationObject,
                  relatedSpecification: apiData.relatedSpecification,
                  referenceDocuments: apiData.referenceDocuments,
                  supplierInformations: apiData.supplierInformations,
                  pgInternalInformtions: apiData.pgInternalInformtions,
                  ownershipObject: apiData.ownershipObject,
                  ipClasses: apiData.ipClasses,
                  securityClass: apiData.securityClass,
                  technicalStandardSupersedes: apiData.technicalStandardSupersedes,
                  whereUsed: apiData.whereUsed,
               }))

               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  console.log("Error", ` Not authorie: ${error}`)
                  notAuthorized(props.history)
                  return
               }
               console.log("Error", ` API request failed: ${error}`)
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchAslData()
      }
      return () => {
         return undefined
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} css={true} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={ASLKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" id="print" className="contentBackground">
                     <br />
                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="9" sm="9">
                              <TopContent
                                 headerContent={data.headerContent}
                                 addionalField={true}
                              />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              <PdfDownloadComponent
                                 pdfDataSet={pdfDataSet}
                                 pdfHide={
                                    userRole && (userRole === "CM" || userRole === "SP")
                                       ? true
                                       : false
                                 }
                              />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {selectedItems["attributeObject"] &&
                              data.attributeObject != null && (
                                 <div>
                                    <Card autoFocus>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "0",
                                             "collapseZero"
                                          )}
                                          aria-controls="collapseZero"
                                       >
                                          {ASLKeys.attributeObject}{" "}
                                          <i
                                             id="0"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseZero"
                                          name="collapseItem"
                                       >
                                          <Attributes
                                             loading={loading}
                                             data={data.attributeObject}
                                          />
                                       </div>
                                    </Collapse>
                                    {!loading &&
                                       data.technicalStandardSupersedes &&
                                       data.technicalStandardSupersedes.length > 0 && (
                                          <React.Fragment>
                                             <br />
                                             <div className="accordionDiv">
                                                <CustomTable
                                                   data={data.technicalStandardSupersedes}
                                                />
                                             </div>
                                          </React.Fragment>
                                       )}
                                 </div>
                              )}
                           {!loading &&
                              selectedItems["organizationObject"] &&
                              data.organizationObject && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "1",
                                             "collapseOne"
                                          )}
                                          aria-controls="collapseOne"
                                       >
                                          {ASLKeys.organizationObject}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseOne"
                                          name="collapseItem"
                                       >
                                          <CommonAttributes
                                             data={data.organizationObject}
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedSpecification &&
                              data.relatedSpecification.length > 0 &&
                              selectedItems["relatedSpecification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "2",
                                             "collapseTwo"
                                          )}
                                          aria-controls="collapseTwo"
                                       >
                                          {ASLKeys.relatedSpecification}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseTwo"
                                          name="collapseItem"
                                       >
                                          <DownloadLinkTable
                                             data={data.relatedSpecification}
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocuments &&
                              data.referenceDocuments.length > 0 &&
                              selectedItems["referenceDocuments"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "4",
                                             "collapseFour"
                                          )}
                                          aria-controls="collapseFour"
                                       >
                                          {ASLKeys.referenceDocuments}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseFour"
                                          name="collapseItem"
                                       >
                                          <ComRefDocDownload
                                             data={data.referenceDocuments}
                                             attributes={data.attributeObject}
                                             headerContent={data.headerContent}
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}

                           {!loading &&
                              data.supplierInformations &&
                              data.supplierInformations.length > 0 &&
                              selectedItems["supplierInformations"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "5",
                                             "collapseFive"
                                          )}
                                          aria-controls="collapseFive"
                                       >
                                          {ASLKeys.supplierInformations}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseFive"
                                          name="collapseItem"
                                       >
                                          <CustomTable data={data.supplierInformations} />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.pgInternalInformtions &&
                              data.pgInternalInformtions.length > 0 &&
                              selectedItems["pgInternalInformtions"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "6",
                                             "collapseSix"
                                          )}
                                          aria-controls="collapseSix"
                                       >
                                          {ASLKeys.pgInternalInformtions}{" "}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseSix"
                                          name="collapseItem"
                                       >
                                          <CustomTable
                                             data={data.pgInternalInformtions}
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.ownershipObject &&
                              selectedItems["ownershipObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "7",
                                             "collapseSeven"
                                          )}
                                          aria-controls="collapseSeven"
                                       >
                                          {ASLKeys.ownershipObject}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseSeven"
                                          name="collapseItem"
                                       >
                                          <CommonAttributes data={data.ownershipObject} />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.ipClasses &&
                              data.ipClasses.length > 0 &&
                              selectedItems["ipClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "8",
                                             "collapseEight"
                                          )}
                                          aria-controls="collapseEight"
                                       >
                                          {ASLKeys.ipClasses}{" "}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseEight"
                                          name="collapseItem"
                                       >
                                          <CustomTable data={data.ipClasses} />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClass &&
                              data.securityClass.length > 0 &&
                              selectedItems["securityClass"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "9",
                                             "collapseNine"
                                          )}
                                          aria-controls="collapseNine"
                                       >
                                          {ASLKeys.securityClass}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseNine"
                                          name="collapseItem"
                                       >
                                          <CustomTable data={data.securityClass} />
                                       </div>
                                    </Collapse>
                                 </div>
                              )
                           }
                           {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {ASLKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default AslAllInfo
