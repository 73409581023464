import { saveAs } from "file-saver"
import React, { useState } from "react"
import { Button, Dropdown, DropdownButton } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { AllInfoPdfGenerate, fetchAPIData, isLoggedIn } from "../../api/AuthService"
import { DOWNLOAD_LINK, PDF_DOWNLOAD_LINK } from "../../constant/Constants"
import { PDFViews } from "../../constant/PDFoptions"
import { expandCollapseAll } from "../commonComponents/EventHandler"

const PdfDownloadComponent = ({ pdfDataSet, genDocHide, pdfHide }) => {
   const [loading, setLoading] = useState(false)
   const [genDocLoading, setGedDocLoading] = useState(false)

   const gendochide = genDocHide ? genDocHide : false
   const pdfhide = pdfHide ? pdfHide : false
   var object_ID = window.location.pathname.split("/")[2]
   var objectID = object_ID ? object_ID : null
   const type = window.location.pathname.split("/")[1]
   // const options = PDFViews[type]?PDFViews[type]:""
   const role = localStorage.getItem("role")
   const CPView = {CM: 'Consolidated Packing View'}
   const options = (type == 'FPP' && role == "") ? CPView : (PDFViews[type] ? PDFViews[type] : "")
   const history = useHistory()
   //Download Allinfo pdf
   const downloadPdfFile = (view) => {
      if (!isLoggedIn()) {
         history.push("/")
      } else {
         let fileName = "download"
         fileName = pdfDataSet["Name"] ? ( pdfDataSet["Rev"] ? pdfDataSet["Name"]+"-Rev"+pdfDataSet["Rev"] : pdfDataSet["Name"] ) : fileName
         if(view && view ==="All"){
            setLoading(true)
         }
         AllInfoPdfGenerate(`${PDF_DOWNLOAD_LINK}`, type, pdfDataSet, objectID, view)
            .then((response) => {
               const blob = new Blob([response.data], {
                  type: "application/pdf",
               })

               let fileContents = URL.createObjectURL(blob)
               saveAs(fileContents, fileName)
               setLoading(false)
            })
            .catch((error) => {
               if (error.response && error.response.status === 422) {
                  //setShow(true)
                  alert(
                     "You have exceeded the limit for the day and access to any additional Part/Specification is blocked! Please contact Support."
                  )
               } 
               else if(error.response && error.response.data.status === 401){
                  history.push("/")
               }
               else {
                  alert("There is an issue downloading the file. Please try again later.")
                  console.log(" error", error)
               }
               setLoading(false)
            })
      }
   }

   //Download GenDoc File
   const downloadGenDocFile = () => {
      if (!isLoggedIn()) {
         history.push("/")
      } else {
         var fileName = "download"
         setGedDocLoading(true)

         fetchAPIData(`${DOWNLOAD_LINK}${objectID}`, { responseType: "blob" })
            .then(function (response) {
               if (
                  response.headers["filename"] != null &&
                  response.headers["filename"] !== ""
               ) {
                  fileName = response.headers["filename"]
               }
               const fileContents = new Blob([response.data])
               saveAs(fileContents, fileName)
               setGedDocLoading(false)
            })
            .catch((error) => {
               if (error.response && error.response.status === 404) {
                  alert("No file is avilable to download.")
               } else if (error.response && error.response.status === 422) {
                  alert(
                     "You have exceeded the limit for the day and access to any additional Part/Specification is blocked! Please contact Support."
                  )
               } 
               else if(error.response && error.response.data.status === 401){
                  history.push("/")
               }
               else {
                  alert("Oops, something went wrong. Please contact Support.")
                  console.log("FCS Error Response:-", error)
               }
               setGedDocLoading(false)
            })
      }
   }

   const RenderOptins = (props) => {
      let keys =  Object.keys(props.data)
      
      return keys.map((key, index) => {
         return  <Dropdown.Item onClick={()=> downloadPdfFile(key)}>{props.data[key]}</Dropdown.Item>
      })
   }

   return (
      <React.Fragment>
         <div>
            {!gendochide && (
               <Button
                  style={{ textAlign: "center",marginRight:"0.1rem" }}
                  className="expandCollapseButton"
                  onClick={downloadGenDocFile}
               >
                  {genDocLoading ? (
                     <i className="fa fa-spinner fa-spin" style={{ marginRight: "5px" }} />
                  ) : (
                     <span>GenDoc &#8595;</span>
                  )}
               </Button>
            )}
            &nbsp;
            {!pdfhide && (
                  <Button
                     style={{ textAlign: "center" }}
                     className="expandCollapseButton"
                     onClick={()=>downloadPdfFile("All")}
                  >
                     {loading ? (
                        <i className="fa fa-spinner fa-spin" style={{ marginRight: "5px" }} />
                     ) : (
                        <span>PDF &#8595;</span>
                     )}
                  </Button>
               )
            }
            &nbsp;
            <Button
               id="btnExpandAll"
               className="expandCollapseButton"
               onClick={expandCollapseAll}
            >
               Collapse All
            </Button>
         </div>
         <React.Fragment>
            {role === "" &&
               options !== "" && (
                  <DropdownButton style={{align:"right",marginTop:"3px"}} id="options" title="Other Views">
                     <RenderOptins data={options} />
                  </DropdownButton>
               )
            }
         </React.Fragment>
      </React.Fragment>
   )
}
export default PdfDownloadComponent