import React, { useMemo, useState } from "react"
import { Col, Container, Form, Row, Table } from "react-bootstrap"
import Pagination from "react-pagination-bootstrap"
import { Link } from "react-router-dom"
import { addBreadCrumbLinks } from "../commonComponents/EventHandler"

const SearchResultNew = ({ results,completeData,handleClientSearch }) => {
   const useSortableData = (items, config = null) => {
      const [sortConfig, setSortConfig] = useState(config)
      const sortedItems = useMemo(() => {
         let sortableItems = [...items]
         if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
               if (sortConfig.key === "releaseDateForUiSorting") {
                  if (new Date(a[sortConfig.key]) < new Date(b[sortConfig.key])) {
                     return sortConfig.direction === "ascending" ? -1 : 1
                  }
                  if (new Date(a[sortConfig.key]) > new Date(b[sortConfig.key])) {
                     return sortConfig.direction === "ascending" ? 1 : -1
                  }
                  return 0
               } else {
                  if (a[sortConfig.key] < b[sortConfig.key]) {
                     return sortConfig.direction === "ascending" ? -1 : 1
                  }
                  if (a[sortConfig.key] > b[sortConfig.key]) {
                     return sortConfig.direction === "ascending" ? 1 : -1
                  }
                  return 0
               }
            })
         }
         return sortableItems
      }, [items, sortConfig])

      const requestSort = (key) => {
         let direction = "ascending"
         if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === "ascending"
         ) {
            direction = "descending"
         }
         setSortConfig({ key, direction })
      }
      return { items: sortedItems, requestSort, sortConfig }
   }

   const [resultsPerPage, setResultsPerPage] = useState(20)
   const [currentPage, setCurrentPage] = useState(1)
   const indexOfLastResult = currentPage * resultsPerPage
   const indexOfFirstResult = indexOfLastResult - resultsPerPage
   const { items, requestSort, sortConfig } = useSortableData(results)
   const currentResult = items.slice(indexOfFirstResult, indexOfLastResult)
   const [activePage, setActivePage] = useState(1)

   const getClassNamesFor = (name) => {
      if (!sortConfig) {
         return "fa fa-sort"
      } else if (sortConfig.key === name) {
         if (sortConfig.direction === "ascending") {
            return "fa fa-caret-up"
         } else {
            return "fa fa-caret-down"
         }
      } else {
         return "fa fa-sort"
      }
   }
   const info_content = currentResult.map((key, index) => (
      <tr key={index}>
         <td width="10%">
            {key.link != null ? (
               <Link
                  onClick={() =>
                     addBreadCrumbLinks(
                        key.name,
                        `/${key.link.toUpperCase().trim()}/${key.id.trim()}`
                     )
                  }
                  to={(location) => ({
                     ...location,
                     pathname: `/${key.link.toUpperCase()}/${key.id}`,
                  })}
               >
                  <span textalign="left">{key.name}</span>
               </Link>
            ) : (
               <span>{key.name}</span>
            )}
         </td>
         <td align="left" width="5%">
            {key.revision}
         </td>
         <td align="left" width="10%">
            {key.displaytype}
         </td>
         <td align="left" width="10%" className = "keepSpace">
            {key.title}
         </td>
         <td align="left" width="10%" className = "keepSpace">
            {key.description}
         </td>
         <td align="left" width="10%">
            {key.maturityState}
         </td>
         <td align="left" width="10%">
            {key["lastModification"]}
         </td>
         <td align="left" width="10%">
            {key["releasedate"]}
         </td>
         <td align="left" width="10%">
            {key["creationDate"]}
         </td>
         <td align="left" width="10%">
            {key["originator"]}
         </td>
      </tr>
   ))
   function toggleCheckbox() {
      var items = document.getElementsByName("tableCheckbox")
      var i
      if (document.getElementById("gridViewCheckbox").checked) {
         for (i = 0; i < items.length; i++) {
            items[i].checked = true
         }
      } else {
         for (i = 0; i < items.length; i++) {
            items[i].checked = false
         }
      }
   }

   function handlePageChange(pageNumber) {
      setCurrentPage(pageNumber)
      setActivePage(pageNumber)
   }

   function handleSearchChange(e) {
        console.log(e.target.value)
        var re1 = new RegExp(e.target.value, 'i');
        var matches = completeData.filter(matcher(re1));
        handleClientSearch(matches)
        setActivePage(1)
        setCurrentPage(1)
    }
    function matcher(regexp) {
        return function (obj) {
        var found = false;
        Object.keys(obj).forEach(function(key){
          if ( ! found) {
            if ((typeof obj[key] == 'string') && regexp.exec(obj[key])) {
              found = true;
            }
          }
        });
        return found;
        };
      } 

   return (
        <React.Fragment>
            <Container fluid className="searchDataContainer">
                <Row className="mt-4 mb-3 mr-1 ml-1">
                    <Col md={3} sm={2} align="left">
                    <div style={{ width: "100%" }}>
                        <table style={{ textAlign: "center", width: "80%" }}>
                            <tbody>
                                <tr>
                                <td
                                    style={{
                                        width: "25%",
                                        verticalAlign: "middle",
                                        textAlign: "left",
                                    }}
                                >
                                    Page Size:
                                </td>
                                <td style={{ width: "40%", textAlign: "left", }}>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => {
                                            setResultsPerPage(e.target.value)
                                            setCurrentPage(1)
                                            setActivePage(1)
                                        }}
                                        className="vendorOptions"
                                    >
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </Form.Control>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </Col>
                    <Col md={1} sm={1} align="right" style={{ paddingTop:"5px"}}>
                        Search:
                    </Col>
                    <Col md={2} sm={2} align="left">
                        <Form.Control type="text" className="vendorOptions" onChange={handleSearchChange}/>
                    </Col>
                    <Col md={6} sm={7} align="right">
                    <div
                        style={{ width: "40%", verticalAlign: "middle" }}
                        id="paginTab"
                    >
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={resultsPerPage}
                            totalItemsCount={results.length}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                    </div>
                    </Col>
                </Row>
                <div className="searchScrollbar">
                    <Table bordered>
                        <thead id="infoTab">
                        <tr>
                            <th width="10%">
                                Name&nbsp;
                                <i
                                    onClick={() => requestSort("name")}
                                    className={getClassNamesFor("name")}
                                ></i>
                            </th>
                            <th width="5%">
                                Revision
                            </th>
                            <th width="10%">
                                Type &nbsp;
                                <i
                                    onClick={() => requestSort("displaytype")}
                                    className={getClassNamesFor("displaytype")}
                                ></i>
                            </th>
                            <th width="10%">Title &nbsp;</th>
                            <th width="10%">Description &nbsp;</th>
                            <th width="10%">Maturity State &nbsp;</th>
                            <th width="10%">Last Modification Date &nbsp;</th>
                            <th width="10%">
                                Release Date &nbsp;
                                <i
                                    onClick={() => requestSort("releaseDateForUiSorting")}
                                    className={getClassNamesFor("releaseDateForUiSorting")}
                                ></i>
                            </th>
                            <th width="10%">Creation Date &nbsp;</th>
                            <th width="10%">Originator &nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>{info_content}</tbody>
                    </Table>
                </div>
                </Container>
      </React.Fragment>
   )
}

export default SearchResultNew
