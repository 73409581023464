import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { DefaultKeys, FETCH_SEP, SEPKeys } from "../../constant/Constants"
import Attributes from "../commonComponents/Attributes"
import CommonAttributes from "../commonComponents/CommonAttributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import CustomCertifications from "../commonComponents/CustomCertifications"
import CustomEnterpriseParts from "../commonComponents/CustomEnterpriseParts"
import CustomMCTable from "../commonComponents/CustomMatrialAndComposition"
import CustomTable from "../commonComponents/CustomTable"
import CustomDerivedTable from "../commonComponents/DerivedTable"
import DownloadLinkTable from "../commonComponents/DownloadLinkTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import GenDocsFileDownTable from "../commonComponents/GenDocsFilesDownload"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../commonComponents/TopContentForSpecs"

function SepAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributeObject: {},
      reach: {},
      stabilityDocument: [],
      materialsAndComposition: [],
      substancesMaterials: [],
      relatedSpecification: [],
      referenceDocuments: [],
      enterpriseParts: [],
      equivalentMEP: [],
      certifications: [],
      relatedATS: [],
      lifecycleApprovalPowerview: [],
      ownershipObject: {},
      ipClasses: [],
      securityClass: [],
      organizationObject: {},
      files: [],
      gpsAssessments: [],
      packagingCertifications: [],
      // billOfMaterial: [],
      whereUsed: null,
   })

   const searchData = props.match.params.objectID
   const [loading, setLoading] = useState(true)
   const [sideNav, setSideNav] = useState()
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)
      var values = pdfData(data, obj, IsFilterView)
      setPdfDataSet(values)
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchSepData() {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")
               var apiData

               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  // Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_SEP}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContentObject)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContentObject
                     ? apiData.headerContentObject
                     : data.headerContent,
                  attributeObject: apiData.attributeObject,
                  organizationObject: apiData.organizationObject,
                  ownershipObject: apiData.ownershipObject,
                  securityClass: apiData.securityClass,
                  referenceDocuments: apiData.referenceDocuments,
                  enterpriseParts: apiData.enterpriseParts,
                  equivalentMEP: apiData.equivalentMEP,
                  certifications: apiData.certifications,
                  lifecycleApprovalPowerview: apiData.lifecycleApprovalPowerview,
                  reach: apiData.reach,
                  relatedSpecification: apiData.relatedSpecification,
                  billOfMaterial: apiData.billOfMaterial,
                  materialsAndComposition: apiData.materialsAndComposition,
                  ipClasses: apiData.ipClasses,
                  files: apiData.files,
                  substancesMaterials: apiData.substancesMaterials,
                  gpsAssessments: apiData.gpsAssessments,
                  stabilityDocument: apiData.stabilityDocument,
                  relatedATS: apiData.relatedATS,
                  packagingCertifications: apiData.certificationMaster,
                  whereUsed: apiData.whereUsed,
               }))
               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  notAuthorized(props.history)
                  return
               }
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         fetchSepData()
         window.scrollTo(0, 0)
      }
      return () => {
         return undefined
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={SEPKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" className="contentBackground">
                     <br />
                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="9" sm="9">
                              <TopContent headerContent={data.headerContent} />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              <PdfDownloadComponent pdfDataSet={pdfDataSet} />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {data.attributeObject && selectedItems["attributeObject"] && (
                              <div>
                                 <Card autoFocus>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(this, "0", "Zero")}
                                       aria-controls="Zero"
                                    >
                                       {SEPKeys.attributeObject}{" "}
                                       <i
                                          id="0"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="Zero"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <Attributes
                                       loading={loading}
                                       data={data.attributeObject}
                                    />
                                 </div>
                              </div>
                           )}
                           {!loading && data.reach && selectedItems["reach"] && (
                              <div>
                                 <Card>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(this, "1", "One")}
                                       aria-controls="One"
                                    >
                                       {SEPKeys.reach}{" "}
                                       <i
                                          id="1"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="One"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <CommonAttributes data={data.reach} />
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              data.stabilityDocument &&
                              data.stabilityDocument.length > 0 &&
                              selectedItems["stabilityDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "15",
                                             "fifteen"
                                          )}
                                          aria-controls="fifteen"
                                       >
                                          {SEPKeys.stabilityDocument}{" "}
                                          <i
                                             id="15"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fifteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.stabilityDocument} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.materialsAndComposition &&
                              data.materialsAndComposition.length > 0 &&
                              selectedItems["materialsAndComposition"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "2", "Two")}
                                          aria-controls="Two"
                                       >
                                          {SEPKeys.materialsAndComposition}{" "}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Two"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMCTable
                                          data={data.materialsAndComposition}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substancesMaterials &&
                              data.substancesMaterials.length > 0 &&
                              selectedItems["substancesMaterials"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "3", "three")}
                                          aria-controls="three"
                                       >
                                          {SEPKeys.substancesMaterials}{" "}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="three"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMCTable data={data.substancesMaterials} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedSpecification &&
                              data.relatedSpecification.length > 0 &&
                              selectedItems["relatedSpecification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "4", "four")}
                                          aria-controls="four"
                                       >
                                          {SEPKeys.relatedSpecification}{" "}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="four"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DownloadLinkTable
                                          data={data.relatedSpecification}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocuments &&
                              data.referenceDocuments.length > 0 &&
                              selectedItems["referenceDocuments"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "5", "five")}
                                          aria-controls="five"
                                       >
                                          {SEPKeys.referenceDocuments}{" "}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="five"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComRefDocDownload
                                          data={data.referenceDocuments}
                                          attributes={data.attributeObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.enterpriseParts &&
                              data.enterpriseParts.length > 0 &&
                              selectedItems["enterpriseParts"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "6", "six")}
                                          aria-controls="six"
                                       >
                                          {SEPKeys.enterpriseParts}{" "}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="six"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomEnterpriseParts
                                          data={data.enterpriseParts}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.equivalentMEP &&
                              data.equivalentMEP.length > 0 &&
                              selectedItems["equivalentMEP"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "7", "seven")}
                                          aria-controls="seven"
                                       >
                                          {SEPKeys.equivalentMEP}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="seven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.equivalentMEP} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.certifications &&
                              data.certifications.length > 0 &&
                              selectedItems["certifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "8", "eight")}
                                          aria-controls="eight"
                                       >
                                          {SEPKeys.certifications}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="eight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomCertifications
                                          data={data.certifications}
                                          header="Raw Material/Ancillary Raw Material Certifications"
                                          subHeader="Certifications"
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedATS &&
                              data.relatedATS.length > 0 &&
                              selectedItems["relatedATS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "16",
                                             "sixteen"
                                          )}
                                          aria-controls="sixteen"
                                       >
                                          {SEPKeys.relatedATS}{" "}
                                          <i
                                             id="16"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="sixteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedATS} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.lifecycleApprovalPowerview &&
                              data.lifecycleApprovalPowerview.length > 0 &&
                              selectedItems["lifecycleApprovalPowerview"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "9", "nine")}
                                          aria-controls="nine"
                                       >
                                          {SEPKeys.lifecycleApprovalPowerview}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="nine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomDerivedTable
                                          data={data.lifecycleApprovalPowerview}
                                          derived="Tasks/Signatures"
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ownershipObject &&
                              selectedItems["ownershipObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "10", "ten")}
                                          aria-controls="ten"
                                       >
                                          {SEPKeys.ownershipObject}{" "}
                                          <i
                                             id="10"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="ten"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes data={data.ownershipObject} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ipClasses &&
                              data.ipClasses.length > 0 &&
                              selectedItems["ipClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "11", "elevan")}
                                          aria-controls="elevan"
                                       >
                                          {SEPKeys.ipClasses}{" "}
                                          <i
                                             id="11"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="elevan"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.ipClasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClass &&
                              data.securityClass.length > 0 &&
                              selectedItems["securityClass"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "12", "twelve")}
                                          aria-controls="twelve"
                                       >
                                          {SEPKeys.securityClass}{" "}
                                          <i
                                             id="12"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twelve"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.securityClass} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.organizationObject &&
                              selectedItems["organizationObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "13",
                                             "thirteen"
                                          )}
                                          aria-controls="thirteen"
                                       >
                                          {SEPKeys.organizationObject}{" "}
                                          <i
                                             id="13"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes data={data.organizationObject} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.files &&
                              data.files.length > 0 &&
                              selectedItems["files"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "14",
                                             "fourteen"
                                          )}
                                          aria-controls="fourteen"
                                       >
                                          {SEPKeys.files}{" "}
                                          <i
                                             id="14"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <GenDocsFileDownTable
                                          data={data.files}
                                          attributes={data.attributesObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.gpsAssessments &&
                              data.gpsAssessments.length > 0 &&
                              selectedItems["gpsAssessments"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "17",
                                             "seventeen"
                                          )}
                                          aria-controls="seventeen"
                                       >
                                          {SEPKeys.gpsAssessments}{" "}
                                          <i
                                             id="17"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="seventeen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.gpsAssessments} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.packagingCertifications && data.packagingCertifications.certificationsList &&
                              data.packagingCertifications.certificationsList.length > 0 &&
                              selectedItems["packagingCertifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "18",
                                             "Eighteen"
                                          )}
                                          aria-controls="Eighteen"
                                       >
                                          {SEPKeys.packagingCertifications}{" "}
                                          <i
                                             id="18"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Eighteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.packagingCertifications.certificationsList} 
                                          certHeader = {data.packagingCertifications.header}/>
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {SEPKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default SepAllInfo
