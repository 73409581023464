import React, { useEffect, useState } from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import "../main.css"
import DesignResults from "./DesignResults"

function DesignParam(props) {
   const [data, setData] = useState([])
   const [calledFrom, setCalledFrom] = useState("Load")
   const vendorData = JSON.parse(JSON.stringify(props.data))

   function handleClick() {
      var groupInfo = document.getElementById("group")
      var thenInfo = document.getElementById("then")
      var finalInfo = document.getElementById("finally")
      var groupInfoVal = groupInfo.options[groupInfo.selectedIndex].value
      var thenInfoVal = thenInfo.options[thenInfo.selectedIndex].value
      var finalInfoVal = finalInfo.options[finalInfo.selectedIndex].value

      if(groupInfoVal === '' && thenInfoVal === '' && finalInfoVal === ''){
         groupInfo.value ='Layer Group'
         groupInfoVal = 'Layer Group'
      }
      if(groupInfoVal === thenInfoVal && groupInfoVal === finalInfoVal){
         thenInfo.value =''
         finalInfo.value =''
         thenInfoVal = ''
         finalInfoVal = ''
      }
      if(groupInfoVal === '' && (thenInfoVal !== '' &&  finalInfoVal !== '')){
         groupInfoVal = thenInfoVal
         groupInfo.value = thenInfoVal
         thenInfo.value =finalInfoVal
         finalInfo.value =''
         thenInfoVal = finalInfoVal
         finalInfoVal = ''
      }
      if(groupInfoVal === '' && thenInfoVal === '' &&  finalInfoVal !== ''){
         groupInfoVal = finalInfoVal
         groupInfo.value = finalInfoVal
         thenInfo.value =''
         finalInfo.value =''
         thenInfoVal = ''
         finalInfoVal = ''
      }
      if(groupInfoVal !== '' && thenInfoVal === '' &&  finalInfoVal !== ''){
         thenInfo.value =finalInfoVal
         finalInfo.value =''
         thenInfoVal = finalInfoVal
         finalInfoVal = ''
      }
      if(groupInfoVal === '' && thenInfoVal !== '' &&  finalInfoVal === ''){
         groupInfoVal = thenInfoVal
         groupInfo.value = thenInfoVal
         thenInfo.value =''
         finalInfo.value =''
         thenInfoVal = ''
         finalInfoVal = ''
      }
      if(groupInfoVal === thenInfoVal && groupInfoVal !== finalInfoVal){
         thenInfo.value = finalInfoVal
         thenInfoVal = finalInfoVal
      }
      if(groupInfoVal === finalInfoVal){
         finalInfo.value =''
         finalInfoVal = ''
      }
      if(thenInfoVal === finalInfoVal){
         finalInfo.value =''
         finalInfoVal = ''
      }
      setCalledFrom("Apply")
      let groupedData = groupBy(vendorData, groupInfoVal,thenInfoVal,finalInfoVal);
      setData(groupedData)
   }
   function cancelClick() {
      var groupInfo = document.getElementById("group")
      var thenInfo = document.getElementById("then")
      var finalInfo = document.getElementById("finally")
      groupInfo.value ='Layer Group'
      thenInfo.value =''
      finalInfo.value =''
      setCalledFrom("Apply")
      let groupedData = groupBy(vendorData, 'Layer Group','','');
      setData(groupedData)
   }


   const groupBy = (array, key1,key2,key3) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      if(key1 !== "" && key2 !=="" && key3 !==""){
        let arr1 = result[currentValue[key1]] || (result[currentValue[key1]] ={});
        let arr2 = arr1[currentValue[key2]] || ( arr1[currentValue[key2]]={});
        let arr3 = arr2[currentValue[key3]] || (arr2[currentValue[key3]] = []);
        arr3.push(currentValue);
      }
      if(key1 !== "" && key2 !=="" && key3 ===""){
        let arr1 = result[currentValue[key1]] || (result[currentValue[key1]] ={});
        let arr2 = arr1[currentValue[key2]] || ( arr1[currentValue[key2]]=[]);
        arr2.push(currentValue);
        }
      if(key1 !== "" && key2 ==="" && key3 ===""){
        let arr1 = result[currentValue[key1]] || (result[currentValue[key1]] =[])
        arr1.push(currentValue);
      }
      return result;
    }, {});
   };

   useEffect(() => {
       let groupedData = groupBy(vendorData, 'Layer Group','Layer','Parameter Set');
       setData(groupedData)
   }, [props.data])

   return (
      <React.Fragment>
         <Container fluid>
            <Row align="center" className="mt-3 mb-3">
               <Form.Label column md="1" sm="1">
                  Group By
               </Form.Label>
               <Col md="2" sm="2">
                  <Form.Control
                     as="select"
                     id="group"
                     className="vendorOptions"
                     defaultValue="Layer Group"
                  >
                     <option value = ""></option>
                     <option value="Layer Group">Layer Group</option>
                     <option value="Layer">Layer</option>
                     <option value="Chg.">Chg.</option>
                     <option value="Parameter Set">Parameter Set</option>
                     <option value="Design Parameter">Design Parameter</option>
                     <option value="Design Parameter Value">Design Parameter Value</option>
                     <option value="RMP Name">RMP Name</option>
                     <option value="Material Function">Material Function</option>
                  </Form.Control>
               </Col>
               <Form.Label column md="1" sm="1">
                  Then By
               </Form.Label>
               <Col md="2" sm="2">
                  <Form.Control
                     as="select"
                     id="then"
                     className="vendorOptions"
                     defaultValue="Layer"
                  >
                     <option value = ""></option>
                     <option value="Layer Group">Layer Group</option>
                     <option value="Layer">Layer</option>
                     <option value="Chg.">Chg.</option>
                     <option value="Parameter Set">Parameter Set</option>
                     <option value="Design Parameter">Design Parameter</option>
                     <option value="Design Parameter Value">Design Parameter Value</option>
                     <option value="RMP Name">RMP Name</option>
                     <option value="Material Function">Material Function</option>
                  </Form.Control>
               </Col>
               <Form.Label column md="1" sm="1">
                  Finally By
               </Form.Label>
               <Col md="2" sm="2">
                  <Form.Control
                     as="select"
                     id="finally"
                     className="vendorOptions"
                     defaultValue="Parameter Set"
                  >
                     <option value = ""></option>
                     <option value="Layer Group">Layer Group</option>
                     <option value="Layer">Layer</option>
                     <option value="Chg.">Chg.</option>
                     <option value="Parameter Set">Parameter Set</option>
                     <option value="Design Parameter">Design Parameter</option>
                     <option value="Design Parameter Value">Design Parameter Value</option>
                     <option value="RMP Name">RMP Name</option>
                     <option value="Material Function">Material Function</option>
                  </Form.Control>
               </Col>
               <Col md="1" sm="1">
                  <Button
                     style={{ backgroundColor: "#09f" }}
                     onClick={handleClick}
                  >
                     Apply
                  </Button>
               </Col>
               <Col md="1" sm="1">
                  <Button
                     style={{ backgroundColor: "#09f" }}
                     onClick={cancelClick}
                  >
                     Cancel
                  </Button>
               </Col>
            </Row>
            <Row>
               <Col md="12" style={{ marginTop: "0px" }}>
                    <div width="100%" style={{overflowX:"auto"}}>
                        <table width="200%">
                        <thead id="infoTab">
                           <tr>
                              <th width="20%">Layer Group</th>
                              <th width="10%">Layer</th>
                              <th width="10%">Chg.</th>
                              <th width="15%">Parameter Set</th>
                              <th width="10%">Design Parameter</th>
                              <th width="10%">Design Parameter Value</th>
                              <th width="10%">RMP Name</th>
                              <th width="15%">Material Function</th>
                            </tr>
                        </thead>
                        <tbody>
                            <DesignResults vendorData={vendorData} data={data} calledFrom={calledFrom}/>
                        </tbody>
                    </table>
                   </div>
               </Col>
            </Row>
         </Container>
      </React.Fragment>
   )
}
export default DesignParam
