import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { DefaultKeys, FETCH_TUP, PREF_MSG, TUPKeys } from "../../constant/Constants"
import Attributes from "../commonComponents/Attributes"
import CommonAttributes from "../commonComponents/CommonAttributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import CustomBOMTable from "../commonComponents/CustomBOMTable"
import CustomSubstitute from "../commonComponents/CustomSubstitute"
import CustomTable from "../commonComponents/CustomTable"
import DerivedTable from "../commonComponents/DerivedTable"
import DownloadLinkTable from "../commonComponents/DownloadLinkTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import GenDocsFileDownTable from "../commonComponents/GenDocsFilesDownload"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../commonComponents/TopContentForSpecs"

function TupAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributes: {},
      notes: [],
      billOfMaterials: [],
      substitutes: [],
      bomMasterCustomerUnit: [],
      bomMasterInnerPack: [],
      substitutesMasterInnerPack: [],
      bomMasterConsumerUnit: [],
      substitutesMasterConsumerUnit: [],
      // substitutesMasterCustomerUnit: [],
      relatedSpecification: [],
      referenceDocument: [],
      relatedATS: [],
      lifecycleApprovalPowerview: [],
      ownership: {},
      ipclasses: [],
      securityClasses: [],
      organization: {},
      fileSection: [],

      // storageTransportationLabelingAssessmentData: {},
      // derivedTo: [],
      // derivedFrom: [],
      whereUsed: null,
   })

   const searchData = props.match.params.objectID
   const [loading, setLoading] = useState(true)
   const [sideNav, setSideNav] = useState()
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})
   const userRole = localStorage.getItem("role")

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)
      var values = pdfData(data, obj, IsFilterView)
      setPdfDataSet(values)
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchTupData() {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")
               var apiData

               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  // Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_TUP}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContent)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContent
                     ? apiData.headerContent
                     : data.headerContent,
                  attributes: apiData.attributes,
                  organization: apiData.organization,
                  // storageTransportationLabelingAssessmentData: apiData.storageTransportationLabelingAssessmentData,
                  billOfMaterials: apiData.billOfMaterials,
                  lifecycleApprovalPowerview: apiData.lifecycleApprovalPowerview,
                  ownership: apiData.ownership,
                  relatedSpecification: apiData.relatedSpecification,
                  bomMasterCustomerUnit: apiData.bomMasterCustomerUnit,
                  // derivedTo: apiData.derivedTo,
                  // derivedFrom: apiData.derivedFrom,
                  bomMasterConsumerUnit: apiData.bomMasterConsumerUnit,
                  bomMasterInnerPack: apiData.bomMasterInnerPack,
                  //substitutesMasterCustomerUnit: apiData.substitutesMasterCustomerUnit,
                  substitutesMasterConsumerUnit: apiData.substitutesMasterConsumerUnit,
                  substitutesMasterInnerPack: apiData.substitutesMasterInnerPack,
                  substitutes: apiData.substitutes,
                  notes: apiData.notes,
                  securityClasses: apiData.securityClasses,
                  relatedATS: apiData.relatedATS,
                  referenceDocument: apiData.referenceDocument,
                  ipclasses: apiData.ipclasses,
                  fileSection: apiData.fileSection,
                  whereUsed: apiData.whereUsed,
               }))
               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  notAuthorized(props.history)
                  return
               }
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchTupData()
      }
      return () => {
         return undefined
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={TUPKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" className="contentBackground">
                     <br />
                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="9" sm="9">
                              <TopContent headerContent={data.headerContent} />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              <PdfDownloadComponent
                                 pdfDataSet={pdfDataSet}
                              />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {selectedItems["attributes"] && data.attributes && (
                              <div>
                                 <Card autoFocus>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(this, "0", "Zero")}
                                       aria-controls="Zero"
                                    >
                                       {TUPKeys.attributes}{" "}
                                       <i
                                          id="0"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="Zero"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <Attributes
                                       loading={loading}
                                       data={data.attributes}
                                    />
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              data.notes &&
                              data.notes.length > 0 &&
                              selectedItems["notes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "1", "One")}
                                          aria-controls="One"
                                       >
                                          {TUPKeys.notes}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="One"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.notes} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.billOfMaterials &&
                              data.billOfMaterials.length > 0 &&
                              selectedItems["billOfMaterials"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "2", "Two")}
                                          aria-controls="Two"
                                       >
                                          {TUPKeys.billOfMaterials}{" "}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Two"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable bom={data.billOfMaterials} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutes &&
                              data.substitutes.length > 0 &&
                              selectedItems["substitutes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "3", "Three")}
                                          aria-controls="Three"
                                       >
                                          {TUPKeys.substitutes}{" "}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Three"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSubstitute data={data.substitutes} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.bomMasterCustomerUnit &&
                              data.bomMasterCustomerUnit.length > 0 &&
                              selectedItems["bomMasterCustomerUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "4", "Four")}
                                          aria-controls="Four"
                                       >
                                          {TUPKeys.bomMasterCustomerUnit}{" "}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Four"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable bom={data.bomMasterCustomerUnit} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.bomMasterInnerPack &&
                              data.bomMasterInnerPack.length > 0 &&
                              selectedItems["bomMasterInnerPack"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "5", "Five")}
                                          aria-controls="Five"
                                       >
                                          {TUPKeys.bomMasterInnerPack}{" "}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Five"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable bom={data.bomMasterInnerPack} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutesMasterInnerPack &&
                              data.substitutesMasterInnerPack.length > 0 &&
                              selectedItems["substitutesMasterInnerPack"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "6", "Six")}
                                          aria-controls="Six"
                                       >
                                          {TUPKeys.substitutesMasterInnerPack}{" "}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Six"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSubstitute
                                          data={data.substitutesMasterInnerPack}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.bomMasterConsumerUnit &&
                              data.bomMasterConsumerUnit.length > 0 &&
                              selectedItems["bomMasterConsumerUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "7", "Seven")}
                                          aria-controls="Seven"
                                       >
                                          {TUPKeys.bomMasterConsumerUnit}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Seven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable bom={data.bomMasterConsumerUnit} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutesMasterConsumerUnit &&
                              data.substitutesMasterConsumerUnit.length > 0 &&
                              selectedItems["substitutesMasterConsumerUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "8", "Eight")}
                                          aria-controls="Eight"
                                       >
                                          {TUPKeys.substitutesMasterConsumerUnit}{" "}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Eight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSubstitute
                                          data={data.substitutesMasterConsumerUnit}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedSpecification &&
                              data.relatedSpecification.length > 0 &&
                              selectedItems["relatedSpecification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "9", "Nine")}
                                          aria-controls="Nine"
                                       >
                                          {TUPKeys.relatedSpecification}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Nine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DownloadLinkTable
                                          data={data.relatedSpecification}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocument &&
                              data.referenceDocument.length > 0 &&
                              selectedItems["referenceDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "10", "Ten")}
                                          aria-controls="Ten"
                                       >
                                          {TUPKeys.referenceDocument}{" "}
                                          <i
                                             id="10"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Ten"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComRefDocDownload
                                          data={data.referenceDocument}
                                          attributes={data.attributes}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedATS &&
                              data.relatedATS.length > 0 &&
                              selectedItems["relatedATS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "15",
                                             "Fifteen"
                                          )}
                                          aria-controls="Fifteen"
                                       >
                                          {TUPKeys.relatedATS}{" "}
                                          <i
                                             id="15"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Fifteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedATS} />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              data.lifecycleApprovalPowerview &&
                              data.lifecycleApprovalPowerview.length > 0 &&
                              selectedItems["lifecycleApprovalPowerview"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "11", "Eleven")}
                                          aria-controls="Eleven"
                                       >
                                          {TUPKeys.lifecycleApprovalPowerview}{" "}
                                          <i
                                             id="11"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Eleven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DerivedTable
                                          data={data.lifecycleApprovalPowerview}
                                          derived=""
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading && data.ownership && selectedItems["ownership"] && (
                              <div>
                                 <Card>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(this, "12", "Twelve")}
                                       aria-controls="Twelve"
                                    >
                                       {TUPKeys.ownership}{" "}
                                       <i
                                          id="12"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="Twelve"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <CommonAttributes data={data.ownership} />
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              data.ipclasses &&
                              data.ipclasses.length > 0 &&
                              selectedItems["ipclasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "13",
                                             "Thirteen"
                                          )}
                                          aria-controls="Thirteen"
                                       >
                                          {TUPKeys.ipclasses}{" "}
                                          <i
                                             id="13"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Thirteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.ipclasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClasses &&
                              data.securityClasses.length > 0 &&
                              selectedItems["securityClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "14",
                                             "Fourteen"
                                          )}
                                          aria-controls="Fourteen"
                                       >
                                          {TUPKeys.securityClasses}{" "}
                                          <i
                                             id="14"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Fourteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.securityClasses} />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              selectedItems["organization"] &&
                              data.organization && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "16",
                                             "Sixteen"
                                          )}
                                          aria-controls="Sixteen"
                                       >
                                          {TUPKeys.organization}{" "}
                                          <i
                                             id="16"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Sixteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes data={data.organization} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.fileSection &&
                              data.fileSection.length > 0 &&
                              selectedItems["fileSection"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "17",
                                             "Seventeen"
                                          )}
                                          aria-controls="Seventeen"
                                       >
                                          {TUPKeys.fileSection}{" "}
                                          <i
                                             id="17"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Seventeen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <GenDocsFileDownTable
                                          data={data.fileSection}
                                          attributes={data.attributesObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {TUPKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                           {selectedItems["message"] && (
                              <p className="errorMsgStyle">{PREF_MSG}</p>
                           )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
         {/* </React.Fragment>} */}
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default TupAllInfo
