import React from "react"
import Attribute from "../commonComponents/Attributes"
import CustomBOMTable from "./CustomBOMTable"

export const CustomMultiBomTable = (props) => {
   const billOfMaterials = props.data
   const attribute = billOfMaterials.topHeader
   const boms = billOfMaterials.boms

   const table_Contents = (boms) =>
      boms.map((bom, index) => {
         if (index === 0) {
            return (
               <div key={index}>
                  <CustomBOMTable bom={bom} />
                  <hr />
               </div>
            )
         } else {
            return (
               <div key={index}>
                  <CustomBOMTable bom={bom} isHeader="true" />
                  <hr />
               </div>
            )
         }
      })

   return (
      <div>
         {attribute && (
            <div>
               <Attribute data={attribute} /> <hr />
            </div>
         )}
         {boms && table_Contents(boms)}
      </div>
   )
}
