import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { DefaultKeys, FETCH_IP, IPKeys } from "../../constant/Constants"
import Attributes from "../commonComponents/Attributes"
import CommonAttributes from "../commonComponents/CommonAttributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import CustomBOMTable from "../commonComponents/CustomBOMTable"
import CustomMasterSpecification from "../commonComponents/CustomMasterSpecification"
import CustomPCTable from "../commonComponents/CustomPCTable"
import CustomSubstitute from "../commonComponents/CustomSubstitute"
import CustomTable from "../commonComponents/CustomTable"
import CustomDerivedTable from "../commonComponents/DerivedTable"
import DownloadLinkTable from "../commonComponents/DownloadLinkTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import GenDocsFileDownTable from "../commonComponents/GenDocsFilesDownload"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../commonComponents/TopContentLatest"

function IpAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributesObject: {},
      weightCharacterstics: [],
      notes: [],
      billOfMaterials: [],
      substitutes: [],
      relatedSpecifications: [],
      masterSpecifications: [],
      referenceDocument: [],
      performanceCharacteristic: [],
      relatedATS: [],
      plants: [],
      lifecycleApprovalPowerview: [],
      ownershipObject: {},
      ipClasses: [],
      securityClasses: [],
      organizationsObject: {},
      files: [],
      whereUsed: null,
   })

   const searchData = props.match.params.objectID
   const [loading, setLoading] = useState(true)
   const [sideNav, setSideNav] = useState()
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)

      var values = pdfData(data, obj, IsFilterView)

      setPdfDataSet(values)
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchCopData() {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")

               var apiData
               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  // Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_IP}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContent)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
                  // apiData = JSON.parse(sessionStorage.getItem(searchData))
                  //  if (fetchState.cancel) { return; }
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContent
                     ? apiData.headerContent
                     : data.headerContent,
                  attributesObject: apiData.attributesObject,
                  organizationsObject: apiData.organizationsObject,
                  billOfMaterials: apiData.billOfMaterials,
                  lifecycleApprovalPowerview: apiData.lifecycleApprovalPowerview,
                  ownershipObject: apiData.ownershipObject,
                  notes: apiData.notes,
                  substitutes: apiData.substitutes,
                  securityClasses: apiData.securityClasses,
                  relatedATS: apiData.relatedATS,
                  relatedSpecifications: apiData.relatedSpecifications,
                  referenceDocument: apiData.referenceDocument,
                  performanceCharacteristic: apiData.performanceCharacteristic,
                  masterSpecifications: apiData.masterSpecifications,
                  weightCharacterstics: apiData.weightCharacterstics,
                  ipClasses: apiData.ipClasses,
                  files: apiData.files,
                  plants: apiData.plants,
                  whereUsed: apiData.whereUsed,
               }))
               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  console.log("Error", ` Not authorie: ${error}`)
                  notAuthorized(props.history)
                  return
               }
               console.log("Error", ` API request failed: ${error}`)
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchCopData()
      }
      return () => {
         return undefined
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={IPKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" className="contentBackground">
                     <br />
                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="9" sm="9">
                              <TopContent headerContent={data.headerContent} />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              <PdfDownloadComponent pdfDataSet={pdfDataSet} />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {data.attributesObject && selectedItems["attributesObject"] && (
                              <div>
                                 <Card autoFocus>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(this, "0", "zero")}
                                       aria-controls="zero"
                                    >
                                       {IPKeys.attributesObject}{" "}
                                       <i
                                          id="0"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="zero"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <Attributes
                                       loading={loading}
                                       data={data.attributesObject}
                                    />
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              data.weightCharacterstics &&
                              selectedItems["weightCharacterstics"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "1", "one")}
                                          aria-controls="one"
                                       >
                                          {IPKeys.weightCharacterstics}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="one"
                                       name="collapseItem"
                                    >
                                       <CommonAttributes
                                          data={data.weightCharacterstics}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.notes &&
                              data.notes.length > 0 &&
                              selectedItems["notes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "2", "two")}
                                          aria-controls="two"
                                       >
                                          {IPKeys.notes}{" "}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="two"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.notes} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.billOfMaterials &&
                              data.billOfMaterials.length > 0 &&
                              selectedItems["billOfMaterials"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "3", "three")}
                                          aria-controls="three"
                                       >
                                          {IPKeys.billOfMaterials}{" "}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="three"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomBOMTable bom={data.billOfMaterials} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutes &&
                              data.substitutes.length > 0 &&
                              selectedItems["substitutes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "4", "four")}
                                          aria-controls="four"
                                       >
                                          {IPKeys.substitutes}{" "}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="four"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSubstitute data={data.substitutes} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedSpecifications &&
                              data.relatedSpecifications.length > 0 &&
                              selectedItems["relatedSpecifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "5", "five")}
                                          aria-controls="five"
                                       >
                                          {IPKeys.relatedSpecifications}{" "}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="five"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DownloadLinkTable
                                          data={data.relatedSpecifications}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.masterSpecifications &&
                              data.masterSpecifications.length > 0 &&
                              selectedItems["masterSpecifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "6", "six")}
                                          aria-controls="six"
                                       >
                                          {IPKeys.masterSpecifications}{" "}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="six"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMasterSpecification
                                          data={data.masterSpecifications}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocument &&
                              data.referenceDocument.length > 0 &&
                              selectedItems["referenceDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "7", "seven")}
                                          aria-controls="seven"
                                       >
                                          {IPKeys.referenceDocument}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="seven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComRefDocDownload
                                          data={data.referenceDocument}
                                          attributes={data.attributesObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.performanceCharacteristic &&
                              data.performanceCharacteristic.length > 0 &&
                              selectedItems["performanceCharacteristic"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "8", "eight")}
                                          aria-controls="eight"
                                       >
                                          {IPKeys.performanceCharacteristic}{" "}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="eight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPCTable
                                          data={data.performanceCharacteristic}
                                       />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.plants &&
                              data.plants.length > 0 &&
                              selectedItems["plants"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "9", "nine")}
                                          aria-controls="nine"
                                       >
                                          {IPKeys.plants}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="nine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.plants} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedATS &&
                              data.relatedATS.length > 0 &&
                              selectedItems["relatedATS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "14",
                                             "fourteen"
                                          )}
                                          aria-controls="fourteen"
                                       >
                                          {IPKeys.relatedATS}{" "}
                                          <i
                                             id="14"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedATS} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.lifecycleApprovalPowerview &&
                              data.lifecycleApprovalPowerview.length > 0 &&
                              selectedItems["lifecycleApprovalPowerview"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "10", "ten")}
                                          aria-controls="ten"
                                       >
                                          {IPKeys.lifecycleApprovalPowerview}
                                          <i
                                             id="10"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="ten"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomDerivedTable
                                          data={data.lifecycleApprovalPowerview}
                                          derived="Tasks/Signatures"
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ownershipObject &&
                              selectedItems["ownershipObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "11", "elevan")}
                                          aria-controls="elevan"
                                       >
                                          {IPKeys.ownershipObject}
                                          <i
                                             id="11"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="elevan"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes data={data.ownershipObject} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ipClasses &&
                              data.ipClasses.length > 0 &&
                              selectedItems["ipClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "12", "twelve")}
                                          aria-controls="twelve"
                                       >
                                          {IPKeys.ipClasses}{" "}
                                          <i
                                             id="12"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twelve"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.ipClasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClasses &&
                              data.securityClasses.length > 0 &&
                              selectedItems["securityClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "13",
                                             "thirteen"
                                          )}
                                          aria-controls="thirteen"
                                       >
                                          {IPKeys.securityClasses}{" "}
                                          <i
                                             id="13"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.securityClasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.organizationsObject &&
                              selectedItems["organizationsObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "15",
                                             "fifteen"
                                          )}
                                          aria-controls="fifteen"
                                       >
                                          {IPKeys.organizationsObject}{" "}
                                          <i
                                             id="15"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fifteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes
                                          data={data.organizationsObject}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.files &&
                              data.files.length > 0 &&
                              selectedItems["files"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "16",
                                             "sixteen"
                                          )}
                                          aria-controls="sixteen"
                                       >
                                          {IPKeys.files}{" "}
                                          <i
                                             id="16"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="sixteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <GenDocsFileDownTable
                                          data={data.files}
                                          attributes={data.attributesObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {IPKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default IpAllInfo
