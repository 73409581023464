import React, { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { DSMReportGenerate } from "../../../api/AuthService"
import { REPORT_SUBMIT } from "../../../constant/Constants"

function ReportSubmitCertRollUp(props) {
   const regex = /^[0-9]{8}\.{1}[0-9]{3}$/
   let y = false

   const checkFormat = (x) => {
     
      if (props.data.partName.length !== 0) {
        
         for (let i = 0; i < x.length; i++) {
            let z = x[i].trim()
            if (!regex.test(z)) {
              
               y = true
            } else {
              
               y = false
            }
         }
      }
   }
   let duplicate_partName = props.data.partName.replace(/[\n]+/g, ",")
   let array = duplicate_partName.split(",")
  
   checkFormat(array)

   const [disabled, setDisabled] = useState(false)
   const [submitLoading, setSubmitLoading] = useState(false)

   let clientErrorCode = 0
   const history = useHistory()

   const sanitizeHelper = (data) => {
     
      const removeNewLine = data.replace(/[\n]+/g, ",")
      const formatedText = removeNewLine.replace(/[\t]+/g, " ")
      const commaSeprated = formatedText.split(",")
      const finalValue = commaSeprated.filter((text) => text.length > 0)
      data = finalValue.join()
     
      return data
   }

   const sanitizedData = (data) => {
      if (data.partName !== "") {
         data.partName = sanitizeHelper(data.partName)
      }

      return data
   }

   const validateForm = () => {
      let formValid = true
      if (props.data.reportName === "Certification Roll-Up") {
         if (props.data.partName === "") {
            formValid = false
         }
         // if(y){
         //    formValid=false
         //    clientErrorCode=996
         // }
         if (props.data.partName.length === 0) {
            formValid = false
            clientErrorCode = 999
         } else if (props.data.certificationClaim.length === 0) {
            formValid = false
            clientErrorCode = 998
         }
      }

      return formValid
   }

   

   const handleSubmit = () => {
      if (validateForm()) {
         props.clearMsg()
        
         const sanitized = sanitizedData(props.data)
        
         setSubmitLoading(true)
         setDisabled(true)

         DSMReportGenerate(`${REPORT_SUBMIT}`, sanitized)
            .then((response) => {
               props.handler(response.status)
               
               setTimeout(() => {
                  history.goBack()
               }, 2000)
               setSubmitLoading(false)
            })
            .catch((error) => {
               if (error.response && error.response.status) {
                  props.handler(error.response.status, error.response.data.message)
               } else {
                  props.handler(1000, error)
               }
               setSubmitLoading(false)
               setDisabled(false)
            })
      } else {
         if (clientErrorCode !== 0) {
            props.handler(clientErrorCode)
         }
         // else if(props.data.partName.length===0) {
         //    props.handler(999)
         // }

         setDisabled(false)
      }
   }

   return (
      <React.Fragment>
         <Row>
            <Col md="11" align="center">
               <Button variant="primary" onClick={handleSubmit} disabled={disabled}>
                  {submitLoading ? (
                     <i
                        className="fa fa-spinner fa-spin"
                        style={{ marginRight: "5px" }}
                     />
                  ) : (
                     <span>Submit</span>
                  )}
               </Button>
               &nbsp;
               <Button variant="primary" onClick={history.goBack}>
                  Cancel
               </Button>
               &nbsp;
               <Button variant="primary" onClick={props.handleClearItems}>
                  Reset
               </Button>
            </Col>
         </Row>
      </React.Fragment>
   )
}
export default ReportSubmitCertRollUp
