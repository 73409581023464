import React from "react"
import { Col, Row } from "react-bootstrap"
import CustomLink from "../commonComponents/CustomLink"

const TopHeaderContents = (props) => {
   const prop = props.headerContent

   return (
      <div>
         <React.Fragment>
            <Row>
               <Col>
                  <Row>
                     <Col md="3" sm="3">
                        <span id="pageheaders">Name: </span>
                        {prop.Name}
                     </Col>
                     <Col md="3" sm="3" align="right">
                        <span id="pageheaders">Revision: </span>
                        {prop.Revision}
                     </Col>
                     <Col md="3" sm="3" align="right">
                        <span id="pageheaders">State: </span>
                        {prop.State}
                     </Col>
                     <Col md="2" sm="2" align="right">
                        <span id="pageheaders">Is ATS: </span>
                        {prop["Is ATS"]}
                     </Col>
                  </Row>
                  <Row style={{ marginTop: ".2rem" }}>
                     <Col md="9" sm="9">
                        <span id="pageheaders">SAP Description: </span>
                        {prop["SAP Description"]}
                     </Col>
                     <Col md="2" sm="2" align="right">
                        <span id="pageheaders">Has ATS: </span>
                        {prop["Has ATS"]}
                     </Col>
                  </Row>
               </Col>

               <Col md="2.5" sm="2.5" style={{ marginTop: "1.5rem" }} align="right">
                  <span id="pageheaders">Master: </span>
                  {prop.link && prop.masterpartid ? (
                     <CustomLink
                        id={prop.masterpartid}
                        link={prop.link}
                        name={prop["Master Part"]}
                     />
                  ) : (
                     prop["Master Part"]
                  )}
               </Col>
            </Row>
         </React.Fragment>
      </div>
   )
}

export default TopHeaderContents
