import React, { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { DSMReportGenerate } from "../../../api/AuthService"
import { REPORT_SUBMIT } from "../../../constant/Constants"

function ReportSubmit(props) {
   const [disabled, setDisabled] = useState(false)
   const [submitLoading, setSubmitLoading] = useState(false)

   let clientErrorCode = 0;
   const history = useHistory()

   const sanitizeHelper = (data) => {
      const removeNewLine = data.replace(/[\n]+/g,",")
      const formatedText = removeNewLine.replace(/[\t]+/g, " ")
      const commaSeprated = formatedText.split(',')
      const finalValue = commaSeprated.filter(text => text.length > 0);
      data = finalValue.join()
      return data
   }

   const sanitizedData = (data) => {
      if (data.partName !== ""){
         data.partName = sanitizeHelper(data.partName)
      }
      if (data.changeAction !== ""){
         data.changeAction = sanitizeHelper(data.changeAction)
      }
      return data
   }

   const validateForm = () => {
      let formValid = true;
      if (
         props.data.reportName === "PDF Download" ||
         props.data.reportName === "Parts and Specification Report"
      ) {
         if (
            props.data.partName === "" &&
            props.data.fileData === "" &&
            props.data.changeAction === ""
         ) {
            formValid = false
         }

         if (
            (props.data.partName !== "" || props.data.changeAction !== "") &&
            props.data.fileData !== ""
         ) {
            formValid = false
         }
         if (props.tabSize && props.tabSize === -1) {
            formValid = false
            clientErrorCode = 997
         }
      } else {
         if (props.data.partName === "" && props.data.fileData === "") {
            formValid = false
         }

         if (props.data.partName !== "" && props.data.fileData !== "") {
            formValid = false
         }

         if (props.tabSize && props.tabSize === -1) {
            formValid = false
            clientErrorCode = 998
         }
      }
      return formValid
   }

   const handleSubmit = () => {
      if (validateForm()) {
         props.clearMsg()
         const sanitized = sanitizedData(props.data)
         setSubmitLoading(true)
         setDisabled(true)
         DSMReportGenerate(`${REPORT_SUBMIT}`, sanitized)
            .then((response) => {
               props.handler(response.status)
               setTimeout(() => {
                  history.goBack()
               }, 2000);
               setSubmitLoading(false)
            })
            .catch((error) => {
               if (error.response && error.response.status) {
                  props.handler(error.response.status, error.response.data.message)
               } else {
                  props.handler(1000, error)
               }
               setSubmitLoading(false)
               setDisabled(false)
            })
      } else {
         if (clientErrorCode !== 0) {
            props.handler(clientErrorCode)
         } else {
            props.handler(999)
         }
         setDisabled(false)
      }
   }

   return (
      <React.Fragment>
         <Row>
            <Col md="11" align="center">
               <Button variant="primary" onClick={handleSubmit} disabled={disabled}>
                  {submitLoading ? (
                     <i className="fa fa-spinner fa-spin" style={{ marginRight: "5px" }} />
                  ) : (
                     <span>Submit</span>
                  )}
               </Button>
               &nbsp;
               <Button variant="primary" onClick={history.goBack}>
                  Cancel
               </Button>
               &nbsp;
               <Button variant="primary" onClick={props.handleClearItems}>
                  Reset
               </Button>
            </Col>
         </Row>
      </React.Fragment>
   )
}
export default ReportSubmit
