import React, { Component } from "react"
//import config from "../../Config"

export class Redirect extends Component {
   constructor(props) {
      super()
   }

   componentDidMount() {
      let gcas=""
      if(localStorage.getItem("calledFrom") && localStorage.getItem("calledFrom") ==="gcas"){
         gcas = localStorage.getItem("gcas")
      }
      localStorage.removeItem("id_token")
      localStorage.removeItem("User")
      localStorage.clear()
      sessionStorage.clear()
      localStorage.setItem("gcas",gcas)

      let authUrl =
         `${process.env.REACT_APP_AUTH_URI}/as/authorization.oauth2?` +
         `response_type=${process.env.REACT_APP_responseType}&` +
         `client_id=${process.env.REACT_APP_clientId}&` +
         `scope=${process.env.REACT_APP_scope}&` +
         `display_name=${process.env.REACT_APP_display_name}&` +
         `redirect_uri=${process.env.REACT_APP_redirectUri}` +
         `&state=/tokens/authcode`
      window.location.href = authUrl
   }

   render() {
      return <section></section>
   }
}
export default Redirect
