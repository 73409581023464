export const PDFViews = {
    APMP:{
        SP:"Supplier View",
    },
    ARMP:{
        SP:"Supplier View",
    },
    APP:{
        CM:"Contract Manufacturing View",
    },
    CUP:{
        CM:"Contract Manufacturing View",
    },
    COP:{
        CM:"Contract Manufacturing View",
    },
    DPP:{
        CM:"Contract Manufacturing View",
    },
    FAB:{
        CM:"Contract Manufacturing View",
        SP:"Supplier View",
    },
    FPP:{
        CM:"Contract Manufacturing View",
    },
    FOP:{ 
        CM:"Contract Manufacturing View",
    },
    IP:{
        CM:"Contract Manufacturing View",  
    },
    IPP:{
        CM:"Contract Manufacturing View",
    },
    IRMS:{
        SP:"Supplier View",
    },
    MEP:{
        SP:"Supplier View",
    },
    MCUP:{
        CM:"Contract Manufacturing View",
        SP:"Supplier View",
    },
    MCOP:{
        CM:"Contract Manufacturing View",
        SP:"Supplier View",
    },
    MIP:{
        CM:"Contract Manufacturing View",
        SP:"Supplier View",
    },
    MPAP:{
        CM:"Contract Manufacturing View",
        SP:"Supplier View",
    },
    MPP:{
        CM:"Contract Manufacturing View",
        SP:"Supplier View",
    },
    MRMP:{
        CM:"Contract Manufacturing View",
        SP:"Supplier View",
    },
    MPMP:{
        CM:"Contract Manufacturing View",
        SP:"Supplier View",
    },
    OPP:{
        SP:"Supplier View",
    },
    PAP:{
        CM:"Contract Manufacturing View",
        SP:"Supplier View",
    },
    PMP:{
        SP:"Supplier View",
    },
    PIP:{
        SP:"Supplier View",
    },
    RMP:{
        CM:"Contract Manufacturing View",
        SP:"Supplier View",
    },
    SWP:{
        CM:"Contract Manufacturing View",
        SP:"Supplier View",
    },
    SEP:{
        SP:"Supplier View",
    },
    TUP:{
        CM:"External View", 
    }

}
