import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import {
   ComIRMkeys,
   ComIRMSubAttributekeys,
   DefaultKeys,
   FETCH_COMMONPAGE_DATA,
} from "../../constant/Constants"
import Attributes from "../commonComponents/Attributes"
import CommonAttribute from "../commonComponents/CommonAttributes"
import CustomTable from "../commonComponents/CustomTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import HeaderComponent from "../commonComponents/HeaderComponent"
import IRMGenDocComponent from "../commonComponents/IRMGenDocComponent"
import ReferenceDocsDownload from "../commonComponents/ReferenceDocsDownload"
import SideNav from "../commonComponents/SideNav"
import ComIRMGenDocsFilesDownload from "./ComIRMGenDocsFilesDownload"
import ComIRMRelatedParts from "./ComIRMRelatedParts"
import IRMRelatedPartsStudyProtocol from "./IRMRelatedPartsStudyProtocol"
import TopContent from "./IRMTopContent"
//import PdfDownloadComponent from '../commonComponents/PdfDownloadComponent'
function ComIRMAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributeList: {},
      platFormAndChassis: [],
      Files: [],
      relatedParts: [],
      relatedPartsStudyProtocol: [],
      referenceDocuments: [],
      multipleOwnershipAccess: [],
      ipSecuritySetting: [],
      securityClasses: [],
      gpsAssessments: [],
      attrAgeConditionControls: {},
      attrStudyObjectivesSuccessCriteria: {},
      attrStudyDesign: {},
      attrStudyClassification: {},
      attrPanelistRecruitingCriteria: {},
      attrSpecialProductElements: {},
      attrPanelistTasks: {},
      attrGPSAssessmentType: {},
      attrStudyLocationDates: {},
      attrAgencyTasks: {},
      attrBudgetOwnerApproval: {},
      attrGPSAssessment: {},
      whereUsed: null,
   })

   const searchData = props.match.params.objectID
   const searchType = "IRM"

   const [loading, setLoading] = useState(false)
   const [sideNav, setSideNav] = useState("")
   const [inProp, setInProp] = useState(false)
   const [pdfDataSet, setPdfDataSet] = useState({})

   const [selectedItems, setSelectedItems] = useState(DefaultKeys)

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)
      var values = pdfData(data, obj, IsFilterView)
      setPdfDataSet(values)
   }

   useEffect(() => {
      const fetchState = { cancel: false }
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchCommonGenDoc() {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")

               let sessionKey = searchType + searchData
               var apiData
               if (
                  sessionStorage.getItem(sessionKey) &&
                  sessionStorage.getItem(sessionKey) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(sessionKey))
               } else {
                  // Load async data from an  Api endpoint.
                  const response = await fetchAPIData(
                     `${FETCH_COMMONPAGE_DATA}/${searchType}/${searchData}`
                  )
                  apiData = response.data
                  if (apiData.commonDocument.headerContent)
                     sessionStorage.setItem(sessionKey, JSON.stringify(response.data))
               }

               if (fetchState.cancel) {
                  return
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.commonDocument.headerContent,
                  attributeList: apiData.commonDocument.Attributes,
                  platFormAndChassis: apiData.commonDocument.platFormAndChassis,
                  Files: apiData.commonDocument.Files,
                  relatedParts: apiData.commonDocument.relatedParts,
                  relatedPartsStudyProtocol:
                     apiData.commonDocument.relatedPartsStudyProtocol,
                  referenceDocuments: apiData.commonDocument.referenceDocuments,
                  multipleOwnershipAccess: apiData.commonDocument.multipleOwnershipAccess,
                  ipSecuritySetting: apiData.commonDocument.ipSecuritySetting,
                  attrAgeConditionControls:
                     apiData.commonDocument.attrAgeAndConditionControls,
                  attrStudyObjectivesSuccessCriteria:
                     apiData.commonDocument.attrStudyObjectiveAndSuccessCriteria,
                  attrStudyDesign: apiData.commonDocument.attrStudyDesign,
                  attrStudyClassification: apiData.commonDocument.attrStudyClassification,
                  attrPanelistRecruitingCriteria:
                     apiData.commonDocument.attrPanelistRecuritingCriteria,
                  attrSpecialProductElements:
                     apiData.commonDocument.attrSpecialProductElement,
                  attrPanelistTasks: apiData.commonDocument.attrPanelistTask,
                  attrGPSAssessmentType: apiData.commonDocument.attrGpsAssesmentTask,
                  attrStudyLocationDates: apiData.commonDocument.attrStudyLocationDate,
                  attrAgencyTasks: apiData.commonDocument.attrAgencyTask,
                  attrBudgetOwnerApproval: apiData.commonDocument.attrBudgetOwnerApproval,
                  attrGPSAssessment: apiData.commonDocument.attrGpsAssesment,
                  securityClasses: apiData.commonDocument.securityClasses,
                  gpsAssessments: apiData.commonDocument.gpsAssessments,
                  whereUsed: apiData.commonDocument.whereUsed,
               }))

               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  console.log("Error", ` Not authorie: ${error}`)
                  notAuthorized(props.history)
                  return
               }
               console.log("Error", ` API request failed: ${error}`)
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchCommonGenDoc()
      }
      return () => {
         fetchState.cancel = true
      }
   }, [searchData, searchType, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} />
         <CSSTransition timeout={500} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name: {data.headerContent.Name} <br />
                                 Revision: {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={ComIRMkeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" className="contentBackground">
                     <br />
                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row>
                           <Col md="9" sm="9">
                              <TopContent headerContent={data.headerContent} />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              <IRMGenDocComponent
                                 data={data.headerContent}
                                 attributeList={data.attributeList}
                                 gendoc={data.headerContent.gendoc}
                                 pdfdataSet={pdfDataSet}
                                 pdfHide={
                                    data.headerContent.Type &&
                                    data.headerContent.Type === "Study Protocol"
                                       ? false
                                       : true
                                 }
                              />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {data.attributeList && selectedItems["attributeList"] && (
                              <div>
                                 <Card autoFocus>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(this, "0", "Zero")}
                                       aria-controls="Zero"
                                    >
                                       {ComIRMkeys.attributeList}{" "}
                                       <i
                                          id="0"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="Zero"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <Attributes
                                       loading={loading}
                                       data={data.attributeList}
                                    />
                                    {!loading && data.attrAgeConditionControls && (
                                       <React.Fragment>
                                          <br />
                                          <Card>
                                             <Card.Body
                                                style={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#eef1f4",
                                                }}
                                             >
                                                {
                                                   ComIRMSubAttributekeys.attrAgeConditionControls
                                                }
                                             </Card.Body>
                                          </Card>
                                          <div className="accordionDiv">
                                             <CommonAttribute
                                                data={data.attrAgeConditionControls}
                                             />
                                          </div>
                                       </React.Fragment>
                                    )}
                                    {!loading && data.attrStudyObjectivesSuccessCriteria && (
                                       <React.Fragment>
                                          <br />
                                          <Card>
                                             <Card.Body
                                                style={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#eef1f4",
                                                }}
                                             >
                                                {
                                                   ComIRMSubAttributekeys.attrStudyObjectivesSuccessCriteria
                                                }
                                             </Card.Body>
                                          </Card>
                                          <div className="accordionDiv">
                                             <CommonAttribute
                                                data={
                                                   data.attrStudyObjectivesSuccessCriteria
                                                }
                                             />
                                          </div>
                                       </React.Fragment>
                                    )}
                                    {!loading && data.attrGPSAssessmentType && (
                                       <React.Fragment>
                                          <br />
                                          <Card>
                                             <Card.Body
                                                style={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#eef1f4",
                                                }}
                                             >
                                                {
                                                   ComIRMSubAttributekeys.attrGPSAssessmentType
                                                }
                                             </Card.Body>
                                          </Card>
                                          <div className="accordionDiv">
                                             <CommonAttribute
                                                data={data.attrGPSAssessmentType}
                                             />
                                          </div>
                                       </React.Fragment>
                                    )}
                                    {!loading && data.attrStudyDesign && (
                                       <React.Fragment>
                                          <br />
                                          <Card>
                                             <Card.Body
                                                style={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#eef1f4",
                                                }}
                                             >
                                                {ComIRMSubAttributekeys.attrStudyDesign}
                                             </Card.Body>
                                          </Card>
                                          <div className="accordionDiv">
                                             <CommonAttribute
                                                data={data.attrStudyDesign}
                                             />
                                          </div>
                                       </React.Fragment>
                                    )}
                                    {!loading && data.attrStudyClassification && (
                                       <React.Fragment>
                                          <br />
                                          <Card>
                                             <Card.Body
                                                style={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#eef1f4",
                                                }}
                                             >
                                                {
                                                   ComIRMSubAttributekeys.attrStudyClassification
                                                }
                                             </Card.Body>
                                          </Card>
                                          <div className="accordionDiv">
                                             <CommonAttribute
                                                data={data.attrStudyClassification}
                                             />
                                          </div>
                                       </React.Fragment>
                                    )}
                                    {!loading && data.attrPanelistRecruitingCriteria && (
                                       <React.Fragment>
                                          <br />
                                          <Card>
                                             <Card.Body
                                                style={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#eef1f4",
                                                }}
                                             >
                                                {
                                                   ComIRMSubAttributekeys.attrPanelistRecruitingCriteria
                                                }
                                             </Card.Body>
                                          </Card>
                                          <div className="accordionDiv">
                                             <CommonAttribute
                                                data={data.attrPanelistRecruitingCriteria}
                                             />
                                          </div>
                                       </React.Fragment>
                                    )}
                                    {!loading && data.attrPanelistTasks && (
                                       <React.Fragment>
                                          <br />
                                          <Card>
                                             <Card.Body
                                                style={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#eef1f4",
                                                }}
                                             >
                                                {ComIRMSubAttributekeys.attrPanelistTasks}
                                             </Card.Body>
                                          </Card>
                                          <div className="accordionDiv">
                                             <CommonAttribute
                                                data={data.attrPanelistTasks}
                                             />
                                          </div>
                                       </React.Fragment>
                                    )}
                                    {!loading && data.attrSpecialProductElements && (
                                       <React.Fragment>
                                          <br />
                                          <Card>
                                             <Card.Body
                                                style={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#eef1f4",
                                                }}
                                             >
                                                {
                                                   ComIRMSubAttributekeys.attrSpecialProductElements
                                                }
                                             </Card.Body>
                                          </Card>
                                          <div className="accordionDiv">
                                             <CommonAttribute
                                                data={data.attrSpecialProductElements}
                                             />
                                          </div>
                                       </React.Fragment>
                                    )}

                                    {!loading && data.attrStudyLocationDates && (
                                       <React.Fragment>
                                          <br />
                                          <Card>
                                             <Card.Body
                                                style={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#eef1f4",
                                                }}
                                             >
                                                {
                                                   ComIRMSubAttributekeys.attrStudyLocationDates
                                                }
                                             </Card.Body>
                                          </Card>
                                          <div className="accordionDiv">
                                             <CommonAttribute
                                                data={data.attrStudyLocationDates}
                                             />
                                          </div>
                                       </React.Fragment>
                                    )}
                                    {!loading && data.attrAgencyTasks && (
                                       <React.Fragment>
                                          <br />
                                          <Card>
                                             <Card.Body
                                                style={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#eef1f4",
                                                }}
                                             >
                                                {ComIRMSubAttributekeys.attrAgencyTasks}
                                             </Card.Body>
                                          </Card>
                                          <div className="accordionDiv">
                                             <CommonAttribute
                                                data={data.attrAgencyTasks}
                                             />
                                          </div>
                                       </React.Fragment>
                                    )}
                                    {!loading && data.attrBudgetOwnerApproval && (
                                       <React.Fragment>
                                          <br />
                                          <Card>
                                             <Card.Body
                                                style={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#eef1f4",
                                                }}
                                             >
                                                {
                                                   ComIRMSubAttributekeys.attrBudgetOwnerApproval
                                                }
                                             </Card.Body>
                                          </Card>
                                          <div className="accordionDiv">
                                             <CommonAttribute
                                                data={data.attrBudgetOwnerApproval}
                                             />
                                          </div>
                                       </React.Fragment>
                                    )}
                                    {!loading &&
                                       data.attrGPSAssessment &&
                                       data.attrGPSAssessment.length > 0 && (
                                          <React.Fragment>
                                             <br />
                                             <Card>
                                                <Card.Body
                                                   style={{
                                                      fontWeight: "bold",
                                                      backgroundColor: "#eef1f4",
                                                   }}
                                                >
                                                   {
                                                      ComIRMSubAttributekeys.attrGPSAssessment
                                                   }
                                                </Card.Body>
                                             </Card>
                                             <div className="accordionDiv">
                                                <CustomTable
                                                   data={data.attrGPSAssessment}
                                                />
                                             </div>
                                          </React.Fragment>
                                       )}
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              data.platFormAndChassis &&
                              selectedItems["platFormAndChassis"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "1", "One")}
                                          aria-controls="One"
                                       >
                                          {ComIRMkeys.platFormAndChassis}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="One"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute
                                          data={data.platFormAndChassis}
                                          col="3"
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.Files &&
                              data.Files.length > 0 &&
                              selectedItems["Files"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "3", "Three")}
                                          aria-controls="Three"
                                       >
                                          {ComIRMkeys.Files}{" "}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Three"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComIRMGenDocsFilesDownload
                                          data={data.Files}
                                          attributes={data.attributeList}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedParts &&
                              data.relatedParts.length > 0 &&
                              selectedItems["relatedParts"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "4", "Four")}
                                          aria-controls="Four"
                                       >
                                          {ComIRMkeys.relatedParts}{" "}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Four"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComIRMRelatedParts data={data.relatedParts} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedPartsStudyProtocol &&
                              data.relatedPartsStudyProtocol.length > 0 &&
                              selectedItems["relatedPartsStudyProtocol"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "5", "Five")}
                                          aria-controls="Five"
                                       >
                                          {ComIRMkeys.relatedPartsStudyProtocol}{" "}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Five"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <IRMRelatedPartsStudyProtocol
                                          data={data.relatedPartsStudyProtocol}
                                          header={"Leg Details"}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocuments &&
                              data.referenceDocuments.length > 0 &&
                              selectedItems["referenceDocuments"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "6", "Six")}
                                          aria-controls="Six"
                                       >
                                          {ComIRMkeys.referenceDocuments}{" "}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Six"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ReferenceDocsDownload
                                          data={data.referenceDocuments}
                                          attributes={data.attributeList}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.multipleOwnershipAccess &&
                              data.multipleOwnershipAccess.length > 0 &&
                              selectedItems["multipleOwnershipAccess"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "7", "Seven")}
                                          aria-controls="Seven"
                                       >
                                          {ComIRMkeys.multipleOwnershipAccess}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Seven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.multipleOwnershipAccess} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ipSecuritySetting &&
                              data.ipSecuritySetting.length > 0 &&
                              selectedItems["ipSecuritySetting"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "8", "Eight")}
                                          aria-controls="Eight"
                                       >
                                          {ComIRMkeys.ipSecuritySetting}{" "}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Eight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.ipSecuritySetting} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClasses &&
                              data.securityClasses.length > 0 &&
                              selectedItems["securityClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "9", "nine")}
                                          aria-controls="nine"
                                       >
                                          {ComIRMkeys.securityClasses}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="nine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.securityClasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.gpsAssessments &&
                              data.gpsAssessments.length > 0 &&
                              selectedItems["gpsAssessments"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "10", "ten")}
                                          aria-controls="ten"
                                       >
                                          {ComIRMkeys.gpsAssessments}{" "}
                                          <i
                                             id="10"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="ten"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.gpsAssessments} />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {ComIRMkeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default ComIRMAllInfo
