import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { FETCH_DYNAMIC_SUB } from "../../constant/Constants"
import HeaderComponent from "../commonComponents/HeaderComponent"
import DynamicResults from "./DynamicSubResults"

function DynamicSubscription(props) {
   const [loading, setLoading] = useState(true)
   const [errorFlag, setErrorFlag] = useState()
   const [subscriptionResults, setSubscriptionResults] = useState([])

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         fetchSubscriptionResults()
      }
   }, [props.history])

   async function fetchSubscriptionResults() {
      try {
         setLoading(true)
         setErrorFlag()
         const subscriptionResults = await fetchAPIData(FETCH_DYNAMIC_SUB)
         if (
            subscriptionResults.data &&
            subscriptionResults.data.subscriptionResults != null
         ) {
            setSubscriptionResults(subscriptionResults.data.subscriptionResults)
            setErrorFlag(0)
            setLoading(false)
         } else {
            setErrorFlag(1)
            setLoading(false)
         }
      } catch (error) {
         if (error.response && error.response.status && error.response.status === 401) {
            notAuthorized(props.history)
         } else {
            setErrorFlag(2)
            setLoading(false)
         }
      }
   }

   return (
      <React.Fragment>
         <HeaderComponent />
         <Container fluid>
            <Row>
               <Col md="12" sm="12" style={{ marginTop: "40px", marginBottom: "20px" }}>
                  <Container fluid className="searchDataContainer">
                     <Row>
                        <Col
                           md="12"
                           sm="12"
                           align="center"
                           className="loginTextHeader"
                           style={{ marginTop: "10px" }}
                        >
                           Dynamic Subscription
                        </Col>
                     </Row>
                     <Row>
                        <Col md="12" sm="12">
                           <DynamicResults
                              results={subscriptionResults}
                              error={errorFlag}
                              refreshHandler={fetchSubscriptionResults}
                              loading={loading}
                           />
                        </Col>
                     </Row>
                  </Container>
               </Col>
            </Row>
         </Container>
      </React.Fragment>
   )
}
export default DynamicSubscription
