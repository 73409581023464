import React from "react"
import CustomLink from "./CustomLink"

const CustomPackingUnit = (props) => {
   const packingUnit = props.packingUnit
   const packingUnit_contents = packingUnit.map((packing, index) => (
      <tr key={index}>
         <td>
            {packing.link != null ? (
               <CustomLink
                  id={packing.id}
                  link={packing.link.toUpperCase().trim()}
                  name={packing.packingUnitType}
               />
            ) : (
               <span>{packing.packingUnitType}</span>
            )}
         </td>
         <td>{packing.auom}</td>
         <td>{packing.gtin}</td>
         <td>{packing.depth}</td>
         <td>{packing.width}</td>
         <td>{packing.height}</td>
         <td>{packing.dimensionUnitOfMeasure}</td>
         <td>{packing.grossWeight}</td>
         <td>{packing.unitOfMeasure}</td>
         <td>{packing.numberOfConsumerUnitsPerUnit}</td>
         <td>{packing.netWeightOfProductInConsumerUnit}</td>
         <td>{packing.unitOfMeasure1}</td>
         <td>{packing.cubeEfficiency}</td>
      </tr>
   ))
   return (
      <div className="tableFixHead">
         <table>
            <thead>
               <tr>
                  <th rowSpan="2">
                     <span>Packing Unit Type </span>
                  </th>
                  <th rowSpan="2">
                     <span>Alternative Unit of Measure</span>
                  </th>
                  <th rowSpan="2">
                     <span>GTIN</span>
                  </th>
                  <th colSpan="3">
                     <span>Dimensions - OD with Bulge</span>
                  </th>
                  <th rowSpan="2">
                     <span>Unit of Measure</span>
                  </th>
                  <th rowSpan="2">
                     <span>Gross Weight</span>
                  </th>
                  <th rowSpan="2">
                     <span>Unit of Measure</span>
                  </th>
                  <th rowSpan="2">
                     <span>Number of Consumer Units Per Unit</span>
                  </th>
                  <th rowSpan="2">
                     <span>Net Weight of Product in Consumer Unit</span>
                  </th>
                  <th rowSpan="2">
                     <span>Unit of Measure</span>
                  </th>
                  <th rowSpan="2">
                     <span>Cube Efficiency</span>
                  </th>
               </tr>
               <tr>
                  <th>Depth</th>
                  <th>Width</th>
                  <th>Height</th>
               </tr>
            </thead>
            <tbody>{packingUnit_contents}</tbody>
         </table>
      </div>
   )
}
export default CustomPackingUnit
