import React, { useEffect } from "react"
import {
   Button,
   ButtonGroup,
   Card,
   Dropdown,
   Row,
   Col,
   Form,
   Table,
   Nav,
} from "react-bootstrap"
// import Button,Form from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal"
import BootstrapSelect from "react-bootstrap-select-dropdown"
import { fetchAPIData, isLoggedIn,notAuthorized } from "../../api/AuthService"
import { WHERE_USED } from "../../constant/Constants"
import { addBreadCrumbLinks } from "../commonComponents/EventHandler"
import { Link, useHistory } from "react-router-dom"
import { useState, useMemo } from "react"
import Pagination from "react-pagination-bootstrap"
export default function WhereUsedModal(props) {
   const [results, setResults] = useState([])
   const [loading, setLoading] = useState(false)
  
   const useSortableData = (items, config = null) => {
      const [sortConfig, setSortConfig] = useState(config)
     
      const sortedItems = useMemo(() => {
         let sortableItems = [...items]
        
         if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
               if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
                  return sortConfig.direction === "ascending" ? -1 : 1
               }
               if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
                  return sortConfig.direction === "ascending" ? 1 : -1
               }
               return 0
            })
         }
         return sortableItems
      }, [items, sortConfig])

      const requestSort = (key) => {
         let direction = "ascending"
         if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === "ascending"
         ) {
            direction = "descending"
         }
         setSortConfig({ key, direction })
      }
      return { items: sortedItems, requestSort, sortConfig }
   }
   const [resultsPerPage, setResultsPerPage] = useState(5)
   const [currentPage, setCurrentPage] = useState(1)
   const indexOfLastResult = currentPage * resultsPerPage
   const indexOfFirstResult = indexOfLastResult - resultsPerPage
   const { items, requestSort, sortConfig } = useSortableData(results)
   const currentResult = items.slice(indexOfFirstResult, indexOfLastResult)
   const [activePage, setActivePage] = useState(1)
   const [error,setError]=useState()
   const [message,setMessage] = useState("")
   const getClassNamesFor = (name) => {
      if (!sortConfig) {
         return "fa fa-sort"
      } else if (sortConfig.key === name) {
         if (sortConfig.direction === "ascending") {
            return "fa fa-caret-up"
         } else {
            return "fa fa-caret-down"
         }
      } else {
         return "fa fa-sort"
      }
   }

   function handlePageChange(pageNumber) {
      setCurrentPage(pageNumber)
      setActivePage(pageNumber)
   }

   const fetchData1 = async () => {
     

      try {
         setLoading(true)
         const response = await fetchAPIData(`${WHERE_USED}/${props.objectid}`)

         if(response.data.highlyConnectedParts !== ""){
            setMessage(response.data.highlyConnectedParts)
         }
        
         if (response.data && response.data.ImpactedParts.length!==0) {
            setResults(response.data.ImpactedParts)
            setLoading(false)
            setError(0)
           
         } else {
            setLoading(false)
            setError(1)
         }
      } catch (error) {
         setLoading(false)
         setError(2)
        }
         console.log("error", error)
      
   }

 

   const impacted_content =
   currentResult && currentResult.length ? (
      currentResult.map((key, index) => (
            <tr key={index}>
         <td width="10%">
            {key.link != null && key.id != null ? (
               <Link
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() =>
                     addBreadCrumbLinks(
                        key.Name,
                        `/${key.link.toUpperCase().trim()}/${key.id.trim()}`
                     )
                  }
                  to={(location) => ({
                     ...location,
                     pathname: `/${key.link.toUpperCase()}/${key.id}`,
                  })}
               >
                  <span textalign="left">{key.Name}</span>
               </Link>
            ) : (
               <span>{key.Name}</span>
            )}
         </td>
         <td align="left" width="10%">
            {key.DisplayType}
         </td>
         <td align="left" width="5%">
            {key.Revision}
         </td>
         <td align="left" width="10%">
            {key.Title}
         </td>
      </tr>
   ))) : (
      <tr>
         <td align="left" width="10%">
            {""}
         </td>
         <td align="left" width="10%">
            {""}
         </td>
         <td align="left" width="5%">
            {""}
         </td>
         <td align="left" width="10%">
            {""}
         </td>
      </tr>
      )

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else if(props.show) {
         document.body.style.overflow = 'hidden'
         fetchData1()
      }
         document.body.style.overflow = 'unset'
   }, [props.show])

   return (
      <Modal
         {...props}
         show={props.show}
         aria-labelledby="contained-modal-title-vcenter"
         centered
         dialogClassName="my-modal"
         style={{ overflowY: "hidden" }}
         backdrop="static"
         keyboard={false}
      >
         <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            <div className="reportLabels2 mt-20" style={{marginTop:"5px",fontFamily:"Century Gothic"}}>Where Used - {props.name} {props.Rev} </div>
            <button type="button" className="close" onClick={props.onHide}>
               &times;
            </button>
         </Modal.Header>
         <Modal.Body>
            <Form>
               <Row style={{ height: "450px" }}>
                  <Col md="3" sm="3">
                     <Card
                        autoFocus
                        style={{
                           backgroundColor: "#003daf",
                           color: "white",
                           height: "410px",
                           fontSize:"13px",
                           fontweight: "bold",
                           fontFamily:"Century Gothic",
                        }}
                     >
                        <Card.Body>Name : {props.name} <br></br>
                        Revision : {props.Rev}  <br></br>
                        <div className="sideNavButton1" style={{marginTop:"20px"}}>
                        <i className="fa fa-angle-right"></i> &nbsp;Where Used
                        </div>
                        </Card.Body>
                     </Card>
                  </Col>
                  <Col md="9" sm="9"  style={{fontFamily:"Century Gothic"}}>
                     {loading ? (
                        <h2 style={{ textAlign: "center", color: "#0173d4" }}>
                           <i
                              className="fa fa-spinner fa-spin"
                              style={{ marginRight: "5px" }}
                           />
                           Loading........
                        </h2>
                     ) : (
                        <React.Fragment>
                           {error===0 &&
                           <React.Fragment>
                               {message !="" && 
                             <div>
                              <p className="alertMsg1">
                                {" "}
                                {message}
                             </p>
                           </div>}
                           <div style={{ width: "100%" ,marginBottom:"30px"}} id="paginTab">
                              <Pagination
                                 activePage={activePage}
                                 itemsCountPerPage={resultsPerPage}
                                 totalItemsCount={results.length}
                                 pageRangeDisplayed={3}
                                 onChange={handlePageChange}
                              />
                           </div>
                           <br />
                           <div style={{marginBottom:"20px"}}>
                           <span style={{fontSize:"15px"}}><span style={{fontWeight: "bold"}}>Name:</span> {props.name}&nbsp;&nbsp;</span>
                           <span style={{fontSize:"15px"}}><span style={{fontWeight: "bold"}}>Revision:</span> {props.Rev}</span>
                           </div>
                           <div className="Modalheading">Where Used</div>
                           <div style={{ overflow: "auto", flex: 1, height: "280px" }}>
                              <Table bordered  style={{ height: "200px" }}>
                                 <thead style={{ position: "sticky", top: -1, color: "Black", backgroundColor:"White" }}>
                                             <tr>
                                               <th width="20%">
                                                  Impacted Part(s)&nbsp;
                                                  <i
                                                   onClick={() => requestSort("Name")}
                                                   className={getClassNamesFor("Name")}
                                                ></i>
                                               </th>
                                               <th width="20%">
                                                  Type &nbsp;
           
                                               </th>
                                               <th width="10%">
                                                  Rev &nbsp;
           
                                               </th>
                                               <th width="30%">Title &nbsp;</th>
                                            </tr>
                                         
                                 </thead>
                                 <tbody>{impacted_content}</tbody>
                              </Table>
                           </div>
                           </React.Fragment>}
                           {error === 1 && (
                <Form.Group as={Row}>
                    <Form.Label column md="12" className="reportLabels1 mt-20" align="center" >
                        No Results Found
                    </Form.Label>
                </Form.Group>
            )}
            {error === 2 &&
                <Form.Group as={Row}>
                    <Form.Label column md="12" className="reportLabels1 mt-20" align="center" style={{ color: "#a00505" }}>
                        There is an issue with the search service. Please contact Support.
                    </Form.Label>
                </Form.Group>
            }
             </React.Fragment>)}
                           </Col>
                 
                 
               </Row>
               <br />
            </Form>
           
         </Modal.Body>
      </Modal>
   )
}
