import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { removeEmptyFromJSON } from "../commonComponents/EventHandler"

const ReportAttributes = (props) => {
   const [data, setData] = useState({})
   const col = props.col ? parseInt(props.col) : 2

   useEffect(() => {
      if (props.data) {
         setData(removeEmptyFromJSON(props.data))
      }
      return () => {
         return undefined
      }
   }, [props.data])

   const attributeContent = () => {
      let newKeys = []
      let keys = []
      let preValue = 0
      let rowContainer = []
      let colContainer = []
      let contenContainer = []

      keys = Object.keys(data)

      for (let i = 0; i <= Math.ceil(keys.length - 1); ) {
         preValue = i
         i = i + col
         newKeys = keys.slice(preValue, i)
         rowContainer.push(<Row key={i}>{colDetails(newKeys, colContainer)}</Row>)
         contenContainer.push(rowContainer)
         rowContainer = []
         colContainer = []
      }
      return contenContainer
   }

   function colDetails(newKeys, colContainer) {
      newKeys.forEach(function (key) {
         colContainer.push(
            <React.Fragment key={key}>
               <Col md="4" sm="4" className="mt-3">
                  <span id="pageheaders">{key}</span>
               </Col>
               <Col md="7" sm="7" className="mt-3 lineBreak">
                  {data[key]}
               </Col>
            </React.Fragment>
         )
      })
      return colContainer
   }
   return (
      <React.Fragment>
         {data && <React.Fragment>{attributeContent()}</React.Fragment>}
      </React.Fragment>
   )
}

export default ReportAttributes
