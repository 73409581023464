import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { DefaultKeys, FETCH_MCP, MCPKeys } from "../../constant/Constants"
import Attributes from "../commonComponents/Attributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import CustomMCTable from "../commonComponents/CustomMatrialAndComposition"
import CustomTable from "../commonComponents/CustomTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../commonComponents/TopContentForSpecs"

function McpAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributesObject: {},
      materialsAndCompositions: [],
      referenceDocument: [],
      gpsAssessments: [],
      organizationObject: {},
      certifications: [],
      whereUsed: null,
   })

   const searchData = props.match.params.objectID
   const [loading, setLoading] = useState(true)
   const [sideNav, setSideNav] = useState()
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)
      var values = pdfData(data, obj, IsFilterView)
      setPdfDataSet(values)
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchMcpData() {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")
               var apiData

               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  // Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_MCP}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContentObject)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContentObject
                     ? apiData.headerContentObject
                     : data.headerContent,
                  attributesObject: apiData.attributesObject,
                  organizationObject: apiData.organizationObject,
                  materialsAndCompositions: apiData.materialsAndCompositions,
                  referenceDocument: apiData.referenceDocument,
                  gpsAssessments: apiData.gpsAssessments,
                  certifications: apiData.certificationMaster,
                  whereUsed: apiData.whereUsed,
               }))
               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  notAuthorized(props.history)
                  return
               }
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         fetchMcpData()
         window.scrollTo(0, 0)
      }
      return () => {
         return undefined
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={MCPKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" className="contentBackground">
                     <br />
                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="9" sm="9">
                              <TopContent headerContent={data.headerContent} />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              <PdfDownloadComponent
                                 pdfDataSet={pdfDataSet}
                                 genDocHide={true}
                                 pdfHide={true}
                              />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {selectedItems["attributesObject"] && data.attributesObject && (
                              <div>
                                 <Card autoFocus>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(this, "0", "Zero")}
                                       aria-controls="Zero"
                                    >
                                       {MCPKeys.attributesObject}{" "}
                                       <i
                                          id="0"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="Zero"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <Attributes
                                       loading={loading}
                                       data={data.attributesObject}
                                    />
                                 </div>
                              </div>
                           )}

                           {!loading &&
                              data.materialsAndCompositions &&
                              data.materialsAndCompositions.length > 0 &&
                              selectedItems["materialsAndCompositions"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "1", "One")}
                                          aria-controls="One"
                                       >
                                          {MCPKeys.materialsAndCompositions}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="One"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMCTable data={data.materialsAndCompositions} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocument &&
                              data.referenceDocument.length > 0 &&
                              selectedItems["referenceDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "2", "Two")}
                                          aria-controls="Two"
                                       >
                                          {MCPKeys.referenceDocument}{" "}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Two"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComRefDocDownload
                                          data={data.referenceDocument}
                                          attributes={data.attributesObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.gpsAssessments &&
                              data.gpsAssessments.length > 0 &&
                              selectedItems["gpsAssessments"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "4", "Four")}
                                          aria-controls="Four"
                                       >
                                          {MCPKeys.gpsAssessments}{" "}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Four"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.gpsAssessments} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.certifications && data.certifications.certificationsList &&
                              data.certifications.certificationsList.length > 0 &&
                              selectedItems["certifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "5",
                                             "Five"
                                          )}
                                          aria-controls="Five"
                                       >
                                          {MCPKeys.certifications}{" "}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="Five"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.certifications.certificationsList} 
                                          certHeader = {data.certifications.header}/>
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {MCPKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default McpAllInfo
