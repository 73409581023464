import React, { useEffect, useMemo, useState } from "react";
import { Form, Row, Table } from "react-bootstrap";
import Pagination from "react-pagination-bootstrap";

const selectedCheckboxes = new Set()
const mainbox = new Set()
function SearchDSResults(props) {
    const [choices, setChoices] = useState([])
    const [mainCheckbox, setMainCheckbox] = useState([])

    const useSortableData = (items, config = null) => {
        const [sortConfig, setSortConfig] = useState(config)
        const sortedItems = useMemo(() => {
            let sortableItems = [...items]
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
                        return sortConfig.direction === "ascending" ? -1 : 1
                    }
                    if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
                        return sortConfig.direction === "ascending" ? 1 : -1
                    }
                    return 0
                })
            }
            return sortableItems
        }, [items, sortConfig])

        const requestSort = (key) => {
            let direction = "ascending"
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === "ascending"
            ) {
                direction = "descending"
            }
            setSortConfig({ key, direction })
        }
        return { items: sortedItems, requestSort, sortConfig }
    }
    const [resultsPerPage, setResultsPerPage] = useState(30)
    const [currentPage, setCurrentPage] = useState(1)
    const indexOfLastResult = currentPage * resultsPerPage
    const indexOfFirstResult = indexOfLastResult - resultsPerPage
    const { items, requestSort, sortConfig } = useSortableData(props.results)
    const currentResult = items.slice(indexOfFirstResult, indexOfLastResult)
    const [activePage, setActivePage] = useState(1)

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return "fa fa-sort"
        } else if (sortConfig.key === name) {
            if (sortConfig.direction === "ascending") {
                return "fa fa-caret-up"
            } else {
                return "fa fa-caret-down"
            }
        } else {
            return "fa fa-sort"
        }
    }

    function selectAll() {
        var items = document.getElementsByName("tableCheckbox")
        for (var i = 0; i < items.length; i++) {
            if (items[i].type === "checkbox") {
                items[i].checked = true
                if (!selectedCheckboxes.has(items[i].value)) {
                    selectedCheckboxes.add(items[i].value)
                }
            }
        }
        setChoices(Array.from(selectedCheckboxes))
        props.handler(Array.from(selectedCheckboxes))
    }

    function UnSelectAll() {
        var items = document.getElementsByName("tableCheckbox")
        for (var i = 0; i < items.length; i++) {
            if (items[i].type === "checkbox") items[i].checked = false
        }
        selectedCheckboxes.clear()
        setChoices(Array.from(selectedCheckboxes))
        props.handler(Array.from(selectedCheckboxes))
    }

    function togglemainCheckbox(e) {
        if (mainbox.has(e.target.value)) {
            mainbox.delete(e.target.value)
        } else {
            mainbox.add(e.target.value)
        }
        setMainCheckbox(Array.from(mainbox))
        if (document.getElementById("gridViewCheckbox").checked) {
            selectAll()
        } else {
            UnSelectAll()
        }
    }

    function toggleCheckbox(e) {
        if (props.type === "organization") {
            if (selectedCheckboxes.has(e.target.value)) {
                selectedCheckboxes.delete(e.target.value)
            } else {
                selectedCheckboxes.add(e.target.value)
            }
            setChoices(Array.from(selectedCheckboxes))

        }
        else {
            const [first] = selectedCheckboxes
            if (selectedCheckboxes.size === 0) {
                selectedCheckboxes.add(e.target.value)
            } else {
                selectedCheckboxes.delete(first)
                selectedCheckboxes.add(e.target.value)
            }
            setChoices(Array.from(selectedCheckboxes))
        }
        props.handler(Array.from(selectedCheckboxes))
    }
    function handlePageChange(pageNumber) {
        setCurrentPage(pageNumber)
        setActivePage(pageNumber)
    }
    const info_content = currentResult.map((key, index) => (
        <tr key={index}>
            <td align="center" width="5%">
                {" "}
                <Form.Check
                    type={props.type === "organization" ? "checkbox" : "radio"}
                    name="tableCheckbox"
                    id="tableCheckbox"
                    value={key["Name"]}
                    onChange={toggleCheckbox}
                    checked={choices.includes(key["Name"]) ? true : false}
                />
            </td>
            <td align="left" width="75%">
                {key["Name"]}
            </td>
        </tr>
    ))
    useEffect(() => {
        selectedCheckboxes.clear()
        mainbox.clear()
    }, [props.results])



    return (
        <React.Fragment>
            {props.error === 0 &&
                <React.Fragment>
                    <div
                        style={{ width: "100%" }}
                        id="paginTab"
                    >
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={resultsPerPage}
                            totalItemsCount={props.results.length}
                            pageRangeDisplayed={3}
                            onChange={handlePageChange}
                        />

                    </div>
                    <br />
                    <br />


                    <div className="DSTable">
                        <Table bordered className="table-responsive">
                            <thead id="template_summ_tab">
                                <tr>
                                    {props.type === "organization" ?
                                        <th width="50px">
                                            <Form.Check
                                                type="checkbox"
                                                style={{ textAlign: "center" }}
                                                onChange={togglemainCheckbox}
                                                id="gridViewCheckbox"
                                                value={activePage}
                                                checked={
                                                    mainCheckbox.includes(
                                                        activePage.toString()
                                                    )
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </th>
                                        :
                                        (<th width="3%" style={{ paddingRight: "30px" }}>
                                        </th>)
                                    }
                                    <th width="900px">
                                        Name &nbsp;
                                        <i
                                            onClick={() => requestSort("Name")}
                                            className={getClassNamesFor("Name")}
                                        ></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{info_content}</tbody>
                        </Table>
                    </div>
                </React.Fragment>
            }
            {props.error === 1 && (
                <Form.Group as={Row}>
                    <Form.Label column md="12" className="reportLabels mt-5" align="center">
                        No Result Found
                    </Form.Label>
                </Form.Group>
            )}
            {props.error === 2 &&
                <Form.Group as={Row}>
                    <Form.Label column md="12" className="reportLabels mt-5" align="center" style={{ color: "#a00505" }}>
                        There is an issue with the search service. Please contact Support.
                    </Form.Label>
                </Form.Group>
            }
        </React.Fragment >
    )
}
export default SearchDSResults
