import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function SubscriptionDetailData(props) {
    const history = useHistory()
    return (
        <React.Fragment>
            {props.error === 2 && (
                <Row className="labels mt-5">
                <Col md={{ span: 6, offset: 3 }} align="center">
                    There is an issue with the dynamic subscription service. Please contact Support.
                </Col>
                </Row>
            )}
            {props.error === 0 && (
                <Row className="mt-5">
                    <Col
                        md={{ span: 10, offset: 1 }}
                        className="reportForm contentBackground shadow mb-3"
                    >
                        <Row>
                            <Col md="12" sm="12" align="center" className ="loginTextHeader">
                                {props.data.name ? props.data.name : "" }: Dynamic Subscription
                            </Col>
                            <Col md="11" sm="10" align="right" className="mt-2 mb-2">
                                <Button variant="primary"
                                    onClick={() => history.push(`/DynamicSubscription/${props.id}`)}
                                >
                                    Edit Details
                                </Button>
                            </Col>
                            <Col md="1" sm ="2" align="left" className="mt-2 mb-2" style={{padding:"0px"}}>
                                <Button variant="primary"
                                    onClick={history.goBack}
                                >
                                    Back
                                </Button>
                            </Col>
                        </Row>

                        <Row style ={{marginRight:"5px",marginLeft:"5px",marginBottom:"15px"}} className=" accordionDiv shadow">
                            <Col sm="5" md ="5" id="pageheaders" className="mb-2">
                                Product Data Type
                            </Col>
                            <Col sm="6" md ="6">
                                {props.data["Product Data Type"]}
                            </Col>
                            <Col sm="5" md ="5" id="pageheaders" className="mb-2">
                                Subscription Frequency 
                            </Col>
                            <Col sm="6" md ="6">
                                {props.data["Subscription Frequency"]}
                            </Col>
                            <Col sm="5" md ="5" id="pageheaders" className="mb-2">
                                Policy 
                            </Col>
                            <Col sm="6" md ="6">
                                {props.data["Policy"]}
                            </Col>
                            <Col sm="5" md ="5" id="pageheaders" className="mb-2">
                                State
                            </Col>
                            <Col sm="6" md ="6">
                                {props.data["State"]}
                            </Col>
                            <Col sm="5" md ="5" id="pageheaders" className="mb-2">
                                Organization
                            </Col>
                            <Col sm="6" md ="6">
                                {props.data["Organization"]}
                            </Col>
                            <Col sm="5" md ="5" id="pageheaders" className="mb-2">
                                Vendor
                            </Col>
                            <Col sm="6" md ="6">
                                {props.data["Vendor"]}
                            </Col>
                            <Col sm="5" md ="5" id="pageheaders" className="mb-2">
                                Plant
                            </Col>
                            <Col sm="6" md ="6">
                                {props.data["Plant"]}
                            </Col>
                            <Col sm="5" md ="5" id="pageheaders" className="mb-2">
                                Brand
                            </Col>
                            <Col sm="6" md ="6">
                                {props.data["Brand"]}
                            </Col>
                            <Col sm="5" md ="5" id="pageheaders" className="mb-2">
                                Segment
                            </Col>
                            <Col sm="6" md ="6">
                                {props.data["Segment"]}
                            </Col>
                            <Col sm="5" md ="5" id="pageheaders" className="mb-2">
                                Manufacturing Status
                            </Col>
                            <Col sm="6" md ="6">
                                {props.data["Manufacturing Status"]}
                            </Col>
                            <Col sm="5" md ="5" id="pageheaders" className="mb-2">
                                Expiration Date
                            </Col>
                            <Col sm="6" md ="6">
                                {props.data["Expiration Date"]}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    )
}
export default SubscriptionDetailData