import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { DefaultKeys, FETCH_FOP, FOPKeys } from "../../constant/Constants"
import Attributes from "../commonComponents/Attributes"
import ComIngredientStatement from "../commonComponents/ComIngredientStatement"
import CommonAttributes from "../commonComponents/CommonAttributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import ComSML from "../commonComponents/ComSML"
import CustomMasterSpecification from "../commonComponents/CustomMasterSpecification"
import CustomMCTable from "../commonComponents/CustomMatrialAndComposition"
import { CustomMultiFILFormulaProcessFormula } from "../commonComponents/CustomMultiFILFormulaProcessFormula"
import CustomPCTable from "../commonComponents/CustomPCTable"
import CustomSapBom from "../commonComponents/CustomSapBom"
import CustomTable from "../commonComponents/CustomTable"
import CustomDerivedTable from "../commonComponents/DerivedTable"
import DownloadLinkTable from "../commonComponents/DownloadLinkTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import GenDocsFileDownTable from "../commonComponents/GenDocsFilesDownload"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import PlainCertifications from "../commonComponents/PlainCertifications"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../commonComponents/TopContentLatest"
import CustomMultiSubstitute from "./CustomFopSubstitute"

function FopAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributes: {},
      weightCharacteristic: [],
      dangerousGoodsClassification: [],
      globalHarmonizedStandard: [],
      ingredientStatement: [],
      storageTransportationLabelingAssessmentData: {},
      chemicalAndPhysicalPropertiesGHCDGCClassification: {},
      chemicalAndPhysicalPropertiesWarehouseClassification: {},
      chemicalAndPhysicalPropertiesEnginuityLegacyData: {},
      productPartStabilityDocument: [],
      masterPartStabilityDocument: [],
      sapBomAsFed: [],
      formulationProcess: [],
      formulationProcessFormula: {},
      substitutes: [],
      marketClearance: [],
      relatedSpecification: [],
      masterSpecification: [],
      referenceDocument: [],
      performanceCharcteristic: [],
      materialsAndCompositions: [],
      plants: [],
      relatedATS: [],
      materialProduced: [],
      lifecycleApprovalPowerview: [],
      ownership: {},
      ipClasses: [],
      securityClasses: [],

      organization: {},
      fileSection: [],
      gpsAssessments: [],
      smartLabel: [],
      certifications: [],
      certificationValidation: [],
      chemicalAndPhysicalProperties: {},
      stabilityResults: [],
      derivedParts: [],
      masterAttribute: {},
      whereUsed: null,
   })

   const searchData = props.match.params.objectID
   const [loading, setLoading] = useState(true)
   const [sideNav, setSideNav] = useState()
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)
      var values = pdfData(data, obj, IsFilterView)
      setPdfDataSet(values)
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchFppData() {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")
               var apiData

               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  // Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_FOP}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContent)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContent
                     ? apiData.headerContent
                     : data.headerContent,
                  attributes: apiData.attributes,
                  organization: apiData.organization,
                  storageTransportationLabelingAssessmentData:
                     apiData.storageTransportationLabelingAssessmentData,
                  chemicalAndPhysicalProperties: apiData.chemicalAndPhysicalProperties,
                  formulationProcess: apiData.formulationProcess,
                  formulationProcessFormula: apiData.formulationProcessFormula,
                  substitutes: apiData.substitutes,
                  performanceCharcteristic: apiData.performanceCharcteristic,
                  weightCharacteristic: apiData.weightCharacteristic,
                  sapBomAsFed: apiData.sapBomAsFed,
                  plants: apiData.plants,
                  derivedParts: apiData.derivedParts,
                  ownership: apiData.ownership,
                  relatedSpecification: apiData.relatedSpecification,
                  lifecycleApprovalPowerview: apiData.lifecycleApprovalPowerview,
                  chemicalAndPhysicalPropertiesGHCDGCClassification:
                     apiData.chemicalAndPhysicalPropertiesGHCDGCClassification,
                  chemicalAndPhysicalPropertiesWarehouseClassification:
                     apiData.chemicalAndPhysicalPropertiesWarehouseClassification,
                  chemicalAndPhysicalPropertiesEnginuityLegacyData:
                     apiData.chemicalAndPhysicalPropertiesEnginuityLegacyData,
                  dangerousGoodsClassification: apiData.dangerousGoodsClassification,
                  globalHarmonizedStandard: apiData.globalHarmonizedStandard,
                  stabilityResults: apiData.stabilityResults,
                  masterSpecification: apiData.masterSpecification,
                  masterAttribute: apiData.masterAttribute,
                  materialProduced: apiData.materialProduced,
                  securityClasses: apiData.securityClasses,
                  referenceDocument: apiData.referenceDocument,
                  materialsAndCompositions: apiData.materialsAndCompositions,
                  marketClearance: apiData.marketClearance,
                  certifications: apiData.certifications,
                  relatedATS: apiData.relatedATS,
                  fileSection: apiData.fileSection,
                  productPartStabilityDocument: apiData.productPartStabilityDocument,
                  masterPartStabilityDocument: apiData.masterPartStabilityDocument,
                  ipClasses: apiData.ipClasses,

                  // new sections added
                  ingredientStatement: apiData.ingredientStatement,
                  gpsAssessments: apiData.gpsAssessments,
                  smartLabel: apiData.smartLabel,
                  certificationValidation: apiData.certificationValidation,
                  whereUsed:apiData.whereUsed,
               }))
               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  notAuthorized(props.history)
                  return
               }
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchFppData()
      }
      return () => {
         return undefined
      }
   }, [searchData, props.history, data.headerContent.gendocView])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} />
         <CSSTransition timeout={500} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={FOPKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" className="contentBackground">
                     <br />

                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="9" sm="9">
                              <TopContent headerContent={data.headerContent} />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              <PdfDownloadComponent
                                 genDocHide={!data.headerContent.gendocView}
                                 pdfDataSet={pdfDataSet}
                              />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {selectedItems["attributes"] && data.attributes && (
                              <div>
                                 <Card autoFocus>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(this, "0", "zero")}
                                       aria-controls="zero"
                                    >
                                       {FOPKeys.attributes}{" "}
                                       <i
                                          id="0"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="zero"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <Attributes
                                       loading={loading}
                                       data={data.attributes}
                                    />
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              data.weightCharacteristic &&
                              data.weightCharacteristic.length > 0 &&
                              selectedItems["weightCharacteristic"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "1", "one")}
                                          aria-controls="one"
                                       >
                                          {FOPKeys.weightCharacteristic}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="one"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.weightCharacteristic} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.dangerousGoodsClassification &&
                              data.dangerousGoodsClassification.length > 0 &&
                              selectedItems["dangerousGoodsClassification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "2", "two")}
                                          aria-controls="two"
                                       >
                                          {FOPKeys.dangerousGoodsClassification}{" "}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="two"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable
                                          data={data.dangerousGoodsClassification}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.globalHarmonizedStandard &&
                              data.globalHarmonizedStandard.length > 0 &&
                              selectedItems["globalHarmonizedStandard"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "3", "three")}
                                          aria-controls="three"
                                       >
                                          {FOPKeys.globalHarmonizedStandard}{" "}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="three"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable
                                          data={data.globalHarmonizedStandard}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ingredientStatement &&
                              data.ingredientStatement.length > 0 &&
                              selectedItems["ingredientStatement"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "33",
                                             "thirtythree"
                                          )}
                                          aria-controls="thirtythree"
                                       >
                                          {FOPKeys.ingredientStatement}{" "}
                                          <i
                                             id="33"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtythree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComIngredientStatement
                                          data={data.ingredientStatement}
                                          parentData={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.storageTransportationLabelingAssessmentData &&
                              selectedItems[
                                 "storageTransportationLabelingAssessmentData"
                              ] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "4", "four")}
                                          aria-controls="four"
                                       >
                                          {
                                             FOPKeys.storageTransportationLabelingAssessmentData
                                          }
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="four"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes
                                          data={
                                             data.storageTransportationLabelingAssessmentData
                                          }
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.chemicalAndPhysicalPropertiesGHCDGCClassification &&
                              selectedItems[
                                 "chemicalAndPhysicalPropertiesGHCDGCClassification"
                              ] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "5", "five")}
                                          aria-controls="five"
                                       >
                                          {
                                             FOPKeys.chemicalAndPhysicalPropertiesGHCDGCClassification
                                          }
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="five"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes
                                          data={
                                             data.chemicalAndPhysicalPropertiesGHCDGCClassification
                                          }
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.chemicalAndPhysicalPropertiesWarehouseClassification &&
                              selectedItems[
                                 "chemicalAndPhysicalPropertiesWarehouseClassification"
                              ] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "6", "six")}
                                          aria-controls="six"
                                       >
                                          {
                                             FOPKeys.chemicalAndPhysicalPropertiesWarehouseClassification
                                          }
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="six"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes
                                          data={
                                             data.chemicalAndPhysicalPropertiesWarehouseClassification
                                          }
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.chemicalAndPhysicalPropertiesEnginuityLegacyData &&
                              selectedItems[
                                 "chemicalAndPhysicalPropertiesEnginuityLegacyData"
                              ] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "7", "seven")}
                                          aria-controls="seven"
                                       >
                                          {
                                             FOPKeys.chemicalAndPhysicalPropertiesEnginuityLegacyData
                                          }
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="seven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes
                                          data={
                                             data.chemicalAndPhysicalPropertiesEnginuityLegacyData
                                          }
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.productPartStabilityDocument &&
                              data.productPartStabilityDocument.length > 0 &&
                              selectedItems["productPartStabilityDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "8", "eight")}
                                          aria-controls="eight"
                                       >
                                          {FOPKeys.productPartStabilityDocument}{" "}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="eight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable
                                          data={data.productPartStabilityDocument}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.masterPartStabilityDocument &&
                              data.masterPartStabilityDocument.length > 0 &&
                              selectedItems["masterPartStabilityDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "9", "nine")}
                                          aria-controls="nine"
                                       >
                                          {FOPKeys.masterPartStabilityDocument}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="nine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable
                                          data={data.masterPartStabilityDocument}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.sapBomAsFed &&
                              data.sapBomAsFed.length > 0 &&
                              selectedItems["sapBomAsFed"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "10", "ten")}
                                          aria-controls="ten"
                                       >
                                          {FOPKeys.sapBomAsFed}
                                          <i
                                             id="10"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="ten"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSapBom data={data.sapBomAsFed} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.formulationProcess &&
                              data.formulationProcess.length > 0 &&
                              selectedItems["formulationProcess"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "11", "elevan")}
                                          aria-controls="elevan"
                                       >
                                          {FOPKeys.formulationProcess}
                                          <i
                                             id="11"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="elevan"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.formulationProcess} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.formulationProcessFormula &&
                              selectedItems["formulationProcessFormula"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "12", "twelve")}
                                          aria-controls="twelve"
                                       >
                                          {FOPKeys.formulationProcessFormula}
                                          <i
                                             id="12"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twelve"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMultiFILFormulaProcessFormula
                                          data={data.formulationProcessFormula}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutes &&
                              data.substitutes.length > 0 &&
                              selectedItems["substitutes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "13",
                                             "thirteen"
                                          )}
                                          aria-controls="thirteen"
                                       >
                                          {FOPKeys.substitutes}
                                          <i
                                             id="13"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMultiSubstitute
                                          data={data.substitutes}
                                          genDoc={data.headerContent.pdfView}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.marketClearance &&
                              selectedItems["marketClearance"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "14",
                                             "fourteen"
                                          )}
                                          aria-controls="fourteen"
                                       >
                                          {FOPKeys.marketClearance}{" "}
                                          <i
                                             id="14"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.marketClearance} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedSpecification &&
                              data.relatedSpecification.length > 0 &&
                              selectedItems["relatedSpecification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "15",
                                             "fifteen"
                                          )}
                                          aria-controls="fifteen"
                                       >
                                          {FOPKeys.relatedSpecification}
                                          <i
                                             id="15"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fifteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DownloadLinkTable
                                          data={data.relatedSpecification}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.masterSpecification &&
                              data.masterSpecification.length > 0 &&
                              selectedItems["masterSpecification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "16",
                                             "sixteen"
                                          )}
                                          aria-controls="sixteen"
                                       >
                                          {FOPKeys.masterSpecification}{" "}
                                          <i
                                             id="16"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="sixteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMasterSpecification
                                          data={data.masterSpecification}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocument &&
                              data.referenceDocument.length > 0 &&
                              selectedItems["referenceDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "17",
                                             "seventeen"
                                          )}
                                          aria-controls="seventeen"
                                       >
                                          {FOPKeys.referenceDocument}{" "}
                                          <i
                                             id="17"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="seventeen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComRefDocDownload
                                          data={data.referenceDocument}
                                          attributes={data.attributes}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.performanceCharcteristic &&
                              data.performanceCharcteristic.length > 0 &&
                              selectedItems["performanceCharcteristic"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "18",
                                             "eighteen"
                                          )}
                                          aria-controls="eighteen"
                                       >
                                          {FOPKeys.performanceCharcteristic}
                                          <i
                                             id="18"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="eighteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPCTable
                                          data={data.performanceCharcteristic}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.materialsAndCompositions &&
                              data.materialsAndCompositions.length > 0 &&
                              selectedItems["materialsAndCompositions"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "19",
                                             "nineteen"
                                          )}
                                          aria-controls="nineteen"
                                       >
                                          {FOPKeys.materialsAndCompositions}{" "}
                                          <i
                                             id="19"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="nineteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMCTable
                                          data={data.materialsAndCompositions}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.plants &&
                              data.plants.length > 0 &&
                              selectedItems["plants"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "20", "twenty")}
                                          aria-controls="twenty"
                                       >
                                          {FOPKeys.plants}
                                          <i
                                             id="20"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twenty"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.plants} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedATS &&
                              data.relatedATS.length > 0 &&
                              selectedItems["relatedATS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "26",
                                             "twentysix"
                                          )}
                                          aria-controls="twentysix"
                                       >
                                          {FOPKeys.relatedATS}{" "}
                                          <i
                                             id="26"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentysix"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedATS} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.materialProduced &&
                              data.materialProduced.length > 0 &&
                              selectedItems["materialProduced"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "21",
                                             "twentyone"
                                          )}
                                          aria-controls="twentyone"
                                       >
                                          {FOPKeys.materialProduced}{" "}
                                          <i
                                             id="21"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyone"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMCTable data={data.materialProduced} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.lifecycleApprovalPowerview &&
                              data.lifecycleApprovalPowerview.length > 0 &&
                              selectedItems["lifecycleApprovalPowerview"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "22",
                                             "twentytwo"
                                          )}
                                          aria-controls="twentytwo"
                                       >
                                          {FOPKeys.lifecycleApprovalPowerview}
                                          <i
                                             id="22"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentytwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomDerivedTable
                                          data={data.lifecycleApprovalPowerview}
                                          derived="Tasks/Signatures"
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading && data.ownership && selectedItems["ownership"] && (
                              <div>
                                 <Card>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(
                                          this,
                                          "23",
                                          "twentythree"
                                       )}
                                       aria-controls="twentythree"
                                    >
                                       {FOPKeys.ownership}
                                       <i
                                          id="23"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="twentythree"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <CommonAttributes data={data.ownership} />
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              data.ipClasses &&
                              data.ipClasses.length > 0 &&
                              selectedItems["ipClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "24",
                                             "twentyfour"
                                          )}
                                          aria-controls="twentyfour"
                                       >
                                          {FOPKeys.ipClasses}{" "}
                                          <i
                                             id="24"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyfour"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.ipClasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClasses &&
                              data.securityClasses.length > 0 &&
                              selectedItems["securityClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "25",
                                             "twentyfive"
                                          )}
                                          aria-controls="twentyfive"
                                       >
                                          {FOPKeys.securityClasses}{" "}
                                          <i
                                             id="25"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyfive"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.securityClasses} />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              selectedItems["organization"] &&
                              data.organization && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "27",
                                             "twentyseven"
                                          )}
                                          aria-controls="twentyseven"
                                       >
                                          {FOPKeys.organization}{" "}
                                          <i
                                             id="27"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyseven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes data={data.organization} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.fileSection &&
                              data.fileSection.length > 0 &&
                              selectedItems["fileSection"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "28",
                                             "twentyeight"
                                          )}
                                          aria-controls="twentyeight"
                                       >
                                          {FOPKeys.fileSection}{" "}
                                          <i
                                             id="28"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyeight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <GenDocsFileDownTable
                                          data={data.fileSection}
                                          attributes={data.attributes}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.gpsAssessments &&
                              data.gpsAssessments.length > 0 &&
                              selectedItems["gpsAssessments"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "34",
                                             "thirtyFour"
                                          )}
                                          aria-controls="thirtyFour"
                                       >
                                          {FOPKeys.gpsAssessments}{" "}
                                          <i
                                             id="34"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtyFour"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.gpsAssessments} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.smartLabel &&
                              data.smartLabel.length > 0 &&
                              selectedItems["smartLabel"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "35",
                                             "thirtyFive"
                                          )}
                                          aria-controls="thirtyFive"
                                       >
                                          {FOPKeys.smartLabel}{" "}
                                          <i
                                             id="35"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtyFive"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComSML
                                          data={data.smartLabel}
                                          parentData={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.certifications &&
                              data.certifications.length > 0 &&
                              selectedItems["certifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "29",
                                             "twentynine"
                                          )}
                                          aria-controls="twentynine"
                                       >
                                          {FOPKeys.certifications}{" "}
                                          <i
                                             id="29"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentynine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <PlainCertifications data={data.certifications} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.certificationValidation &&
                              data.certificationValidation.length > 0 &&
                              selectedItems["certificationValidation"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtySix"
                                          )}
                                          aria-controls="twentynine"
                                       >
                                          {FOPKeys.certificationValidation}{" "}
                                          <i
                                             id="36"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtySix"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.certificationValidation} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.chemicalAndPhysicalProperties &&
                              selectedItems["chemicalAndPhysicalProperties"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "30", "thirty")}
                                          aria-controls="thirty"
                                       >
                                          {FOPKeys.chemicalAndPhysicalProperties}
                                          <i
                                             id="30"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collthirtyapseThree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttributes
                                          data={data.chemicalAndPhysicalProperties}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.stabilityResults &&
                              data.stabilityResults.length > 0 &&
                              selectedItems["stabilityResults"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtySix"
                                          )}
                                          aria-controls="thirtySix"
                                       >
                                          {FOPKeys.stabilityResults}{" "}
                                          <i
                                             id="36"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtySix"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.stabilityResults} />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {FOPKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default FopAllInfo
