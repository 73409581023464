import "font-awesome/css/font-awesome.min.css"
import React from "react"
import { Col } from "react-bootstrap"
import HeaderComponent from "./commonComponents/HeaderComponent"

function ServiceUnavailable(props) {
   sessionStorage.removeItem("breadCrumb")

   const errorCode = props.match.params.errorCode

   let errorMessage = ""

   switch (errorCode) {
      case "403": {
         errorMessage = "You do not have access to this object."
         break
      }

      case "404": {
         errorMessage = "Object is not available in Spec Reader Database."
         break
      }
      case "408": {
         errorMessage = "Request timed out, please try again!"
         break
      }
      case "409": {
         errorMessage = sessionStorage.getItem("409ErrorMsg")
         sessionStorage.removeItem("409ErrorMsg")
         break
      }
      case "422": {
         errorMessage =
            "You have exceeded the limit for the day and access to any additional Part/Specification is blocked! Please contact Support."
         break
      }
      default: {
         errorMessage = "Oops, something went wrong. Please contact Support."
         break
      }
   }

   return (
      <React.Fragment>
         <HeaderComponent />
         <Col md={{ span: 10, offset: 1 }} className="searchTable">
            <span style={{ color: "white", textAlign: "center" }}>{errorMessage}</span>
         </Col>
      </React.Fragment>
   )
}
export default ServiceUnavailable
