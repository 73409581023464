import React from "react"
import { Col, Container, Form, Row } from "react-bootstrap"
import Logo from "../../resource/img/logo.png"
import { LOGOUT_PING } from "../../constant/Constants"
import { logout } from "../../api/AuthService"
function AppLogout(props) {
   logout(LOGOUT_PING, props.history)

   return (
      <React.Fragment>
         <Container fluid className="contentBackground sticky-top shadow">
            <Row>
               <Col md="12">
                  <img src={Logo} alt="logo" style={{ width: "90px" }} />
               </Col>
            </Row>
         </Container>
         <Container fluid>
            <Row>
               <Col>
                  <div className="searchContent">
                     <Form.Row>
                        <Col>
                           <br />
                           <p className="labels">
                              You are logged out of the application. Please try again.
                           </p>
                        </Col>
                     </Form.Row>
                  </div>
               </Col>
            </Row>
         </Container>
      </React.Fragment>
   )
}
export default AppLogout
