import React from "react"
import { Col, Row } from "react-bootstrap"
import CustomLink from "../commonComponents/CustomLink"

const TopContentLatest = (props) => {
   const prop = props.headerContent
   if (props.loading) {
      return (
         <h3 style={{ marginLeft: "30rem", color: "#0640a4" }}>
            <i className="fa fa-spinner fa-spin" style={{ marginRight: "5px" }} />
            Loading........
         </h3>
      )
   }
   return (
      <div>
         <React.Fragment>
            <Row>
               <Col md="3" sm="3">
                  <span id="pageheaders">
                     Name:{" "}
                  </span>
                  {prop.Name}
               </Col>
               <Col md="3" sm="3">
                  <span id="pageheaders">Revision: </span>
                  {prop.Revision}
               </Col>
               {prop.State !== undefined && prop.State != null && (
                  <Col md="3" sm="3" >
                     <span id="pageheaders">State: </span>
                     {prop.State}
                  </Col>
               )}
               <Col md="3" sm="3" >
                  <span id="pageheaders">Has ATS: </span>
                  {prop["Has ATS"]}
               </Col>
            </Row>
            <Row>
               {prop["Has ATS in BOM"] !== undefined && prop["Has ATS in BOM"] != null && (
                  <Col md="3" sm="3">
                     <span id="pageheaders">Has ATS in BOM: </span>
                     {prop["Has ATS in BOM"]}
                  </Col>
               )}
               {prop["SAP Type"] !== undefined && prop["SAP Type"] != null && (
                  <Col md="3" sm="3" >
                     <span id="pageheaders">SAP Type: </span>
                     {prop["SAP Type"]}
                  </Col>
               )}
            
               {prop["Characteristic Update Status"] !== undefined && prop["Characteristic Update Status"] != null && (
                  <Col md="3" sm="3" >
                     <span id="pageheaders">Characteristic Update Status: </span>
                     {prop["Characteristic Update Status"]}
                  </Col>
               )}
               {prop["Model Update Status"] !== undefined && prop["Model Update Status"] != null && (
                  <Col md="3" sm="3">
                     <span id="pageheaders">Model Update Status: </span>
                     {prop["Model Update Status"]}
                  </Col>
               )}
               {prop["Reason for Change in Generic Model"] !== undefined && prop["Reason for Change in Generic Model"] != null && (
                  <Col md="3" sm="3" >
                     <span id="pageheaders">Reason for Change in Generic Model: </span>
                     {prop["Reason for Change in Generic Model"]}
                  </Col>
               )}
               {prop["Master Part"] !== undefined && prop["Master Part"] != null && (
                  <Col md="3" sm="3" >
                     <span id="pageheaders">Master Part: </span>
                     {prop.link &&
                     prop.masterpartid &&
                     prop.masterpartid.toLowerCase().trim() !== "no access" ? (
                        <CustomLink
                           id={prop.masterpartid}
                           link={prop.link}
                           name={prop["Master Part"]}
                        />
                     ) : (
                        prop["Master Part"]
                     )}
                  </Col>
               )}
            </Row>
         </React.Fragment>
      </div>
   )
}

export default TopContentLatest
