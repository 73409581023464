import React from "react"
import { Table } from "react-bootstrap"
import CustomLink from "../commonComponents/CustomLink"
import { setNonDispalyValue } from "../commonComponents/EventHandler"

export const CustomMultiSubstitute = (props) => {
   let items = []
   items = props.data
   const table_Contents = (items) =>
      items.map((item, index) => {
         return (
            <React.Fragment key={index}>
               {item && (
                  <div
                     style={{ border: "1px solid black", marginTop: "4px" }}
                     key={index}
                  >
                     <Table borderless size="sm" responsive>
                        <thead>
                           <tr>
                              <th colSpan="13" style={{ textAlign: "center" }}>
                                 <div>Description: {item.sub["Description"]}</div>
                                 <div>Instructions: {item.sub["Instructions"]}</div>
                                 <hr style={{ backgroundColor: "black" }}></hr>
                              </th>
                           </tr>
                           <tr>
                              <th colSpan="10" style={{ textAlign: "left" }}>
                                 Substitute Parts
                              </th>
                              <th colSpan="3" style={{ textAlign: "left" }}>
                                 Substitute For
                              </th>
                           </tr>
                           <tr>
                              <SubstitutesHeaders
                                 subparts={item.sub["Substitute Parts"]}
                                 subfor={item.sub["Substitute For"]}
                              />
                           </tr>
                        </thead>
                        <tbody>
                           <SubstitutesData
                              subparts={item.sub["Substitute Parts"]}
                              subfor={item.sub["Substitute For"]}
                              index={index}
                           />
                        </tbody>
                     </Table>
                  </div>
               )}
            </React.Fragment>
         )
      })

   const getKeys = (keysInformation = []) => {
      return Object.keys(keysInformation)
   }

   const SubstitutesHeaders = (props) => {
      let subpartKeys = []
      let subforKeys = []
      subpartKeys = getKeys(props.subparts[0])
      subforKeys = getKeys(props.subfor)

      const header = subpartKeys.concat(subforKeys)

      return header.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const SubstitutesData = (props) => {
      let subpartKeys = []
      let subforKeys = []
      subpartKeys = getKeys(props.subparts[0])
      subforKeys = getKeys(props.subfor)
      var items = props.subparts

      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderPartRow
                  key={index + props.index}
                  data={row}
                  subforData={props.subfor}
                  subpartKeys={subpartKeys}
                  subforKeys={subforKeys}
               />
               {index === 0 && (
                  <RenderForRow
                     key={index + props.index + 9000}
                     data={row}
                     subforData={props.subfor}
                     subpartKeys={subpartKeys}
                     subforKeys={subforKeys}
                     subpartData={props.subparts}
                  />
               )}
            </tr>
         )
      })
   }

   const getColumnKeys = (columKeyInfo) => {
      return Object.keys(columKeyInfo)
   }

   const RenderColumn = (props) => {
      return props.keys.map((key, index) => {
         return (
            <div key={index}>
               <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
               <span>{props.data[key]}</span>
            </div>
         )
      })
   }

   const RenderLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            props.data["id"] &&
            key.toLowerCase() === "n"
         ) {
            let name = props.data[key]

            return (
               <div key={index} style={{ minWidth: "9rem" }}>
                  <span>
                     {props.data["link"] && props.data["id"].toLowerCase().trim() !== "no access" ? (
                        <CustomLink
                           id={props.data["id"]}
                           link={props.data["link"]}
                           name={name}
                        />
                     ) : (
                        <span>{name}</span>
                     )}
                  </span>
               </div>
            )
         } else if (props.data[key] && setNonDispalyValue(key)) {
            return null
         }
      })
   }

   const RenderPartRow = (props) => {
      return props.subpartKeys.map((key, index) => {
         if (props.data[key] && typeof props.data[key] == "object" && key === "Name") {
            let colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderLinkColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         } else if (props.data[key] != null && typeof props.data[key] === "object") {
            let colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn data={props.data[key]} isbold={true} keys={colKeys} />
               </td>
            )
         } else if (setNonDispalyValue(key)) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   const RenderSubColumn = (props) => {
      if (props.data) {
         var keys = getKeys(props.data[0])
         return props.data.map((key, index) => {
            return (
               <div key={index}>
                  <RenderSubForRow key={index} data={key} keys={keys} />
                  <br />
               </div>
            )
         })
      } else return null
   }

   const RenderSubForRow = (props) => {
      return props.keys.map((key, index) => {
         if (
            key.toLowerCase() === "n" &&
            props.data["link"] &&
            props.data["link"] !== ""
         ) {
            let name = props.data[key]
            return (
               <div style={{ minWidth: "9rem" }} key={index}>
                  {props.data["id"] &&
                  props.data["id"].toLowerCase().trim() !== "no access" ? (
                     <React.Fragment>
                        <span className="bomFontClass">{key}:</span>
                        <CustomLink
                           id={props.data["id"].trim()}
                           link={props.data["link"]}
                           name={name}
                        />
                     </React.Fragment>
                  ) : (
                     <React.Fragment>
                        <span className="bomFontClass">{key}:</span>
                        <span>{name}</span>
                     </React.Fragment>
                  )}
               </div>
            )
         } else if (
            key.toLowerCase() === "id" ||
            key.toLowerCase() === "link" ||
            key.toLowerCase() === "rev"
         ) {
            return null
         } else {
            if (props.data[key] != null)
               return (
                  <div key={index}>
                     <span className="bomFontClass">{key}:</span>
                     {props.data[key]}
                  </div>
               )
            else return null
         }
      })
   }

   const RenderForRow = (props) => {
      let span = props.subpartData.length
      return props.subforKeys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         } else {
            return (
               <td key={index} rowSpan={span}>
                  <RenderSubColumn data={props.subforData[key]} />
               </td>
            )
         }
      })
   }

   return <div>{items && table_Contents(items)}</div>
}
export default CustomMultiSubstitute
