import React from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import Attributes from "./ReportAttributes"

function ReportDetailData(props) {
    const history = useHistory()
    return (
        <React.Fragment>
            {props.error === 2 && (
                <Row className="labels mt-5">
                    <Col md={{ span: 6, offset: 3 }} align="center">
                        There is an issue with the report service. Please contact Support.
                    </Col>
                </Row>
            )}
            {props.error === 0 && (
                <Row className="mt-5">
                    <Col
                        md={{ span: 10, offset: 1 }}
                        className="reportForm contentBackground shadow mb-3"
                    >
                        <Row>
                            <Col md="12" align="center" className="loginTextHeader mb-3">
                                Report Details
                            </Col>
                            <Col md="12">
                                <Button variant="primary" onClick={history.goBack} style={{float:"right",marginTop:"-10px",marginBottom:"5px"}}>
                                    Back
                                </Button>
                            </Col>
                            <Col md="12">
                                <Card autoFocus>
                                    <Card.Body className="accordionStyle">
                                        {props.data.reportDetail["Name"]}
                                    </Card.Body>
                                </Card>
                                <div className="accordionDiv shadow">
                                    <Row>
                                        <Col md="3" sm="3" className="mt-3">
                                            <span id="pageheaders">State:</span>
                                            &nbsp;{props.data.headerContent.State}
                                        </Col>
                                        <Col md="3" sm="3" className="mt-3">
                                            <span id="pageheaders">Owner:</span>
                                            &nbsp;{props.data.headerContent.Owner}
                                        </Col>
                                        <Col md="5" sm="5" className="mt-3">
                                            <span id="pageheaders">Modified Date:</span>
                                            &nbsp;{props.data.headerContent.Modified}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="accordionDiv shadow">
                                    <Attributes data={props.data.reportDetail} col="1" />
                                </div>
                                <Card autoFocus>
                                    <Card.Body className="accordionStyle">
                                        IP Security Settings
                                    </Card.Body>
                                </Card>
                                <div className="accordionDiv shadow">
                                    <Attributes data={props.data.ipSecuritySetting} col="1" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    )
}
export default ReportDetailData