import React, { useEffect, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import BootstrapSelect from 'react-bootstrap-select-dropdown'
import { DSMReportGenerate, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { FETCH_SEARCH_3D_RESULT, typeDropdown } from "../../constant/Constants"
import SearchDSResults from "./SearchDSResults"

function SearchDSData(props) {
    const { handleSetFrom, ...rest } = props;
    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false)
    const [errorFlag, setErrorFlag] = useState(1)
    const [search, setSearch] = useState({
        type: "",
        name: "",
    })
    const [searchButtonDisable, setsearchButtonDisable] = useState(true)
    const [message, setMessage] = useState("")
    const [selectedItems, setSelectedItems] = useState([])

    const handleSelectedItems = (obj) => {
        setSelectedItems(obj)
    }

    function validateInput() {
        if (search.type === "") {
            setMessage("*Type is mandatory")
            return false
        } else {
            return true
        }
    }

    const handleSearch = () => {
        if (validateInput()) {
            setLoading(true)
            DSMReportGenerate(`${FETCH_SEARCH_3D_RESULT}`, search)
                .then((response) => {
                    if (response.data && response.data.subscriptionFields !== null) {
                        setResults(response.data.subscriptionFields)
                        setLoading(false)
                        setErrorFlag(0)
                    }
                    else {
                        setErrorFlag(1)
                        setLoading(false)
                    }

                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        notAuthorized(props.history)
                        setLoading(false)
                    }
                    else {
                        setLoading(false)
                        setErrorFlag(2)
                    }
                })
        }
    }

    const handelSubmit = (e) => {
        if (!isLoggedIn()) {
            props.history.push("/")
        }
        e.preventDefault()
        handleSearch()
    }
    const closeSeach = (e) => {
        setSearch((data) => ({
            ...data,
            type: "",
            name: "",
        }))
        setResults([])
        setMessage("")
        setLoading(false)
        setErrorFlag(1)
        handleSetFrom()
        if (selectedItems.length > 0 && e.target.value === "Submit") {
            props.onHide(selectedItems.toString())
        } else {
            props.onHide()
        }
        setSelectedItems([])
    }

    const handleTypeChange = (selectedOptions) => {
        var typeArray = selectedOptions.selectedKey
        setMessage("")
        setSearch((data) => ({
            ...data,
            type: typeArray.toString(),
        }));
    }

    function handleInputChange(e) {
        setMessage("")
        const { name, value } = e.target
        setSearch((data) => ({
            ...data,
            [name]: value,
        }));
    }

    useEffect(() => {
        var isEmpty = Object.values(search).every(data => data === "");
        if (!isEmpty) {
            setsearchButtonDisable(false)
        }
        else {
            setsearchButtonDisable(true)
        }
    }, [search])

    return (
        <Modal
            {...rest}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="my-modal"
            style={{ overflowY: "hidden" }}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
                <button type="button" className="close" onClick={closeSeach}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handelSubmit}>
                    <Row style={{ height: "410px" }}>
                        <Col md="4" sm="5">
                            <Form.Label className="loginTextHeader" >
                                Advanced Search
                            </Form.Label>
                            <br />
                            <Col style={{ maxHeight: "350px" }}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column md="12" className="reportLabels" id="view">
                                        Type
                                    </Form.Label>
                                    <BootstrapSelect
                                        name="type"
                                        options={props.from ? typeDropdown[props.from].map((value) => { return (value) }) : []}
                                        isMultiSelect={true}
                                        onChange={handleTypeChange}
                                        className="searchType"
                                    />
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column md="12" className="reportLabels" id="name">
                                        Name
                                    </Form.Label>
                                    <Form.Control
                                        column="true"
                                        type="text"
                                        style={{ width: "100%" }}
                                        name="name"
                                        id="name"
                                        onChange={handleInputChange}
                                        value={search.name}
                                        autoComplete="off"
                                        placeholder="Enter text"
                                    />
                                </Form.Group>
                                <Form.Row>
                                    <Col md="11" sm="11" align="center">
                                        <p style={{ marginTop: "4px" }} className="alertMsg">
                                            {message}
                                        </p>
                                    </Col>
                                </Form.Row>
                                <Form.Group align="right">
                                    <Button variant="primary" disabled={searchButtonDisable} onClick={handleSearch}>Search</Button>
                                </Form.Group>
                            </Col>
                        </Col>
                        <Col md="8" sm="7">
                            <Col className="loginTextHeader" align="center">
                                Search Results
                            </Col>
                            {loading ?
                                <h2 style={{ textAlign: "center", color: "#0173d4", marginTop: "15%" }}>
                                    <i
                                        className="fa fa-spinner fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                    Loading........
                                </h2>
                                :
                                <SearchDSResults results={results} error={errorFlag} type={search.type} handler={handleSelectedItems} />

                            }
                        </Col>
                    </Row>
                    <br />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button value="Submit" onClick={closeSeach} disabled={selectedItems.length > 0 ? false : true} >Submit</Button>
                <Button value="Close" onClick={closeSeach}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}
export default SearchDSData
