import React from "react"
import { Link } from "react-router-dom"
import { addBreadCrumbLinks } from "../commonComponents/EventHandler"

const BaseCodeDetails = (props) => {
   let baseCodeDetails = []
   baseCodeDetails = props.data

   const baseCodeDetails_contents =
      baseCodeDetails &&
      baseCodeDetails.map((baseCodeDetail, index) => (
         <tr key={index}>
            <td>
               {baseCodeDetail["name"]["id"] &&
               baseCodeDetail["name"]["id"].toString().trim().toLowerCase() !==
                  "no access" &&
               baseCodeDetail["name"]["link"] ? (
                  <Link
                     onClick={() =>
                        addBreadCrumbLinks(
                           baseCodeDetail["name"]["name"],
                           `/${baseCodeDetail["name"]["link"].toUpperCase().trim()}/${
                              baseCodeDetail["name"]["id"]
                           }`
                        )
                     }
                     to={(location) => ({
                        ...location,
                        pathname: `/${baseCodeDetail["name"]["link"].toUpperCase()}/${
                           baseCodeDetail["name"]["id"]
                        }`,
                     })}
                  >
                     <span textalign="left">{baseCodeDetail["name"]["name"]}</span>
                  </Link>
               ) : (
                  <span>{baseCodeDetail["name"]["name"]}</span>
               )}
            </td>

            <td>{baseCodeDetail.gtin_customerUnitPart}</td>
            <td>{baseCodeDetail.gtin_innerPack}</td>
            <td>{baseCodeDetail.gtin_consumerUnitPart}</td>

            <td>
               <span>{baseCodeDetail.customerUnitPart["L*W*D"]}</span>
               <br />
               <span>{baseCodeDetail.customerUnitPart["GW"]}</span>
            </td>
            <td>
               <span> {baseCodeDetail.innerPack["L*W*D"]}</span>
               <br />
               <span> {baseCodeDetail.innerPack["GW"]}</span>
            </td>
            <td>
               <span> {baseCodeDetail.consumerUnitPart["L*W*D"]}</span>
               <br />
               <span> {baseCodeDetail.consumerUnitPart["GW"]}</span>
            </td>
         </tr>
      ))
   return (
      <div className="tableFixHead">
         <table>
            <thead style={{ border: "1px" }}>
               <tr>
                  <th rowSpan="2">
                     <span>Name </span>
                  </th>
                  <th colSpan="3" style={{ textAlign: "center" }}>
                     <span>GTIN</span>
                  </th>

                  <th colSpan="3" style={{ textAlign: "center" }}>
                     <span>
                        W&D (L*W*D)
                        <br />
                        Gross Weight (GW)
                     </span>
                  </th>
               </tr>
               <tr>
                  <th>Customer Unit Part</th>
                  <th>Inner Pack</th>
                  <th>Consumer Unit Part</th>
                  <th>Customer Unit Part</th>
                  <th>Inner Pack</th>
                  <th>Consumer Unit Part</th>
               </tr>
            </thead>
            <tbody>{baseCodeDetails_contents}</tbody>
         </table>
      </div>
   )
}
export default BaseCodeDetails
