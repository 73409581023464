import React, { useEffect, useMemo, useState, useRef } from "react"
import { Col, Container, Form, Row, Table, DropdownButton, Button } from "react-bootstrap"
import DropdownItem from "react-bootstrap/DropdownItem"
import Pagination from "react-pagination-bootstrap"
import { Link, useHistory } from "react-router-dom"
import { addBreadCrumbLinks } from "../commonComponents/EventHandler"
import ExportToExcel from "../vendorInfo/ExportToExcel"
import CommonView from "./CommonView"
import {
   PendingViewActions,
   SummaryViewActions,
   WorkViewActions,
   FavoritesViewActions,
   MOVE_REMOVE_API,
   EBP_VIEW_PDF_DOWNLOAD
} from "../../constant/Constants"
import { MoveRemoveAPI, isLoggedIn, EBPViewPdfDownload } from "../../api/AuthService"
import VendorMailModal from "./VendorMailModal"
import { saveAs } from "file-saver"


const VendorResults = ({ view, results, error, handleViewMorePart, fromViewMore, siteInfo }) => {

   const useSortableData = (items, config = null) => {
      const [sortConfig, setSortConfig] = useState(config)
      const sortedItems = useMemo(() => {
         let sortableItems = [...items]
         if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
               if (sortConfig.key === "ReleaseDate" || sortConfig.key === "EBPModified") {
                  if (new Date(a[sortConfig.key]) < new Date(b[sortConfig.key])) {
                     return sortConfig.direction === "ascending" ? -1 : 1
                  }
                  if (new Date(a[sortConfig.key]) > new Date(b[sortConfig.key])) {
                     return sortConfig.direction === "ascending" ? 1 : -1
                  }
                  return 0
               } else {
                  if (a[sortConfig.key] < b[sortConfig.key]) {
                     return sortConfig.direction === "ascending" ? -1 : 1
                  }
                  if (a[sortConfig.key] > b[sortConfig.key]) {
                     return sortConfig.direction === "ascending" ? 1 : -1
                  }
                  return 0
               }
            })
         }
         return sortableItems
      }, [items, sortConfig])

      const requestSort = (key) => {
         let direction = "descending"
         if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === "descending"
         ) {
            direction = "ascending"
         }
         setSortConfig({ key, direction })
      }
      return { items: sortedItems, requestSort, sortConfig }
   }

   const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

   const [resultsPerPage, setResultsPerPage] = useState(20)
   const [currentPage, setCurrentPage] = useState(1)
   const indexOfLastResult = currentPage * resultsPerPage
   const indexOfFirstResult = indexOfLastResult - resultsPerPage
   const [filteredData, setFilteredData] = useState([])
   const [activePage, setActivePage] = useState(1)
   const [message, setMessage] = useState("")
   const [success, setSuccess] = useState(false)
   const [dynamicAction, setDynamicAction] = useState(PendingViewActions)
   const [show, setShow] = useState(false)
   const [moveData, setMoveData] = useState({})
   const [isAlertvisible, setIsAlertVisible] = useState(true);
   const [pdfDisable, setPdfDisable] = useState(false)
   const history = useHistory()


   const rows = results
   const [selectedRowIds, setSelectedRowIds] = useState([])
   const [selectedNames, setSelectedNames] = useState([])
   const [selectAllRows, setSelectAllRows] = useState(false)

   const releaseDateArray = results.filter((item) => item.EBPModified !== null).map(item => item.EBPModified)
   const releaseDateObjects = releaseDateArray.map((date) => new Date(date))
   const oldestReleaseDate = new Date(Math.min.apply(null, releaseDateObjects))


   const [filterDates, setFilterDates] = useState({
      fromdateformat: '',
      todateformat: '',
      selectdateformat: '',
      sortDateByRange: false,
      sortByDate: false,
      sortByDateMessage: false,
      sortDateByRangeMessage: false,
      sortByMonth: false,
      sortByMonthMessage: false,
      firstOfMonth: '',
      lastOfMonth: '',
      filtered: false,
   })

   const currentMonth = (new Date()).getMonth();
   const currentYear = (new Date()).getFullYear();
   const firstDayCurrDate = new Date(currentYear, currentMonth, 1)
   const lastDayCurrDate = new Date(currentYear, currentMonth + 1, 0)
   const firstDateString = `${currentYear}/${currentMonth + 1}/${firstDayCurrDate.getDate()}`
   const lastDateString = `${currentYear}/${currentMonth + 1}/${lastDayCurrDate.getDate()}`

   const [searchData, setSearchData] = useState('')
   const [regSearch, setRegSearch] = useState(false)

   const [dataSearched, setDataSearched] = useState([])
   const [actualFilteredData, setActualFilteredData] = useState([])



   const inputRef = useRef()
   const [searching, setSearching] = useState(false)
   const [showFilter, setShowFilter] = useState(true)


   function handleSearchChange(e) {
      setSearching(true)
      setRegSearch(true)
      setShowFilter(false)
      if ((e.target.value) === '') {
         setShowFilter(true)
      }
      setSearchData(e.target.value)
      var re1 = new RegExp(e.target.value, 'i');
      var regData = view === 'Pending'
         ?
         (
            filteredData.length !== 0 ? filteredData : finalCurrentMonth
         )
         :
         results

      var matches = regData.filter(matcher(re1, ['Name', 'Type', 'Title', 'ReasonForChange']));
      setDataSearched(matches)
      if (inputRef.current.value) {
         setFilteredData(actualFilteredData)
      }

      setActivePage(1)
      setCurrentPage(1)
   }

   function matcher(regexp, columns) {
      return function (obj) {
         var found = false
         columns.forEach(function (column) {
            if (!found && obj[column] && typeof obj[column] === 'string' && regexp.exec(obj[column])) {
               found = true
            }
         });
         return found
      }
   }


   function dateCheck(from, to, check) {
      let fDate, lDate, cDate
      fDate = Date.parse(from)
      lDate = Date.parse(to)
      cDate = Date.parse(check)
      if ((cDate <= lDate && cDate >= fDate)) {
         return true
      }
      return false
   }

   const finalCurrentMonth = results.filter((item) => {
      if (item.EBPModified) {
         const setFilterDate = item.EBPModified.split(" ")[0].split("/")
         const settomonth = setFilterDate[0];
         const settodate = setFilterDate[1];
         const settoyear = setFilterDate[2];
         const toFilterDate = settoyear + "/" + settomonth + "/" + settodate;
         const start = firstDateString
         const end = lastDateString
         return dateCheck(start, end, toFilterDate)
      } else {
         return true
      }
   })

   const handleSortByDateRange = () => {
      handleClearFilter()
      setIsAlertVisible(true);
      setFilterDates((dates) => ({
         ...dates,
         fromdateformat: '',
         todateformat: '',
         sortDateByRange: true,
         sortByDateMessage: false,
         sortByMonthMessage: false,
         sortDateByRangeMessage: false,
         filtered: false
      }))
      if (filterDates.sortByDate) {
         setFilterDates((dates) => ({
            ...dates,
            sortByDate: false,
         }))
      }
      if (filterDates.sortByMonth) {
         setFilterDates((dates) => ({
            ...dates,
            sortByMonth: false,
         }))
      }
   }

   const handlefromdate = (e) => {
      const getfromdatevalue = e.target.value;
      const setfromformat = getfromdatevalue.split("-");
      const setfromyear = setfromformat[0];
      const setfrommonth = setfromformat[1];
      const setfromdate = setfromformat[2];
      const setfromformatdate = setfrommonth + "/" + setfromdate + "/" + setfromyear;
      setFilterDates((dates) => ({
         ...dates,
         fromdateformat: setfromformatdate,
      }))
   }

   const handletodate = (e) => {
      const gettodatevalue = e.target.value;
      const setdateformat = gettodatevalue.split('-');
      const settoyear = setdateformat[0];
      const settomonth = setdateformat[1];
      const settodate = setdateformat[2];
      const settodateformat = settomonth + "/" + settodate + "/" + settoyear;
      setFilterDates((dates) => ({
         ...dates,
         todateformat: settodateformat,
      }))
   }

   const handleDateByRange = (e) => {
      setIsAlertVisible(false);
      e.preventDefault();
      setDataSearched([])
      inputRef.current.value = ''
      let start = ''
      let end = ''
      let toFilterDate = ''
      if ((filterDates.fromdateformat === '') && (filterDates.todateformat === '')) {
         alert("Please enter the Date Range!")
      } else if ((filterDates.fromdateformat === '') && (filterDates.todateformat !== '')) {
         start = oldestReleaseDate.toLocaleDateString('en-US')
         const startComponents = start.split('/')
         const month = startComponents[0]
         const day = startComponents[1]
         const year = startComponents[2]

         const formattedMonth = month.length === 1 ? `0${month}` : month
         const formattedDay = day.length === 1 ? `0${day}` : day

         const formattedStart = `${formattedMonth}/${formattedDay}/${year}`
         setFilterDates((dates) => ({
            ...dates,
            fromdateformat: formattedStart,
         }))
         end = filterDates.todateformat
      } else if ((filterDates.fromdateformat !== '') && (filterDates.todateformat === '')) {
         start = filterDates.fromdateformat
         end = (new Date()).toLocaleDateString('en-US')
         const endComponents = end.split('/')
         const month = endComponents[0]
         const day = endComponents[1]
         const year = endComponents[2]

         const formattedMonth = month.length === 1 ? `0${month}` : month
         const formattedDay = day.length === 1 ? `0${day}` : day

         const formattedEnd = `${formattedMonth}/${formattedDay}/${year}`
         setFilterDates((dates) => ({
            ...dates,
            todateformat: formattedEnd,
         }))
      } else {
         start = filterDates.fromdateformat
         end = filterDates.todateformat
      }
      if ((new Date(start)) < (new Date(end))) {
         rangeDataDisplay(start, end, toFilterDate)
      } else if ((new Date(start)) > (new Date(end))) {
         alert("Please select To Date later than From Date!")
      }
   }

   const rangeDataDisplay = (start, end, toFilterDate) => {
      setFilterDates((dates) => ({
         ...dates,
         filtered: true
      }))
      const filteredDateData = results.filter((item) => {
         if (item.EBPModified) {
            const setFilterDate = item.EBPModified.split(" ")[0].split("/")
            const settomonth = setFilterDate[0];
            const settodate = setFilterDate[1];
            const settoyear = setFilterDate[2];
            toFilterDate = settomonth + "/" + settodate + "/" + settoyear;
            return dateCheck(start, end, toFilterDate)
         } else {
            return true
         }
      })
      setActualFilteredData(filteredDateData)
      const searchFilterData = filteredDateData.filter(item => dataSearched.includes(item))
      setFilteredData(inputRef.current.value !== '' ? searchFilterData : filteredDateData)

      setFilterDates((dates) => ({
         ...dates,
         sortDateByRange: false,
         sortDateByRangeMessage: true,
      }))
      setDataSearched([])
   }



   const handleSortByDate = () => {
      handleClearFilter()
      setIsAlertVisible(true);
      setDataSearched([])
      inputRef.current.value = ''
      setFilterDates((dates) => ({
         ...dates,
         selectdateformat: '',
         sortByDate: true,
         sortByDateMessage: false,
         sortByMonthMessage: false,
         sortDateByRangeMessage: false,
         sortByDateMessage: false,
         filtered: false
      }))
      if (filterDates.sortDateByRange) {
         setFilterDates((dates) => ({
            ...dates,
            sortDateByRange: false
         }))
      }
      if (filterDates.sortByMonth) {
         setFilterDates((dates) => ({
            ...dates,
            sortByMonth: false
         }))
      }
   }

   const handleSelectDate = (e) => {
      const getSelectDateValue = e.target.value;
      const setSelectFormat = getSelectDateValue.split("-");
      const setSelectYear = setSelectFormat[0];
      const setSelectMonth = setSelectFormat[1];
      const setSelectDate = setSelectFormat[2];
      const setSelectFormatDate = setSelectMonth + "/" + setSelectDate + "/" + setSelectYear;
      setFilterDates((dates) => ({
         ...dates,
         selectdateformat: setSelectFormatDate
      }))
   }

   const handleSelectDateSubmit = (e) => {
      setIsAlertVisible(false);
      setDataSearched([])
      inputRef.current.value = ''
      e.preventDefault();
      if (filterDates.selectdateformat) {
         setFilterDates((dates) => ({
            ...dates,
            filtered: true
         }))
         const filteredDateData = results.filter((item) => {
            if (item.EBPModified) {
               const setFilterDate = item.EBPModified.split(" ")[0].split("/")
               const settomonth = setFilterDate[0];
               const settodate = setFilterDate[1];
               const settoyear = setFilterDate[2];
               const toFilterDate = Date.parse(settomonth + "/" + settodate + "/" + settoyear);

               const currentDate = Date.parse(filterDates.selectdateformat)
               if (toFilterDate === currentDate) {
                  return true
               }
               return false
            } else {
               return true
            }
         })
         setActualFilteredData(filteredDateData)
         const searchFilterData = filteredDateData.filter(item => dataSearched.includes(item))
         setFilteredData(inputRef.current.value !== '' ? searchFilterData : filteredDateData)
         // setFilteredData(filteredDateData)
         setFilterDates((dates) => ({
            ...dates,
            sortByDate: false,
            sortByDateMessage: true
         }))
      } else {
         alert("Please choose the date")
      }
   }

   const handleSortByMonth = () => {
      handleClearFilter()
      setIsAlertVisible(true);
      setFilterDates((dates) => ({
         ...dates,
         firstOfMonth: '',
         lastOfMonth: '',
         sortByMonth: true,
         sortByDateMessage: false,
         sortByMonthMessage: false,
         sortDateByRangeMessage: false,
         filtered: false
      }))
      if (filterDates.sortDateByRange) {
         setFilterDates((dates) => ({
            ...dates,
            sortDateByRange: false,
         }))
      }
      if (filterDates.sortByDate) {
         setFilterDates((dates) => ({
            ...dates,
            sortByDate: false
         }))
      }
   }

   const handleSelectMonth = (e) => {
      const getSelectDateValue = e.target.value;
      const setMonthFormat = getSelectDateValue.split("-");

      const setYear = setMonthFormat[0];
      const setMonth = setMonthFormat[1];

      const firstDay = new Date(setYear, parseInt(setMonth, 10) - 1, 1)
      const lastDay = new Date(setYear, parseInt(setMonth, 10), 0)

      const firstDateString = `${setMonth}/${firstDay.getDate()}/${setYear}`
      const lastDateString = `${setMonth}/${lastDay.getDate()}/${setYear}`

      setFilterDates((dates) => ({
         ...dates,
         firstOfMonth: firstDateString,
         lastOfMonth: lastDateString
      }))
   }

   const handleMonthSubmit = (e) => {
      setIsAlertVisible(false);
      setDataSearched([])
      inputRef.current.value = ''
      e.preventDefault();
      if (filterDates.firstOfMonth && filterDates.lastOfMonth) {
         setFilterDates((dates) => ({
            ...dates,
            filtered: true
         }))
         const filteredDateData = results.filter((item) => {
            if (item.EBPModified) {
               const setFilterDate = item.EBPModified.split(" ")[0].split("/")
               const settomonth = setFilterDate[0];
               const settodate = setFilterDate[1];
               const settoyear = setFilterDate[2];
               const toFilterDate = settomonth + "-" + settodate + "-" + settoyear;

               const start = (filterDates.firstOfMonth)
               const end = (filterDates.lastOfMonth)

               return dateCheck(start, end, toFilterDate)
            } else {
               return true
            }

         })
         setActualFilteredData(filteredDateData)
         const searchFilterData = filteredDateData.filter(item => dataSearched.includes(item))
         setFilteredData(inputRef.current.value !== '' ? searchFilterData : filteredDateData)

         setFilterDates((dates) => ({
            ...dates,
            sortByMonth: false,
            sortByMonthMessage: true
         }))
      } else {
         alert("Please select the Month and Year")
      }
   }

   let currentResults = view === "Pending"
      ?
      (
         filterDates.filtered
            ?
            filteredData.sort((a, b) => new Date(b.EBPModified) - new Date(a.EBPModified))
            :
            finalCurrentMonth.sort((a, b) => new Date(b.EBPModified) - new Date(a.EBPModified))
      )
      :
      results.sort((a, b) => new Date(b.EBPModified) - new Date(a.EBPModified))

   var searchInput = inputRef?.current?.value;

   let finalData;
   if (dataSearched.length) {
      finalData = dataSearched.sort((a, b) => new Date(b.EBPModified) - new Date(a.EBPModified))
   }
   else if (searchInput !== undefined && filteredData.length == 0) {
      finalData = dataSearched
   }
   else if (filteredData.length !== 0) {
      if (searchInput) {
         finalData = dataSearched;
      }
      else {
         finalData = currentResults;
      }

   }
   else {
      finalData = currentResults
   }

   if (searchInput === '' && filteredData.length === 0) {
      finalData = currentResults
   }

   const { items, requestSort, sortConfig } = useSortableData(finalData)

   const currentResult = items.slice(indexOfFirstResult, indexOfLastResult)

   const exportData = items


   function handlePDFDownload(Name, Link, Id, Rev) {
      if (!isLoggedIn()) {
         history.pushState("/")
      } else {
         setPdfDisable(true)
         let fileName = "download"
         fileName = Name ? (Rev ? Name + "-Rev" + Rev : Name) : fileName
         const data = {
            Name: Name,
            Rev: Rev,
            Id: Id,
            Type: Link
         }
         EBPViewPdfDownload(`${EBP_VIEW_PDF_DOWNLOAD}`, data)
            .then((response) => {
               const blob = new Blob([response.data], {
                  type: "application/pdf",
               })

               let fileContents = URL.createObjectURL(blob)
               saveAs(fileContents, fileName)
               setPdfDisable(false)
            })
            .catch((error) => {
               if (error.response && error.response.status === 422) {
                  //setShow(true)
                  alert(
                     "You have exceeded the limit for the day and access to any additional Part/Specification is blocked! Please contact Support."
                  )
               }
               else if (error.response && error.response.data.status === 401) {
                  history.push("/")
               }
               else {
                  alert("There is an issue downloading the file. Please try again later.")
                  console.log(" error", error)
               }
               setPdfDisable(false)
            })
      }
   }


   const handleHeaderCheckBoxChange = () => {
      if (selectAllRows) {
         setSelectedRowIds([])
         setSelectedNames([])
      } else {
         const allRowIds = rows.map((row) => row.ebpId)
         const allNames = rows.map((row) => row.Name)
         setSelectedRowIds(allRowIds)
         setSelectedNames(allNames)
      }
      setSelectAllRows(!selectAllRows)
   }

   const handleRowCheckBoxChange = (rowId, rowName) => {
      const updatedSelectedRowIds = selectedRowIds.includes(rowId)
         ? selectedRowIds.filter((id) => id !== rowId)
         : [...selectedRowIds, rowId]
      setSelectedRowIds(updatedSelectedRowIds)
      const allRowsSelected = rows.every((row) =>
         updatedSelectedRowIds.includes(row.ebpId)
      )
      const updatedSelectedNames = selectedNames.includes(rowName)
         ? selectedNames.filter((name) => name !== rowName)
         : [...selectedNames, rowName]
      setSelectedNames(updatedSelectedNames)

      setSelectAllRows(allRowsSelected)
   }

   const isChecked = (value) => {
      if (value === "All") {
         return selectAllRows
      } else {
         return selectedRowIds.includes(value) ? true : false
      }
   }

   const getClassNamesFor = (name) => {
      if (!sortConfig) {
         return "fa fa-sort"
      } else if (sortConfig.key === name) {
         if (sortConfig.direction === "ascending") {
            return "fa fa-caret-up"
         } else {
            return "fa fa-caret-down"
         }
      } else {
         return "fa fa-sort"
      }
   }

   const impacted_content = currentResult.length ? (currentResult[0].ImpactedParts.length ? (currentResult[0].ImpactedParts.map((key, index) => (
      <tr key={index}>
         <td width="10%">
            {key.link != null && key.id != null ? (
               <Link
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() =>
                     addBreadCrumbLinks(
                        key.Name,
                        `/${key.link.toUpperCase().trim()}/${key.id.trim()}`
                     )
                  }
                  to={(location) => ({
                     ...location,
                     pathname: `/${key.link.toUpperCase()}/${key.id}`,
                  })}
               >
                  <span textalign="left">{key.Name}</span>
               </Link>
            ) : (
               <span>{key.Name}</span>
            )}
         </td>
         <td align="left" width="10%">
            {key.DisplayType}
         </td>
         <td align="left" width="5%">
            {key.Revision}
         </td>
         <td align="left" width="10%">
            {key.Title}
         </td>
      </tr>
   ))) : (
      <tr>
         <td align="left" width="10%">
            {""}
         </td>
         <td align="left" width="10%">
            {""}
         </td>
         <td align="left" width="5%">
            {""}
         </td>
         <td align="left" width="10%">
            {""}
         </td>
      </tr>
   )) : []


   const info_content = currentResult.map((key, index) => (
      <tr key={index}>
         <td width="10%">
            {key.link != null && key.id != null ? (
               <Link
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() =>
                     addBreadCrumbLinks(
                        key.Name,
                        `/${key.link.toUpperCase().trim()}/${key.id.trim()}`
                     )
                  }
                  to={(location) => ({
                     ...location,
                     pathname: `/${key.link.toUpperCase()}/${key.id}`,
                  })}
               >
                  <span textalign="left">{key.Name}</span>
               </Link>
            ) : (
               <span>{key.Name}</span>
            )}
         </td>
         <td align="left" width="10%">
            {key.Type}
         </td>
         <td align="left" width="5%">
            {key.Rev}
         </td>
         <td align="left" width="10%">
            {key.Title}
         </td>
      </tr>
   ))

   function moveFromView(data) {
      MoveRemoveAPI(`${MOVE_REMOVE_API}`, data)
         .then((response) => {
            setSuccess(true)
            if (view === 'Pending' || view === 'Summary' || view === 'Work') {
               setMessage(
                  "Your request is submitted and the Part(s)/Specification(s) will be moved after few minutes."
               )
            } else {
               setMessage(
                  "The action performed on the selected Part(s)/Specificatio(s) is in process. Please refresh the screen after few minutes."
               )
            }

            setTimeout(() => {
               setMessage("")
            }, 3000)
         })
         .catch((error) => {
            if (error.response && error.response.status) {
               console.log(error.response.status, error.response.data.message)
            } else {
               console.log(1000, error)
            }
            setSuccess(false)
            setMessage(
               "Currently there is some issue at vendor services. Please try after some time."
            )
            setTimeout(() => {
               setMessage("")
            }, 3000)
         })
   }

   function handleOnClick(event) {
      const selectedIds = selectedRowIds
      const selectedPartNames = selectedNames
      const viewName = view === "Work" ? "In-Work with P&G" : view
      if (selectedRowIds.length !== 0) {
         let data = {}
         let alertMsg = ''
         if (event === "RemoveFromFavorite") {
            data = {
               ebpstate: "Accepted",
               favourite: false,
               wip: false,
               ebpid: selectedIds,
               ebpName: selectedPartNames,
               event: "to remove from Favorites",
               vendor: siteInfo
            }
            alertMsg = "The selected part(s) will be removed from Favorites View. Do you wish to continue?"
         } else if (event === "MoveToFavorite") {
            data = {
               ebpstate: "Accepted",
               favourite: true,
               wip: false,
               ebpid: selectedIds,
               ebpName: selectedPartNames,
               event: `from ${viewName} to Favorites`,
               vendor: siteInfo
            }
            if (view === 'Pending') {
               alertMsg = `Moving parts to Favorites View indicate the parts are Accepted by [${siteInfo}]. Selected Parts will be removed from Pending and In-Work with P&G Views. Do you wish to proceed?`
            } else if (view === 'Work') {
               alertMsg = `Moving parts to Favorites View indicate the parts are Accepted by [${siteInfo}]. Selected Parts will be removed from Pending and In-Work with P&G Views. Do you wish to proceed?`
            } else if (view === 'Summary') {
               alertMsg = 'The selected part(s) will be added to Favorites View. Do you wish to continue?'
            }
         } else if (event === "MoveToSummary") {
            data = {
               ebpstate: "Accepted",
               favourite: false,
               wip: false,
               ebpid: selectedIds,
               ebpName: selectedPartNames,
               event: `from ${viewName} to Summary`,
               vendor: siteInfo
            }
            if (view === 'Work' || view === 'Pending') {
               alertMsg = `Moving parts to Summary View indicate the parts are Accepted by [${siteInfo}]. Selected Parts will be removed from Pending and In-Work with P&G Views. Do you wish to proceed?`
            }

         } else if (event === "MoveToP&G") {
            data = {
               ebpstate: "Pending",
               favourite: false,
               wip: true,
               ebpid: selectedIds,
               ebpName: selectedPartNames,
               event: `from ${viewName} to In-Work with P&G`,
               vendor: siteInfo
            }
            if (view === 'Summary') {
               alertMsg = `Moving parts to In-Work with P&G View indicate the parts are Rejected by [${siteInfo}]. Selected Parts will be added to Pending and In-Work with P&G Views. Do you wish to proceed?`
            } else if (view === 'Pending') {
               alertMsg = `Moving parts to In-Work with P&G View indicate the parts are Rejected by [${siteInfo}]. Selected Parts will be added to In-Work with P&G View. Do you wish to proceed?`
            }
         }
         setMoveData(data)
         moveOrRemove(data, alertMsg)

      } else {
         alert("You must select one or more parts for the selected Action")
      }
   }

   const moveOrRemove = (toMoveData, alertMsg) => {
      let deleteForm;
      deleteForm = window.confirm(alertMsg)
      if (deleteForm && alertMsg !== "" && toMoveData.wip) {
         setShow(true)
      }
      else {
         if (deleteForm) {
            moveFromView(toMoveData)
         }
      }
   }

   function handlePageChange(pageNumber) {
      setCurrentPage(pageNumber)
      setActivePage(pageNumber)
   }

   useEffect(() => {
      if (view == "Summary") {
         setDynamicAction(SummaryViewActions)
      } else if (view == "Work") {
         setDynamicAction(WorkViewActions)
      } else if (view == "Favorites") {
         setDynamicAction(FavoritesViewActions)
      } else if (view == "Pending") {
         setDynamicAction(PendingViewActions)
      } else {
         setDynamicAction([])
      }
   }, [view])

   function formatDate(inputDate) {
      const dateParts = inputDate.split("/")
      const month = new Date(inputDate).toLocaleString('en-us', { month: 'short' });
      const day = dateParts[1]
      const year = dateParts[2]
      return `${day}-${month}-${year}`
   }

   function handleClearFilter() {
      setSearching(false)
      setIsAlertVisible(true)
      setResultsPerPage(20)
      setCurrentPage(1)
      inputRef.current.value = ''
      setActivePage(1)
      setFilterDates((dates) => ({
         ...dates,
         sortDateByRange: false,
         fromdateformat: '',
         todateformat: '',
         selectdateformat: '',
         sortDateByRange: false,
         sortByDate: false,
         sortByDateMessage: false,
         sortDateByRangeMessage: false,
         sortByMonth: false,
         sortByMonthMessage: false,
         firstOfMonth: '',
         lastOfMonth: '',
         filtered: false,
      }))
      setSelectedRowIds([]);
      setSelectedNames([]);
      setSelectAllRows(false);
      setFilteredData([])
      setActualFilteredData([])
      setDataSearched([])
      if (!showFilter) {
         setShowFilter(true)
      }
   }


   return (
      <React.Fragment>
         {error === 2 && (
            <Row className="labels">
               <Col md={{ span: 6, offset: 3 }} align="center">
                  There is an issue with the vendor service. Please contact Support.
               </Col>
            </Row>
         )}
         {error === 1 && (
            <Row className="labels" align="center">
               <Col md={{ span: 6, offset: 3 }}>No Records Found.</Col>
            </Row>
         )}
         {error === 0 && !fromViewMore && (
            <React.Fragment>
               <Container fluid className="searchDataContainer">
                  <Row className="mt-4 mb-3">
                     <Col md={4} sm={4} align="left">
                        <div style={{ width: "100%" }}>
                           <table style={{ width: "100%" }}>
                              <tbody>
                                 <tr>
                                    {view != "Search" &&
                                       <>
                                          <td style={{
                                             width: "18%",
                                             verticalAlign: "middle",
                                             textAlign: "left",
                                          }} >
                                             Page Size
                                          </td>
                                          <td style={{ width: "25%" }}>
                                             <Form.Control
                                                as="select"
                                                onChange={(e) => {
                                                   setResultsPerPage(e.target.value)
                                                   setCurrentPage(1)
                                                   setActivePage(1)
                                                }}
                                                className="vendorOptions"
                                             >
                                                <option value="20">20</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                             </Form.Control>
                                          </td></>}
                                    <td>
                                       <DropdownButton title="Action">
                                          {dynamicAction &&
                                             dynamicAction.map((item, index) => (
                                                <DropdownItem
                                                   value={item.value}
                                                   key={index}
                                                   onClick={() => handleOnClick(item.value)}
                                                >
                                                   {item.display}
                                                </DropdownItem>
                                             ))}
                                          <DropdownItem>
                                             <ExportToExcel view={view} data={exportData} />
                                          </DropdownItem>
                                       </DropdownButton>
                                    </td>
                                    {view === "Pending" &&
                                       <>
                                          <td>
                                             <DropdownButton title="Filter By" disabled={!showFilter}>
                                                <DropdownItem onClick={handleSortByDate}>
                                                   Date
                                                </DropdownItem>
                                                <DropdownItem onClick={handleSortByDateRange}>
                                                   Date Range
                                                </DropdownItem>
                                                <DropdownItem onClick={handleSortByMonth}>
                                                   Month
                                                </DropdownItem>
                                             </DropdownButton>
                                          </td>
                                          <td>
                                             <Button
                                                style={{ backgroundColor: "#007bff", marginLeft: "5px" }}
                                                onClick={() => handleClearFilter()}>
                                                Clear
                                             </Button>
                                          </td>
                                       </>
                                    }
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </Col>
                     {view !== 'Search' && 
                     <>
                        <Col md={2} sm={2} align="right" style={{ paddingTop: "7px" }}>
                           Search:
                        </Col>
                        <Col md={2} sm={2} align="centre">
                           <Form.Control
                              type="text"
                              className="vendorOptions"
                              ref={inputRef}
                              onChange={handleSearchChange}
                           />
                        </Col>
                        <Col md={4} sm={4} align="right">
                           <div
                              style={{ width: "40%", verticalAlign: "middle" }}
                              id="paginTab"
                           >
                              <Pagination
                                 activePage={activePage}
                                 itemsCountPerPage={resultsPerPage}
                                 totalItemsCount={view === "Pending" ? finalData.length : results.length}
                                 pageRangeDisplayed={6}
                                 onChange={handlePageChange}
                              />
                           </div>
                        </Col>
                     </>
                     }
                  </Row>
                  {view === 'Search' &&
                     <p
                        style={{ marginTop: "4px", fontWeight: "bold" }}
                        className={"alertMsg"}
                     >
                        {currentResult[0].highlyConnectedParts}
                     </p>
                  }
                  {filterDates.sortDateByRangeMessage && filteredData.length > 0 &&
                     <p
                        style={{ marginTop: "4px", fontWeight: "bold" }}
                        className={"filteredDataMsg"}
                     >
                        Filtered By Date Range {formatDate(filterDates.fromdateformat)} to {formatDate(filterDates.todateformat)}
                     </p>
                  }
                  {filterDates.sortByDateMessage && filteredData.length > 0 &&
                     <p
                        style={{ marginTop: "4px", fontWeight: "bold" }}
                        className={"filteredDataMsg"}
                     >
                        Filtered By Date {formatDate(filterDates.selectdateformat)}
                     </p>
                  }
                  {filterDates.sortByMonthMessage && filteredData.length > 0 &&
                     <p
                        style={{ marginTop: "4px", fontWeight: "bold" }}
                        className={"filteredDataMsg"}
                     >
                        Filtered By Month {
                           ((new Date(filterDates.firstOfMonth)).getMonth()) < 10
                              ?
                              `${monthNames[(new Date(filterDates.firstOfMonth)).getMonth()]}`
                              :
                              `${monthNames[(new Date(filterDates.firstOfMonth)).getMonth()]}`
                        }-{(new Date(filterDates.firstOfMonth)).getFullYear()}
                     </p>
                  }
                  {filterDates.sortDateByRange &&
                     <div>
                        <div className="row fthight">
                           <div className="col-sm-8  mt-3">
                              {
                                 showFilter &&
                                 <form>
                                    <div className="row mb-4">
                                       <label className="col-sm-2 col-form-label">From Date<span className="astriccolor">*</span></label>
                                       <div className="col-sm-5">
                                          <input type="date" className="form-control" name="fromdate" placeholder="dd-mm-yyyy" onChange={(e) => handlefromdate(e)} />
                                          <span className="text-danger"> </span>
                                       </div>
                                    </div>

                                    <div className="row mb-4 ">
                                       <label className="col-sm-2 col-form-label">To Date<span className="astriccolor">*</span></label>
                                       <div className="col-sm-5">
                                          <input type="date" className="form-control" name="todate" placeholder="dd-mm-yyyy" onChange={(e) => handletodate(e)} />
                                       </div>
                                    </div>

                                    <div className="row mb-4 ">
                                       <label className="col-sm-2 col-form-label"></label>
                                       <div className="col-sm-5">
                                          <Button
                                             style={{ backgroundColor: "#09f" }}
                                             onClick={handleDateByRange}
                                          >
                                             Submit
                                          </Button>
                                       </div>
                                    </div>
                                 </form>
                              }
                           </div>
                        </div>
                     </div>
                  }

                  {filterDates.sortByDate &&
                     <div>
                        <div className="row fthight">
                           <div className="col-sm-8  mt-3">
                              {
                                 showFilter &&
                                 <form>
                                    <div className="row mb-4">
                                       <label className="col-sm-2 col-form-label">Select Date<span className="astriccolor">*</span></label>
                                       <div className="col-sm-5">
                                          <input type="date" className="form-control" name="selectdate" placeholder="dd-mm-yyyy" onChange={(e) => handleSelectDate(e)} />
                                          <span className="text-danger"> </span>
                                       </div>
                                    </div>

                                    <div className="row mb-4 ">
                                       <label className="col-sm-2 col-form-label"></label>
                                       <div className="col-sm-5">
                                          <Button
                                             style={{ backgroundColor: "#09f" }}
                                             onClick={handleSelectDateSubmit}
                                          >
                                             Submit
                                          </Button>
                                       </div>
                                    </div>
                                 </form>
                              }
                           </div>
                        </div>
                     </div>
                  }

                  {filterDates.sortByMonth &&
                     <div>
                        <div className="row fthight">
                           <div className="col-sm-8  mt-3">
                              {
                                 showFilter &&
                                 <form>
                                    <div className="row mb-4">
                                       <label className="col-sm-2 col-form-label">Select Month<span className="astriccolor">*</span></label>
                                       <div className="col-sm-5">
                                          <input type="month" className="form-control" name="selectmonth" placeholder="mm-yyyy" onChange={(e) => handleSelectMonth(e)} />
                                          <span className="text-danger"> </span>
                                       </div>
                                    </div>

                                    <div className="row mb-4 ">
                                       <label className="col-sm-2 col-form-label"></label>
                                       <div className="col-sm-5">
                                          <Button
                                             style={{ backgroundColor: "#09f" }}
                                             onClick={handleMonthSubmit}
                                          >
                                             Submit
                                          </Button>
                                       </div>
                                    </div>
                                 </form>
                              }
                           </div>
                        </div>
                     </div>
                  }
                  {isAlertvisible && view === "Pending" &&
                     <p id="msg" className="successMsgVendor"  >
                        The default view displays the current month's data only. Please use the Filter By option to see more data.
                     </p>
                  }
                  <p
                     style={{ marginTop: "4px", fontWeight: "bold" }}
                     className={success ? "successMsg" : "alertMsg"}
                  >
                     {message}
                  </p>
                  {view != "Search" ? ( //Because Pending/In-work/Summary/Favourite view has same table structure
                     <CommonView
                        currentResult={currentResult}
                        requestSort={requestSort}
                        getClassNamesFor={getClassNamesFor}
                        handleViewMorePart={handleViewMorePart}
                        isChecked={isChecked}
                        handleHeaderCheckBoxChange={handleHeaderCheckBoxChange}
                        handleRowCheckBoxChange={handleRowCheckBoxChange}
                        dataSearched={dataSearched}
                        searchInput={searchInput}
                        searching={searching}
                        pdfDisable={pdfDisable}
                        handlePDFDownload={handlePDFDownload}
                        view = {view}
                     />
                  ) : (
                     <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                           <Table bordered id="vendorInfoTable" style={{ height: "50vh" }}>
                              <thead id="infoTab">
                                 <tr>
                                    <th width="10%">
                                       Name&nbsp;

                                    </th>
                                    <th width="10%">
                                       Type &nbsp;

                                    </th>
                                    <th width="5%">
                                       Rev &nbsp;

                                    </th>
                                    <th width="10%">Title &nbsp;</th>
                                 </tr>
                              </thead>
                              <tbody>{info_content}</tbody>
                           </Table>
                        </div>
                        <div
                           style={{ overflow: "auto", flex: 1, height: "50vh" }}
                        >
                           <Table bordered id="vendorInfoTable" style={{ height: "50vh" }}>
                              <thead style={{ position: "sticky", top: -1, backgroundColor: "white" }}>
                                 <tr>
                                    <th width="15%">
                                       Impacted Part(s)&nbsp;
                                    </th>
                                    <th width="10%">
                                       Type &nbsp;

                                    </th>
                                    <th width="5%">
                                       Rev &nbsp;

                                    </th>
                                    <th width="10%">Title &nbsp;</th>
                                 </tr>
                              </thead>
                              <tbody>{impacted_content}</tbody>
                           </Table>
                        </div>
                     </div>
                  )}
               </Container>
            </React.Fragment>
         )
         }
         <VendorMailModal
            show={show}
            setShow={setShow}
            selectedNames={selectedNames}
            moveFromView={moveFromView}
            moveData={moveData} />
      </React.Fragment >
   )
}

export default VendorResults
