import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { DefaultKeys, DPPKeys, FETCH_DPP } from "../../constant/Constants"
import Attribute from "../commonComponents/Attributes"
import ComIngredientStatement from "../commonComponents/ComIngredientStatement"
import CommonAttribute from "../commonComponents/CommonAttributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import ComSML from "../commonComponents/ComSML"
import CustomMasterSpecification from "../commonComponents/CustomMasterSpecification"
import CustomMCTable from "../commonComponents/CustomMatrialAndComposition"
import { CustomMultiBomTable } from "../commonComponents/CustomMultiBomTable"
import { CustomMultiSubstituteTable } from "../commonComponents/CustomMultiSubstituteTable"
import CustomPCTable from "../commonComponents/CustomPCTable"
import CustomPQRView from "../commonComponents/CustomPQRView"
import CustomSapBom from "../commonComponents/CustomSapBom"
import CustomTable from "../commonComponents/CustomTable"
import DerivedTable from "../commonComponents/DerivedTable"
import DownloadLinkTable from "../commonComponents/DownloadLinkTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import GenDocsFileDownTable from "../commonComponents/GenDocsFilesDownload"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import PlainCertifications from "../commonComponents/PlainCertifications"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../commonComponents/TopContentLatest"

function DppAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributeObject: {},
      weightCharcteristic: [],
      dangerousGoodsClassification: [],
      globalHarmonizedStandard: [],
      ingredientStatement: [],
      storageTransportationLabelingAssessmentData: {},
      productPartStabilityDocument: [],
      masterPartStabilityDocument: [],
      notes: [],
      sapBOMFed: [],
      marketClearance: [],
      substancesMaterials: [],
      materialsAndCompositions: [],
      billOfMaterials: {},
      substitutions: [],
      relatedSpecification: [],
      masterSpecification: [],
      referenceDocuments: [],
      performanceCharacteristics: [],
      plants: [],
      pqrViewManufacturer: [],
      pqrSupplierView: [],
      relatedATS: [],
      materialProduced: [],
      lifecycleApprovalPowerview: [],
      ownershipObject: {},
      ipClasses: [],
      securityClass: [],
      organizationObject: {},
      fileSection: [],
      gpsAssessments: [],
      smartLabel: [],
      certifications: [],
      certificationValidation: [],
      stabilityResult: [],
      whereUsed: null,
   })

   const [loading, setLoading] = useState(true)
   const searchData = props.match.params.objectID
   const [sideNav, setSideNav] = useState()
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})
   //const userRole = localStorage.getItem("role")

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)

      var values = pdfData(data, obj, IsFilterView)

      setPdfDataSet(values)
   }

   useEffect(() => {
      const fetchState = { cancel: false }
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchDppData(fetchState) {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")

               var apiData

               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  // Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_DPP}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContentObject)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
               }
               if (fetchState.cancel) {
                  return
               }
               setData((data) => ({
                  ...data,

                  headerContent: apiData.headerContentObject
                     ? apiData.headerContentObject
                     : data.headerContent,
                  attributeObject: apiData.attributeObject,
                  organizationObject: apiData.organizationObject,
                  relatedSpecification: apiData.relatedSpecification,
                  lifecycleApprovalPowerview: apiData.lifecycleApprovalPowerview,
                  ownershipObject: apiData.ownershipObject,
                  derivedTo: apiData.derivedTo,
                  derivedFrom: apiData.derivedFrom,
                  performanceCharacteristics: apiData.performanceCharacteristics,
                  plants: apiData.plants,
                  securityClass: apiData.securityClass,
                  referenceDocuments: apiData.referenceDocuments,
                  masterSpecification: apiData.masterSpecification,
                  masterAttribute: apiData.masterAttribute,
                  notes: apiData.notes,
                  billOfMaterials: apiData.billOfMaterials,
                  substitutions: apiData.substitutions,
                  weightCharcteristic: apiData.weightCharcteristic,
                  sapBOMFed: apiData.sapBOMFed,
                  manufacturerEquivalents: apiData.manufacturerEquivalents,
                  supplierEquivalence: apiData.supplierEquivalence,
                  relatedATS: apiData.relatedATS,
                  materialProduced: apiData.materialProduced,
                  dangerousGoodsClassification: apiData.dangerousGoodsClassification,
                  globalHarmonizedStandard: apiData.globalHarmonizedStandard,
                  stabilityResult: apiData.stabilityResult,
                  marketClearance: apiData.marketClearance,
                  certifications: apiData.certifications,
                  storageTransportationLabelingAssessmentData:
                     apiData.storageTransportationLabelingAssessmentData,
                  substancesMaterials: apiData.substancesMaterials,
                  materialsAndCompositions: apiData.materialsAndCompositions,
                  fileSection: apiData.fileSection,
                  productPartStabilityDocument: apiData.productPartStabilityDocument,
                  masterPartStabilityDocument: apiData.masterPartStabilityDocument,
                  ipClasses: apiData.ipClasses,
                  pqrSupplierView: apiData.pqrSupplierView,
                  pqrViewManufacturer: apiData.pqrViewManufacturer,
                  ingredientStatement: apiData.ingredientStatement,
                  gpsAssessments: apiData.gpsAssessment,
                  smartLabel: apiData.smartLabel,
                  certificationValidation: apiData.certificationValidation,
                  whereUsed: apiData.whereUsed,
               }))

               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  console.log("Error", ` Not authorie: ${error}`)
                  notAuthorized(props.history)
                  return
               }
               console.log("Error", ` API request failed: ${error}`)
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchDppData(fetchState)
      }

      return () => {
         fetchState.cancel = true
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={DPPKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" id="print" className="contentBackground">
                     <br />
                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="9" sm="9">
                              <TopContent headerContent={data.headerContent} />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              <PdfDownloadComponent
                                 genDocHide={!data.headerContent.gendocView}
                                 pdfDataSet={pdfDataSet}
                              />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {data.attributeObject && selectedItems["attributeObject"] && (
                              <div>
                                 <Card autoFocus>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(this, "0", "zero")}
                                       aria-controls="zero"
                                    >
                                       {DPPKeys.attributeObject}{" "}
                                       <i
                                          id="0"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="zero"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <Attribute
                                       loading={loading}
                                       data={data.attributeObject}
                                    />
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              data.weightCharcteristic &&
                              data.weightCharcteristic.length > 0 &&
                              selectedItems["weightCharcteristic"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "1", "one")}
                                          aria-controls="one"
                                       >
                                          {DPPKeys.weightCharcteristic}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="one"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.weightCharcteristic} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.dangerousGoodsClassification &&
                              data.dangerousGoodsClassification.length > 0 &&
                              selectedItems["dangerousGoodsClassification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "2", "two")}
                                          aria-controls="two"
                                       >
                                          {DPPKeys.dangerousGoodsClassification}{" "}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="two"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable
                                          data={data.dangerousGoodsClassification}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.globalHarmonizedStandard &&
                              data.globalHarmonizedStandard.length > 0 &&
                              selectedItems["globalHarmonizedStandard"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "3", "three")}
                                          aria-controls="three"
                                       >
                                          {DPPKeys.globalHarmonizedStandard}{" "}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="three"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable
                                          data={data.globalHarmonizedStandard}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ingredientStatement &&
                              data.ingredientStatement.length > 0 &&
                              selectedItems["ingredientStatement"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "4", "four")}
                                          aria-controls="four"
                                       >
                                          {DPPKeys.ingredientStatement}{" "}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="four"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComIngredientStatement
                                          data={data.ingredientStatement}
                                          parentData={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.storageTransportationLabelingAssessmentData &&
                              selectedItems[
                                 "storageTransportationLabelingAssessmentData"
                              ] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "5", "five")}
                                          aria-controls="five"
                                       >
                                          {
                                             DPPKeys.storageTransportationLabelingAssessmentData
                                          }{" "}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="five"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute
                                          data={
                                             data.storageTransportationLabelingAssessmentData
                                          }
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.productPartStabilityDocument &&
                              data.productPartStabilityDocument.length > 0 &&
                              selectedItems["productPartStabilityDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "6", "six")}
                                          aria-controls="six"
                                       >
                                          {DPPKeys.productPartStabilityDocument}{" "}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="six"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable
                                          data={data.productPartStabilityDocument}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.masterPartStabilityDocument &&
                              data.masterPartStabilityDocument.length > 0 &&
                              selectedItems["masterPartStabilityDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "7", "seven")}
                                          aria-controls="seven"
                                       >
                                          {DPPKeys.masterPartStabilityDocument}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="seven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable
                                          data={data.masterPartStabilityDocument}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.notes &&
                              data.notes.length > 0 &&
                              selectedItems["notes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "8", "eight")}
                                          aria-controls="eight"
                                       >
                                          {DPPKeys.notes}{" "}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="eight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.notes} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.sapBOMFed &&
                              data.sapBOMFed.length > 0 &&
                              selectedItems["sapBOMFed"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "9", "nine")}
                                          aria-controls="nine"
                                       >
                                          {DPPKeys.sapBOMFed}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="nine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomSapBom data={data.sapBOMFed} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.marketClearance &&
                              selectedItems["marketClearance"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "10", "ten")}
                                          aria-controls="ten"
                                       >
                                          {DPPKeys.marketClearance}{" "}
                                          <i
                                             id="10"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="ten"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.marketClearance} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substancesMaterials &&
                              data.substancesMaterials.length > 0 &&
                              selectedItems["substancesMaterials"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "33", "thirtyThree")}
                                          aria-controls="thirtyThree"
                                       >
                                          {DPPKeys.substancesMaterials}{" "}
                                          <i
                                             id="33"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtyThree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMCTable data={data.substancesMaterials} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.materialsAndCompositions &&
                              data.materialsAndCompositions.length > 0 &&
                              selectedItems["materialsAndCompositions"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "11", "elevan")}
                                          aria-controls="elevan"
                                       >
                                          {DPPKeys.materialsAndCompositions}{" "}
                                          <i
                                             id="11"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="elevan"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMCTable
                                          data={data.materialsAndCompositions}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.billOfMaterials &&
                              selectedItems["billOfMaterials"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "12", "twelve")}
                                          aria-controls="twelve"
                                       >
                                          {DPPKeys.billOfMaterials}{" "}
                                          <i
                                             id="12"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twelve"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMultiBomTable data={data.billOfMaterials} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutions &&
                              data.substitutions.length > 0 &&
                              selectedItems["substitutions"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "13",
                                             "thirteen"
                                          )}
                                          aria-controls="thirteen"
                                       >
                                          {DPPKeys.substitutions}{" "}
                                          <i
                                             id="13"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMultiSubstituteTable
                                          data={data.substitutions}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedSpecification &&
                              data.relatedSpecification.length > 0 &&
                              selectedItems["relatedSpecification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "14",
                                             "fourteen"
                                          )}
                                          aria-controls="fourteen"
                                       >
                                          {DPPKeys.relatedSpecification}{" "}
                                          <i
                                             id="14"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fourteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DownloadLinkTable
                                          data={data.relatedSpecification}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.masterSpecification &&
                              data.masterSpecification.length > 0 &&
                              selectedItems["masterSpecification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "15",
                                             "fifteen"
                                          )}
                                          aria-controls="fifteen"
                                       >
                                          {DPPKeys.masterSpecification}{" "}
                                          <i
                                             id="15"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="fifteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMasterSpecification
                                          data={data.masterSpecification}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocuments &&
                              data.referenceDocuments.length > 0 &&
                              selectedItems["referenceDocuments"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "16",
                                             "sixteen"
                                          )}
                                          aria-controls="sixteen"
                                       >
                                          {DPPKeys.referenceDocuments}{" "}
                                          <i
                                             id="16"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="sixteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComRefDocDownload
                                          data={data.referenceDocuments}
                                          attributes={data.attributeObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.performanceCharacteristics &&
                              data.performanceCharacteristics.length > 0 &&
                              selectedItems["performanceCharacteristics"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "17",
                                             "seventeen"
                                          )}
                                          aria-controls="seventeen"
                                       >
                                          {DPPKeys.performanceCharacteristics}{" "}
                                          <i
                                             id="17"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="seventeen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPCTable
                                          data={data.performanceCharacteristics}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.plants &&
                              data.plants.length > 0 &&
                              selectedItems["plants"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "18",
                                             "eighteen"
                                          )}
                                          aria-controls="eighteen"
                                       >
                                          {DPPKeys.plants}{" "}
                                          <i
                                             id="18"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="eighteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.plants} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.pqrViewManufacturer &&
                              data.pqrViewManufacturer.length > 0 &&
                              selectedItems["pqrViewManufacturer"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "19",
                                             "nineteen"
                                          )}
                                          aria-controls="nineteen"
                                       >
                                          {DPPKeys.pqrViewManufacturer}
                                          <i
                                             id="19"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="nineteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPQRView data={data.pqrViewManufacturer} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.pqrSupplierView &&
                              data.pqrSupplierView.length > 0 &&
                              selectedItems["pqrSupplierView"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "20", "twenty")}
                                          aria-controls="twenty"
                                       >
                                          {DPPKeys.pqrSupplierView}
                                          <i
                                             id="20"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twenty"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPQRView data={data.pqrSupplierView}  view="supplier" />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.relatedATS &&
                              data.relatedATS.length > 0 &&
                              selectedItems["relatedATS"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "21",
                                             "twentyone"
                                          )}
                                          aria-controls="twentyone"
                                       >
                                          {DPPKeys.relatedATS}{" "}
                                          <i
                                             id="21"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyone"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.relatedATS} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.materialProduced &&
                              data.materialProduced.length > 0 &&
                              selectedItems["materialProduced"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "22",
                                             "twentytwo"
                                          )}
                                          aria-controls="twentytwo"
                                       >
                                          {DPPKeys.materialProduced}{" "}
                                          <i
                                             id="22"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentytwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.materialProduced} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.lifecycleApprovalPowerview &&
                              data.lifecycleApprovalPowerview.length > 0 &&
                              selectedItems["lifecycleApprovalPowerview"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "23",
                                             "twentythree"
                                          )}
                                          aria-controls="twentythree"
                                       >
                                          {DPPKeys.lifecycleApprovalPowerview}{" "}
                                          <i
                                             id="23"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentythree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DerivedTable
                                          data={data.lifecycleApprovalPowerview}
                                          derived="Tasks/Signatures"
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ownershipObject &&
                              selectedItems["ownershipObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "24",
                                             "twentyfour"
                                          )}
                                          aria-controls="twentyfour"
                                       >
                                          {DPPKeys.ownershipObject}{" "}
                                          <i
                                             id="24"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyfour"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.ownershipObject} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ipClasses &&
                              data.ipClasses.length > 0 &&
                              selectedItems["ipClasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "25",
                                             "twentyfive"
                                          )}
                                          aria-controls="twentyfive"
                                       >
                                          {DPPKeys.ipClasses}{" "}
                                          <i
                                             id="25"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyfive"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.ipClasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClass &&
                              data.securityClass.length > 0 &&
                              selectedItems["securityClass"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "26",
                                             "twentysix"
                                          )}
                                          aria-controls="twentysix"
                                       >
                                          {DPPKeys.securityClass}{" "}
                                          <i
                                             id="26"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentysix"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.securityClass} />
                                    </div>
                                 </div>
                              )}

                           {!loading &&
                              data.organizationObject &&
                              selectedItems["organizationObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "27",
                                             "twentyseven"
                                          )}
                                          aria-controls="twentyseven"
                                       >
                                          {DPPKeys.organizationObject}{" "}
                                          <i
                                             id="27"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyseven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.organizationObject} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.fileSection &&
                              data.fileSection.length > 0 &&
                              selectedItems["fileSection"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "28",
                                             "twentyeight"
                                          )}
                                          aria-controls="twentyeight"
                                       >
                                          {DPPKeys.fileSection}{" "}
                                          <i
                                             id="28"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentyeight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <GenDocsFileDownTable
                                          data={data.fileSection}
                                          attributes={data.attributesObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.gpsAssessments &&
                              data.gpsAssessments.length > 0 &&
                              selectedItems["gpsAssessments"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "29",
                                             "twentynine"
                                          )}
                                          aria-controls="twentynine"
                                       >
                                          {DPPKeys.gpsAssessments}{" "}
                                          <i
                                             id="29"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="twentynine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.gpsAssessments} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.smartLabel &&
                              data.smartLabel.length > 0 &&
                              selectedItems["smartLabel"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(this, "30", "thirty")}
                                          aria-controls="thirty"
                                       >
                                          {DPPKeys.smartLabel}{" "}
                                          <i
                                             id="30"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirty"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComSML
                                          data={data.smartLabel}
                                          parentData={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.certifications &&
                              data.certifications.length > 0 &&
                              selectedItems["certifications"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "31",
                                             "thirtyone"
                                          )}
                                          aria-controls="thirtyone"
                                       >
                                          {DPPKeys.certifications}{" "}
                                          <i
                                             id="31"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtyone"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <PlainCertifications data={data.certifications} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.certificationValidation &&
                              data.certificationValidation.length > 0 &&
                              selectedItems["certificationValidation"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "32",
                                             "thirtytwo"
                                          )}
                                          aria-controls="thirtytwo"
                                       >
                                          {DPPKeys.certificationValidation}{" "}
                                          <i
                                             id="32"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="thirtytwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.certificationValidation} />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {DPPKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default DppAllInfo
