import React, { useEffect, useState } from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { fetchAPIData, isLoggedIn } from "../../api/AuthService"
import {
   FETCH_REPORT_TEMPLATE,
   reportTypes,
   FETCH_HELP_DSM,
} from "../../constant/Constants"
import HeaderComponent from "../commonComponents/HeaderComponent"
import ATSReport from "./ReportTemplates/ATSReport"
import MassArtWorkReport from "./ReportTemplates/MassArtWorkReport"
import PartAndSpec from "./ReportTemplates/PartAndSpec"
import PDFDownloadReport from "./ReportTemplates/PDFDownloadReport"
import RefDocumentReport from "./ReportTemplates/RefDocumentReport"
import WhereUsed from "./ReportTemplates/WhereUsed"
import CharMasterReport from "./ReportTemplates/CharMasterReport"
import CertificationRollUp from "./ReportTemplates/CertificationRollUp"
import { DSMReportGenerate, notAuthorized } from "../../api/AuthService"
import { FETCH_SEARCH_3D_RESULT } from "../../constant/Constants"

import "./response.css"

function GenReport(props) {
   const userID = localStorage.getItem("UserId")
   const UserName = localStorage.getItem("User")
   const userRole = localStorage.getItem("role")
   const [reportName, setReportName] = useState("Empty")
   const [templateVal, setTemplateVal] = useState("0")
   const [template, setTemplate] = useState([])
   const [templateData, setTemplateData] = useState({})
   const templateId = props.location.state?.id ? props.location.state.id : "0"
   const [calledFrom, setCalledFrom] = useState(0)

   const [loading, setLoading] = useState(false)
  
   const [errorFlag, setErrorFlag] = useState(1)
   const [businessClaims, setbusinessClaims] = useState([])
   const [templateCertClaims, settemplateCertClaims] = useState([])
   const [templateCertClaim, settemplateCertClaim] = useState([])
   
   const [charMasterAreas, setCharMasterAreas] = useState([])
   const [templateCharMasterAreas, setTemplateCharMasterAreas] = useState([])
   const [temSelectedCharMasterAreas, setTemSelectedCharMasterAreas] = useState([])
  
   const handleReportChange = (e) => {
      const repName = e.target.value
      setReportName(e.target.value)
      document.getElementById("view").value = "0"
      setTemplateData({})
      if (repName === "Characteristic Master Report") {
         handleSearch()
      } else if (repName === "Certification Roll-Up") {
         handleBusinessClaims()
      }
   }

   const handleSearch = () => {
     
      const search = { type: "businessAreas", name: "" }

     
      DSMReportGenerate(`${FETCH_SEARCH_3D_RESULT}`, search)
         .then((response) => {
            if(charMasterAreas.length > 0){
               setCharMasterAreas([])
               setTemplateCharMasterAreas([])
            }
            if (response.data && response.data.subscriptionFields !== null) {
               
               for (let i = 0; i < response.data.subscriptionFields.length; i++) {
                  setCharMasterAreas((prevArray)=>[...prevArray, response.data.subscriptionFields[i].Name])
               }
               setErrorFlag(0)
            } else {
               setErrorFlag(1)
            }
         })
         .catch((error) => {
            if (error.response && error.response.status === 401) {
               notAuthorized(props.history)
               setLoading(false)
            } else {
               setLoading(false)
               setErrorFlag(2)
            }
         })
   }
   function handleClick() {
      window.open(`${FETCH_HELP_DSM}`)
   }

   const handleBusinessClaims = () => {
     
      const search = { type: "certificationClaims", name: "" }

      
      DSMReportGenerate(`${FETCH_SEARCH_3D_RESULT}`, search)
         .then((response) => {
           
            if(businessClaims.length > 0){
               setbusinessClaims([])
               settemplateCertClaims([])
            }
               if (response.data && response.data.subscriptionFields !== null) {
               for (let i = 0; i < response.data.subscriptionFields.length; i++) {
               setbusinessClaims((prevArray)=>[...prevArray, response.data.subscriptionFields[i].Name])
                  
               }
               //  setResults(response.data.subscriptionFields)

               setErrorFlag(0)
            } else {
               setErrorFlag(1)
            }
         })
         .catch((error) => {
            if (error.response && error.response.status === 401) {
               notAuthorized(props.history)
               setLoading(false)
            } else {
               setLoading(false)
               setErrorFlag(2)
            }
         })
   }

   async function fetchTemplateDetail(tempId) {
      try {
         const response = await fetchAPIData(`${FETCH_REPORT_TEMPLATE}/${tempId}`)
         if (response.data) {
            const tempdata = response.data.templateDetails

           
        

            if (tempdata.certificationClaims !== null) {
               setbusinessClaims([])
               settemplateCertClaims([])
               for (let i = 0; i < tempdata.certificationClaims.length; i++) {
               settemplateCertClaims((prevArray)=>[...prevArray, tempdata.certificationClaims[i]])
                  
               }
              
               setErrorFlag(0)
            }
          

           if (tempdata.certificationClaim !== null) {
            settemplateCertClaim([])
               for (let i = 0; i < tempdata.certificationClaim.length; i++) {
                  settemplateCertClaim((prevArray)=>[...prevArray, tempdata.certificationClaim[i]])
                  
               }
              
               setErrorFlag(0)
            }
            if (tempdata.buisness_Area !== null) {
               setTemplateCharMasterAreas([])
               setCharMasterAreas([])
               for (let i = 0; i < tempdata.buisness_Area.length; i++) {
               setTemplateCharMasterAreas((prevArray)=>[...prevArray, tempdata.buisness_Area[i]])
               }
               setErrorFlag(0)
            }

            if (tempdata.buisness_Areas !== null) {
               setTemSelectedCharMasterAreas([])
               for (let i = 0; i < tempdata.buisness_Areas.length; i++) {
               setTemSelectedCharMasterAreas((prevArray)=>[...prevArray, tempdata.buisness_Areas[i]])
               }
               setErrorFlag(0)  
               }
            

            if (reportTypes.indexOf(tempdata.reportType) === -1) {
               setReportName("invalid type")
            } else {
               setReportName(tempdata.reportType)
            }
            setTemplateData(tempdata)
         }
      } catch (error) {
         console.log("template apply api error", error)
      }
   }

   function handleClear() {
      setTemplateVal("0")
      setReportName("Empty")
   }

   async function fetchData() {
      try {
         const response = await fetchAPIData(`${FETCH_REPORT_TEMPLATE}`)
         if (response.data) {
            setTemplate(response.data.templateColumns)
            if (templateId !== "0") {
               document.getElementById("view").value = templateId
            }
         }
      } catch (error) {
         console.log(error)
      }
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/")
      } else {
         fetchData()
         if (templateId !== "0") {
            fetchTemplateDetail(templateId)
            setCalledFrom(1)
           
         }
      }
   }, [props.history])

   const handleTemplateChange = (e) => {
      setTemplateVal(e.target.value)
   }

   const handleTemplateApply = (e) => {
      setCalledFrom(1)
      fetchTemplateDetail(templateVal)
   }

   return (
      <React.Fragment>
         <HeaderComponent />
         <Container fluid>
            <Row className="mt-5">
               <Col
                  md={{ span: 10, offset: 1 }}
                  className="contentBackground reportForm shadow"
               >
                  <Form>
                     <Col md="12" align="center" className="loginTextHeader">
                        Report Request
                     </Col>

                     {userRole !== "CM" && userRole !== "SP" && (
                        <div className="helpHeader" onClick={handleClick}>
                           (?) Help
                        </div>
                     )}
                     <br />
                     <Form.Group as={Row} className="mb-3" controlId="formPlaintextText">
                        <Form.Label column sm="5" md="5" className="reportLabels">
                           User Name
                        </Form.Label>
                        <Col sm="4" md="4">
                           <Form.Control
                              as="input"
                              className="reportOptions"
                              value={userID}
                              readOnly
                           ></Form.Control>
                        </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5" md="5" className="reportLabels">
                           Template Name
                        </Form.Label>
                        <Col sm="4" md="4">
                           <Form.Control
                              as="select"
                              id="view"
                              className="reportOptions"
                              onChange={handleTemplateChange}
                           >
                              <option value="0">----Select Template----</option>
                              {template &&
                                 template.map((value, index) => {
                                    return (
                                       <option
                                          key={index}
                                          name={value["Template Name"]}
                                          value={value.id}
                                       >
                                          {value["Template Name"]}
                                       </option>
                                    )
                                 })}
                           </Form.Control>
                        </Col>
                        <Col sm="2" md="2">
                           <Button
                              variant="primary"
                              disabled={templateVal !== "0" ? false : true}
                              onClick={handleTemplateApply}
                           >
                              Apply
                           </Button>
                        </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5" md="5" className="reportLabels">
                           Report Type
                        </Form.Label>
                        <Col sm="4" md="4">
                           <Form.Control
                              as="select"
                              id="reportType"
                              className="reportOptions"
                              value={reportName}
                              onChange={handleReportChange}
                           >
                              <option value="Empty">----Select Report----</option>
                              <option value="ATS Report">ATS Report</option>
                              //For Emp and Non Emp Internals
                              {userRole !== "CM" && userRole !== "SP" && (
                                 <option value="Certification Roll-Up">
                                    Certification Roll-Up
                                 </option>
                              )}
                              {userRole !== "CM" && userRole !== "SP" && (
                                 <option value="Characteristic Master Report">
                                    Characteristic Master Report
                                 </option>
                              )}
                              <option value="Mass Artwork Download">
                                 Mass Artwork Download
                              </option>
                              <option value="Parts and Specification Report">
                                 Parts and Specification Report
                              </option>
                              <option value="PDF Download">PDF Download</option>
                              <option value="Reference Document Report">
                                 Reference Document Report
                              </option>
                              {userRole !== "CM" && userRole !== "SP" && (
                                 <option value="Where Used Report">
                                    Where Used Report
                                 </option>
                              )}
                           </Form.Control>
                        </Col>
                     </Form.Group>
                     {reportName && reportName === "ATS Report" && (
                        <ATSReport
                           templateData={templateData}
                           reportName={reportName}
                           clearItem={handleClear}
                        />
                     )}
                     {reportName && reportName === "Mass Artwork Download" && (
                        <MassArtWorkReport
                           templateData={templateData}
                           reportName={reportName}
                           calledFrom={calledFrom}
                           clearItem={handleClear}
                        />
                     )}
                     {reportName && reportName === "Parts and Specification Report" && (
                        <PartAndSpec
                           reportName={reportName}
                           templateData={templateData}
                           clearItem={handleClear}
                        />
                     )}
                     {reportName && reportName === "PDF Download" && (
                        <PDFDownloadReport
                           templateData={templateData}
                           reportName={reportName}
                           clearItem={handleClear}
                        />
                     )}
                     {reportName && reportName === "Reference Document Report" && (
                        <RefDocumentReport
                           templateData={templateData}
                           reportName={reportName}
                           clearItem={handleClear}
                        />
                     )}
                    {reportName && reportName === "Characteristic Master Report" && (
                        <CharMasterReport
                        templateData={templateData}
                        reportName={reportName}
                        clearItem={handleClear}
                        buisnessArea={templateData.buisness_Area ? templateCharMasterAreas : charMasterAreas}
                        templateBusinessArea={temSelectedCharMasterAreas}
                        />
                     )}
                     
                     {reportName && reportName === "Certification Roll-Up" && (
                        <CertificationRollUp
                           templateData={templateData}
                           reportName={reportName}
                           clearItem={handleClear}
                           certificationClaims={templateData.certificationClaims ? templateCertClaims : businessClaims}
                           templateCertClaim={templateCertClaim}
                        />
                     )}
                     {reportName && reportName === "Where Used Report" && (
                        <WhereUsed
                           templateData={templateData}
                           reportName={reportName}
                           clearItem={handleClear}
                        />
                     )}
                     {reportName &&
                        (reportName === "invalid type" || reportName === "Empty") && (
                           <Col md="12" sm="12" align="center">
                              {reportName === "invalid type" && (
                                 <p
                                    className="alertMsg"
                                    style={{ marginTop: "30px", fontWeight: "bold" }}
                                 >
                                    This Report type is not available in Spec Reader.
                                 </p>
                              )}
                              <Button
                                 className="mb-2"
                                 variant="primary"
                                 onClick={() => props.history.push("/DsmReport")}
                              >
                                 Cancel
                              </Button>
                           </Col>
                        )}
                  </Form>
               </Col>
            </Row>
            <br />
         </Container>
      </React.Fragment>
   )
}
export default GenReport
