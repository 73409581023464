import React from "react"
import { Table } from "react-bootstrap"

const CustomMarketOfSale = (props) => {
   const countries_Sales = props.countriesOfSale

   var countriesOfSale = []
   var countriesOfSaleRestrictions = []
   var datetime = ""
   var poaOverrideLegalReworkRequired = []

   if (countries_Sales.countriesOfSale != null) {
      countriesOfSale = countries_Sales.countriesOfSale
   }
   if (countries_Sales.poaOverrideLegalReworkRequired != null) {
      poaOverrideLegalReworkRequired = countries_Sales.poaOverrideLegalReworkRequired
   }
   if (countries_Sales.countriesOfSaleRestrictions != null) {
      countriesOfSaleRestrictions = countries_Sales.countriesOfSaleRestrictions
   }
   if (countries_Sales.dateAndTimeOfLastCountryOfSaleCalculation != null) {
      datetime = countries_Sales.dateAndTimeOfLastCountryOfSaleCalculation
   }

   const countries_Sales_contents = countriesOfSale.map((sale, index) => (
      <tr key={index}>
         <td>{sale}</td>
         <td>
            {countriesOfSaleRestrictions[index] ? countriesOfSaleRestrictions[index] : ""}
         </td>
         {props.extraColumn?
         <td>
            {poaOverrideLegalReworkRequired[index] ? poaOverrideLegalReworkRequired[index] : ""}
         </td>:""}
      </tr>
   ))
   return (
      <div>
         <Table size="sm" responsive>
            <thead>
               <tr>
                  <th>Date and Time of Last Market of Sale Calculation: {datetime}</th>
               </tr>
            </thead>
            <thead>
               <tr>
                  <th colSpan = {props.extraColumn ? "3" : "2"}>
                     {" "}
                     <hr />
                  </th>
               </tr>
            </thead>
            <thead>
               <tr>
                  <th>Market of Sale</th>
                  <th>
                     <span>
                        Market of Sale Restrictions/Conditions?{" "}
                     </span>
                  </th>
                  {props.extraColumn?
                  <th>
                        POA Override - Legal Rework Required{" "}
                  </th>:""}
               </tr>
            </thead>
            <tbody>{countries_Sales_contents}</tbody>
         </Table>
      </div>
   )
}
export default CustomMarketOfSale
