import { Button, Col, Container, Row, Table, Form } from "react-bootstrap"
import React, { useMemo, useState, useRef } from "react"

import Pagination from "react-pagination-bootstrap"
import { Link, useHistory } from "react-router-dom"
import { DELETE_SUBSCRIPTION } from "../../constant/Constants"
import { DSMDelete } from "../../api/AuthService"

function DynamicSubResults(props) {
   const history = useHistory()
   const useSortableData = (items, config = null) => {
      const [sortConfig, setSortConfig] = useState(config)
      const sortedItems = useMemo(() => {
         let sortableItems = [...items]
         if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
               if (sortConfig.key === "Create Date") {
                  if (new Date(a[sortConfig.key]) < new Date(b[sortConfig.key])) {
                     return sortConfig.direction === "ascending" ? -1 : 1
                  }
                  if (new Date(a[sortConfig.key]) > new Date(b[sortConfig.key])) {
                     return sortConfig.direction === "ascending" ? 1 : -1
                  }
                  return 0
               } else {
                  if (a[sortConfig.key] < b[sortConfig.key]) {
                     return sortConfig.direction === "ascending" ? -1 : 1
                  }
                  if (a[sortConfig.key] > b[sortConfig.key]) {
                     return sortConfig.direction === "ascending" ? 1 : -1
                  }
                  return 0
               }
            })
         }
         return sortableItems
      }, [items, sortConfig])

      const requestSort = (key) => {
         let direction = "ascending"
         if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === "ascending"
         ) {
            direction = "descending"
         }
         setSortConfig({ key, direction })
      }
      return { items: sortedItems, requestSort, sortConfig }
   }

   const [resultsPerPage, setResultsPerPage] = useState(30)
   const [currentPage, setCurrentPage] = useState(1)
   const indexOfLastResult = currentPage * resultsPerPage
   const indexOfFirstResult = indexOfLastResult - resultsPerPage
   const { items, requestSort, sortConfig } = useSortableData(props.results)
   const currentResult = items.slice(indexOfFirstResult, indexOfLastResult)
   const[isAlertvisible,setIsAlertVisible]=React.useState(false);
   const [activePage, setActivePage] = useState(1)

   const selectedcurrentResult = useRef([])

   const handleSelectAllChange = () => {
      const selectAllStatus =
         selectedcurrentResult.current.length !== props.results.length

      if (selectAllStatus) {
         selectedcurrentResult.current = props.results.map((employee) => employee.id)
      } else {
         selectedcurrentResult.current = []
      }

      setForceUpdate((prevState) => !prevState)
   }

   const handleCheckboxChange = (userId) => {
      const index = selectedcurrentResult.current.indexOf(userId)

      if (index === -1) {
         selectedcurrentResult.current.push(userId)
      } else {
         selectedcurrentResult.current.splice(index, 1)
      }

      setForceUpdate((prevState) => !prevState)
   }

   const selectAll = selectedcurrentResult.current.length === props.results.length
   const isSendButtonDisabled = selectedcurrentResult.current.length === 0
   const [forceUpdate, setForceUpdate] = useState(false)
   let deleteForm;
   function show(){
      setIsAlertVisible(true);
      setTimeout(()=>{
         setIsAlertVisible(false);
      },4000)
   }

   const sendCurrentResultIDs = () => {
      deleteForm = window.confirm(
         "Do you want to delete the selected Subscription(s)?"
      )

      if (deleteForm) {
         const selectedIds = selectedcurrentResult.current

         DSMDelete(`${DELETE_SUBSCRIPTION}`, { objectId: selectedIds })
            .then((response) => {
              
            })
            .catch((error) => {
               console.error("API errr", error)
            })
         show()
      }
   }
  

   const getClassNamesFor = (name) => {
      if (!sortConfig) {
         return "fa fa-sort"
      } else if (sortConfig.key === name) {
         if (sortConfig.direction === "ascending") {
            return "fa fa-caret-up"
         } else {
            return "fa fa-caret-down"
         }
      } else {
         return "fa fa-sort"
      }
   }
   const subscriptionInfo = currentResult.map((key, index) => (
      <tr key={key.id}>
         <td align="center" width="5%">
            {" "}
            <Form.Check
               type={props.type !== "organization" ? "checkbox" : "radio"}
               onChange={() => handleCheckboxChange(key.id)}
               checked={selectedcurrentResult.current.includes(key.id)}
            />
         </td>
         <td align="left" width="10%">
            <Link
               to={(location) => ({
                  ...location,
                  pathname: `/SubscriptionDetails/${key.id}`,
               })}
            >
               <span textalign="left">{key["Product Data Type"]}</span>
            </Link>
         </td>
         <td align="left" width="5%">
            {key["State"]}
         </td>
         <td align="left" width="10%">
            {key["Policy"]}
         </td>
         <td align="left" width="10%">
            {key["Organization"]}
         </td>
         <td align="left" width="10%">
            {key["Vendor"]}
         </td>
         <td align="left" width="10%">
            {key["Subscription Frequency"]}
         </td>
         <td align="left" width="5%">
            {key["Plant"]}
         </td>
         <td align="left" width="5%">
            {key["Brand"]}
         </td>
         <td align="left" width="5%">
            {key["Segment"]}
         </td>
         <td align="left" width="10%">
            {key["Manufacturing Status"]}
         </td>
         <td align="left" width="10%">
            {key["Expiration Date"]}
         </td>
      </tr>
   ))
   function handlePageChange(pageNumber) {
      setCurrentPage(pageNumber)
      setActivePage(pageNumber)
   }
   const handleRefresh=()=>{
      props.refreshHandler()
      window.location.reload();
   }

   return (
      <React.Fragment>
         <React.Fragment>
         {isAlertvisible && <Row>
            <Col md="11" sm="11" align="center">
               <p  id="msg"className="successMsg" style={{ marginTop: "4px", fontWeight: "bold" }} >
               Your Subscription(s) deletion request will be processed in the background and will complete shortly. Please refresh the screen after few minutes
               </p>
            </Col>
         </Row>} 
            <Row
               className="mb-3"
               style={{ marginBottom: "10px", paddingLeft: "15px", paddingRight: "15px" }}
            >
               <Col md={10} sm={7} align="left">
                  <Button
                     variant="primary"
                     onClick={() => history.push("/DynamicSubscription")}
                  >
                     Create
                  </Button>

                  <Button
                     variant="primary"
                     onClick={sendCurrentResultIDs}
                     style={{ border: "none", marginLeft: "5px" }}
                     disabled={isSendButtonDisabled}
                     className={isSendButtonDisabled ? "disabled" : ""}
                  >
                     Delete
                  </Button>

                  <Button
                     variant="primary"
                     onClick={handleRefresh}
                     style={{ border: "none", marginLeft: "5px" }}
                  >
                     Refresh
                  </Button>
               </Col>
               <Col className="pageinationSub" align="right">
                  <div style={{ width: "40%", verticalAlign: "middle" }} id="paginTab">
                     {props.error === 0 && (
                        <Pagination
                           activePage={activePage}
                           itemsCountPerPage={resultsPerPage}
                           totalItemsCount={props.results.length}
                           pageRangeDisplayed={6}
                           onChange={handlePageChange}
                        />
                     )}
                  </div>
               </Col>
            </Row>
            {props.loading ? (
               <h2 style={{ textAlign: "center", color: "#0173d4" }}>
                  <i className="fa fa-spinner fa-spin" style={{ marginRight: "5px" }} />
                  Loading........
               </h2>
            ) : (
               <div className="searchScrollbar">
                  <Table bordered>
                     <thead id="template_summ_tab">
                        <tr>
                           {props.error === 0 && props.type !== "organization" ? (
                              <th width="5%">
                                 <Form.Check
                                    type="checkbox"
                                    style={{ textAlign: "center" }}
                                    checked={selectAll}
                                    onChange={handleSelectAllChange}
                                 />
                              </th>
                           ) : (
                              <th width="3%" style={{ paddingRight: "10px" }}>
                                 <Form.Check
                                    type="checkbox"
                                    style={{ textAlign: "center" }}
                                    
                                 />
                              </th>
                           )}
                           <th width="10%">
                              Product Data Type&nbsp;
                              <i
                                 onClick={() => requestSort("Product Data Type")}
                                 className={getClassNamesFor("Product Data Type")}
                              ></i>
                           </th>
                           <th width="5%">State</th>
                           <th width="10%">Policy</th>
                           <th width="10%">Organization</th>
                           <th width="10%">Vendor</th>
                           <th width="10%">Subscription Frequency </th>
                           <th width="5%">Plant </th>
                           <th width="5%">Brand </th>
                           <th width="5%">Segment </th>
                           <th width="10%">Manufacturing Status </th>
                           <th width="10%">Expiration Date </th>
                        </tr>
                     </thead>
                     {props.error === 0 && <tbody>{subscriptionInfo}</tbody>}
                     {props.error === 1 && (
                        <tbody>
                           <tr>
                              <td
                                 colSpan="12"
                                 style={{
                                    textAlign: "center",
                                    color: "#0173d4",
                                    fontSize: "18px",
                                 }}
                              >
                                 No Records Found.
                              </td>
                           </tr>
                        </tbody>
                     )}
                     {props.error === 2 && (
                        <tbody>
                           <tr>
                              <td
                                 colSpan="12"
                                 style={{
                                    textAlign: "center",
                                    color: "#0173d4",
                                    fontSize: "18px",
                                 }}
                              >
                                 There is an issue with the Dynamic Subscription service.
                                 Please contact Support.
                              </td>
                           </tr>
                        </tbody>
                     )}
                  </Table>
               </div>
            )}
         </React.Fragment>
      </React.Fragment>
   )
}
export default DynamicSubResults
