import { saveAs } from "file-saver"
import React, { useState } from "react"
import { Alert } from "react-bootstrap"
import LoadingOverlay from "react-loading-overlay"
import { fetchAPIData } from "../../api/AuthService"
import { DOWNLOAD_LINK } from "../../constant/Constants"
import CustomLink from "../commonComponents/CustomLink"
import { setNonDispalyValue } from "../commonComponents/EventHandler"

const ComIRMRelatedParts = (props) => {
   let data = []
   data = props.data

   const [loading, setLoading] = useState(false)
   const [show, setShow] = useState(false)
   const [errorMeg, setErrorMsg] = useState(false)

   const getHeader = (headerData = []) => {
      var keys = getKeys(headerData)

      return keys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         if (key.toLowerCase() === "zcsurl") {
            return <th key={key}></th>
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getKeys = (keysInformation = []) => {
      return Object.keys(keysInformation)
   }
   const getColumnKeys = (columKeyInfo) => {
      return Object.keys(columKeyInfo)
   }

   const RenderColumn = (props) => {
      return props.keys.map((key, index) => {
         if (props.data[key] !== "" && props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const getRowsData = (tableData) => {
      var items = tableData
      var keys = getKeys(tableData[0])
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const RenderRow = (props) => {
      return props.keys.map((key, index) => {
         if (props.data[key] != null && typeof props.data[key] === "object") {
            var colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn data={props.data[key]} isbold={true} keys={colKeys} />
               </td>
            )
         } else if (
            (key.toLowerCase() === "name" || key.toLowerCase() === "document name") &&
            props.data["id"] &&
            props.data["id"].toLowerCase().trim() !== "no access"
         ) {
            var name = props.data[key]
            return (
               <td style={{ minWidth: "9rem" }} key={index}>
                  {props.data["link"] && props.data["link"] !== "" && props.data["id"] ? (
                     <CustomLink
                        id={props.data["id"]}
                        link={props.data["link"]}
                        name={name}
                     />
                  ) : (
                     <span>{name}</span>
                  )}
               </td>
            )
         }
         if (key.toLowerCase() === "zcsurl") {
            let isFileAttached = props.data["isFileAttached"]
               ? props.data["isFileAttached"]
               : ""

            //check for access
            let isAccess = true
            if (
               (props.data["id"] &&
                  props.data["id"].toLowerCase().trim() === "no access") ||
               props.data["link"] === "PQR" ||
               props.data["link"] === "IMP" ||
               isFileAttached === "false"
            ) {
               isAccess = false
            }

            return (
               <td key={index}>
                  {isAccess && props.data["id"] ? (
                     <button onClick={() => downloadFile(props.data["id"].trim())}>
                        <i className="fa fa-download" aria-hidden="true"></i>
                     </button>
                  ) : (
                     <button disabled>
                        <i className="fa fa-download" aria-hidden="true"></i>
                     </button>
                  )}
               </td>
            )
         } else if (setNonDispalyValue(key)) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   const downloadFile = (object_ID) => {
      var fileName = "download"
      setLoading(true)

      fetchAPIData(`${DOWNLOAD_LINK}${object_ID}`, { responseType: "blob" })
         .then(function (response) {
            if (
               response.headers["filename"] != null &&
               response.headers["filename"] !== ""
            ) {
               fileName = response.headers["filename"]
            }
            const fileContents = new Blob([response.data])
            saveAs(fileContents, fileName)
            setLoading(false)
         })
         .catch((error) => {
            if (error.response && error.response.status === 404) {
               setShow(true)
               setErrorMsg("No file available to download")
            } else if (error.response && error.response.status === 422) {
               setShow(true)
               setErrorMsg(
                  "You have exceeded the limit for the day and access to any additional Part/Specification is blocked! Please contact Support."
               )
            } else {
               setShow(true)
               setErrorMsg("Oops, something went wrong. Please contact Support.")
               console.log("FCS Error Response:-", error)
            }
            setLoading(false)
         })
   }

   if (show) {
      return (
         <Alert
            style={{ textAlign: "center", marginLeft: "8rem", marginRight: "8rem" }}
            variant="info"
            onClose={() => setShow(false)}
            dismissible
         >
            <p>{errorMeg}</p>
         </Alert>
      )
   }

   return (
      <div className="tableFixHead">
         {data && data.length > 0 && (
            <LoadingOverlay active={loading} spinner text="Downloading....">
               <table
               >
                  <thead>
                     <tr>{getHeader(data[0])}</tr>
                  </thead>
                  <tbody>{getRowsData(data)}</tbody>
               </table>
            </LoadingOverlay>
         )}
      </div>
   )
}
export default ComIRMRelatedParts
