import React from "react"
import { Col } from "react-bootstrap"
import "font-awesome/css/font-awesome.min.css"
import HeaderComponent from "./commonComponents/HeaderComponent"

function NotAccess(props) {
   sessionStorage.removeItem("breadCrumb")

   return (
      <React.Fragment>
         <HeaderComponent />
         <Col md={{ span: 10, offset: 1 }} className="searchTable">
            <span style={{ color: "white", textAlign: "center" }}>
               You do not have access to this object.
            </span>
         </Col>
      </React.Fragment>
   )
}
export default NotAccess
