import React from "react"
import { Col } from "react-bootstrap"
export default function PageNotFound() {
   //sessionStorage.removeItem("breadCrumb")

   return (
      <React.Fragment>
         <Col md={{ span: 10, offset: 1 }} className="searchTable">
            <span style={{ color: "white", textAlign: "center" }}>Page not found..</span>
         </Col>
      </React.Fragment>
   )
}
