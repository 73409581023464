import React from "react"
import { Col, Row } from "react-bootstrap"

const CLTopContent = (props) => {
   const prop = props.headerContent

   if (props.loading) {
      return (
         <h3 style={{ marginLeft: "30rem", color: "#0640a4" }}>
            <i className="fa fa-spinner fa-spin" style={{ marginRight: "5px" }} />
            Loading........
         </h3>
      )
   }
   return (
      <div>
         <React.Fragment>
            <Row>
               <Col md="3" sm="2.5">
                  <span id="pageheaders">Name: </span>
                  {props.parentName && props.parentName !== ""
                     ? props.parentName
                     : prop.Name}
               </Col>

               <Col md="2" sm="2.5">
                  <span id="pageheaders">Revision: </span>
                  {props.parentRev && props.parentRev !== ""
                     ? props.parentRev
                     : prop.Revision}
               </Col>

               <Col md="2" sm="2.5">
                  <span id="pageheaders">State: </span>
                  {props.parentState && props.parentState !== ""
                     ? props.parentState
                     : prop.State}
               </Col>
               {prop["Copy List Name"] !== undefined && (
                  <Col md="4" sm="4">
                     <span id="pageheaders">Copy List Name: </span>
                     {prop["Copy List Name"]}
                  </Col>
               )}
            </Row>
         </React.Fragment>
      </div>
   )
}

export default CLTopContent
