import React from "react"
import { Col } from "react-bootstrap"
import "../main.css"
import SearchResultDisp from "./SearchResultDisp"
import HeaderComponent from "../commonComponents/HeaderComponent"
require("react-data-components/css/table-twbs.css")

function SearchFromHeader(props) {
   const searchVal = props.match.params.searchText
   const searchType = props.match.params.searchType

   return (
      <React.Fragment>
         <HeaderComponent value={searchVal} type={searchType} />
         <Col className="searchTable">
            <SearchResultDisp
               searchData={searchVal}
               searchType={searchType}
               history={props.history}
            />
         </Col>
      </React.Fragment>
   )
}
export default SearchFromHeader
