import React from "react"
import { Table } from "react-bootstrap"

const MarketsOfSale = (props) => {
   const markets_Sales = props.marketsOfSale
   var marketsOfSale = []
   var poaOverrideLegalReworkRequired = []
   var marketsOfSaleRestrictions = []
   var datetime = ""
   if (markets_Sales.marketsOfSale != null) {
      marketsOfSale = markets_Sales.marketsOfSale
   }
   if (markets_Sales.poaOverrideLegalReworkRequired != null) {
      poaOverrideLegalReworkRequired = markets_Sales.poaOverrideLegalReworkRequired
   }
   if (markets_Sales.marketsOfSaleRestrictions != null) {
      marketsOfSaleRestrictions = markets_Sales.marketsOfSaleRestrictions
   }
   if (markets_Sales.dateAndTimeOfLastMarketOfSaleCalculation != null) {
      datetime = markets_Sales.dateAndTimeOfLastMarketOfSaleCalculation
   }
   const markets_Sales_contents = marketsOfSale.map((sale, index) => (
      <tr key={index}>
         <td>{sale}</td>
         <td>
            {marketsOfSaleRestrictions[index] ? marketsOfSaleRestrictions[index] : ""}
         </td>
         {props.extraColumn?
         <td>
            {poaOverrideLegalReworkRequired[index] ? poaOverrideLegalReworkRequired[index] : ""}
         </td>:""}
      </tr>
   ))

   return (
      <div>
         <Table size="sm" responsive>
            <thead>
               <tr>
                  <th colSpan = "2">
                     Date and Time of Last Market of Sale Calculation:
                     <br />
                     Please note that changes which have occurred after this calculation
                     Date and Time are NOT reflected in the table below
                  </th>
                  <th>{datetime}</th>
               </tr>
            </thead>
            <thead>
               <tr>
                  <th style={{width: props.extraColumn ? "30%" : "50%"}}>Market of Sale</th>
                  <th>Market of Sale Restrictions/Conditions?</th>
                  {props.extraColumn?
                  <th>
                        POA Override - Legal Rework Required{" "}
                  </th>:""}
               </tr>
            </thead>
            <tbody>{markets_Sales_contents}</tbody>
         </Table>
      </div>
   )
}
export default MarketsOfSale
