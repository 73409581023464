import React from "react"
import {
   Alert,
   Button,
   Col,
   Container,
   Form,
   FormControl,
   InputGroup,
   OverlayTrigger,
   Row,
   Tooltip,
   CloseButton
} from "react-bootstrap"
import { isLoggedIn } from "../../api/AuthService"
import { SearchOptionValues } from "../../constant/Constants"
import Logo from "../../resource/img/logo.png"
import Logout from "../login/Logout"
import "../main.css"
import SearchResultDisp from "./SearchResultDisp"

class SearchComponent extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         loading: false,
         topMargin: {},
         message: "",
         optionValue: this.props.match.params.searchType
            ? this.props.match.params.searchType
            : "gcas",
         visible: sessionStorage.getItem("freshLogin") ? true : false,
         show:true,
      }

      this.searchText = React.createRef()
      this.handleSubmit = this.handleSubmit.bind(this)
      this.handleRadioChange = this.handleRadioChange.bind(this)
      this.handleOnFocus = this.handleOnFocus.bind(this)
      this.handleBannerClose = this.handleBannerClose.bind(this)
      this.expiryDate = localStorage.getItem("expirationDate")
      this.userRole = localStorage.getItem("role")
   }
   handleBannerClose() {
      this.setState({
         show: false,
      })
      localStorage.removeItem("expirationDate");
   }

   closeDisclaimer() {
      sessionStorage.removeItem("freshLogin")
      this.setState({
         visible: false,
      })
   }
   handleSubmit(e) {
      if (!isLoggedIn()) {
         this.props.history.push("/login")
      }
      e.preventDefault()
      if (this.validateForm()) {
         this.setPropsValue()
         this.setSessionValuesForSearchOptions(this.state.optionValue)
      }

      this.closeDisclaimer()
   }

   validateForm() {
      let formIsValid = true
      if (this.searchText.current.value === "") {
         formIsValid = false
         this.setState({
            message: "* Please enter some value above.",
            loading: false,
         })
      }
      return formIsValid
   }

   handleChange = (e) => {
      this.setState({
         message: "",
      })
   }
   handleOnFocus = (e) => {
      this.closeDisclaimer()
   }
   setPropsValue = () => {
      this.setState({
         loading: true,
         topMargin: { marginTop: "3%" },
         //searchValue: btoa(this.searchText.current.value),
         searchValue: btoa(encodeURIComponent(this.searchText.current.value)),
      })
   }

   componentDidMount() {
      sessionStorage.removeItem("breadCrumb")

      if (!isLoggedIn()) {
         this.props.history.push("/login")
      }
      if (this.props.match.params.searchText != null) {
         this.searchText.current.value = decodeURIComponent(atob(this.props.match.params.searchText))
         if (this.props.match.params.searchType) {
            this.setState({
               optionValue: this.props.match.params.searchType,
            })
         }

         this.setPropsValue()
      } else if (
         sessionStorage["lastSearchWithOption"] &&
         sessionStorage["lastSearchWithOption"] !== ""
      ) {
         var searchAndType = sessionStorage["lastSearchWithOption"]
         searchAndType = decodeURIComponent(atob(searchAndType))
         var arry = searchAndType.split("_")

         this.searchText.current.value = arry[0] ? arry[0] : ""

         if (arry[1]) {
            this.setState({
               optionValue: arry[1],
            })
         }
         this.setPropsValue()
      }
   }

   setSessionValuesForSearchOptions = (optionVal) => {
      //set last search Value
      sessionStorage["lastSearchWithOption"] = ""
      var searchCacheKey = `${this.searchText.current.value}_${optionVal}`
      //sessionStorage.setItem("lastSearchWithOption", btoa(searchCacheKey))
      sessionStorage.setItem("lastSearchWithOption", btoa(encodeURIComponent(searchCacheKey)))
   }

   handleRadioChange(event) {
      var selectedOption = ""

      this.closeDisclaimer()

      if (event.target.childNodes[0]) {
         selectedOption = event.target.childNodes[0].value
      } else {
         selectedOption = event.target.value
      }

      this.setState({
         optionValue: selectedOption,
      })

      if (this.validateForm()) {
         this.setPropsValue()
         this.setSessionValuesForSearchOptions(selectedOption)
      }
   }

   render() {
      return (
         <React.Fragment>
            <Container fluid className="contentBackground sticky-top shadow">
               <Row>
                  <Col md="12" style={{ paddingLeft: "3px" }}>
                     <img src={Logo} alt="logo" />
                     <Logout />
                  </Col>
               </Row>
               <Row>
                  {/* <Col style={{ marginTop: "5px",textAlign:"center" }} >
                <Alert variant="primary" >
                    <div style={{fontSize:"18px",color:"red"}}>
                        Spec Reader is currently unavailable. Please see 'Known Issues' at The Junction for further information.
                        </div>
                </Alert>
                </Col> */}
               </Row>
            </Container>
            <Container fluid>
               {this.expiryDate && this.state.show &&(
                  <Row>
                     <Col md="12" className="notice">
                        {this.userRole !== "" ? (
                           <p style={{ display: "inline-block" }}>
                              Your access will expire on {this.expiryDate}. Please contact your Sponsor for renewal. If the renewal request has been submitted, please ignore this message.
                              <CloseButton style={{ marginLeft: "30px" }} onClick={this.handleBannerClose} />
                           </p>

                        ) : (

                           <p style={{ display: "inline-block" }}>
                              Your access will expire on {this.expiryDate}. Click
                              <a href={process.env.REACT_APP_RENEWAL_LINK} target="_blank" rel="noopener noreferrer"> here
                              </a> to renew now. If renewal request has been submitted, please ignore this message.
                              <CloseButton style={{ marginLeft: "30px" }} onClick={this.handleBannerClose} />
                           </p>
                        )}
                     </Col>
                  </Row>
               )}
               <Row>
                  <Col>
                     <div className="searchContent" style={this.state.topMargin}>
                        <Form onSubmit={this.handleSubmit} className="loginForm">
                           <Form.Group
                              controlId="searchBox"
                              style={{ marginBottom: "0rem" }}
                           >
                              <InputGroup>
                                 <FormControl
                                    className={`placeHolderText searchBox ${this.state.blinkUser}`}
                                    ref={this.searchText}
                                    placeholder="&#61442; What are you Looking For?"
                                    type="text"
                                    onChange={this.handleChange}
                                    onFocus={this.handleOnFocus}
                                 />
                                 <InputGroup.Append>
                                    <Button bsPrefix="searchBtnText" type="submit">
                                       Search
                                    </Button>
                                 </InputGroup.Append>
                              </InputGroup>
                              <InputGroup>
                                 {SearchOptionValues && (
                                    <div className="radbtnClass">
                                       {SearchOptionValues.map((item, index) => (
                                          <OverlayTrigger
                                             key={index}
                                             delay={{ show: 300, hide: 300 }}
                                             overlay={
                                                <Tooltip id={item.value}>
                                                   {item.toolTip}
                                                </Tooltip>
                                             }
                                             placement="bottom"
                                          >
                                             <span
                                                key={index}
                                                onClick={this.handleRadioChange.bind(
                                                   this
                                                )}
                                                style={{ cursor: "pointer" }}
                                             >
                                                <input
                                                   id={item.value}
                                                   value={item.value}
                                                   checked={
                                                      this.state.optionValue ===
                                                      item.value
                                                   }
                                                   type="radio"
                                                   readOnly
                                                />{" "}
                                                {item.display} &nbsp;
                                             </span>
                                          </OverlayTrigger>
                                       ))}
                                    </div>
                                 )}
                              </InputGroup>
                           </Form.Group>
                        </Form>
                        <Form.Row>
                           <Col>
                              <p style={{ marginLeft: "1.1rem" }} className="labels">
                                 {this.state.message}
                              </p>
                           </Col>
                        </Form.Row>
                     </div>
                  </Col>
               </Row>
               {this.state.visible && (
                  <Col md="12" className="mb-5">
                     <Alert variant="primary">
                        <Alert.Heading style={{ color: "red", textAlign: "justify" }}>
                           Please ensure you are logged in using Chrome.{process.env.REACT_APP_ENVIORMENT}
                        </Alert.Heading>
                        <hr />
                        <p style={{ textAlign: "justify" }}>
                           This system is the property of this Corporation/organization,
                           and is intended for the use of authorized users only. All
                           activities of individuals using this computing system with or
                           without authority, or in excess of their authority, may be
                           monitored and recorded by system personnel. If any such
                           monitoring reveals evidence of criminal activity or is in
                           violation of foreign or U.S. state or federal law, such
                           evidence may be provided to law enforcement officials and/or
                           used for further legal action by this Corporation and/or the
                           organization's Information Protection group. Unauthorized use
                           of this system is prohibited and may result in revocation of
                           access, disciplinary action and/or legal action. The company
                           reserves the right to monitor and review user activity, files
                           and electronic messages.
                        </p>
                        <p>
                           <b>Reminder:</b> Information transmitted to a foreign person on
                           this network may be subject to applicable Export Control laws.
                           Contact your Export Coordinator for assistance.
                        </p>
                        <p>
                           This is a private computer/communications facility. Access to
                           it for any reason must be specifically authorized.System
                           personnel will/may monitor for unauthorized activity. Anyone
                           using this system expressly consents to such monitoring. Your
                           continued access, if unauthorized, may result in criminal
                           and/or civil proceedings.
                        </p>
                        <p>
                           Activity in P&G's Spec Reader is automatically recorded and may
                           be used to produce aggregated reporting / statistical analysis.
                           By using the Spec Reader System, you accept automatic recording
                           of your activities.
                        </p>
                        <hr />
                        <p className="mb-0">
                           <h4>
                              Please accept sole responsibility for your password and
                              access. It is not to be shared with anyone.
                           </h4>
                        </p>
                     </Alert>
                  </Col>
               )}
               {this.state.loading && this.state.searchValue.trim() && (
                  <Row>
                     <Col md="12" className="mb-5">
                        <SearchResultDisp
                           history={this.props.history}
                           searchType={this.state.optionValue}
                           searchData={this.state.searchValue.trim()}
                        />
                     </Col>
                  </Row>
               )}
            </Container>
         </React.Fragment>
      )
   }
}
export default SearchComponent
