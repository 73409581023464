import React, { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"

function VendorMailModal(props) {
   const [mailBody, setMailBody] = useState("")

   function textValidation(value) {
      let exp = "^[A-Za-z0-9 /\\n\\t,()&.]+$"
      if (value.match(exp)) {
         return true
      } else {
         return false
      }
   }

   function handleMailBodyChange(e) {
      if (e.target.value === "") {
         setMailBody("")
      } else {
         let checkedText = textValidation(e.target.value)
         if (checkedText) {
            setMailBody(e.target.value)
         }
      }
   }

   function handleSubmit(e) {
      let data = props.moveData
      data = { ...data, 'inWorkMessage': mailBody }
      props.setShow(false)
      props.moveFromView(data)
      setMailBody("")
   }
   function handleSubmitClose(e) {
      props.setShow(false)
   }

   return (
      <Modal
         aria-labelledby="contained-modal-title-vcenter"
         centered
         dialogClassName="my-modal"
         style={{ overflowY: "hidden" }}
         backdrop="static"
         keyboard={false}
         show={props.show}
      >
         <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Email details</Modal.Title>
            <button type="button" className="close" onClick={handleSubmitClose}>&times;</button>
         </Modal.Header>
         <Modal.Body>
            <Form>
               <Form.Group className="mb-3">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control type="text" value={props.selectedNames} readOnly />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Email Body</Form.Label>
                  <Form.Control
                     as="textarea"
                     placeholder="Type message"
                     rows={3}
                     onChange={handleMailBodyChange}
                     value={mailBody}
                     autoFocus
                  />
               </Form.Group>
            </Form>
            <p>
               Note: Allowed special characters are parentheses(()), ampersand(&), period(.), comma(,) only.
            </p>
         </Modal.Body>
         <Modal.Footer>
            <Button value="Close" onClick={handleSubmitClose}>Close</Button>
            <Button value="Submit" onClick={handleSubmit}>Send Email</Button>
         </Modal.Footer>
      </Modal>
   )
}
export default VendorMailModal
