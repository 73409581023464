import "bootstrap/dist/css/bootstrap.min.css"
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import APMPAllInfo from "./components/apmpComponents/ApmpAllInfo"
import APPAllInfo from "./components/appComponents/AppAllInfo"
import ARMPAllInfo from "./components/armpComponents/ArmpAllInfo"
import ASLAllInfo from "./components/aslComponents/AslAllInfo"
import BsfAllInfo from "./components/bsfComponents/BsfAllInfo"
import CDBAllInfo from "./components/cdbComponents/CdbAllInfo"
import CmnAllInfo from "./components/cmnComponents/CmnAllInfo"
import COMIRMInfo from "./components/commonPage/ComIRMAllInfo"
import CommonPage from "./components/commonPage/ComPageAllInfo"
import CopAllInfo from "./components/copComponents/CopAllInfo"
import CopyListAllInfo from "./components/copListComponents/CopyListAllInfo"
import CupAllInfo from "./components/cupComponents/CupAllInfo"
import DPPAllInfo from "./components/dppComponents/DppAllInfo"
import GenReport from "./components/dynamicReports/GenReport"
import ReportDetails from "./components/dynamicReports/ReportDetails"
import ReportInfo from "./components/dynamicReports/ReportInfo"
import TemplateInfo from "./components/dynamicReports/TemplateSummary/TemplateInfo"
import DynamicSub from "./components/dynamicSubscription/CreateDynamicSub"
import DynamicSubscription from "./components/dynamicSubscription/DynamicSubscription"
import SubscriptionDetails from "./components/dynamicSubscription/SubscriptionDetails"
import EmpAllInfo from "./components/empComponents/EmpAllInfo"
import FABAllInfo from "./components/fabComponents/FabAllInfo"
import FcAllInfo from "./components/fcComponents/FcAllInfo"
import FopAllInfo from "./components/fopComponents/FopAllInfo"
import FppAllInfo from "./components/fppComponents/FppAllInfo"
import GCASRoute from "./components/GCASComponents/GCASRoute"
import IMPAllInfo from "./components/impComponents/ImpAllInfo"
import IpAllInfo from "./components/ipComponents/IpAllInfo"
import IPPAllInfo from "./components/ippComponents/IppAllInfo"
import IRMSAllInfo from "./components/irmsComponents/IrmsAllInfo"
import AppLogout from "./components/login/AppLogout"
import Login from "./components/login/Login"
import Redirect from "./components/login/Redirect"
import MCOPAllInfo from "./components/mcopComponents/McopAllInfo"
import MCPAllInfo from "./components/mcpComponents/McpAllInfo"
import MCUPAllInfo from "./components/mcupComponents/McupAllInfo"
import MEPAllInfo from "./components/mepComponents/MepAllInfo"
import MIPAllInfo from "./components/mipComponents/MipAllInfo"
import MPAPAllInfo from "./components/mpapComponents/MpapAllInfo"
import MPMPAllInfo from "./components/mpmpComponents/MpmpAllInfo"
import MPPAllInfo from "./components/mppComponents/MppAllInfo"
import MpsAllInfo from "./components/mpsComponents/MpsAllInfo"
import MRMPAllInfo from "./components/mrmpComponents/MrmpAllInfo"
import NotAccess from "./components/NotAcess"
import OPPAllInfo from "./components/oppComponents/OppAllInfo"
import pageNotFound from "./components/PageNotFound"
import PAPAllInfo from "./components/papComponents/PapAllInfo"
import PIPAllInfo from "./components/pipComponents/PipAllInfo"
import PmpAllInfo from "./components/pmpComponents/PmpAllInfo"
import POAAllInfo from "./components/poaComponents/PoaAllInfo"
import PQRInfo from "./components/pqrComponents/PqrAllInfo"
import PsubAllInfo from "./components/psubComponents/PsubAllInfo"
import RmpAllInfo from "./components/rmpComponents/RmpAllInfo"
import SbvAllInfo from "./components/sbvComponents/SbvAllInfo"
import SearchComponent from "./components/search/SearchComponent"
import SearchFromHeader from "./components/search/SearchFromHeader"
import SEPAllInfo from "./components/sepComponents/SepAllInfo"
import ServiceUnavailable from "./components/ServiceUnavailable"
import SisAllInfo from "./components/sisComponents/SisAllInfo"
import SmlAllInfo from "./components/smlComponents/SmlAllInfo"
import SwpAllInfo from "./components/swpComponents/SwpAllInfo"
import TupAllInfo from "./components/tupComponents/TupAllInfo"
import VendorInfo from "./components/vendorInfo/VendorInfo"
import "./index.css"

function RouterSettings() {
   return (
      <Router>
         <Switch>
            <Route exact path="/" component={Redirect} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={AppLogout} />
            <Route exact path="/search/" component={SearchComponent} />
            <Route
               exact
               path="/search/:searchText/:searchType"
               component={SearchFromHeader}
            />
            <Route exact path="/GCAS/:objectID" component={GCASRoute} />
            <Route exact path="/FPP/:objectID" component={FppAllInfo} />
            <Route exact path="/CUP/:objectID" component={CupAllInfo} />
            <Route exact path="/COP/:objectID" component={CopAllInfo} />
            <Route exact path="/CMA/:objectID" component={CmnAllInfo} />
            <Route exact path="/RMP/:objectID" component={RmpAllInfo} />
            <Route exact path="/FOP/:objectID" component={FopAllInfo} />
            <Route exact path="/PMP/:objectID" component={PmpAllInfo} />
            <Route exact path="/IP/:objectID" component={IpAllInfo} />
            <Route exact path="/TUP/:objectID" component={TupAllInfo} />
            <Route exact path="/APP/:objectID" component={APPAllInfo} />
            <Route exact path="/PAP/:objectID" component={PAPAllInfo} />
            <Route exact path="/MPAP/:objectID" component={MPAPAllInfo} />
            <Route exact path="/APMP/:objectID" component={APMPAllInfo} />
            <Route exact path="/IPP/:objectID" component={IPPAllInfo} />
            <Route exact path="/MIP/:objectID" component={MIPAllInfo} />
            <Route exact path="/MCOP/:objectID" component={MCOPAllInfo} />
            <Route exact path="/MCUP/:objectID" component={MCUPAllInfo} />
            <Route exact path="/CDB/:objectID" component={CDBAllInfo} />
            <Route exact path="/ASL/:objectID" component={ASLAllInfo} />
            <Route exact path="/PIP/:objectID" component={PIPAllInfo} />
            <Route exact path="/OPP/:objectID" component={OPPAllInfo} />
            <Route exact path="/FAB/:objectID" component={FABAllInfo} />
            <Route exact path="/IRMS/:objectID" component={IRMSAllInfo} />
            <Route exact path="/MRMP/:objectID" component={MRMPAllInfo} />
            <Route exact path="/MPP/:objectID" component={MPPAllInfo} />
            <Route exact path="/MPMP/:objectID" component={MPMPAllInfo} />
            <Route exact path="/DPP/:objectID" component={DPPAllInfo} />
            <Route exact path="/COM/IRM/:objectID" component={COMIRMInfo} />
            <Route exact path="/COM/SPS/:objectID" component={CommonPage} />
            <Route exact path="/COM/TMS/:objectID" component={CommonPage} />
            <Route exact path="/COM/IPM/:objectID" component={CommonPage} />
            <Route exact path="/COM/ACS/:objectID" component={CommonPage} />
            <Route exact path="/COM/ATS/:objectID" component={CommonPage} />
            <Route exact path="/COM/ILST/:objectID" component={CommonPage} />
            <Route exact path="/COM/IAPS/:objectID" component={CommonPage} />
            <Route exact path="/COM/LIS/:objectID" component={CommonPage} />
            <Route exact path="/COM/MI/:objectID" component={CommonPage} />
            <Route exact path="/COM/PI/:objectID" component={CommonPage} />
            <Route exact path="/COM/PROS/:objectID" component={CommonPage} />
            <Route exact path="/COM/SP/:objectID" component={CommonPage} />
            <Route exact path="/COM/SOP/:objectID" component={CommonPage} />
            <Route exact path="/COM/RMPI/:objectID" component={CommonPage} />
            <Route exact path="/COM/QUAL/:objectID" component={CommonPage} />
            <Route exact path="/COM/CPP/:objectID" component={CommonPage} />
            <Route exact path="/COM/ARTCAD/:objectID" component={CommonPage} />
            {/* <Route exact path="/COM/:objectType/:objectID" component={CommonPage} /> */}
            <Route exact path="/MEP/:objectID" component={MEPAllInfo} />
            <Route exact path="/SEP/:objectID" component={SEPAllInfo} />
            <Route exact path="/POA/:objectID" component={POAAllInfo} />
            <Route exact path="/ARMP/:objectID" component={ARMPAllInfo} />
            <Route exact path="/IMP/:objectID" component={IMPAllInfo} />
            <Route exact path="/MCP/:objectID" component={MCPAllInfo} />
            <Route exact path="/PSUB/:objectID" component={PsubAllInfo} />
            <Route exact path="/FC/:objectID" component={FcAllInfo} />
            <Route exact path="/MPS/:objectID" component={MpsAllInfo} />
            <Route exact path="/vendorInfo/" component={VendorInfo} />
            <Route exact path="/PQR/:objectID" component={PQRInfo} />
            <Route exact path="/SIS/:objectID" component={SisAllInfo} />
            <Route exact path="/BSF/:objectID" component={BsfAllInfo} />
            <Route exact path="/SWP/:objectID" component={SwpAllInfo} />
            <Route exact path="/SML/:objectID" component={SmlAllInfo} />
            <Route exact path="/SBV/:objectID" component={SbvAllInfo} />
            <Route exact path="/CL/:objectID" component={CopyListAllInfo} />
            <Route exact path="/EMP/:objectID" component={EmpAllInfo} />
            <Route exact path="/DsmReport/" component={ReportInfo} />
            {/* DynamicSubscription Changes Start*/}
            <Route exact path="/Subscription/" component={DynamicSubscription} />
            <Route exact path="/SubscriptionDetails/:objectID" component={SubscriptionDetails} />
             {/* DynamicSubscription Changes end*/}
            <Route exact path="/GenReport/" component={GenReport} />
            <Route exact path="/Templates/" component={TemplateInfo} />
            <Route exact path="/ReportDetail/:objectID" component={ReportDetails} />
            <Route exact path="/DynamicSubscription/" component={DynamicSub} />
            <Route exact path="/DynamicSubscription/:searchID" component={DynamicSub} />
            <Route
               exact
               path="/serviceNotFound/:errorCode"
               component={ServiceUnavailable}
            />
            <Route exact path="/NotAccess" component={NotAccess} />
            <Route exact path="/:objectID" component={GCASRoute} />
            <Route exact path="" component={pageNotFound} />
         </Switch>
      </Router>
   )
}
const rootElement = document.getElementById("root")
ReactDOM.render(<RouterSettings />, rootElement)
