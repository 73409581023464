import React from "react"
import { Col, Row } from "react-bootstrap"

const IRMTopContent = (props) => {
   const prop = props.headerContent
   if (props.loading) {
      return (
         <h3 style={{ marginLeft: "30rem", color: "#0640a4" }}>
            <i className="fa fa-spinner fa-spin" style={{ marginRight: "5px" }} />
            Loading........
         </h3>
      )
   }
   return (
      <div>
         <React.Fragment>
            <Row>
               <Col md="3" sm="3">
                  <span id="pageheaders">Name: </span>
                  {prop.Name}
               </Col>
               <Col md="2" sm="2">
                  <span id="pageheaders">Revision: </span>
                  {prop.Revision}
               </Col>
               <Col md="2" sm="2">
                  <span id="pageheaders">State: </span>
                  {prop.State}
               </Col>
            </Row>
         </React.Fragment>
      </div>
   )
}

export default IRMTopContent
