import React from "react"
import Attribute from "../commonComponents/CommonAttributes"
import CustomSubstitute from "./CustomSubstitute"

export const CustomMultiSubstituteTable = (props) => {
   const substitutes = props.data

   const table_Contents = (substitutes) => {
      return substitutes.map((data, index) => {
         return (
            data && (
               <div key={index}>
                  {data.attribute && (
                     <div>
                        <Attribute data={data.attribute} isSpace={true} />
                        <hr />
                     </div>
                  )}
                  <CustomSubstitute data={data.substitute} />
                  <hr />
               </div>
            )
         )
      })
   }
   return <div>{substitutes && table_Contents(substitutes)}</div>
}
