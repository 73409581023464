import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Collapse, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { CDBKeys, DefaultKeys, FETCH_CDB, PREF_MSG } from "../../constant/Constants"
import Attributes from "../commonComponents/Attributes"
import CommonAttributes from "../commonComponents/CommonAttributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import CustomTable from "../commonComponents/CustomTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import SideNav from "../commonComponents/SideNav"
import SingleColumnAttributes from "../commonComponents/SingleColumnAttributes"
import TopContent from "../commonComponents/TopContents"

function CdbAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributeObject: {},
      organizationObject: {},
      packageQualityParameters: [],
      productQualityParameters: [],
      ownershipObject: {},
      plants: [],
      referenceDocuments: [],
      whereUsed: null,
   })

   const searchData = props.match.params.objectID
   const [loading, setLoading] = useState(true)
   const [sideNav, setSideNav] = useState()
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)

      var values = pdfData(data, obj, IsFilterView)

      setPdfDataSet(values)
   }

   useEffect(() => {
      const fetchState = { cancel: false }

      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchCdbData() {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")

               var apiData

               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  // Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_CDB}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContentObject)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
               }
               if (fetchState.cancel) {
                  return
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContentObject
                     ? apiData.headerContentObject
                     : data.headerContent,
                  attributeObject: apiData.attributeObject,
                  organizationObject: apiData.organizationObject,
                  packageQualityParameters: apiData.packageQualityParameters,
                  productQualityParameters: apiData.productQualityParameters,
                  ownershipObject: apiData.ownershipObject,
                  referenceDocuments: apiData.referenceDocuments,
                  plants: apiData.plants,
                  whereUsed: apiData.whereUsed,
               }))

               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  console.log("Error", ` Not authorie: ${error}`)
                  notAuthorized(props.history)
                  return
               }
               console.log("Error", ` API request failed: ${error}`)
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchCdbData()
      }
      return () => {
         fetchState.cancel = true
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={CDBKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" id="print" className="contentBackground">
                     <br />
                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="8" sm="8">
                              <TopContent headerContent={data.headerContent} />
                           </Col>
                           <Col md="4" sm="4" align="right">
                              <PdfDownloadComponent pdfDataSet={pdfDataSet} />
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {selectedItems["attributeObject"] &&
                              data.attributeObject != null && (
                                 <div>
                                    <Card autoFocus>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "0",
                                             "collapseZero"
                                          )}
                                          aria-controls="collapseZero"
                                       >
                                          {CDBKeys.attributeObject}{" "}
                                          <i
                                             id="0"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseZero"
                                          name="collapseItem"
                                       >
                                          <Attributes
                                             loading={loading}
                                             data={data.attributeObject}
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              selectedItems["organizationObject"] &&
                              data.organizationObject && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "1",
                                             "collapseOne"
                                          )}
                                          aria-controls="collapseOne"
                                       >
                                          {CDBKeys.organizationObject}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseOne"
                                          name="collapseItem"
                                       >
                                          <CommonAttributes
                                             data={data.organizationObject}
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}

                           {!loading &&
                              data.referenceDocuments &&
                              data.referenceDocuments.length > 0 &&
                              selectedItems["referenceDocuments"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "3",
                                             "collapseThree"
                                          )}
                                          aria-controls="collapseThree"
                                       >
                                          {CDBKeys.referenceDocuments}{" "}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseThree"
                                          name="collapseItem"
                                       >
                                          <ComRefDocDownload
                                             data={data.referenceDocuments}
                                             attributes={data.attributeObject}
                                             headerContent={data.headerContent}
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.packageQualityParameters &&
                              data.packageQualityParameters.length > 0 &&
                              selectedItems["packageQualityParameters"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "4",
                                             "collapseFour"
                                          )}
                                          aria-controls="collapseFour"
                                       >
                                          {CDBKeys.packageQualityParameters}{" "}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseFour"
                                          name="collapseItem"
                                       >
                                          <CustomTable
                                             data={data.packageQualityParameters}
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.productQualityParameters &&
                              data.productQualityParameters.length > 0 &&
                              selectedItems["productQualityParameters"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "5",
                                             "collapseFive"
                                          )}
                                          aria-controls="collapseFive"
                                       >
                                          {CDBKeys.productQualityParameters}{" "}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseFive"
                                          name="collapseItem"
                                       >
                                          <CustomTable
                                             data={data.productQualityParameters}
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.ownershipObject &&
                              selectedItems["ownershipObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "6",
                                             "collapseSix"
                                          )}
                                          aria-controls="collapseSix"
                                       >
                                          {CDBKeys.ownershipObject}{" "}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseSix"
                                          name="collapseItem"
                                       >
                                          <SingleColumnAttributes
                                             data={data.ownershipObject}
                                          />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {!loading &&
                              data.plants &&
                              data.plants.length > 0 &&
                              selectedItems["plants"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "7",
                                             "collapseSeven"
                                          )}
                                          aria-controls="collapseSeven"
                                       >
                                          {CDBKeys.plants}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <Collapse in={true}>
                                       <div
                                          className="accordionDiv shadow"
                                          id="collapseSeven"
                                          name="collapseItem"
                                       >
                                          <CustomTable data={data.plants} />
                                       </div>
                                    </Collapse>
                                 </div>
                              )}
                           {selectedItems["message"] && (
                              <p className="errorMsgStyle">{PREF_MSG}</p>
                           )}
                           {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {CDBKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )
   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default CdbAllInfo
