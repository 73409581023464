import React, { useEffect, useState } from "react"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { CSSTransition } from "react-transition-group"
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { DefaultKeys, FETCH_PSUB, PSUBKeys } from "../../constant/Constants"
import Attribute from "../commonComponents/Attributes"
import CommonAttribute from "../commonComponents/CommonAttributes"
import ComRefDocDownload from "../commonComponents/ComRefDocDownload"
import PsubCountriesOfSale from "../commonComponents/CustomMarketOfSale"
import { CustomMultiBomTable } from "../commonComponents/CustomMultiBomTable"
import { CustomMultiSubstituteTable } from "../commonComponents/CustomMultiSubstituteTable"
import CustomPackingUnit from "../commonComponents/CustomPackingUnit"
import CustomPCTable from "../commonComponents/CustomPCTable"
import CustomTable from "../commonComponents/CustomTable"
import CustomTrasportUnit from "../commonComponents/CustomTrasportUnit"
import DownloadLinkTable from "../commonComponents/DownloadLinkTable"
import { errorHandling, handleClick, pdfData } from "../commonComponents/EventHandler"
import HeaderComponent from "../commonComponents/HeaderComponent"
import PdfDownloadComponent from "../commonComponents/PdfDownloadComponent"
import SideNav from "../commonComponents/SideNav"
import TopContent from "../psubComponents/TopHeaderContents"

function PsubAllInfo(props) {
   const [data, setData] = useState({
      headerContent: {},
      attributesObject: {},
      organizationsObject: {},
      billOfMaterials: {},
      substitutes: [],
      notes: [],
      performanceCharacteristic: [],
      weightsDimensionsObject: {},
      packingUnit: [],
      transportUnitHead: {
         transportUnit: [],
         transportUnit1: [],
      },
      specification: [],
      referenceDocument: [],
      marketOfSale: {},
      plants: [],
      ownershipObject: {},
      ipclasses: [],
      securityClaases: [],
      isATS: [],
      hasATS: [],
      //technicalStandardSupersedes: [],
      technicalStandardSupersededBy: [],
      whereUsed: null,
   })

   const [loading, setLoading] = useState(false)
   const searchData = props.match.params.objectID
   const [sideNav, setSideNav] = useState("")
   const [inProp, setInProp] = useState(false)
   const [selectedItems, setSelectedItems] = useState(DefaultKeys)
   const [pdfDataSet, setPdfDataSet] = useState({})

   const handleSelectedItems = (obj, IsFilterView) => {
      setSelectedItems(obj)

      var values = pdfData(data, obj, IsFilterView)

      setPdfDataSet(values)
   }

   useEffect(() => {
      const fetchState = { cancel: false }

      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         async function fetchFppData(fetchState) {
            try {
               setLoading(true)
               setInProp(true)
               setSideNav("")
               // setSelectedItems(DefaultKeys)
               var apiData
               if (
                  sessionStorage.getItem(searchData) &&
                  sessionStorage.getItem(searchData) !== ""
               ) {
                  apiData = JSON.parse(sessionStorage.getItem(searchData))
               } else {
                  //Load async data from an Fpp Api endpoint.
                  const response = await fetchAPIData(`${FETCH_PSUB}/${searchData}`)
                  apiData = response.data
                  if (apiData.headerContent)
                     sessionStorage.setItem(searchData, JSON.stringify(response.data))
                  //apiData = JSON.parse(sessionStorage.getItem(searchData))
               }
               if (fetchState.cancel) {
                  return
               }
               setData((data) => ({
                  ...data,
                  headerContent: apiData.headerContent
                     ? apiData.headerContent
                     : data.headerContent,
                  //headerContent: null,
                  attributesObject: apiData.attributes,
                  organizationsObject: apiData.organizations,
                  billOfMaterials: apiData.billOfMaterials,
                  plants: apiData.plants,
                  ownershipObject: apiData.ownership,
                  specification: apiData.specification,
                  performanceCharacteristic: apiData.performanceCharacterstics,
                  ipclasses: apiData.ipClasses,
                  securityClaases: apiData.securityClass,
                  substitutes: apiData.substitutes,
                  referenceDocument: apiData.referenceDocument,
                  weightsDimensionsObject: apiData.weightAndDimension,
                  packingUnit: apiData.packingUnit,
                  transportUnitHead: apiData.transportUnitHead,
                  marketOfSale: apiData.marketOfSale,
                  notes: apiData.notes,
                  isATS: apiData.isATS,
                  hasATS: apiData.hasATS,
                  technicalStandardSupersededBy: apiData.technicalStandardSupersededBy,
                  whereUsed: apiData.whereUsed,
               }))
               setLoading(false)
               setNavigation()
            } catch (error) {
               if (error.response && error.response.data.status === 401) {
                  console.log("Error", ` Not authorie: ${error}`)
                  notAuthorized(props.history)
                  return
               }
               console.log("Error", ` API request failed: ${error}`)
               setLoading(false)
               errorHandling(error, props.history)
            }
         }
         window.scrollTo(0, 0)
         fetchFppData(fetchState)
      }

      return () => {
         fetchState.cancel = true
      }
   }, [searchData, props.history])

   return (
      <React.Fragment>
         <HeaderComponent name={data.headerContent.Name} pdfDataSet={pdfDataSet} css={true} />
         <CSSTransition timeout={300} in={inProp} classNames="my-node">
            <Container fluid>
               <Row>
                  <Col sm="3" md="2" className="leftSection">
                     <br />
                     <Col sm="3" md="2" className="labels sideNav">
                        {!loading && data.headerContent && data.headerContent.Name && (
                           <div>
                              <p style={{ marginLeft: "15px" }}>
                                 Name : {data.headerContent.Name} <br />
                                 Revision : {data.headerContent.Revision}
                              </p>
                              {sideNav && (
                                 <SideNav
                                    data={sideNav}
                                    handler={handleSelectedItems}
                                    resData={data}
                                    Keys={PSUBKeys}
                                    Name={data.headerContent.Name}
                                    searchData={searchData}
                                    Revision={data.headerContent.Revision}
                                    whereUsed={data.whereUsed}
                                 />
                              )}
                           </div>
                        )}
                     </Col>
                  </Col>
                  <Col sm="9" md="10" id="print" className="contentBackground">
                     <br />

                     {!loading && data.headerContent && data.headerContent.Name ? (
                        <Row id="data">
                           <Col md="9" sm="9">
                              <TopContent
                                 headerContent={data.headerContent}
                                 addionalField={true}
                              />
                           </Col>
                           <Col md="3" sm="3" align="right">
                              {sideNav && (
                                 <PdfDownloadComponent pdfDataSet={pdfDataSet} />
                              )}
                           </Col>
                        </Row>
                     ) : (
                        !loading && (
                           <div>
                              <Alert variant="info">
                                 <p style={{ marginLeft: "30rem" }}>
                                    {" "}
                                    No data available to show.
                                 </p>
                              </Alert>
                           </div>
                        )
                     )}
                     <br />
                     <Row>
                        <Col md="12" className="dataCol contentBackground">
                           {selectedItems["attributesObject"] && data.attributesObject && (
                              <div>
                                 <Card autoFocus>
                                    <Card.Body
                                       className="accordionStyle"
                                       onClick={handleClick.bind(
                                          this,
                                          "0",
                                          "collapseZero"
                                       )}
                                       aria-controls="collapseZero"
                                    >
                                       {PSUBKeys.attributesObject}{" "}
                                       <i
                                          id="0"
                                          name="accordItem"
                                          className="fa fa-minus-circle accordIconStyle"
                                       ></i>
                                    </Card.Body>
                                 </Card>
                                 <div
                                    className="accordionDiv shadow"
                                    id="collapseZero"
                                    name="collapseItem"
                                    style={{ display: "block" }}
                                 >
                                    <Attribute
                                       loading={loading}
                                       data={data.attributesObject}
                                    />
                                    {!loading && data.isATS && data.isATS.length > 0 && (
                                       <React.Fragment>
                                          <br />
                                          <Card>
                                             <Card.Body
                                                style={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#eef1f4",
                                                }}
                                             >
                                                IS ATS For
                                             </Card.Body>
                                          </Card>
                                          <div className="accordionDiv">
                                             <CustomTable data={data.isATS} />
                                          </div>
                                       </React.Fragment>
                                    )}
                                    {!loading && data.hasATS && data.hasATS.length > 0 && (
                                       <React.Fragment>
                                          <br />
                                          <Card>
                                             <Card.Body
                                                style={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#eef1f4",
                                                }}
                                             >
                                                Has ATS (R):
                                             </Card.Body>
                                          </Card>
                                          <div className="accordionDiv">
                                             <CustomTable data={data.hasATS} />
                                          </div>
                                       </React.Fragment>
                                    )}
                                    {!loading &&
                                       data.technicalStandardSupersededBy &&
                                       data.technicalStandardSupersededBy.length > 0 && (
                                          <React.Fragment>
                                             <br />
                                             <Card>
                                                <Card.Body
                                                   style={{
                                                      fontWeight: "bold",
                                                      backgroundColor: "#eef1f4",
                                                   }}
                                                >
                                                   This Technical Standard is superseded
                                                   by (R) :
                                                </Card.Body>
                                             </Card>
                                             <div className="accordionDiv">
                                                <CustomTable
                                                   data={
                                                      data.technicalStandardSupersededBy
                                                   }
                                                />
                                             </div>
                                          </React.Fragment>
                                       )}
                                 </div>
                              </div>
                           )}
                           {!loading &&
                              selectedItems["organizationsObject"] &&
                              data.organizationsObject && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "1",
                                             "collapseOne"
                                          )}
                                          aria-controls="collapseOne"
                                       >
                                          {PSUBKeys.organizationsObject}{" "}
                                          <i
                                             id="1"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseOne"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.organizationsObject} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.billOfMaterials &&
                              selectedItems["billOfMaterials"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "2",
                                             "collapseTwo"
                                          )}
                                          aria-controls="collapseTwo"
                                       >
                                          {PSUBKeys.billOfMaterials}{" "}
                                          <i
                                             id="2"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseTwo"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMultiBomTable data={data.billOfMaterials} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.substitutes &&
                              data.substitutes.length > 0 &&
                              selectedItems["substitutes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "3",
                                             "collapseThree"
                                          )}
                                          aria-controls="collapseThree"
                                       >
                                          {PSUBKeys.substitutes}{" "}
                                          <i
                                             id="3"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseThree"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomMultiSubstituteTable
                                          data={data.substitutes}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.notes &&
                              data.notes.length > 0 &&
                              selectedItems["notes"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "4",
                                             "collapseFour"
                                          )}
                                          aria-controls="collapseFour"
                                       >
                                          {PSUBKeys.notes}{" "}
                                          <i
                                             id="4"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseFour"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.notes} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.performanceCharacteristic &&
                              data.performanceCharacteristic.length > 0 &&
                              selectedItems["performanceCharacteristic"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "5",
                                             "collapseFive"
                                          )}
                                          aria-controls="collapseFive"
                                       >
                                          {PSUBKeys.performanceCharacteristic}{" "}
                                          <i
                                             id="5"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseFive"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPCTable
                                          data={data.performanceCharacteristic}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.weightsDimensionsObject &&
                              selectedItems["weightsDimensionsObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "6",
                                             "collapseSix"
                                          )}
                                          aria-controls="collapseSix"
                                       >
                                          {PSUBKeys.weightsDimensionsObject}{" "}
                                          <i
                                             id="6"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseSix"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute
                                          data={data.weightsDimensionsObject}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.packingUnit &&
                              data.packingUnit.length > 0 &&
                              selectedItems["packingUnit"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "7",
                                             "collapseSeven"
                                          )}
                                          aria-controls="collapseSeven"
                                       >
                                          {PSUBKeys.packingUnit}{" "}
                                          <i
                                             id="7"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseSeven"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomPackingUnit
                                          packingUnit={data.packingUnit}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.transportUnitHead &&
                              selectedItems["transportUnitHead"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "8",
                                             "collapseEight"
                                          )}
                                          aria-controls="collapseEight"
                                       >
                                          {PSUBKeys.transportUnitHead}{" "}
                                          <i
                                             id="8"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseEight"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTrasportUnit
                                          transportUnitHead={data.transportUnitHead}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.specification &&
                              data.specification.length > 0 &&
                              selectedItems["specification"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "9",
                                             "collapseNine"
                                          )}
                                          aria-controls="collapseNine"
                                       >
                                          {PSUBKeys.specification}{" "}
                                          <i
                                             id="9"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseNine"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <DownloadLinkTable data={data.specification} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.referenceDocument &&
                              data.referenceDocument.length > 0 &&
                              selectedItems["referenceDocument"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "10",
                                             "collapseTen"
                                          )}
                                          aria-controls="collapseTen"
                                       >
                                          {PSUBKeys.referenceDocument}{" "}
                                          <i
                                             id="10"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseTen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <ComRefDocDownload
                                          data={data.referenceDocument}
                                          attributes={data.attributesObject}
                                          headerContent={data.headerContent}
                                       />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.marketOfSale &&
                              selectedItems["marketOfSale"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "11",
                                             "collapseElevan"
                                          )}
                                          aria-controls="collapseElevan"
                                       >
                                          {PSUBKeys.marketOfSale}{" "}
                                          <i
                                             id="11"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseElevan"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <PsubCountriesOfSale
                                          countriesOfSale={data.marketOfSale}
                                          isVisible={true}
                                       ></PsubCountriesOfSale>
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.plants &&
                              data.plants.length > 0 &&
                              selectedItems["plants"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "12",
                                             "collapseTwelve"
                                          )}
                                          aria-controls="collapseTwelve"
                                       >
                                          {PSUBKeys.plants}{" "}
                                          <i
                                             id="12"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseTwelve"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.plants} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ownershipObject &&
                              selectedItems["ownershipObject"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "13",
                                             "collapseThirteen"
                                          )}
                                          aria-controls="collapseThirteen"
                                       >
                                          {PSUBKeys.ownershipObject}{" "}
                                          <i
                                             id="13"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseThirteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CommonAttribute data={data.ownershipObject} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.ipclasses &&
                              data.ipclasses.length > 0 &&
                              selectedItems["ipclasses"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "14",
                                             "collapseFourteen"
                                          )}
                                          aria-controls="collapseFourteen"
                                       >
                                          {PSUBKeys.ipclasses}{" "}
                                          <i
                                             id="14"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseFourteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.ipclasses} />
                                    </div>
                                 </div>
                              )}
                           {!loading &&
                              data.securityClaases &&
                              data.securityClaases.length > 0 &&
                              selectedItems["securityClaases"] && (
                                 <div>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "15",
                                             "collapseFifteen"
                                          )}
                                          aria-controls="collapseFifteen"
                                       >
                                          {PSUBKeys.securityClaases}{" "}
                                          <i
                                             id="15"
                                             name="accordItem"
                                             className="fa fa-minus-circle accordIconStyle"
                                          ></i>
                                       </Card.Body>
                                    </Card>
                                    <div
                                       className="accordionDiv shadow"
                                       id="collapseFifteen"
                                       name="collapseItem"
                                       style={{ display: "block" }}
                                    >
                                       <CustomTable data={data.securityClaases} />
                                    </div>
                                 </div>
                              )}
                              {!loading &&
                              data.whereUsed !== null &&
                              selectedItems["whereUsed"] && (
                                 <div style={{ display: "none" }}>
                                    <Card>
                                       <Card.Body
                                          className="accordionStyle"
                                          onClick={handleClick.bind(
                                             this,
                                             "36",
                                             "thirtysix"
                                          )}
                                          aria-controls="thirtysix"
                                       >
                                          {PSUBKeys.whereUsed}{" "}
                                       </Card.Body>
                                    </Card>
                                 </div>
                              )}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </CSSTransition>
      </React.Fragment>
   )

   function setNavigation() {
      var sideNavObj = ""
      setTimeout(function getNavData() {
         sideNavObj = document.getElementsByClassName("accordionStyle")
         setSideNav(sideNavObj)
      }, 10)
   }
}
export default PsubAllInfo
